<template>
  <div class="finance">
    <div class="banxin">
      <div class="bannercai">
        <!-- <img :src="banSrc" alt=""> -->
        <el-carousel trigger="click" height="500px">
          <el-carousel-item v-for="(item, index) in lunboList" :key="index">
            <!-- <h3 class="small">{{ item }}</h3> -->
            <img class="banner-img" :src="item.url" />
            <router-link v-if="item.link_url != ''" :to="item.link_url" class="linkLun"></router-link>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <!-- 内容 -->
    <div class="content">
      <!-- 金融 -->
      <div class="card-list">
        <div class="card" v-for="item in dataList" :key="item.id">
          <div class="title"><span class="l-style"></span><span>{{ item.title }}</span></div>
          <div class="card-con">
            <div class="sub-tit tac">{{ item.subTitle }}</div>
            <div class="card-img">
              <img class="images" :src='item.pic' />
            </div>
            <div class="con-value" v-html="item.descript"></div>
            <div class="btn">
              <el-button type="primary" size="small" plain @click="goJrDetail(item)">了解详情</el-button>
            </div>
          </div>

        </div>
      </div>
      <ul class="finance-list">
        <li class="card" v-for="(item, index) in tableData" :key="index">
          <div class="card-title"><span class="l-style"></span><span>{{ item.name }}</span>
            <div class="count-down">
              <el-tag class="tag" v-for="(item1, index) in item.tag" :key='index'>{{ item1 }}</el-tag>
            </div>
          </div>
          <ul class="card-con">
            <li class="card-con-item">
              <div class="item1" style="font-weight: bold; color: #1463de;">资金方：{{ item.financier }}</div>
              <div class="item1">单笔最高期限：{{ item.deadline }}年</div>
              <div class="item1">单笔最高额度：{{ item.quota }}万</div>
              <!-- <div class="item1">利率：{{item.rate * 100}}%年化</div>
                        <div class="item1">
                          <el-button type="primary" plain size="small" @click="openBox">我要贷款</el-button>
                        </div> -->
              <!-- <div class="item1 item2"><div class="btns" @click="quotedPrice">报价</div></div> -->
            </li>
            <li class="card-con-item">
              <div class="item1">利率：{{ item.rate * 100 }}%年化</div>
              <!-- <div class="item1 item3" @click="detailBtn()">查看详情 》</div> -->
            </li>
            <!-- <li class="card-con-item">
                        <div class="card-con-imgs">
                            <el-image class="images-style" v-for="url in item.pics" :key="url" :src="url" lazy :preview-src-list="item.pics"></el-image>
                        </div>
                    </li> -->
          </ul>
          <div class="card-con-right">
            <el-button style="margin-top: 15px" type="primary" size="small" plain @click="applyFinance(item)">立即申请
            </el-button>
            <!-- <img class="card-con-right-img" src="../../assets/imgs/wxcode.png" />
                    <div class="card-con-right-value">微信扫一扫</div>
                    <div class="card-con-right-value">立即报价</div> -->
          </div>

        </li>
      </ul>
      <el-pagination class="pagination-style" @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="pagination.page" :page-sizes="[10, 20, 50, 100]" :page-size="pagination.size"
        layout="total, sizes, prev, pager, next, jumper" :total="pagination.total">
      </el-pagination>
      <apply-finance ref="applyfinance" :visible.sync="visiblePrice" @closeq="closeq" @openq="openq"
        :fid="itemDetail.id" :obj="itemDetail"></apply-finance>
    </div>
    <!--    <detail :visible.sync="visible"></detail>-->
  </div>
</template>

<script>
import applyFinance from './applyFinance.vue'
import { getFinances,queryIntrductionList } from '@/api/finance';
import { getLunbotu } from '@/api'
// import detail from './detail.vue'
export default {
  components: { applyFinance },
  created() {
    this.form.name = this.$route.query.id
    this.getIntrductionList();
    this.getFinances();
    this.getLunbotu();
  },
  data() {
    return {
      lunboList: [],
      itemDetail: {},
      visiblePrice: false,
      tableData: [],
      dataList: [],
      form: {
        name: '',
        pageIndex: 1,
        pageSize: 10
      },
      pagination: {
        total: 0,
        size: 10,
        page: 0
      },
      visible: false
    }
  },
  methods: {
    getIntrductionList(){
      queryIntrductionList({ pageIndex: 1, pageSize: 10 }).then(res => {
        if (res.code == 200) {
          this.dataList = res.data.content;
        }
      })
    },
    goJrDetail(item){
      this.$router.push({
        path: '/financeToDetail',
        query: {
          id: item.id
        }
      })
    },
    // Banner图
    getLunbotu() {
      getLunbotu({ limitN: 3, location: 7 }).then(res => {
        if (res.code == 200) {
          this.lunboList = res.data;
          console.log('lunbotu', res.data)
        }
      })
    },
    closeq() {
      this.visiblePrice = false
    },
    openq() {
      this.visiblePrice = true
    },
    openBox() {
      this.visible = true
    },
    handleSizeChange(val) {
      this.pagination.size = val
      this.pagination.page = 1
      this.form.pageSize = val
      this.getFinances();
    },
    handleCurrentChange(val) {
      this.pagination.page = val
      this.form.pageIndex = val
      this.getFinances();
    },
    getFinances() {
      let that = this;
      getFinances({
        name: that.form.name,
        pageIndex: that.form.pageIndex,
        pageSize: that.form.pageSize
      }).then(res => {
        console.log(res)
        let datas = res.data.content
        for (var i = 0; i < datas.length; i++) {
          datas[i].tag = datas[i].tag.split(",")
        }
        that.tableData = datas;
        that.pagination.total = res.data.totalElements;
        that.pagination.size = res.data.size;
        that.pagination.page = res.data.number + 1;
      })
    },
    applyFinance(item) {
      this.$refs.applyfinance.getApplyFinance(item.id)
      this.itemDetail = item
      //this.visiblePrice = true
    }
  }
}
</script>

<style lang="less" scoped>
@import url("../../assets/common.less");

.banxin {
  width: 100%;
  margin: 0 auto;
}

.finance {
  background: @beijingse;
  padding-bottom: 20px;

  .content {
    max-width: 1190px;
    width: 100%;
    margin: auto;
    margin-top: 40px;
    background: #ffffff;
    box-shadow: 0 0 3px 3px rgb(235, 235, 235);
  }

  .content {
    .card-list {
      display: flex;
      border: 1px solid @bordercolor;
      height: 370px;
      box-sizing: border-box;

      .card {
        flex: 1;
        border-right: 1px solid @bordercolor;
        padding: 30px 16px;

        .title {
          font-size: 18px;
          line-height: 20px;
          display: flex;

          .l-style {
            display: inline-block;
            width: 2px;
            height: 18px;
            background: @primary;
            margin-right: 6px;
          }
        }

        .card-con {
          position: relative;
          height: calc(100% - 20px);
          margin: 0 16px;

          .sub-tit {
            color: #999;
            padding: 16px 0;
          }

          .con-value {
            color: #666;
            height: 160px;
            overflow: hidden;
            font-size: 14px
          }

          .card-img {
            width: 100%;
            height: 77px;
            text-align: center;

            // position: absolute;
            // bottom: 100px;
            .images {
              height: 65px;
              width: 65px;
              margin: auto;
            }
          }

          .btn {
            margin: auto;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: 0;
            width: 40%;
            text-align: center;
            cursor: pointer;

            /deep/ .el-button {
              width: 100%;
            }
          }
        }

      }

      .card:last-child {
        border-right: none;
      }
    }

    .finance-list {
      margin-top: 20px;

      .card {
        border: 1px solid @bordercolor;
        border-bottom: none;
        padding: 20px 20px 10px;
        // margin-bottom: 20px;
        display: flex;
        flex-wrap: wrap;

        &-title {
          font-size: 16px;
          display: flex;
          align-items: center;
          width: 100%;
          margin-bottom: 10px;

          .l-style {
            display: inline-block;
            width: 2px;
            height: 18px;
            background: @primary;
            margin-right: 6px;
          }

          .count-down {
            width: 26%;
            margin-left: auto;
            text-align: center;

            span {
              color: #666;
            }

            .tag {
              margin-right: 5px;
              background-color: #fff;
              border: 1px solid @primary;
              color: @primary;
              font-size: 12px;
              height: 20px;
              line-height: 20px;
              padding: 0 8px;
              border-radius: 0;
            }
          }
        }

        &-con {
          font-size: 14px;
          width: 80%;

          &-item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 10px 0;

            .item1 {
              width: calc((100% - 100px)/2);
            }

            .item1:last-child {
              width: 200px;
            }

            .item2 {
              position: relative;

              .btns {
                position: absolute;
                font-size: 16px;
                padding: 4px 16px;
                border: 1px solid @primary;
                border-radius: 4px;
                top: -10px;
                right: 0;
                cursor: pointer;
                color: @primary;
              }

              .btns:hover {
                background-color: rgba(20, 99, 158, 0.1);
              }
            }

            .item3 {
              color: #999;
              cursor: pointer;
            }
          }
        }

        &-con-right {
          width: 20%;
          text-align: center;

          &-img {
            width: 100px;
            height: 100px;
            margin: 10px auto 0;
            display: block;
          }

          &-value {
            font-size: 12px;
          }
        }

        &-con-imgs {
          width: 100%;

          .con-imgs-item {
            width: 50px;
            height: 50px;
            margin: 0 4px;
          }
        }

        .images-style {
          width: 10%;
          padding: 4px;
          box-sizing: border-box;
          margin-bottom: 6px;
        }
      }

      .card:last-child {
        border-bottom: 1px solid @bordercolor;
      }
    }

    .pagination-style {
      text-align: right;
      margin-bottom: 20px;
      padding: 16px 26px 16px 16px;
    }
  }
}

.bannercai {
  width: 100%;
  height: 500px;

  img {
    width: 100%;
    height: 100%;
  }
}

/deep/ .el-pager li.active {
  color: #fff;
  background: #3883e5;
}

/deep/ .el-pager li {
  background: #f4f4f5;
  margin: 0 2px;
  color: #686a6e;
}

/deep/ .el-pagination__editor.el-input {
  border: 1px solid #f4f4f5;
}
.linkLun{
    display: block;
    width:100%;
    height: 100%;
    position: absolute;
    left: 0;
    top:0;
    right: 0;
    bottom: 0;
}
</style>
