import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    phone:'',
    userInfo:{}
  },
  getters: {
    phone:function(state){
      return state.phone
    },
    userInfo:function(state){
      return state.userInfo
    }
  },
  mutations: {
    phone:(state,phone)=>{
      state.phone = phone
    },
    userInfo:(state,userInfo)=>{
      state.userInfo = userInfo
    }
  },
  actions: {
  },
  modules: {
  }
})

