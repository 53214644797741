import { render, staticRenderFns } from "./footerTemplate.vue?vue&type=template&id=22c35dfb&scoped=true&"
import script from "./footerTemplate.vue?vue&type=script&lang=js&"
export * from "./footerTemplate.vue?vue&type=script&lang=js&"
import style0 from "./footerTemplate.vue?vue&type=style&index=0&id=22c35dfb&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22c35dfb",
  null
  
)

export default component.exports