<template>
  <div class="informationDetail">
    <h1>{{ title }}</h1>
    <div v-html="content"></div>
  </div>
</template>

<script>
import {news2Detail} from '@/api/index'

export default {
  created() {
    this.nid = this.$route.query.nid;
    this.getCompany(this.nid);
  },
  watch: {
    // 利用watch方法检测路由变化：
    $route: function(to, from) {
      if (to.fullPath !== from.fullPath) {
        this.nid = to.query.nid;// 获取参数
        this.getCompany(this.nid);
      }
    }
  },
  data() {
    return {
      nid: '',
      title: '',
      content: ''
    }
  },
  methods: {
    getCompany(id) {
      let that = this;
      debugger;
      news2Detail(id).then(res => {
        that.content = res.data.content
        that.title = res.data.title
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import url("../../assets/common.less");

.informationDetail {
  max-width: 1100px;
  margin: 20px auto 0;

  .phone {
    color: @hui;
    padding: 20px
  }

  h1 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 18px;
    color: @primary;
  }
}
</style>
