/*
 * @Author: guojiren 7755@123.com
 * @Date: 2022-08-30 14:47:25
 * @LastEditors: lishuguang 2275408726@qq.com
 * @LastEditTime: 2023-04-26 20:55:51
 * @FilePath: \xinghe-xinxi-pc\src\api\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import fetch from '../utils/fetch'
import { axgInfo } from '@/utils/fetch2'

// 合同详情
export function getAgreeDetail(id) {
  return fetch({
    url: '/api/mall/yshAgreement/getById?id=' + id,
    method: 'POST',
  })
}

// 合同列表
export function getAgreeList(data) {
  return fetch({
    url: '/api/mall/yshAgreement/list',
    method: 'POST',
    data,
  })
}

// 注册
export function register(data) {
  return fetch({
    url: '/api/user/register',
    method: 'POST',
    data,
  })
}
// 资质认证
export function updateUser(data) {
  return fetch({
    url: '/api/user/updateUser',
    method: 'POST',
    data,
  })
}

// 个人资质认证
export function updatePerson(data) {
  return fetch({
    url: '/api/user/personAttestation',
    method: 'POST',
    data,
  })
}

// 上传预约信息
export function subscribe(data) {
  return fetch({
    url: axgInfo + '/api/mall/messageManagement/addPC',
    method: 'POST',
    data,
  })
}
// 获取验证码
export function getSmsCode(data) {
  return fetch({
    url: '/api/sms/getSmsCode',
    method: 'POST',
    data,
  })
}

// 登录
export function loginPwd(data) {
  return fetch({
    url: '/api/user/loginPwd',
    method: 'POST',
    data,
  })
}

// 澄清公告
export function logOff(data) {
  return fetch({
    url: '/api/user/logOff',
    method: 'POST',
    data,
  })
}

export function smsLogin(data) {
  return fetch({
    url: '/api/user/login',
    method: 'POST',
    data,
  })
}
// 获取用户信息
export function getUserInfo() {
  return fetch({
    url: '/api/mall/yshUser/getUserInfo',
    method: 'POST',
    data: '',
  })
}
export function getInfo() {
  return fetch({
    url: '/api/ysc/yscCompanyJoin/getInfo',
    method: 'POST',
    data: '',
  })
}
export function getAllTypeList() {
  return fetch({
    url: '/api/ysc/yscCompany/getAllTypeList',
    method: 'POST',
    data: '',
  })
}
export function doCompanyJoinApply(data) {
  return fetch({
    url: '/api/ysc/yscCompanyJoin/doCompanyJoinApplyPc',
    method: 'POST',
    data,
  })
}
export function getConfigInfo(data) {
  return fetch({
    url: '/api/indexconfig/getByType?type=' + data,
    method: 'GET',
  })
}

export function getDictInfo(data) {
  return fetch({
    url: '/api/indexconfig/getDictInfo?type=' + data,
    method: 'GET',
  })
}

export function getBottomTopN(data) {
  return fetch({
    url:
      '/api/indexconfig/getBottomTopN?newsType=' +
      data.newsType +
      '&cateId=' +
      data.cateId,
    method: 'GET',
  })
}

export function news2Detail(data) {
  return fetch({
    url: '/api/mall/yshNews2/todetail?id=' + data,
    method: 'POST',
  })
}
// 获取轮播图
export function getLunbotu(data) {
  return fetch({
    url:
      '/api/mall/swiper/getSwiperLimitN?limitN=' +
      data.limitN +
      '&location=' +
      data.location,
    method: 'GET',
  })
}
// 获取二维码
export function getByType2(data) {
  return fetch({
    url: '/api/system/csconfig/getByType?type=' + data,
    method: 'GET',
  })
}

//  获取悬浮广告
export function getAd(data) {
  return fetch({
    url: 'api/system/ggg/getByType?type=' + data,
    method: 'GET',
  })
}

//  浏览量
export function saveView(data) {
  return fetch({
    url: '/api/pageView/save',
    method: 'POST',
    data:data
  })
}
