<template>
    <div>商城</div>
</template>

<script>
export default {
    data(){
        return{
            
        }
    }
}
</script>

<style lang="less" scoped>

</style>