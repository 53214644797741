<!--
 * @Author: guojiren 7755@123.com
 * @Date: 2022-08-31 11:39:04
 * @LastEditors: guojiren 7755@123.com
 * @LastEditTime: 2022-08-31 11:40:32
 * @FilePath: \xinghe-xinxi-pc\src\views\finance\toDetail.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="financeDetail">
     <h1>{{ jrDetail.title }}</h1>
    <div v-html="jrDetail.content"></div>
<!--     <div class="phone">联系方式：{{ phone }}</div> -->
  </div>
</template>

<script>
import { jrDetail } from '@/api/finance'
export default {
  created() {
    this.id = this.$route.query.id;
     this.getDetail();
  },
  data() {
    return {
      id:'',
      jrDetail:{}
    }
  },
  methods: {
    getDetail() {
      let that = this;
      jrDetail(this.id).then(res => {
        that.jrDetail = res.data
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import url("../../assets/common.less");

.financeDetail {
  max-width: 1100px;
  margin: 20px auto 0;

  .phone {
    color: @hui;
    padding: 20px
  }

  h1 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 18px;
    color: @primary;
  }
}
</style>
