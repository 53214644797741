import {
  post,
  json,
  get
} from '../utils/fetch';

// 获取供求列表
export function getList(data) {
  return post('/api/mall/yshSupply/getList', data);
}
// 获取供应详情
export function getSupplyInfo(data) {
  return post('/api/mall/yshSupply/getSupplyInfo', data);
}
// 获取求购详情
export function getPurchaseInfo(data) {
  return post('/api/mall/yshPurchase/getPurchaseInfo', data);
}
// 获取企业求购列表

export function getListCompanyPurchase(data) {
  return get('/api/mall/yshPurchase/pc/getListCompanyPurchase', data);
}

// 获取店铺信息
export function getById(data) {
  return post('/api/mall/yshUser/getById', data);
}

// 查询浏览量前10的店铺
export function getHotShop(data) {
  return get('/api/recommend/getHotShop', data);
}
// 查询浏览量前5的药材
export function getHotMaterial(data) {
  return get('/api/recommend/getHotMaterial', data);
}

// 查询浏览量前5的药材
export function getSeekList(data) {
  return post('/api/mall/yshPurchase/getList', data);
}

// 询价留言
export function messageSave(data) {
  return post('/api/tYshLeaveMessage/save', data);
}

// 获取产地规格
export function getAddress(data) {
  return post('/api/mall/yshSupply/getAddress?name=' + data);
}
//企业采购计划详情

export function getCompanyPurchaseInfo(data) {
  return get('/api/mall/yshPurchase/pc/getCompanyPurchaseInfo?id=' + data);
}
// 查看供求推荐信息列表
export function getAdviceGoods(data) {
  return get('/api/mall/yshSupply/getAdviceGoods', data);
}