<template>
<div class="w">
  <div class="page flex-col">

    <div class="section_1 flex-col">

      <div class="box_1 flex-row justify-between">
        <img class="label_2" referrerpolicy="no-referrer"
          src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng9f0924056986b6c9fb7451131964e5e769f444a1eecac30042c39eac0e3d6a33" />
        <span class="text_6">产品中心</span>
        <img class="label_3" referrerpolicy="no-referrer"
          src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng753270a6fd442eec7c7de7ae8a3a2ecc8b057ca5d7e52609ab8556e6a2b62f11" />
      </div>
      <div class="group_4 flex-col"></div>
      <div class="box_2 ">
        <el-input placeholder="请输入产品名称" v-model="searchVal" class="input-with-select" @keyup.enter.native="searchByname">
          <el-button slot="append" icon="el-icon-search" @click="searchByname">&nbsp;搜索&nbsp;&nbsp;</el-button>
        </el-input>
      </div>
      <div class="grid_2 flex-row">
        <div class="list-items_1 flex-col" v-for="(item, index) in productList" :key="index">
          <img @click="goDetail(item.id)" class="image_2" referrerpolicy="no-referrer" :src="'/info-admin' + item.mainpic" />
          <span class="text_7" v-html="item.name"></span>
        </div>
      </div>
      <div class="pagination">
        <el-pagination class="pagination-style" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pagenum"
          :page-sizes="[10, 20, 50, 100]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>
    </div>
  </div>
  </div>
</template>
<script>
import { getProductList } from '@/api/information'
import { INFO_URL } from '@/config/globalConfig'
export default {
  data() {
    return {
      productList: [],
      constants: {},
      pagenum: 1,
      pageSize: 10,
      total: 0,
      searchVal: '',
      currentId: '',
      baseUrl: INFO_URL
    }
  },
  props: {
    // currentid: {
    //   type: String,
    //   default: ''
    // }
  },
  created() {
    this.getProduct()
  },
  methods: {
    goDetail(id) {
      this.$router.push({
        name: 'proDetail',
        query: {
          id
        }
      })
    },
    searchByname() {
      this.getProduct(this.currentid)
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.pagenum = 1
      this.getProduct(this.currentid)
    },
    handleCurrentChange(val) {
      this.pagenum = val
      this.getProduct(this.currentid)
    },
    getProduct() {
      const id = this.$route.query.id
      let that = this
      let params = {
        pageIndex: that.pagenum, // 传入页码
        pageSize: that.pageSize, // 传入每页条数
        name: that.searchVal, // 传入搜索的对象
        companyid: id,
        approveStatus: 2
      }
      getProductList(params).then((res) => {
        that.productList = res.data.content
        that.total = res.data.totalElements
      })
    }
  }
}
</script>
<style lang="less" scoped>
.pagination {
  display: flex;
  // align-items: center;
  // width: 70%;

  .pagination-style {
    margin: 10px auto;
  }
}
@import url('./common.less');
@import url('./index.less');
</style>
