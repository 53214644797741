<template>
  <div class="gyDetail">
    <div class="card">
      <div class="card-title">联系方式</div>
      <div class="card-list">
        <div class="card-list-item">
          <span class="label">联系人员：</span>
          <span>王老板</span>
        </div>
        <div class="card-list-item">
          <span class="label">联系电话：</span>
          <span>18765678890</span>
        </div>
        <div class="card-list-item">
          <span class="label">发布时间：</span>
          <span>2022-05-23</span>
        </div>
        <el-button class="call" type="primary" plain size="samll"
          >拨打电话</el-button
        >
      </div>
    </div>
    <div class="card">
      <div class="card-title">供应详情</div>
      <div class="card-list">
        <div class="item1">
          <span class="label">品名：</span>
          <span>生地</span>
        </div>
        <div class="item1">
          <span class="label">规格：</span>
          <span>四五级</span>
        </div>
        <div class="item1">
          <span class="label">产地：</span>
          <span>河南</span>
        </div>
        <div class="item1">
          <span class="label">数量：</span>
          <span>20吨</span>
        </div>
        <div class="item1">
          <span class="label">价格：</span>
          <span>价格协商</span>
        </div>
        <div class="item1">
          <span class="label">描述：</span>
          <span>无尘无底，指数均匀</span>
        </div>
        <div class="item1">
          <span class="label">库存地：</span>
          <span>河南省焦作温县</span>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-title">药材库</div>
      <div class="card-list">
        <el-image
          class="images-style"
          v-for="url in urls"
          :key="url"
          :src="url"
          lazy
          :preview-src-list="urls"
        ></el-image>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      urls: [
        'https://fuss10.elemecdn.com/a/3f/3302e58f9a181d2509f3dc0fa68b0jpeg.jpeg',
        'https://fuss10.elemecdn.com/1/34/19aa98b1fcb2781c4fba33d850549jpeg.jpeg',
        'https://fuss10.elemecdn.com/0/6f/e35ff375812e6b0020b6b4e8f9583jpeg.jpeg',
        'https://fuss10.elemecdn.com/9/bb/e27858e973f5d7d3904835f46abbdjpeg.jpeg',
        'https://fuss10.elemecdn.com/d/e6/c4d93a3805b3ce3f323f7974e6f78jpeg.jpeg',
        'https://fuss10.elemecdn.com/3/28/bbf893f792f03a54408b3b7a7ebf0jpeg.jpeg',
        'https://fuss10.elemecdn.com/2/11/6535bcfb26e4c79b48ddde44f4b6fjpeg.jpeg'
      ],
      srcList: [
        'https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg',
        'https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg'
      ]
    }
  },
  created () {

  },
  methods: {

  }
}
</script>

<style lang="less" scoped>
.gyDetail {
  max-width: 1190px;
  margin: 20px auto 0;
  padding-bottom: 40px;
  .card {
    box-shadow: 0 0 10px #ddd;
    padding: 16px 20px;
    margin-bottom: 20px;
    &-title {
      font-size: 17px;
      border-left: 4px solid rgb(20, 99, 158);
      padding-left: 10px;
      line-height: 18px;
    }
    &-list {
      display: flex;
      align-items: center;
      margin: 12px 0 5px;
      flex-wrap: wrap;
      &-item {
        flex: 1;
      }
      .label {
        color: #999;
      }
      .item1 {
        width: 33%;
        margin-bottom: 10px;
      }
      .images-style {
        width: 20%;
        padding: 6px;
        box-sizing: border-box;
        margin-bottom: 6px;
      }
    }
  }
}
</style>