<template>
  <div class="page flex-col">
  
    <div class="group_1 flex-col">
    
      <div class="section_3 flex-row justify-between">
        <img
          class="label_2"
          referrerpolicy="no-referrer"
          src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng9f0924056986b6c9fb7451131964e5e769f444a1eecac30042c39eac0e3d6a33"
        />
        <span class="text_6">公司简介</span>
        <img
          class="label_3"
          referrerpolicy="no-referrer"
          src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng753270a6fd442eec7c7de7ae8a3a2ecc8b057ca5d7e52609ab8556e6a2b62f11"
        />
      </div>
      <div class="section_4 flex-col"></div>
      <img
        class="image_2"
        referrerpolicy="no-referrer"
        :src="baseUrl+companyData.pic"
      />
      <div class="text_7">
        <div v-html="companyData.decriptions"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { INFO_URL } from '@/config/globalConfig'
export default {
  data() {
    return {
      constants: {},
      baseUrl:INFO_URL
    };
  },
  props:{
    companyData: {
        type: Object,
        default: {
          name:"",
          content:'',
          phone:''
        },
    },
  },
  methods: {},
};
</script>
<style lang="less" scoped>
  @import url("./common.less");
  @import url("./index.less");
</style>
  