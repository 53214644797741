<template>
  <div class="price">
    <!-- banner s -->
    <div class="banxin1">
      <div class="bannercai">
        <!-- <img :src="banSrc" alt=""> -->
        <el-carousel trigger="click" height="500px">
          <el-carousel-item v-for="(item, index) in lunboList" :key="index">
            <!-- <h3 class="small">{{ item }}</h3> -->
            <img class="banner-img" :src="item.url" />
            <router-link
              v-if="item.link_url != ''"
              :to="item.link_url"
              class="linkLun"
            ></router-link>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <div class="tab-banxin">
      <el-tabs v-model="activeName" @tab-click="handleClick" type="border-card">
        <el-tab-pane label="市场价格" name="first">
          <div class="banxin">
            <div class="search-module">
              <div class="select-item tp">
                <div>品名：</div>
                <el-input
                  type="text"
                  v-model="form.mname"
                  style="width: 300px; padding-left: 0"
                  placeholder="请输入"
                >
                </el-input>
                <el-button
                  style="width: 100px; background: #ff5722; color: #fff"
                  @click="getprices"
                  >搜索</el-button
                >
              </div>
              <div class="select-item tp">
                <div>市场：</div>
                <div
                  v-for="(item, index) in scOption"
                  :key="index"
                  :class="{ active: scActive == item.value }"
                  @click="
                    scActive = item.value
                    getprices()
                  "
                >
                  {{ item.label }}
                </div>
              </div>
              <div class="select-item">
                <div>类别：</div>
                <div
                  v-for="(item, index) in leibieOption"
                  :key="index"
                  :class="{ active: leibieActive === item.id }"
                  @click="
                    leibieActive = item.id
                    getprices()
                  "
                >
                  {{ item.name }}
                </div>
              </div>
            </div>
            <!-- 筛选条件 -->
            <div class="list-module" v-if="entryType === 'ckgy'">
              <el-table
                class="xh-table"
                border
                :data="tableData"
                style="width: 100%"
                align="center"
              >
                <el-table-column
                  prop="name"
                  label="品名"
                  align="center"
                  width="120"
                ></el-table-column>
                <el-table-column
                  prop="mspec"
                  label="规格"
                  align="center"
                  width="80"
                ></el-table-column>
                <el-table-column
                  prop="addr"
                  label="产地"
                  align="center"
                  width="80"
                ></el-table-column>
                <el-table-column
                  width="130"
                  prop="price"
                  align="center"
                  label="价格（元/kg）"
                ></el-table-column>
                <el-table-column
                  width="120"
                  prop="trend"
                  label="走势"
                  align="center"
                >
                  <template slot-scope="scoped">
                    <template
                      v-if="parseFloat(scoped.row.trend * 100).toFixed(2) < 0"
                    >
                      <div class="green">
                        下降
                        <!-- {{ parseFloat(scoped.row.trend * 100).toFixed(2) }}% -->
                      </div>
                    </template>
                    <template
                      v-else-if="
                        parseFloat(scoped.row.trend * 100).toFixed(2) > 0
                      "
                    >
                      <div class="red">
                        上升
                        <!-- {{ parseFloat(scoped.row.trend * 100).toFixed(2) }}% -->
                      </div>
                    </template>
                    <template v-else>
                      <div>
                        平稳
                        <!-- {{
                          parseFloat(scoped.row.month_range * 100).toFixed(2)
                        }}% -->
                      </div>
                    </template>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="market"
                  label="市场"
                  align="center"
                ></el-table-column>
                <el-table-column
                  prop="zdfd"
                  label="数量"
                  align="center"
                ></el-table-column>
                <el-table-column
                  prop="action"
                  label="发布时间"
                  align="center"
                ></el-table-column>
                <el-table-column
                  prop="action"
                  label="库存地"
                  align="center"
                ></el-table-column>
                <el-table-column prop="action" label="操作" align="center">
                  <template>
                    <el-button
                      size="small"
                      plain
                      type="primary"
                      @click="gyDetail"
                      >详情</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="list-module" v-else>
              <el-table
                class="xh-table"
                border
                :data="tableData"
                style="width: 100%"
                align="center"
              >
                <el-table-column
                  prop="mname"
                  label="品名"
                  align="center"
                  width="110"
                ></el-table-column>
                <el-table-column
                  prop="mspec"
                  label="规格"
                  align="center"
                  width="80"
                ></el-table-column>
                <el-table-column
                  prop="maddr"
                  label="产地"
                  align="center"
                  width="80"
                ></el-table-column>
                <el-table-column
                  width="130"
                  prop="price"
                  align="center"
                  label="价格（元/kg）"
                ></el-table-column>
                <el-table-column
                  width="120"
                  prop="trend"
                  label="走势"
                  align="center"
                >
                  <template slot-scope="scoped">
                    <template
                      v-if="parseFloat(scoped.row.trend * 100).toFixed(2) < 0"
                    >
                      <div class="green">
                        下降
                        <!-- {{ parseFloat(scoped.row.trend * 100).toFixed(2) }}% -->
                      </div>
                    </template>
                    <template
                      v-else-if="
                        parseFloat(scoped.row.trend * 100).toFixed(2) > 0
                      "
                    >
                      <div class="red">
                        上升
                        <!-- {{ parseFloat(scoped.row.trend * 100).toFixed(2) }}% -->
                      </div>
                    </template>
                    <template v-else>
                      <div>
                        平稳
                        <!-- {{
                          parseFloat(scoped.row.month_range * 100).toFixed(2)
                        }}% -->
                      </div>
                    </template>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="market"
                  label="市场"
                  align="center"
                ></el-table-column>
                <el-table-column
                  width="110"
                  align="center"
                  prop="week_range"
                  label="周涨幅"
                  :key="getNum()"
                >
                  <template #header>
                    <div @click="sortMethod('week_range')">
                      <span>周涨幅</span>
                      <i
                        v-if="
                          form.order != 'week_range' ||
                          (form.order == 'week_range' && form.sort == 'asc')
                        "
                        class="el-icon-top color-red"
                      ></i>
                      <i
                        v-if="
                          form.order != 'week_range' ||
                          (form.order == 'week_range' && form.sort == 'desc')
                        "
                        class="el-icon-bottom color-green"
                      ></i>
                    </div>
                  </template>
                  <template slot-scope="scoped">
                    <template
                      v-if="
                        parseFloat(scoped.row.week_range * 100).toFixed(2) < 0
                      "
                    >
                      <div class="green el-icon-bottom">
                        {{
                          parseFloat(scoped.row.week_range * 100).toFixed(2)
                        }}%
                      </div>
                    </template>
                    <template
                      v-else-if="
                        parseFloat(scoped.row.week_range * 100).toFixed(2) > 0
                      "
                    >
                      <div class="red el-icon-top">
                        {{
                          parseFloat(scoped.row.week_range * 100).toFixed(2)
                        }}%
                      </div>
                    </template>
                    <template v-else>
                      <div>
                        {{
                          parseFloat(scoped.row.week_range * 100).toFixed(2)
                        }}%
                      </div>
                    </template>
                  </template>
                </el-table-column>
                <el-table-column
                  width="110"
                  align="center"
                  prop="month_range"
                  label="月涨幅"
                >
                  <template slot="header">
                    <div @click="sortMethod('month_range')">
                      <span>月涨幅</span>
                      <i
                        v-if="
                          form.order != 'month_range' ||
                          (form.order == 'month_range' && form.sort == 'asc')
                        "
                        class="el-icon-top color-red"
                      ></i>
                      <i
                        v-if="
                          form.order != 'month_range' ||
                          (form.order == 'month_range' && form.sort == 'desc')
                        "
                        class="el-icon-bottom color-green"
                      ></i>
                    </div>
                  </template>
                  <template slot-scope="scoped">
                    <template
                      v-if="
                        parseFloat(scoped.row.month_range * 100).toFixed(2) < 0
                      "
                    >
                      <div class="green el-icon-bottom">
                        {{
                          parseFloat(scoped.row.month_range * 100).toFixed(2)
                        }}%
                      </div>
                    </template>
                    <template
                      v-else-if="
                        parseFloat(scoped.row.month_range * 100).toFixed(2) > 0
                      "
                    >
                      <div class="red el-icon-top">
                        {{
                          parseFloat(scoped.row.month_range * 100).toFixed(2)
                        }}%
                      </div>
                    </template>
                    <template v-else>
                      <div>
                        {{
                          parseFloat(scoped.row.month_range * 100).toFixed(2)
                        }}%
                      </div>
                    </template>
                  </template>
                </el-table-column>
                <el-table-column
                  width="110"
                  align="center"
                  prop="quarter_range"
                  label="季涨幅"
                >
                  <template slot="header">
                    <div @click="sortMethod('quarter_range')">
                      <span>季涨幅</span>
                      <i
                        v-if="
                          form.order != 'quarter_range' ||
                          (form.order == 'quarter_range' && form.sort == 'asc')
                        "
                        class="el-icon-top color-red"
                      ></i>
                      <i
                        v-if="
                          form.order != 'quarter_range' ||
                          (form.order == 'quarter_range' && form.sort == 'desc')
                        "
                        class="el-icon-bottom color-green"
                      ></i>
                    </div>
                  </template>
                  <template slot-scope="scoped">
                    <template
                      v-if="
                        parseFloat(scoped.row.quarter_range * 100).toFixed(2) <
                        0
                      "
                    >
                      <div class="green el-icon-bottom">
                        {{
                          parseFloat(scoped.row.quarter_range * 100).toFixed(2)
                        }}%
                      </div>
                    </template>
                    <template
                      v-else-if="
                        parseFloat(scoped.row.quarter_range * 100).toFixed(2) >
                        0
                      "
                    >
                      <div class="red el-icon-top">
                        {{
                          parseFloat(scoped.row.quarter_range * 100).toFixed(2)
                        }}%
                      </div>
                    </template>
                    <template v-else>
                      <div>
                        {{
                          parseFloat(scoped.row.quarter_range * 100).toFixed(2)
                        }}%
                      </div>
                    </template>
                  </template>
                </el-table-column>
                <el-table-column
                  width="110"
                  align="center"
                  prop="yearr_range"
                  label="年涨幅"
                >
                  <template slot="header">
                    <div @click="sortMethod('yearr_range')">
                      <span>年涨幅</span>
                      <i
                        v-if="
                          form.order != 'yearr_range' ||
                          (form.order == 'yearr_range' && form.sort == 'asc')
                        "
                        class="el-icon-top color-red"
                      ></i>
                      <i
                        v-if="
                          form.order != 'yearr_range' ||
                          (form.order == 'yearr_range' && form.sort == 'desc')
                        "
                        class="el-icon-bottom color-green"
                      ></i>
                    </div>
                  </template>
                  <template slot-scope="scoped">
                    <template
                      v-if="
                        parseFloat(scoped.row.yearr_range * 100).toFixed(2) < 0
                      "
                    >
                      <div class="green el-icon-bottom">
                        {{
                          parseFloat(scoped.row.yearr_range * 100).toFixed(2)
                        }}%
                      </div>
                    </template>
                    <template
                      v-else-if="
                        parseFloat(scoped.row.yearr_range * 100).toFixed(2) > 0
                      "
                    >
                      <div class="red el-icon-top">
                        {{
                          parseFloat(scoped.row.yearr_range * 100).toFixed(2)
                        }}%
                      </div>
                    </template>
                    <template v-else>
                      <div>
                        {{
                          parseFloat(scoped.row.yearr_range * 100).toFixed(2)
                        }}%
                      </div>
                    </template>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="action"
                  align="center"
                  label="历史价格"
                  width="110"
                  fixed="right"
                >
                  <template slot-scope="scoped">
                    <el-button
                      type="primary"
                      plain
                      size="small"
                      @click="lookBtn(scoped.row)"
                      >历史价格
                    </el-button>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="action"
                  label="供货信息"
                  align="center"
                  width="110"
                  fixed="right"
                >
                  <template slot-scope="scoped">
                    <el-button
                      type="primary"
                      plain
                      size="small"
                      @click="ghBtn(scoped.row)"
                      >供货信息
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <el-pagination
              class="pagination-style"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="pagination.page"
              :page-sizes="[1, 10, 20, 50, 100]"
              :page-size="pagination.size"
              layout="total, sizes, prev, pager, next, jumper"
              :total="pagination.total"
            >
            </el-pagination>
          </div>
        </el-tab-pane>
        <el-tab-pane label="市场指数" name="second">
          <div class="banxin">
            <div class="content-other">
              <div class="list-one">
                <div class="search-module">
                  <div class="select-item tp">
                  <div>分类：</div>
                  <div
                    v-show="index !== 1 && index !== 2"
                    v-for="(item, index) in leibieOption"
                    :key="index"
                    :class="{ active: flActive == item.id }"
                    @click="
                      flActive = item.id
                      queryName = ''
                      initZs()
                      initChart()
                      getNames(item.id)
                    "
                  >
                    {{ item.name }}
                  </div>
                </div>
                <div class="select-item tp">
                  <div>指数：</div>
                  <div
                    v-for="(item, index) in zsOption"
                    :key="index"
                    :class="{ active: zsActive == item.value }"
                    @click="
                    zsActive = item.value
                    initZs()
                    initChart()
                    "
                  >
                    {{ item.label }}
                  </div>
                </div>
                <div class="select-item tp">
                  <div>类型：</div>
                  <div
                    v-show="item.show.indexOf(zsActive) !== -1"
                    v-for="(item, index) in leixinOption"
                    :key="index"
                    :class="{ active: leixinActive == item.value }"
                    @click="
                      leixinActive = item.value
                      initChart()
                    "
                  >
                    {{ item.label }}
                  </div>
                </div>
                <div class="select-item tp">
                  <div>品种：</div>
                  <el-select
                    v-model="queryName"
                    clearable
                    filterable
                    remote
                    reserve-keyword
                    placeholder="请输入关键词"
                    @change="initChart"
                    style="width: 300px; padding-left: 0"
                  >
                    <el-option
                      v-for="(item, index) in nameOptions"
                      :key="index"
                      :label="item"
                      :value="item"
                    >
                    </el-option>
                  </el-select>
                </div>
                <div class="select-item">
                  <div>时间：</div>
                  <el-date-picker
                    style="width: 300px"
                    :disabled="true"
                    v-model="queryTime"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                  >
                  </el-date-picker>
                </div>
              </div>
              <div id="line1" v-if="activeName == 'second'" style="width: 100%"></div>
              <div id="bar1" style="width: 100%"></div>
              <div id="line2" style="width: 100%"></div>
              <div class="yelp">
                <div class="y-title">指数点评</div>
                <div class="y-content">
                  <div class="y-content-header">一、{{ yelpForm.title }}</div>
                  <div class="y-content-detail" v-html="yelpForm.content" @click="toDetail(yelpForm.id)"></div>
                </div>
              </div>
            </div>
            <div class="list-other">
              <el-row>
                <el-col class="content" :span="24"><div>大盘指数</div></el-col>
              </el-row>
              <el-row>
                <el-col class="content1" :span="12"><div>日指数：</div></el-col>
                <el-col class="content2" :span="12"><div>{{dpzsMark.rzsValue}}</div></el-col>
              </el-row>
              <el-row>
                <el-col class="content1" :span="12"><div>日涨跌幅：</div></el-col>
                <el-col v-if="dpzsMark.rzfBl < 0" class="content2" :span="12"><div class="green el-icon-bottom">{{dpzsMark.rzfBl}}%</div></el-col>
                <el-col v-else-if="dpzsMark.rzfBl > 0" class="content2" :span="12"><div class="red el-icon-top">{{dpzsMark.rzfBl}}%</div></el-col>
                <el-col v-else class="content2" :span="12"><div>{{dpzsMark.rzfBl}}%</div></el-col>
              </el-row>
              <el-row>
                <el-col class="content1" :span="12"><div>周指数：</div></el-col>
                <el-col class="content2" :span="12"><div>{{dpzsMark.zzsValue}}</div></el-col>
              </el-row>
              <el-row>
                <el-col class="content1" :span="12"><div>周涨跌幅：</div></el-col>
                <el-col v-if="dpzsMark.zzfBl < 0" class="content2" :span="12"><div class="green el-icon-bottom">{{dpzsMark.zzfBl}}%</div></el-col>
                <el-col v-else-if="dpzsMark.zzfBl > 0" class="content2" :span="12"><div class="red el-icon-top">{{dpzsMark.zzfBl}}%</div></el-col>
                <el-col v-else class="content2" :span="12"><div>{{dpzsMark.zzfBl}}%</div></el-col>
              </el-row>
              <el-row>
                <el-col class="content1" :span="12"><div>月指数：</div></el-col>
                <el-col class="content2" :span="12"><div>{{dpzsMark.yzsValue}}</div></el-col>
              </el-row>
              <el-row>
                <el-col class="content1" :span="12"><div>月涨跌幅：</div></el-col>
                <el-col v-if="dpzsMark.yzfBl < 0" class="content2" :span="12"><div class="green el-icon-bottom">{{dpzsMark.yzfBl}}%</div></el-col>
                <el-col v-else-if="dpzsMark.yzfBl > 0" class="content2" :span="12"><div class="red el-icon-top">{{dpzsMark.yzfBl}}%</div></el-col>
                <el-col v-else class="content2" :span="12"><div>{{dpzsMark.yzfBl}}%</div></el-col>
              </el-row>
              <el-row>
                <el-col class="content1" :span="12"><div>季指数：</div></el-col>
                <el-col class="content2" :span="12"><div>{{dpzsMark.jzsValue}}</div></el-col>
              </el-row>
              <el-row>
                <el-col class="content1" :span="12"><div>季涨跌幅：</div></el-col>
                <el-col v-if="dpzsMark.jzfBl < 0" class="content2" :span="12"><div class="green el-icon-bottom">{{dpzsMark.jzfBl}}%</div></el-col>
                <el-col v-else-if="dpzsMark.jzfBl > 0" class="content2" :span="12"><div class="red el-icon-top">{{dpzsMark.jzfBl}}%</div></el-col>
                <el-col v-else class="content2" :span="12"><div>{{dpzsMark.jzfBl}}%</div></el-col>
              </el-row>
              <el-row>
                <el-col class="content1" :span="12"><div>年指数：</div></el-col>
                <el-col class="content2" :span="12"><div>{{dpzsMark.nzsValue}}</div></el-col>
              </el-row>
              <el-row>
                <el-col class="content1" :span="12"><div>年涨跌幅：</div></el-col>
                <el-col v-if="dpzsMark.nzfBl < 0" class="content2" :span="12"><div class="green el-icon-bottom">{{dpzsMark.nzfBl}}%</div></el-col>
                <el-col v-else-if="dpzsMark.nzfBl > 0" class="content2" :span="12"><div class="red el-icon-top">{{dpzsMark.nzfBl}}%</div></el-col>
                <el-col v-else class="content2" :span="12"><div>{{dpzsMark.nzfBl}}%</div></el-col>
              </el-row>
              <el-row>
                <el-col class="content1" :span="12"><div>涨落排行：</div></el-col>
                <el-col class="content2" :span="12"><div @click="goClick" style="text-decoration: underline;cursor: pointer;">点击进入</div></el-col>
              </el-row>
            </div>
          </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="指数点评" name="third">
          <div class="zhishu">
            <div
              class="zhishu-div"
              v-for="(item, index) in zhishuList"
              :key="index"
              @click="handleZsClick(item)"
            >
              <div class="title-text">●&nbsp;{{ item.title }}</div>
              <div>{{ item.pubDate }}</div>
            </div>
          </div>
          <el-pagination
            class="pagination-style"
            @size-change="handleSizeChange2"
            @current-change="handleCurrentChange2"
            :current-page="pagination2.page"
            :page-sizes="[1, 10, 20, 50, 100]"
            :page-size="pagination2.size"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pagination2.total"
          >
          </el-pagination>
        </el-tab-pane>
        <el-tab-pane label="涨落排行" name="fourth">
          <div class="banxin">
            <div class="search-module">
              <div class="select-item tp">
                <div>市场：</div>
                <div
                  v-for="(item, index) in scOption"
                  :key="index"
                  :class="{ active: scActive == item.value }"
                  @click="
                    scActive = item.value
                    getZLList()
                  "
                >
                  {{ item.label }}
                </div>
              </div>
              <div class="select-item tp">
                <div>类别：</div>
                <div
                  v-show="item.id !== -2"
                  v-for="(item, index) in leibieOption"
                  :key="index"
                  :class="{ active: leibieActive == item.id }"
                  @click="
                    leibieActive = item.id
                    getZLList()
                  "
                >
                  {{ item.name }}
                </div>
              </div>
              <div class="select-item tp">
                <div>涨落：</div>
                <div
                  v-for="(item, index) in zlOption"
                  :key="index"
                  :class="{
                    active: zlActive === item.value,
                    'zj-color': index == 1,
                    'lj-color': index == 2,
                  }"
                  @click="
                    zlActive = item.value
                    getZLList()
                  "
                >
                  {{ item.label }}
                </div>
              </div>
              <div class="select-item">
                <div>时间：</div>
                <div
                  v-for="(item, index) in sjOption"
                  :key="index"
                  :class="{ active: sjActive == item.value }"
                  @click="handleTimeChange(index)"
                >
                  {{ item.label }}
                  <i
                    v-show="sjActive == item.value"
                    :class="
                      sort == 1
                        ? 'el-icon-top color-red'
                        : 'el-icon-bottom color-green'
                    "
                  ></i>
                </div>
              </div>
            </div>
            <div class="list-module">
              <el-table
                class="xh-table"
                border
                :data="tableData3"
                style="width: 100%"
                align="center"
              >
                <el-table-column
                  prop="market"
                  label="市场"
                  align="center"
                ></el-table-column>
                <el-table-column
                  prop="mname"
                  label="品名"
                  align="center"
                ></el-table-column>
                <el-table-column
                  prop="mspec"
                  label="规格"
                  align="center"
                ></el-table-column>
                <el-table-column
                  prop="maddr"
                  label="产地"
                  align="center"
                ></el-table-column>
                <el-table-column
                  width="150"
                  prop="oldPrice"
                  align="center"
                  label="原价（元/kg）"
                ></el-table-column>
                <el-table-column
                  width="150"
                  prop="currentPrice"
                  align="center"
                  label="现价（元/kg）"
                ></el-table-column>
                <el-table-column
                  prop="riseFall"
                  width="120px"
                  label="涨跌幅"
                  align="center"
                >
                  <template slot-scope="scoped">
                    <template
                      v-if="
                        parseFloat(scoped.row.riseFall * 100).toFixed(2) < 0
                      "
                    >
                      <div class="green el-icon-bottom">
                        {{ parseFloat(scoped.row.riseFall * 100).toFixed(2) }}%
                      </div>
                    </template>
                    <template
                      v-else-if="
                        parseFloat(scoped.row.riseFall * 100).toFixed(2) > 0
                      "
                    >
                      <div class="red el-icon-top">
                        {{ parseFloat(scoped.row.riseFall * 100).toFixed(2) }}%
                      </div>
                    </template>
                    <template v-else>
                      <div>
                        {{ parseFloat(scoped.row.riseFall * 100).toFixed(2) }}%
                      </div>
                    </template>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="action"
                  align="center"
                  label="历史价格"
                  width="150"
                >
                  <template slot-scope="scoped">
                    <el-button
                      type="primary"
                      plain
                      size="small"
                      @click="lookBtn(scoped.row)"
                      >历史价格
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>

            <el-pagination
              class="pagination-style"
              @size-change="handleSizeChange3"
              @current-change="handleCurrentChange3"
              :current-page="pagination3.page"
              :page-sizes="[1, 10, 20, 50, 100]"
              :page-size="pagination3.size"
              layout="total, sizes, prev, pager, next, jumper"
              :total="pagination3.total"
            >
            </el-pagination>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import {
  getprices,
  getAllMcate,
  getStockList,
  getPmList,
  zhishuList,
  zlList,
} from "@/api/price";
import {
  getLunbotu,
  saveView,
  getUserInfo
} from "@/api";
export default {
  data () {
    return {
      oldData: '',
      colors: [],
      activeName: "",
      lunboList: [],
      keyword: "",
      pagination: {
        total: 0,
        size: 10,
        page: 0,
      },
      sort: 0,
      pagination2: {
        total: 0,
        size: 20,
        page: 1,
      },
      pagination3: {
        total: 0,
        size: 10,
        page: 1,
      },
      tableData3: [],
      scOption: [{
        label: "全部",
        value: "",
      },
      {
        label: "亳州市场",
        value: "亳州",
      },
      {
        label: "安国市场",
        value: "安国",
      },
      {
        label: "成都市场",
        value: "成都",
      },
      {
        label: "玉林市场",
        value: "玉林",
      },
      ],
      leixinOption: [{
        label: "日指数",
        value: "ri",
        show: ["jiage"],
      },
      {
        label: "周指数",
        value: "zhou",
        show: ["jiage", "huanbi"],
      },
      {
        label: "月指数",
        value: "yue",
        show: ["jiage", "huanbi"],
      },
      {
        label: "季指数",
        value: "ji",
        show: ["jiage"],
      },
      {
        label: "年指数",
        value: "nian",
        show: ["jiage", "tongbi"],
      },
      ],
      leibieOption: [
        {
          name: "全部",
          id: "",
        },
        // {
        //   name: "今日变动",
        //   id: -1,
        // },
        // {
        //   name: "疫情检测品种",
        //   id: -2,
        // },
      ],
      zsOption: [{
        label: "价格指数",
        value: "jiage",
      },
      {
        label: "环比指数",
        value: "huanbi",
      },
      {
        label: "同比指数",
        value: "tongbi",
      },
      ],
      zlOption: [{
        label: "全部",
        value: '',
      },
      {
        label: "涨价品种",
        value: 1,
      },
      {
        label: "落价品种",
        value: 0,
      },
      ],
      sjOption: [{
        label: "日",
        value: 0,
        icon: "el-icon-bottom",
      },
      {
        label: "周",
        value: 1,
        icon: "el-icon-bottom",
      },
      {
        label: "月",
        value: 2,
        icon: "el-icon-bottom",
      },
      {
        label: "季度",
        value: 3,
        icon: "el-icon-bottom",
      },
      {
        label: "年度",
        value: 4,
        icon: "el-icon-bottom",
      },
      ],
      dpzsMark: [],
      nameOptions: [],
      sjActive: 0,
      flActive: '',
      oldSjActive: 0,
      zlActive: '',
      scActive: "",
      typeActive: 0,
      zsActive: "jiage",
      leibieActive: "",
      leixinActive: "ri",
      zhishuList: [],
      tableData: [],
      entryType: "",
      yelpForm: {},
      form: {
        mname: '', //品名
        keyword: "",
        type: 0,
        dateFlag: 0,
        order: '',
        sort: '',
        pageIndex: 1,
        pageSize: 10,
      },
      queryName: "",
      queryTime: [],
      line1: {
        grid: {
          top: 10,
          left: 50,
          right: 50,
        },
        tooltip: {
          trigger: "axis",
        },
        xAxis: {
          data: [],
        },
        yAxis: {},
        dataZoom: [{
          startValue: "2014-06-01",
        },
        {
          type: "inside",
        },
        ],
        series: {
          name: "",
          type: "line",
          data: [],
        },
      },
      line2: {
        title: {
          text: "年度平均线",
          left: "center",
        },
        grid: {
          left: 50,
          right: 50,
        },
        tooltip: {
          trigger: "axis",
        },
        xAxis: {
          type: "category",
          data: [],
        },
        yAxis: {
          type: "value",
        },
        series: [{
          data: [],
          type: "line",
          smooth: true,
        },],
      },
      bar1: {
        title: {
          text: "季度平均线",
          left: "center",
        },
        grid: {
          left: 50,
          right: 50,
        },
        tooltip: {
          trigger: "axis",
        },
        xAxis: {
          type: "category",
          data: [],
        },
        yAxis: {
          type: "value",
        },
        series: [{
          data: [],
          itemStyle: {
            normal: {
              color: (params) => {
                console.log(params, 88)
                console.log(this.colors)
                if (this.colors[params.dataIndex] == 1) {
                  return "#FF1001";
                } else if (this.colors[params.dataIndex] == 0) {
                  return "#5DFCFF";
                }
              },
            },
          },
          type: "bar",
        },],
      },
    };
  },
  created () {
    this.activeName = this.$route.query.activeName || "first";
    this.entryType = this.$route.query.type || "";
    this.form.keyword = this.$route.query.id || "";
    if(this.$route.query.activeName && this.$route.query.activeName == 'third') {
      this.getZhishuList();
    }else {
      this.getprices();
    }
    this.getLunbotu();
    this.getDict();
  },
  mounted () { },
  methods: {
    // 指数点评详情
    toDetail(id) {
      this.$router.push({
        name: "zsDetail",
        query: {
          id: id,
        },
      });
    },
    // Banner图
    getLunbotu () {
      getLunbotu({
        limitN: 3,
        location: 8,
      }).then((res) => {
        if (res.code == 200) {
          this.lunboList = res.data;
        }
      });
    },
    getDict () {
      getAllMcate().then((res) => {
        this.leibieOption = this.leibieOption.concat(res.data || []);
      });
      getPmList().then((res) => {
        this.nameOptions = res.data || [];
      });
    },
    getNames (id) {
      getPmList({ mcate: id }).then((res) => {
        this.nameOptions = res.data || [];
      });
    },
    handleTimeChange (index) {
      this.sjActive = this.sjOption[index].value;
      if (this.sjActive == this.oldSjActive) {
        this.sort =
          this.sort === 1 ? 0 : 1;
      } else {
        this.oldSjActive = this.sjActive;
      }
      this.getZLList();
    },
    getZhishuList () {
      zhishuList({
        newstype: 10,
        page: this.pagination2.page,
        limit: this.pagination2.size,
      }).then((res) => {
        this.zhishuList = res.data.content || [];
        this.yelpForm = this.zhishuList[0]
        this.pagination2.total = res.data.totalElements;
      });
    },
    initZs () {
      if (this.zsActive == "jiage") {
        this.leixinActive = "ri";
      } else if (this.zsActive == "huanbi") {
        this.leixinActive = "zhou";
      } else if (this.zsActive == "tongbi") {
        this.leixinActive = "nian";
      }
    },
    initChart () {
      getStockList({
        queryName: this.queryName,
        zs: this.leixinActive,
        ds: this.zsActive,
        mcate: this.flActive
      }).then((res) => {
        this.dpzsMark = res.data.dpzsMark;
        this.line1.xAxis.data = res.data.lineDataList.map(function (item) {
          return item[0];
        });
        this.line1.series.data = res.data.lineDataList.map(function (item) {
          return item[1];
        });
        this.line2.xAxis.data = res.data.yearAvgMap.yearAvgListX;
        this.line2.series[0].data = res.data.yearAvgMap.yearAvgListY;
        this.bar1.xAxis.data = res.data.quaAvgMap.quaAvgListX;
        this.bar1.series[0].data = res.data.quaAvgMap.quaAvgListY;
        this.colors = res.data.quaAvgMap.quaAvgListColor;
        var chartDom1 = document.getElementById("line1");
        var chartDom2 = document.getElementById("line2");
        var chartDom3 = document.getElementById("bar1");
        var myChart1 = echarts.init(chartDom1);
        var myChart2 = echarts.init(chartDom2);
        var myChart3 = echarts.init(chartDom3);
        this.line1 && myChart1.setOption(this.line1);
        this.line2 && myChart2.setOption(this.line2);
        this.bar1 && myChart3.setOption(this.bar1);
        const _that = this;
        _that.queryTime = [];
        _that.queryTime.push(new Date(this.line1.xAxis.data[0]));
        _that.queryTime.push(
          new Date(this.line1.xAxis.data[this.line1.xAxis.data.length - 1])
        );
        myChart1.on("dataZoom", function (params) {
          _that.queryTime = [];
          const startValueIndex =
            myChart1.getModel().option.dataZoom[1].startValue;
          const endValueIndex = myChart1.getModel().option.dataZoom[0].endValue;
          const start =
            myChart1.getModel().option.xAxis[0].data[startValueIndex];
          const end = myChart1.getModel().option.xAxis[0].data[endValueIndex];
          const startValue = new Date(start);
          const endValue = new Date(end);
          _that.queryTime.push(startValue);
          _that.queryTime.push(endValue);
        });
      });
    },
    handleSizeChange (val) {
      this.pagination.size = val;
      this.pagination.page = 1;
      this.form.pageSize = val;
      this.getprices();
    },
    handleCurrentChange (val) {
      this.pagination.page = val;
      this.form.pageIndex = val;
      this.getprices();
    },
    handleSizeChange2 (val) {
      this.pagination2.size = val;
      this.pagination2.page = 1;
      this.getZhishuList();
    },
    handleCurrentChange2 (val) {
      this.pagination2.page = val;
      this.getZhishuList();
    },
    handleSizeChange3 (val) {
      this.pagination3.size = val;
      this.pagination3.page = 1;
      this.getZLList();
    },
    handleCurrentChange3 (val) {
      this.pagination3.page = val;
      this.getZLList();
    },
    searchPrice () {
      this.form.keyword = this.keyword;
      this.getprices();
    },

    lookBtn (row) {
      getUserInfo().then((res) => {
        if (res.code == 200) {
          this.$router.push({
            name: "priceDetail",
            query: {
              id: row.id,
              mname: row.mname,
              mspec: row.mspec,
              maddr: row.maddr,
              market: row.market,
            },
          });
        } else {
          this.$notify({
            title: '信息',
            message: '请先登录以获取更多信息',
            type: 'info',
            offset: 100,
            duration: 2600
          });
        }
      })
    },
    ghBtn (row) {
      this.$router.push({
        name: "gq",
        query: {
          id: row.id,
          mname: row.mname,
          mspec: row.mspec,
          maddr: row.maddr,
        },
      });
    },
    gyDetail () {
      this.$router.push({
        name: "gyDetail",
      });
    },
    handleZsClick (row) {
      saveView({
        viewType: 2,
        relateId: row.id,
      }).then((res) => { });
      this.$router.push({
        name: "zsDetail",
        query: {
          id: row.id,
        },
      });
    },
    handleClick () {
      if (this.activeName == "first") {
        this.getprices();
      } else if (this.activeName == "second") {
        this.getZhishuList();
        setTimeout(() => {
          this.initChart();
        }, 500);
      } else if (this.activeName == "third") {
        this.getZhishuList();
      } else if (this.activeName == "fourth") {
        this.getZLList();
      }
    },
    getZLList () {
      zlList({
        market: this.scActive,
        mcate: this.leibieActive,
        isFall: this.zlActive,
        timeType: this.sjActive,
        isAsc: this.sort,
        isChange: this.leibieActive === -1 ? 1 : 0,
        page: this.pagination3.page,
        pageSize: this.pagination3.size,
      }).then((res) => {
        this.tableData3 = res.data || [];
        this.pagination3.total = res.total;
      });
    },
    getprices () {
      let that = this;
      getprices({
        mname: that.form.mname,
        market: this.scActive,
        mcate: this.leibieActive,
        isChange: this.leibieActive === -1 ? 1 : 0,
        isCheck: this.leibieActive === -2 ? 1 : 0,
        page: that.form.pageIndex,
        pageSize: that.form.pageSize,
        order: that.form.order,
        sort: that.form.sort,
      }).then((res) => {
        that.tableData = res.data;
        that.pagination.total = res.total;
      });
    },
    sortMethod (arg) {
      this.form.order = arg
      if (!this.form.sort) {
        this.form.sort = 'asc'
      } else {
        this.form.sort = this.form.sort === 'asc' ? 'desc' : 'asc'
      }
      this.getprices()
    },
    // 随便写个随机数
    getNum () {
      return (String(+new Date()) + (parseInt(Math.random() * 10000000)))
    },
    // 点击进入
    goClick () {
      this.activeName = "fourth";
      this.getZLList();
    }
  },
};
</script>

<style lang="less" scoped>
@import url('../../assets/common.less');

.zhishu {
  margin-top: 20px;
  padding-left: 10px;
  padding-right: 10px;

  .zhishu-div {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;

    .title-text {
      cursor: pointer;
    }

    .title-text:hover {
      color: #409eff;
      text-decoration: underline;
    }
  }
}

.fl {
  float: left;
}

.fr {
  float: right;
}

.banxin1 {
  width: 100%;
  margin: 0 auto;
}

.tab-banxin {
  max-width: 1199px;
  margin: 40px auto 0;
}

.banxin {
  // margin-top: 50px;
  max-width: 1190px;
  // box-shadow: 0 0 3px 3px rgb(235, 235, 235);
}

.green {
  color: green;
  font-weight: bold;
}

.price {
  background: @beijingse;
  padding-bottom: 20px;

  .s-txt {
    font-size: 12px;
    color: #666666;
    line-height: 40px;
    margin-right: 10px;
  }

  .search-module {
    width: 100%;
    text-align: center;
    background: #fff;
    padding: 30px 25px;
    box-sizing: border-box;
    padding-top: 10px;

    .el-icon-search {
      width: 90px;
      margin-left: 39px;
    }

    .el-button {
      border-radius: 0;
    }
  }

  .content-other {
    display: flex;
  }

  .list-one {
    width: 75%;
  }

  .list-other {
    width: 25%;
    height: 500px;
    text-align: center;
    padding: 30px 25px;
    box-sizing: border-box;
    margin-top: 10px;
    padding-top: 10px;
    border: 1px solid #e9e9e9;
    .content {
      width: 100%;
      height: 40px;
      line-height: 40px;
      text-align: center;
      background-color: #fff;
      color: #333;
      font-size: 14px;
      font-weight: 600;
      border-bottom: 2px solid #f00;
    }
    .content1 {
      width: 40%;
      height: 40px;
      line-height: 40px;
      text-align: center;
      background-color: #fff;
      border-bottom: 1px #333 dashed;
      color: #333;
      font-size: 14px;
    }
    .content2 {
      width: 60%;
      height: 40px;
      line-height: 40px;
      text-align: center;
      background-color: #fff;
      border-bottom: 1px #333 dashed;
      color: #333;
      font-size: 14px;
    }
  }

  .tp {
    border-bottom: none !important;
  }

  .select {
    margin: auto;
    height: 22px;

    &-item {
      display: flex;
      align-items: center;
      line-height: 42px;
      border: 1px solid #eee;
      padding: 10px 0;
      flex-wrap: wrap;

      div {
        font-size: 16px;
        padding: 0px 10px;
        margin-right: 14px;
        cursor: pointer;
      }

      div:first-child {
        margin-right: 0;
      }

      .active {
        background: rgb(20, 99, 158);
        border-radius: 4px;
        color: #fff;
      }
    }
  }

  .list-module {
    // padding-left:40px;
    // background:#fff;
    .el-table {
      font-size: 15px;
      color: #666;
    }

    .xh-table {
      /deep/ th {
        // background-color: #f1f1f1;
        color: #333333;
        height: 40px;
        font-weight: bold;
        font-size: 16px;
        padding-top: 5px;
        padding-bottom: 5px;
      }
    }
  }

  .pagination-style {
    text-align: right;
    margin-bottom: 20px;
    background: #fff;
    padding: 16px 0;
    padding-right: 20px;
    // border-bottom-left-radius:5px;
    //  border-bottom-right-radius:5px;
  }
}

.bannercai {
  width: 100%;
  height: 500px;
  text-align: center;
  img {
    // width: 100%;
    height: 100%;
  }
}

.el-input {
  width: 200px;
}

.el-button--primary,
.el-button--primary:focus,
.el-button--primary:hover {
  background: #3883e5;
  border-color: #3883e5;
}

/deep/ .el-table--enable-row-hover .el-table__body tr > td.el-table__cell {
  background: #fff;
  padding-left: 15px;
}

/deep/ .el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border-bottom: 1px solid #f1f1f1;
}

/deep/ .el-table th.el-table__cell > .cell {
  // padding-left: 25px;
  // padding-right: 20px;
}

/deep/.el-pagination .el-select .el-input {
  border: 1px solid #f4f4f5;
}

/deep/ .el-pagination__editor.el-input {
  border: 1px solid #f4f4f5;
}

/deep/ .el-pager li.active {
  color: #fff;
  background: #3883e5;
}

/deep/ .el-pager li {
  background: #f4f4f5;
  margin: 0 2px;
  color: #686a6e;
}

/deep/ .el-button--primary.is-plain:hover {
  background: #3883e5;
}

/deep/ .el-tabs__item {
  font-size: 17px;
}

/deep/ .el-range-editor.is-disabled input {
  color: #000000;
  font-size: 16px;
}

.red {
  color: #ff5602;
  font-weight: bold;
}

.linkLun {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

#line1,
#line2,
#bar1 {
  width: 100%;
  height: 400px;
  background: #ffffff;
}
.yelp {
  width: 100%;
  .y-title {
    text-align: center;
    width: 112px;
    height: 45px;
    background: #1b86fb;
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 45px;
  }
  .y-content {
    width: 100%;
    // width: 969px;
    height: 120px;
    background: #f6f6f6;
    padding: 15px 10px 40px 21px;
    .y-content-header {
      height: 25px;
      font-size: 18px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      line-height: 25px;
      margin-bottom: 15px;
    }
    .y-content-detail {
      height: 25px;
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #4f5156;
      line-height: 25px;
      overflow: hidden;
      background: #f6f6f6;
      text-overflow: ellipsis;
      white-space: nowrap;
      vertical-align: middle;
      cursor: pointer;
    }
  }
}

.line1 {
  height: 600px;
}
.color-green {
  color: #0fbb0f;
  font-weight: 600;
}
.color-red {
  color: red;
  font-weight: 600;
}

/deep/ .el-table .caret-wrapper {
  display: none;
}
/deep/ .el-table .sort-caret.descending,
/deep/ .el-table .sort-caret.ascending {
  // font-family: 'iconfont' !important;
  // font-size: 12px;
  // font-style: normal;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
  // border: none;
  // width: 12px;
  // height: 12px;
  display: none;
}
/deep/ .el-table .sort-caret.ascending::before {
  content: '\e6e6';
}
/deep/ .el-table .sort-caret.descending::before {
  content: '\e6e6';
}
/deep/ .el-table .ascending .sort-caret.ascending,
/deep/ .el-table .descending .sort-caret.descending {
  color: #0077aa;
}

.zj-color {
  color: #f54337;
}
.lj-color {
  color: #00a900;
}
</style>