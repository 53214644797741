<template>
  <div class="page flex-col">
    <div class="block_15 flex-col">
      <div class="" style="cursor: pointer;">
        <el-menu :default-active="activeIndex" class="el-menu-demo" style="padding-left: calc(50% - 96px);" background-color="#1B86FB"
          text-color="#fff" active-text-color="#ffd04b" mode="horizontal" @select="handleSelect">

          <el-menu-item index="7" @click="goHome">
            <img @click="goHome" class="label_1" style="margin-right: 5px;" referrerpolicy="no-referrer"
              src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng4800a65da7d1d6a12222f5e1e99ede4df7d61ee6b3ba4847cc4b3bfe3102e0dd" />
            <span>公司首页</span> </el-menu-item>
          <el-menu-item v-if="!tabActive" index="1" @click="goNavList(0)">公司简介</el-menu-item>
          <el-menu-item v-if="!tabActive" index="2" @click="goNavList(1)">产品中心</el-menu-item>
          <el-menu-item v-if="tabActive" index="3" @click="goGsjs">公司简介</el-menu-item>
          <el-menu-item v-if="tabActive" index="4" @click="goPro">产品中心</el-menu-item>
          <!-- <el-menu-item index="5" @click="goHome()">公司资质</el-menu-item>
        <el-menu-item index="6" @click="goHome()">联系我们</el-menu-item> -->
          <div class="section_1 flex-col"></div>

        </el-menu>
      </div>
      <!-- <div class="block_2 flex-row" style="cursor: pointer;">
        <div class="text-wrapper_1 flex-col">
          <span class="text_1" @click="goGsjs()">公司简介</span>
        </div>
          <span class="text_2" @click="goPro()">产品中心</span>
        <span class="text_3">公司资质</span>
        <span class="text_4">联系我们</span>
        <div class="section_1 flex-col"></div>
        <img @click="()=>{this.mark=0}"
          class="label_1"
          referrerpolicy="no-referrer"
          src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng4800a65da7d1d6a12222f5e1e99ede4df7d61ee6b3ba4847cc4b3bfe3102e0dd"
        />
        <span class="text_5" @click="()=>{this.mark=0;this.currentId=undefined}">首页</span>
      </div> -->
      <home v-if="mark==0" ref="home" :productList="productList" @getDetailid="getId" :companyData="companyData" :lunboList="lunboList"
        @goGsjs="goGsjs" @goPro="goPro
      "></home>
      <gsjs v-if="mark==1&&currentId==undefined" :companyData="companyData"></gsjs>
      <production v-if="mark==2&&currentId==undefined" @getDetailid="getId" :currentid="currentid">
      </production>
      <prodetail v-if="currentId!=undefined&&mark!=0" :productDetail="productDetail" :productDetailList="productDetailList" @goHome="goHome"
        @goPro="goPro"></prodetail>
    </div>
  </div>
</template>
<script>
import Home from './detail2.vue'
import Gsjs from '../gsjs/gsjs.vue'
import Production from '../production/index.vue'
import Prodetail from '../proDetail/index.vue'
import { getCompany, getProductList, getProductByid } from '@/api/information'
import { INFO_URL } from '@/config/globalConfig'
export default {
  data() {
    return {
      mark: 0,
      baseUrl: INFO_URL,
      currentId: undefined,
      tabActive: false,
      currentid: -1,
      activeIndex: '7',
      name: '',
      content: '',
      phone: '',
      addr: '',
      decriptionscontent: '',
      pageSize: 10,
      pagenum: 1,
      searchVal: '',
      companyData: {},
      productDetail: {},
      productList: [],
      productDetailList: [],
      lunboList: []
    }
  },
  components: {
    Home,
    Gsjs,
    Production,
    Prodetail
  },
  created() {
    this.currentid = this.$route.query.id
    this.getCompany(this.currentid)
    this.getProduct(this.currentid)
  },
  methods: {
    goNavList(val) {
      switch (val) {
        case 0:
          window.scrollTo({
            top: this.$el.querySelector('.block_19').offsetTop,
            behavior: 'smooth'
          })
          // this.$el.querySelector('.block_19').scrollIntoView({block:'start',behavior:'smooth'});
          break
        case 1:
          window.scrollTo({
            top: this.$el.querySelector('.block_21').offsetTop,
            behavior: 'smooth'
          })
          // this.$el.querySelector('.block_21').scrollIntoView({block:'start',behavior:'smooth'});
          break
        case 2:
          this.$el.querySelector('.block_22').scrollIntoView({ block: 'start', behavior: 'smooth' })
          break
        case 3:
          this.$el.querySelector('.block_24').scrollIntoView({ block: 'start', behavior: 'smooth' })
          break
      }
    },
    handleSelect(key, keyPath) {
      this.activeIndex = key
      console.log(key, 'key')
    },
    getId(id) {
      window.scrollTo({ top: 0 })
      this.tabActive = true
      this.currentId = id
      this.mark = 3
      getProductByid({ id: id }).then((res) => {
        this.productDetail = res.data
        if (this.productDetail.picsurl) {
          this.productDetailList = this.productDetail.picsurl.split(',')
        }
      })
    },
    goGsjs() {
      window.scrollTo({ top: 0 })
      this.tabActive = true
      // this.activeIndex = '3'
      this.currentId = undefined
      this.mark = 1
    },
    goPro() {
      window.scrollTo({ top: 0 })
      this.tabActive = true
      // this.activeIndex = '4'
      this.currentId = undefined
      this.mark = 2
    },
    goHome() {
      window.scrollTo({ top: 0 })
      this.currentId = undefined
      this.tabActive = false
      this.mark = 0
      this.activeIndex = '1'
    },
    getCompany(id) {
      let that = this
      getCompany(id).then((res) => {
        that.companyData = res.data
        if (that.companyData.qualitypics) {
          that.lunboList = that.companyData.qualitypics.split(',')
        }
      })
    },
    getProduct(id) {
      let that = this
      let params = {
        pageIndex: that.pagenum, // 传入页码
        pageSize: that.pageSize, // 传入每页条数
        name: that.searchVal, // 传入搜索的对象
        companyid: id,
        approveStatus: 2
      }
      getProductList(params).then((res) => {
        that.productList = res.data.content
        that.productList.map((item) => {
          item.thumbpic = that.baseUrl + item.thumbpic
        })
      })
    }
  }
}
</script>
<style lang="less" scoped>
// .el-menu-item{
//   width: 150px
// }
@import url('./common.less');
@import url('./index.less');
</style>

