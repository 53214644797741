<template>
  <div class="home">
    <el-backtop :bottom="60" class="el-backtop1">
      <i class="to-top"></i>
    </el-backtop>
    <!-- header -->
    <div class="header">
      <!-- 最顶部信息 s -->
      <div class="index-top">
        <div class="banxin">
          <div class="top-main">
            <div class="top-left fl">
              <ul class="dflex">
                <li @click="goyinpian">
                  <span class="active">中药产业商城</span>
                </li>
                <li @click="gojiankang">
                  <span class="active">大健康产业商城</span>
                </li>
              </ul>
            </div>
            <div class="top-right fr">
              <ul class="dflex">
                <li><span class="active" @click="goIndex">首页</span></li>
                <li>
                  <template v-if="!userInfo.nickname">
                    <span @click="toLogin">登录</span>
                    <span @click="toReg">注册</span>

                    <!--                    <span @click="open">-->
                    <!--                      登录-->
                    <!--                    </span>-->
                    <!--                    <span @click="register">注册</span>-->
                  </template>
                  <template v-else>
                    <span class="el-dropdown-link" @click="navToPersonalCenter">个人中心</span>
                    <!-- <el-dropdown @command="handleCommand">
                      <span class="el-dropdown-link">
                        个人中心<i
                          class="el-icon-arrow-down el-icon--right"
                        ></i>
                      </span> -->
                    <!-- <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item command="0"
                          ><i class="el-icon-set-up"></i
                          >我的资质</el-dropdown-item
                        >
                        <el-dropdown-item command="6"
                          ><i class="el-icon-set-up"></i
                          >我的店铺</el-dropdown-item
                        >
                        <el-dropdown-item command="7"
                          ><i class="el-icon-set-up"></i
                          >修改公告</el-dropdown-item
                        >
                        <el-dropdown-item command="8"
                          ><i class="el-icon-set-up"></i
                          >留言列表</el-dropdown-item
                        >
                        <el-dropdown-item command="9"
                          ><i class="el-icon-set-up"></i
                          >供求发布</el-dropdown-item
                        > -->
                    <!-- <el-dropdown-item command="1">
                          <i class="el-icon-s-management"></i
                          >投标管理
                        </el-dropdown-item> -->
                    <!-- <el-dropdown-item command="3"
                          ><i class="el-icon-s-management"></i
                          >我的投标</el-dropdown-item
                        >
                        <el-dropdown-item command="4"
                          ><i class="el-icon-s-order"></i
                          >我的合同</el-dropdown-item
                        > -->
                    <!-- <el-dropdown-item command="6"
                          ><i class="el-icon-s-order"></i
                          >入驻申请(服务大厅)</el-dropdown-item
                        > -->
                    <!-- <el-dropdown-item command="2"
                          ><i class="el-icon-s-release"></i
                          >退出登录</el-dropdown-item
                        >
                        <el-dropdown-item command="5"
                          ><i class="el-icon-s-release"></i
                          >注销账号</el-dropdown-item
                        >
                      </el-dropdown-menu>
                    </el-dropdown> -->
                  </template>
                </li>
                <!-- <li><span>会员中心</span></li>
                <li><span>帮助中心</span></li>
                <li><span>意见反馈</span></li> -->
              </ul>
            </div>
          </div>
        </div>
      </div>
      <!-- 最顶部信息 s -->

      <!-- 顶部搜索框 s -->
      <div ref="xiding" :class="headerFixed ? 'issFixed' : ''" class="index-middle xiding">
        <div class="banxin">
          <div class="middle-main dflex">
            <div class="logo" @click="goIndex">
              <img :src="imgUrl" alt="" class="logo-style fl" />
              <div class="logo-tit fl">{{ systemName }}</div>
            </div>
            <div class="search-middle">
              <el-input v-model="inputSearch" class="input-with-select" placeholder="请输入您要查询的内容">
                <el-select slot="prepend" v-model="select" placeholder="请选择">
                  <el-option label="服务大厅" value="1"></el-option>
                  <el-option label="招采投标" value="2"></el-option>
                  <!--                  <el-option label="供应链金融" value="3"></el-option>-->
                  <el-option label="价格指数" value="4"></el-option>
                </el-select>
                <el-button slot="append" icon="el-icon-search" @click="searchBtn">搜 索</el-button>
              </el-input>
            </div>
            <div v-if="rightImgUrl" class="tel-icon">
              <img :src="rightImgUrl" alt="" />
            </div>
          </div>
        </div>
      </div>
      <!-- 顶部搜索框 e -->

      <!-- nav s -->
      <div class="navmain">
        <ul class="header-list">
          <li v-for="item in headData" :key="item.value"
            :class="{ 'active-tab': activeValue == item.value }" class="header-label"
            @click="goPage(item)">
            <router-link to="" :style="
                item.value == 'message' ? 'color:red;font-weight: 1000;' : ''
              ">{{ item.label }}</router-link>
          </li>
        </ul>
      </div>
      <!-- nav e -->
      <!-- <div v-if="!userInfo.nickname" class="login-btns">
        <el-button @click="open">登录 / 注册</el-button>
        <el-button @click="register">注册</el-button>
      </div>
      <div v-else class="login-btns" style="line-height: 42px; cursor: pointer;">
          <el-dropdown @command="handleCommand">
              <span class="el-dropdown-link">
                {{userInfo.nickname}}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command=1><i class="el-icon-s-management"></i>招标管理</el-dropdown-item>
                <el-dropdown-item command=2><i class="el-icon-s-release"></i>退出登录</el-dropdown-item>
              </el-dropdown-menu>
          </el-dropdown>

      </div> -->
    </div>
    <!-- 内容 -->
    <div class="content">
      <router-view></router-view>
    </div>
    <!-- 底部 -->
    <div class="footer">
      <div class="footxin">
        <div class="container">
          <template v-if="sortFooter.length > 0">
            <div v-for="(item, index) in sortFooter" :key="index" class="footer-main">
              <h3>{{ item.label }}</h3>

              <a v-for="(news, index2) in item.dataList" :key="`${index}${index2}`" class="linka"
                @click="goFootPage(news)">{{ news.title }}</a>
            </div>
          </template>
        </div>
        <div class="copright">
          <span><i>地址：</i>{{ companyAddr }}</span>
          <span><i>电话：</i>{{ telPhone }}</span>
          <span><i>邮箱：</i>{{ email }}</span>
        </div>
        <div class="copright">
          <span class="cop2">互联网药品信息服务资格证书</span>
          <span class="cop2">{{ certificate }}</span>
          <img src="https://www.beian.gov.cn/img/new/gongan.png" alt=""
            style="vertical-align: middle; margin-left: 20px;margin-right: 2px" />
          <a class="beian"
            href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=34160202000846"
            target="_blank">皖公网安备：34160202000846</a>
          <i style="margin-left: 20px">备案号：</i>
          <a class="beian" href="https://beian.miit.gov.cn/" target="_blank">{{
            beian
          }}</a>
        </div>
        <div class="wechart">
          <div class="weitem">
            <img :src="imgQr" alt="" />
            <p>微信公众号</p>
          </div>
          <div class="weitem">
            <img :src="imgbidwxapp" alt="" />
            <p>招投标小程序</p>
          </div>

          <div class="weitem">
            <img :src="imgwxapp" alt="" />
            <p>微信小程序</p>
          </div>
          <div class="weitem">
            <img :src="imgQr" alt="" />
            <p>手机H5</p>
          </div>
          <div class="weitem">
            <img :src="imgQr" alt="" />
            <p>Android下载</p>
          </div>
          <!--          <div class="weitem">-->
          <!--            <img :src="imgQr" alt="">-->
          <!--            <p>IOS下载</p>-->
          <!--          </div>-->
        </div>
      </div>
    </div>
    <!-- 登录、注册 -->

    <!-- 客服位置 s-->
    <div class="adv" style="position: fixed;
    top: 210px;
    z-index: 90;">
      <div style="position: fixed;;left:5px;display: flex;
    flex-direction: column;cursor: pointer;" @click="goAdvPage()" v-if="advleft">
        <i class="el-icon-circle-close" style="float:right" @click.stop="advleft=false"></i>
        <img style="width:200px" src="@/assets/imgs/gg1.png" alt="">
        <img style="width:200px;margin-top:6px" src="@/assets/imgs/gg2.png" alt="">
        <img style="width:200px;margin-top:6px" src="@/assets/imgs/gg3.png" alt="">
      </div>
      <div style="position: fixed;display: flex;
    flex-direction: column;right: 5px;cursor: pointer;" @click="goAdvPage()" v-if="advright">
        <i class="el-icon-circle-close" style="margin-left:auto" @click.stop="advright=false"></i>
        <img style="width:200px;" src="@/assets/imgs/gg1.png" alt="">
        <img style="width:200px;margin-top:6px" src="@/assets/imgs/gg2.png" alt="">
        <img style="width:200px;margin-top:6px" src="@/assets/imgs/gg3.png" alt="">
      </div>
    </div>
    <div class="kefuWrap">
      <div class="kefu-main pr">
        <div v-show="isShow" class="kefuleft">
          <div>
            <img :src="weImg" alt="" />
          </div>
          <div>手机扫码加我微信</div>
        </div>
        <div class="kefuright">
          <div class="kefu" @click="apply"
            v-if="(checkFlag==null||checkFlag==-1 || checkFlag== -2|| checkFlag==1)&&buttonv">
            <img alt="" style="width: 70px;margin-left: -10px;" src="../assets/imgs/enn.png" />
          </div>
          <div class="kefu" @mouseenter="enterButon" @mouseleave="leaveButton">
            <img alt="" src="../assets/imgs/kefukefu.png" />
          </div>
          <div v-show="isZai" class="zai">
            <button>
              <div>
                <img alt="" src="../assets/imgs/zai-icon.png" />
              </div>
              <div>在线咨询</div>
            </button>
          </div>
          <!-- <div @mouseenter="enterButon" @mouseleave="leaveButton">
            <button class="weibutton">
              <div>
                <img src="../assets/imgs/wei-icon.png" alt="">
              </div>
              <div>微信客服</div>
            </button>
          </div> -->
        </div>
      </div>
    </div>
    <!-- 客服位置 e-->
  </div>
</template>

<script>
import { INFO_URL } from '@/config/globalConfig'
import { getBottomTopN, getConfigInfo, getDictInfo, getUserInfo, getByType2, logOff } from '@/api'
import {
  B2B2C_URL,
  B2B_URL,
  COMPANY_ADDR,
  TEL_PHONE,
  CERTIFICATE,
  EMAIL,
  BEIAN
} from '@/config/globalConfig'
import store from '../store'
import Cookies from 'js-cookie'
// import { CLOSING } from 'ws';
export default {
  components: {},
  data() {
    return {
      currentPath: this.$router.currentRoute.path,
      checkFlag: -2,
      isZai: false, //在线咨询是否有
      isKefu: false,
      buttonv: false,
      advleft: false,
      advright: false,
      weImg: '',
      beian: BEIAN,
      companyAddr: COMPANY_ADDR,
      telPhone: TEL_PHONE,
      certificate: CERTIFICATE,
      email: EMAIL,
      isShow: false,
      headData: [
        {
          label: '首页',
          value: 'home'
        },
        // {
        //   label: '安心购',
        //   value: 'message',
        // },
        {
          label: '招采投标',
          value: 'bid'
        },
        {
          label: '价格指数',
          value: 'price'
        },
        {
          label: '服务大厅',
          value: 'information'
        },
        {
          label: '中药产业商城',
          value: 'zy'
        },
        {
          label: '大健康产业商城',
          value: 'jk'
        },
        {
          label: '供求信息',
          value: 'gq'
        }
      ],
      inputSearch: '', //搜索框input值
      select: '1', //搜索框 select值
      activeValue: 'finance',
      imgUrl: require('@/assets/imgs/logo.png'),
      systemName: '安徽亳药集团',
      rightImgUrl: '',
      entryType: '',
      userInfo: '',
      imgQr: require('@/assets/imgs/qr.png'),
      imgwxapp: require('@/assets/imgs/qr/wxapp.jpg'),
      imgbidwxapp: require('@/assets/imgs/qr/bidwxapp.jpg'),
      offsetTop: 0,
      offsetHeight: 0,
      headerFixed: false,
      footerConfigList: [],
      isLogon: false
    }
  },
  created() {
    this.getUserInfo()
    this.getConfig()
    this.getBottomInfo()
    this.getByType2()
    if (this.$router.currentRoute.path == '/information') {
      this.buttonv = true
      this.advleft = true
      this.advright = true
    } else {
      this.buttonv = false
      this.advleft = false
      this.advright = false
    }
  },
  computed: {
    sortFooter: function () {
      return this.footerSortById(this.footerConfigList, 'id')
      //this.modules是原数组
    }
  },

  mounted() {
    this.activeValue = this.$route.name || 'finance'
    this.$nextTick(() => {
      // 获取吸顶元素的dom
      let xiding = this.$refs.xiding
      // 吸顶元素到top的距离
      this.offsetTop = xiding.offsetTop
      // 元素自身的高度
      this.offsetHeight = xiding.offsetHeight
      // 监听滚动条
      window.addEventListener('scroll', this.handleScroll)
    })
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
  },

  methods: {
    goAdvPage() {
      window.open('https://test.boyaocaigou.com/', '_blank')
    },
    apply() {
      // 点击入驻申请，若我的资质未填写或者未审核通过，则跳转我的资质界面，若审核通过跳转企业维护界面
      if (!this.userInfo.nickname) {
        this.toLogin()
        return
      }
      getUserInfo().then((res) => {
        if (res.code == 200) {
          this.checkFlag = res.data.checkFlag
          console.log(this.checkFlag)
          if (this.checkFlag == -1) {
            this.$message.warning('您还没有认证资质，请认证资质哦！')
            setTimeout(() => {
              this.$router.push({
                name: 'qualifications'
              })
            }, 1000)
            return
          } else if (this.checkFlag == 1) {
            // 审核通过
            setTimeout(() => {
              this.$router.push({
                name: 'joinApply'
              })
            }, 1000)
            return
          } else if (this.checkFlag == 0) {
            this.$message.warning('您的资质正在审核中，请等候！')
            setTimeout(() => {
              this.$router.push({
                name: 'qualifications'
              })
            }, 1000)
            return
          } else if (this.checkFlag == 2) {
            this.$message.warning('您的资质认证未通过，请重新认证！')
            setTimeout(() => {
              this.$router.push({
                name: 'qualifications'
              })
            }, 1000)
            return
          }
        }
      })
    },
    // 获取二维码
    getByType2() {
      getByType2('5').then((res) => {
        if (res.code === 200) {
          this.weImg = res.data[0].url
        }
      })
    },

    footerSortById(array, key) {
      return array.sort(function (a, b) {
        var x = a[key]
        var y = b[key]
        return x < y ? -1 : x > y ? 1 : 0
      })
    },

    getConfig() {
      let that = this
      getConfigInfo(5).then((res) => {
        if (res.code == 200) {
          // Cookies.set('indexConfig',res.data)
          // that.imgUrl = res.data.logoUrl
          that.systemName = res.data.title
          that.rightImgUrl = res.data.rightImgUrl
          document.title = res.data.title
        }
        console.log('-----', res)
      })
    },
    getBottomInfo() {
      let that = this
      getDictInfo('XINXI_PAGE_CATE').then((res) => {
        if (res.code == 200) {
          let result = res.data
          that.getBottomItem(result)
        }
      })
    },

    getBottomItem(result) {
      let that = this
      let footDataList = []
      for (let id in result) {
        let footData = { id: id, label: result[id] }
        getBottomTopN({ newsType: 5, cateId: id }).then((res) => {
          if (res.code == 200) {
            footData['dataList'] = res.data
          }
          footDataList.push(footData)
        })
      }
      footDataList = footDataList.sort((a, b) => a.id - b.id)
      that.footerConfigList = footDataList
    },
    goFootPage(news) {
      let newsId = news.id
      if (news.contentType == 2) {
        window.open(news.linkUrl, '_blank')
      } else {
        // this.$router.push({
        //   path: '/footerTemplate?nid=' + newsId
        // })
        const routeUrl = this.$router.resolve({
          path: '/footerTemplate?nid=' + newsId
          // query: { nid:newsId },
        })
        window.open(routeUrl.href, '_blank')
      }
    },
    goIndex() {
      this.$router.push({
        path: '/'
      })
    },
    navToPersonalCenter() {
      this.$router.push({
        name: 'personalCenter'
      })
    },
    handleCommand(val) {
      console.log(val)
      if (val == 1 || val == 3) {
        if (this.checkFlag == -1) {
          this.$message.warning('您还没有认证资质，请认证资质哦！')
          setTimeout(() => {
            this.$router.push({
              name: 'qualifications'
            })
          }, 1000)
          return
        } else if (this.checkFlag == 0) {
          this.$message.warning('您的资质正在审核中，请等候！')
          setTimeout(() => {
            this.$router.push({
              name: 'qualifications'
            })
          }, 1000)
          return
        } else if (this.checkFlag == 2) {
          this.$message.warning('您的资质认证未通过，请重新认证！')
          setTimeout(() => {
            this.$router.push({
              name: 'qualifications'
            })
          }, 1000)
          return
        }
        if (val == 1) {
          this.$router.push({
            path: '/bidManagement'
          })
        }
        if (val == 3) {
          this.$router.push({
            path: '/bidManagement'
          })
        }
      } else if (val == 2) {
        // store.dispatch('logout');
        this.$router.push({
          path: '/home'
        })
        Cookies.remove('token')
        localStorage.removeItem('token')
        store.commit('userInfo', {})
        // 退出登录
        this.$message({
          message: '退出成功！',
          type: 'success'
        })
        location.reload()
      } else if (val == 0) {
        if (this.userInfo.nickname) {
          this.$router.push({
            path: '/qualifications'
          })
        } else {
          this.toLogin()
        }
      } else if (val == 4) {
        this.$router.push({
          path: '/agreement'
        })
      } else if (val == 6) {
        this.$router.push({
          path: '/qualifications2'
        })
      } else if (val == 5) {
        this.$confirm('确定注销账号？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            logOff({}).then((res) => {
              if (res.code == 200) {
                this.$message.success('账号注销成功')
                this.$router.push({
                  path: '/home'
                })
                Cookies.remove('token')
                localStorage.removeItem('token')
                store.commit('userInfo', {})
                // // 退出登录
                // this.$message({
                //   message: '退出成功！',
                //   type: 'success',
                // })
                location.reload()
              }
              console.log(res, '注销')
            })
            // this.$message({
            //   type: 'success',
            //   message: '删除成功!'
            // });
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消'
            })
          })
      } else if (val == 6) {
        this.$router.push({
          name: 'myShop',
          query: {
            username: this.$store.state.userInfo.personalphone
          }
        })
      } else if (val == 7) {
        this.$router.push({
          path: '/notice'
        })
      } else if (val == 8) {
        this.$router.push({
          path: '/comment'
        })
      } else if (val == 9) {
        this.$router.push({
          path: '/release'
        })
        // window.open(INFO_URL + '/loginAuto?username=' + this.$store.state.userInfo.personalphone)
      }
    },
    // 获取用户信息
    getUserInfo() {
      getUserInfo().then((res) => {
        this.$store.commit('userInfo', res.data || {})
        if (res.code == 200) {
          this.checkFlag = res.data.checkFlag
        }
      })
    },
    toLogin() {
      this.$router.push('/login')
    },
    toReg() {
      this.$router.push('/register')
    },
    goyinpian() {
      window.open(B2B_URL, '_blank')
    },
    gojiankang() {
      window.open(B2B2C_URL, '_blank')
    },
    goPage(val) {
      if (val.value == 'jk') {
        window.open(B2B2C_URL, '_blank')
        return
      }
      if (val.value == 'ddyc') {
        this.$message.warning('项目开发中')
        return
      }
      if (val.value === 'zy') {
        window.open(B2B_URL, '_blank')
        return
      }
      this.activeValue = val.value
      if (val.value == 'information') {
        this.buttonv = true
        this.advleft = true
        this.advright = true
      } else {
        this.buttonv = false
        this.advleft = false
        this.advright = false
      }
      this.$router.push({
        name: val.value
      })
    },
    searchBtn() {
      let selectValue = this.select
      let inputValue = this.inputSearch
      console.log('select' + selectValue, 'input' + inputValue)
      // 1 服务大厅；2 招采投标；3 供应链金融；4 价格指数
      if (selectValue == 1) {
        this.$router.push({
          name: 'InformationSearch',
          query: {
            keywords: inputValue,
            catId: ''
          }
        })
      } else if (selectValue == 2) {
        this.$router.push({
          name: 'bid',
          query: {
            id: inputValue
          }
        })
      } else if (selectValue == 3) {
        this.$router.push({
          name: 'finance',
          query: {
            id: inputValue
          }
        })
      } else {
        this.$router.push({
          name: 'price',
          query: {
            id: inputValue
          }
        })
      }
    },

    handleScroll() {
      // 得到页面滚动的距离，兼容写法
      let scrollTop =
        window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      // 判断页面滚动的距离是否大于吸顶元素的位置
      if (scrollTop > 162) {
        this.headerFixed = true
      } else if (scrollTop < 10) {
        this.headerFixed = false
      }
    },
    enterButon() {
      this.isShow = true
    },
    leaveButton() {
      this.isShow = false
    }
  },
  watch: {
    $route: {
      handler(val) {
        this.activeValue = val.name
        if (val.path == '/home') {
          this.buttonv = false
          this.advleft = false
          this.advright = false
        }
        console.log(val, '$route')
      },
      immediate: true,
      deep: true
    },
    '$store.state.userInfo': {
      handler(val) {
        this.userInfo = val
      },
      deep: true
    }
  }
}
</script>

<style lang="less" scoped>
@import url('../assets/common.less');

.kefuWrap {
  position: fixed;
  right: 40px;
  bottom: 100px;
  z-index: 99;

  .kefuleft {
    border: 1px solid @primary;
    text-align: center;
    font-size: 14px;
    color: #666;
    padding: 8.5px;
    position: absolute;
    top: 50%;
    left: -144px;
    transform: translateY(-50%);

    img {
      width: 100px;
      height: 100px;
    }
  }

  .kefuright {
    width: 50px;

    .kefu {
      text-align: right;
      cursor: pointer;
    }

    // >div {
    //   height: 70px;

    //   button {
    //     display: block;
    //     width: 100%;
    //     height: 100%;
    //     border: none;
    //     padding-top: 13px;
    //     box-sizing: border-box;
    //     background: @primary;
    //     color: #fff;
    //     text-align: center;
    //     font-size: 12px;
    //     cursor: pointer;
    //   }
    // }

    // .zai {
    //   border-bottom: 1px solid rgba(32, 33, 36, .2);
    // }
  }
}

.issFixed {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 2000;
}

.home {
  height: 100%;

  .navmain {
    width: 100%;
    height: 40px;
    line-height: 40px;
    // background: @primary;
    margin: 0 auto;
  }

  .header {
    min-width: 1190px;
    width: 100%;
    margin: auto;
    // background-color: rgb(246, 246, 246);

    &-list {
      max-width: 1190px;
      display: flex;
      align-items: center;
      height: 100%;
      margin: auto;

      .header-label {
        font-size: 16px;
        cursor: pointer;

        a {
          color: rgb(63, 63, 63);
          text-decoration: none;
          padding: 0 30px;
          // border-right: 1px solid #0f69df;
        }
      }

      .header-label:hover {
        // background: #4b99ff;

        a {
          color: rgb(56, 131, 229);
        }
      }

      .header-label:last-child a {
        border-right: none;
      }
    }

    .login-btns {
      position: absolute;
      right: 20px;
      top: 20px;
    }
  }

  .content {
    min-width: 1210px;
    // padding: 20px 0;
    min-height: calc(100% - 141px);
  }

  .footer {
    min-width: 1210px;
    background-color: #333333;
    color: #fff;
    text-align: center;
    padding-top: 25px;
    padding-bottom: 25px;
    margin-top: 0;

    div {
      font-size: 12px;
      font-family: 微软雅黑;
    }

    .wechart {
      display: -webkit-flex;
      width: 60%;
      display: flex;

      margin: 0 auto;
      margin-top: 10px;
      color: #ababab;
    }

    .weitem {
      flex: 1;
      padding-top: 10px;

      img {
        width: 90px;
        height: 90px;
      }
    }

    .copright {
      text-align: center;
      max-width: 1190px;
      margin: 10px auto 0;
      color: #ababab;

      > span {
        margin-right: 20px;
      }

      > .cop2 {
        margin-right: 5px;
      }

      > span:last-child {
        margin-right: 0;
      }
    }
  }

  .container {
    display: flex;
    padding-bottom: 20px;
    border-bottom: 1px solid #898383;
    margin-bottom: 20px;

    .footer-main {
      flex: 1;
      text-align: center;

      h3 {
        font-size: 14px;
        margin-bottom: 10px;
      }

      .linka {
        display: block;
        font-size: 12px;
        color: #ababab;
        text-decoration: none;
        line-height: 2;
      }

      .linka:hover {
        color: #fff;
      }
    }
  }

  .footxin {
    max-width: 1190px;
    margin: 0 auto;
  }
}

.el-backtop1 {
  position: fixed;
  background-color: #ffffff08;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  cursor: pointer;
  z-index: 5;

  .to-top {
    display: inline-block;
    width: 40px;
    height: 40px;
    background-image: url('~@/assets/imgs/totop.png');
    background-position: center center;
  }
}

.el-backtop:hover {
  background: none;
}

.banxin {
  max-width: 1190px;
  margin: 0 auto;

  .middle-main {
    .logo {
      min-width: 330px;
    }
  }
}

.fl {
  float: left;
}

.fr {
  float: right;
}

.index-top {
  background: #f6f6f6;
  line-height: 38px;
  border-bottom: 1px solid #eeeeee;
  font-size: 12px;
  color: #666666;

  .top-left {
    span {
      color: #666;
      text-decoration: none;
      border-right: 1px solid #d3d3d3;
      padding: 0 20px;
      cursor: pointer;
    }

    li:last-child span:last-child {
      border-right: none;
    }
  }

  .top-main {
    height: 39px;

    .active {
      color: #1069de;
    }
  }

  .top-left {
    color: #666666;
  }

  .top-right {
    span {
      color: #666;
      text-decoration: none;
      border-right: 1px solid #d3d3d3;
      padding: 0 20px;
      cursor: pointer;
    }

    li:last-child span:last-child {
      border-right: none;
    }
  }
}

.index-middle {
  min-width: 1210px;
  background: #fff;
  padding: 15px 0;

  .logo {
    cursor: pointer;
    height: 62px;
    margin-right: 60px;
  }

  .logo-style {
    width: 62px;
    height: 62px;
    display: block;
  }

  .logo-tit {
    font-size: 26px;
    margin-left: 8px;
    color: rgb(49, 119, 211);
    font-weight: bold;
    line-height: 62px;
  }
}

.search-middle {
  width: 550px;
  padding-top: 10px;

  .el-button {
    background: #3883e5;
    color: #fff;
    border-radius: 0;
    border: 1px solid #3883e5;
  }

  /deep/ .el-input-group__append {
    border: none;
  }

  .el-select {
    width: 120px;
    background: #fff;
    border-right: 1px solid #c0c4cc;
  }

  .input-with-select .el-input-group__prepend {
    background-color: #fff;
  }

  .el-input-group {
    border: 2px solid #3883e5;
  }

  /deep/ .el-icon-search {
    color: #fff;
  }

  /deep/ .el-input__inner {
    border: none;
  }
}

.tel-icon {
  width: 175px;
  height: 47px;
  margin-top: 8px;
  margin-left: 70px;

  img {
    width: 100%;
  }
}

.active-tab {
  border-bottom: 4px solid rgb(56, 131, 229);

  a {
    color: rgb(56, 131, 229) !important;
  }
}

.beian {
  color: #ababab;
  text-decoration: none;
}
</style>
