import {
  post,
  json,
  get
} from '../utils/fetch';

// 获取供求列表
export function limit(data) {
  return get('/api/tYshLeaveMessage/limit', data);
}


// 轮播图
export function getSwiperLimitN(data) {
  return get('/api/mall/swiper/getSwiperLimitN', data);
}