<template>
  <div v-if="!showpayqrcode">
    <el-select v-model="value" placeholder="请选择">
      <el-option
        v-for="item in options"
        :key="item.value"
        :label="item.label"
        :value="item.value">
      </el-option>
      
    </el-select>
    <div style="margin-top: 20px">
      <el-radio-group v-model="radio2" size="medium">
        <el-radio label="1" border>扫码支付</el-radio>
        <el-radio label="2" border>银联支付</el-radio>
      </el-radio-group>
      <!-- <el-radio v-model="radio2" label="1" border size="medium">扫码支付</el-radio>
      <el-radio v-model="radio2" label="2" border size="medium">银联支付</el-radio> -->
    </div>
    <el-button @click="createOrder" type="primary" style="margin-top: 20px;">确定</el-button>
    <div v-html="content" ref="yinlianHtml"></div>
  </div>
  <div v-else>
    <img :src="'data:image/png;base64,'+imgurl" alt="">
  </div>
</template>

<script>
import {addZtbOrder,getInqueryMaterialMain} from '@/api/bid'

export default {
  data() {
      return {
        options: [{
          value: '选项1',
          label: '20230821测试'
        }, {
          value: '选项2',
          label: '20230823测试'
        }, {
          value: '选项3',
          label: '20230825测试'
        }, {
          value: '选项4',
          label: '20230815测试'
        }, {
          value: '选项5',
          label: '20230816测试'
        }],
        value: '',
        content: '',
        radio2: '1',
        spid:18,
        depositNum:0,
        showpayqrcode: false,
        imgurl:''
      }
    },
    created(){
      this.spid = this.$route.query.spid
      getInqueryMaterialMain({inquiryId:this.spid}).then(res=>{
        let options = []
        options.push({
          value:res.data.id,
          label:res.data.conpanyname
        })
        this.value = res.data.id
        this.options = options
        this.depositNum = res.data.depositNum
      })
    },
    methods:{
      createOrder(){
        let that = this
        let paymentMethod = this.radio2;
        addZtbOrder({spid:this.spid,totalPrice:this.depositNum*100,paymentMethod:paymentMethod,otherInfo:''}).then(res=>{
          debugger
          if(paymentMethod == '1'){//扫码支付
             if(res.code == 0){
                that.imgurl = res.data
                that.showpayqrcode = true
             }else{
                that.$message.error(res.msg)
             }
          }else{
            //方案1
            var JSScript = "";
            //使用正则获取is 内容
            that.content = res.msg
            res.msg.replace(/<script.*?>([\s\S]+?)<\/script>/img,function(_,js){//正则匹配出script中的内容
              JSScript = js
            })
            //这里需要使用snextTick需要等dom更新后才能操作reportHTML
            this.$nextTick(() => {
              //将is 脚本内容插入到标签当中
              var newElement = document.createElement("script")
              newElement.innerHTML = JSScript
              this.$refs.yinlianHtml.append(newElement)
              //document.sendOrder.submit()
            })  
          }
                
          // window.localStorage.removeItem('callbackHTML')
          // window.localStorage.setItem('callbackHTML', res.msg)
          // var newWin = window.open('', '_blank')
          // console.log(localStorage.getItem('callbackHTML'))
          // newWin.document.write(res.msg)
          // newWin.document.close()
        })
      }
    }
}
</script>

<style>

</style>