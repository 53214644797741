import { Message } from "element-ui";
// 数字
const numberReg = /^\d+$|^\d+[.]?\d+$/;
// 中文
const cnReg = /^[\u4e00-\u9fa5]+$/;
// 邮箱
const emailReg =
  /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/;
// 手机号
const phoneReg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;

const pwdReg =
  /^(?![A-Za-z0-9]+$)(?![a-z0-9\\W]+$)(?![A-Za-z\\W]+$)(?![A-Z0-9\\W]+$)[a-zA-Z0-9\\W]{8,20}$/;

const idcardReg = /^\d{15}|\d{18}$/;

var taxNo18Reg = /^([159Y]{1})([1239]{1})([0-9ABCDEFGHJKLMNPQRTUWXY]{6})([0-9ABCDEFGHJKLMNPQRTUWXY]{9})([0-90-9ABCDEFGHJKLMNPQRTUWXY])$/;
var taxNo15Reg=/^[A-Za-z0-9]\w{14}$/g;

var bankNoReg = /^([1-9]{1})(\d{14}|\d{15}|\d{16}|\d{18})$/

export default {
  // 数字验证
  validateNumber: function (rule, value, callback) {
    if (!numberReg.test(value)) {
      callback(new Error("请输入数字"));
    } else {
      callback();
    }
  },
  // 中文验证
  validateCn: function (rule, value, callback) {
    if (!cnReg.test(value)) {
      callback(new Error("请输入中文"));
    } else {
      callback();
    }
  },
  // 邮箱验证
  validateEmail: function (rule, value, callback) {
    if (!emailReg.test(value)) {
      callback(new Error("请输入正确的邮箱"));
    } else {
      callback();
    }
  },
  // 电话验证
  validatePhone: function (rule, value, callback) {
    if (!phoneReg.test(value)) {
      callback(new Error("请输入正确的手机号码"));
    } else {
      callback();
    }
  },
  /**
   * 密码验证
   */
  checkPwd: function (rulw, value, callback) {
    if (!pwdReg.test(value)) {
      callback(new Error("密码长度大于等于8位"));
    } else {
      callback();
    }
    // var regexp = new RegExp('^(?![A-Za-z0-9]+$)(?![a-z0-9\\W]+$)(?![A-Za-z\\W]+$)(?![A-Z0-9\\W]+$)[a-zA-Z0-9\\W]{8,20}$');
    // if (!regexp.test(value)) {
    //     callback(new Error('请输入正确的手机号码'))
    //     showWarningMsg('密码由大小字母+数字+特殊符号组成，长度大于等于8位')
    //     return false;
    // }

    // return true
  },

  /**
   * 身份证验证
   */
  checkIdCard: function (rule, value, callback) {
    // if (idcardReg.test(value)) {
    //     return true;
    // }
    // showWarningMsg('身份证必须是15或18位数字')
    // return false

    if (!idcardReg.test(value)) {
      callback(new Error("身份证必须是15或18位数字"));
    } else {
      callback();
    }
  },

  checkTaxNo:function(rule, value, callback) {
    if (value.length == 0) {
        callback(new Error("请填写号码"));
        return false
    }
    if(value.length != 18 && value.length != 15){
        callback(new Error("请填写18位或15位号码"));
        return false
    }
    //十八位新码
    if(value.length == 18){
        if (!taxNo18Reg.test(value)) {
            callback(new Error("号码格式不正确"));
            return false;
        }
        var str = "0123456789ABCDEFGHJKLMNPQRTUWXY";
        var ws =[1,3,9,27,19,26,16,17,20,29,25,13,8,24,10,30,28];
        var codes = new Array();
        codes[0] = value.substr(0, value.length-1);
        codes[1] = value.substr(value.length - 1, value.length);
        var  sum = 0;
        for (var  i = 0; i < 17; i++) {
            sum += str.indexOf(codes[0].charAt(i)) * ws[i];
        }
        var  c18 = 31 - (sum % 31);
        if (c18 == 31) {
            c18 = 'Y';
        } else if (c18 == 30) {
            c18 = '0';
        }
        if (str.charAt(c18) != codes[1].charAt(0)) {
            callback(new Error("号码格式不正确"));
            return false;
        }
        callback();

    }
    //十五位编码
    else{
        if (!taxNo15Reg.test(value)) {
            callback(new Error("号码格式不正确"));
            return false;
        }
        callback();
    }

},

/**
 * 银行卡验证
 */
   checkBankCard:function(rule, value, callback) {
    let reg = /^([1-9]{1})(\d{14}|\d{15}|\d{16}|\d{18})$/
    if (!reg.test(value)) {
        callback(new Error(('请输入正确的银行卡号')));
    }
    callback();
}
};
// function showWarningMsg(message) {
//     Message({
//         message: message, // error.message,
//         type: 'warning',
//         // duration: 5 * 1000,
//     })
// }
