<template>
  <div>
    <div class="content">
      恭喜！支付成功，快去投标吧
    </div>
    
  </div>
</template>
<script>
import { subscribe } from '@/api'

export default {
  data () {
    return {
    }
  },
  created () {
  },
  methods: {
    
  },
}
</script>
<style lang="less" scoped>
.content{
    text-align: center;
    margin-top: 50px;
    font-size: 28px;
    color: green;
}
</style>
