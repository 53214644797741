<template>
  <div class="sear">
    <div class="bannercai">
      <div class="banxinfuwu"></div>
    </div>
    <div class="banxin bgwhite" style="position: relative">
      <div class="fenlei dflex" v-if="name">
        <span class="fentxt">关键词：
          <em class="emText">{{name}}</em>
        </span>
      </div>
      <!-- <div class="fenlei dflex">
        <span class="fentxt">分类：</span>
        <div>
          <el-radio-group  v-model="radioCatId"  @change="handleChange">
            <el-radio-button v-for="(item,index) in cateList" :key="index" :label="item.value"
                            >{{item.label}}</el-radio-button>
          </el-radio-group>
        </div>
      </div> -->
      <ul class="classify">
        <li @click="handleChange('')" :class="'' === radioCatId?'active':''">全部</li>
        <li v-for="item in cateList" :key="item.key" @click="handleChange(item.value)" :class="item.value === radioCatId?'active':''">{{item.label}}
        </li>
      </ul>
      <div class="infomain">
        <div class="info">
          <ul class="recommend-list">
            <template v-if="cList.length >0">
              <li v-for="(item,index) in cList" :key="index" :span="6">
                <div class="card card-two" @click="lookDetail(item.id)">
                  <div class="card-two-item" style="padding-top: 0;">
                    <img :src="'/info-admin'+item.logopic" class="card-two-item-img" style="" />
                    <div class="card-two-item-right">
                      <div class="card-two-item-right-title">{{ item.name }}</div>
                      <div class="card-two-item-right-addr"><i class="el-icon-location-outline icon-addr"></i>{{ item.addr }}
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </template>
            <template v-else>
              <el-empty description="没有找到相关数据"></el-empty>
            </template>

          </ul>
        </div>
        <el-pagination :current-page="pagination.page" :page-size="pagination.size" :total="pagination.total" class="pagination-style"
          layout="total, prev, pager, next, jumper" @current-change="handleCurrentChange">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { getAllListByType, getAllType } from '@/api/information'
import { INFO_URL } from '@/config/globalConfig'

export default {
  data() {
    return {
      baseUrl: INFO_URL,
      pagination: {
        total: 0,
        size: 10,
        page: 1
      },
      radioCatId: '',
      name: '',
      catId: '',
      cList: [],
      cateList: []
    }
  },
  watch: {
    // 利用watch方法检测路由变化：
    $route: function (to, from) {
      if (to.fullPath !== from.fullPath) {
        this.name = ''
        if (typeof to.query.keywords !== 'undefined' || to.query.keywords !== '') {
          this.name = to.query.keywords
        }

        this.catId = ''
        this.radioCatId = ''
        if (typeof to.query.catId !== 'undefined' || to.query.catId !== '') {
          this.catId = to.query.catId
          this.radioCatId = this.catId
        }
        this.getAllType()
        this.getAllTypeListNoTop()
      }
    }
  },
  created() {
    this.name = ''
    if (typeof this.$route.query.keywords !== 'undefined' || this.$route.query.keywords !== '') {
      this.name = this.$route.query.keywords
    }

    this.catId = ''
    this.radioCatId = ''
    if (typeof this.$route.query.catId !== 'undefined' || this.$route.query.catId !== '') {
      this.catId = this.$route.query.catId
      this.radioCatId = this.catId
    }
    this.getAllType()
    this.getAllTypeListNoTop()
  },
  methods: {
    getAllType() {
      let that = this
      that.cateList = []
      getAllType().then((res) => {
        let result = res.data
        for (let key in result) {
          that.cateList.push({ value: key, label: result[key].slice(0, 2) })
        }
      })
    },
    lookDetail(id) {
      this.$router.push({
        path: '/comDesc',
        query: {
          id: id
        }
      })
    },
    handleSizeChange(val) {
      this.pagination.size = val
      this.pagination.page = 1
      this.getAllTypeListNoTop()
    },
    handleCurrentChange(val) {
      this.pagination.page = val
      this.getAllTypeListNoTop()
    },
    getAllTypeListNoTop() {
      let that = this
      that.cList = []
      let requestParam = {
        name: that.name,
        companytype: that.catId,
        pageIndex: that.pagination.page,
        pageSize: that.pagination.size
      }
      getAllListByType(requestParam).then((res) => {
        that.cList = res.data.content
        this.pagination.total = res.data.totalElements
      })
    },
    handleChange(val) {
      this.catId = val
      this.radioCatId = val
      this.$router.push({
        name: 'InformationSearch',
        query: {
          keywords: this.name,
          catId: this.catId
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import url('../../assets/common.less');

.recommend-list {
  padding: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 20px;
}

.classify {
  position: absolute;
  top: 20px;
  right: -90px;
  li {
    background-color: #fff;
    font-size: 14px;
    padding: 10px 20px;
    cursor: pointer;
    &:hover {
      color: @primary;
    }
    &.active {
      background-color: @primary;
      color: #fff;
    }
  }
}

.banxin {
  max-width: 1190px;
  margin: 0 auto;
  padding-top: 20px;
}

.sear {
  background: #f1f1f1;
  padding-bottom: 20px;

  .pagination-style {
    text-align: right;
    margin-right: 20px;
    border-top: 1px solid #f1f1f1;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .bgwhite {
    background: #fff;
  }

  .fenlei {
    padding-left: 10px;
    border-bottom: 1px solid #f1f1f1;
    padding-bottom: 20px;
  }

  .fentxt {
    font-size: 14px;
    color: #666;
    margin-right: 15px;
    line-height: 40px;
    .emText {
      font-style: normal;
      background-color: @primary;
      color: #fff;
      padding: 5px 10px;
      letter-spacing: 1px;
    }
  }

  .liebiao {
    color: @hui;
    font-size: 14px;
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 20px;

    li {
      line-height: 60px;
      height: 60px;
      padding: 10px;
      vertical-align: middle;
      border-bottom: 1px solid #f1f1f1;

      > div {
        margin-right: 20px;
      }

      > div:last-child {
        margin-right: 0;
      }
    }

    .imglogo {
      width: 60px;
      height: 60px;
    }

    .lie-logo {
      margin-right: 20px;
    }

    .lie-title {
      margin-right: 20px;
      color: @primary;
    }
  }
}

.bannercai {
  width: 100%;
  height: 325px;
  background: url(../../assets/imgs/bannercai.jpg) 50% no-repeat;
  background-size: cover;
}

.info {
  background-color: #b4d2d3;

  .info-title {
    font-size: 16px;
    line-height: 19px;
    padding-bottom: 12px;
    color: @hui;
    padding-left: 6px;
    border-bottom: 1px solid #f1f1f1;

    .l-style {
      display: inline-block;
      width: 4px;
      height: 18px;
      background: @primary;
      margin-right: 6px;
      border-radius: 4px;
    }
  }

  .el-row {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .card {
    height: 200px;
    position: relative;
    border-right: 1px solid #f1f1f1;
    border-bottom: 1px solid #f1f1f1;
    cursor: pointer;
    margin-left: 20px;
    background-color: #fff;
  }

  .el-col-6 {
    width: 16.6%;
  }

  .card-two {
    overflow: hidden;
    border: 1px solid #f1f1f1;
    margin-left: 0;
    border-radius: 20px;
    &-item {
      text-align: center;
      padding: 20px 0;
      padding-top: 30px;

      &-img {
        margin-bottom: 16px;
        height: 120px;
        width: 100%;
      }

      &-right {
        flex: 1;

        &-title {
          font-size: 12px;
          color: @hui;
          margin-bottom: 6px;
          white-space: nowrap;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &-addr {
          color: #9f9f9f;
          font-size: 12px;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
          padding: 0 5px;

          .icon-addr {
            margin-right: 4px;
            font-size: 16px;
          }
        }
      }

      &-value {
        width: 100%;
        font-size: 12px;
      }
    }

    &-item:last-child {
      border-bottom: none;
    }
  }

  .card-two:hover {
    box-shadow: 0 0 16px #aaa;
    // margin-top:-4px;
  }
}

.el-row .el-col:nth-child(6) .card {
  border-right: none;
}

.el-row .el-col:last-child .card {
  border-right: none;
}
</style>
