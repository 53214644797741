<template>
  <div>
    <div class="home">
      <!-- <img class="banner-img" :src="bannerImg" /> -->
      <div class="block">
        <!-- <span class="demonstration">Click 指示器触发</span> -->
        <el-carousel trigger="click" height="500px">
          <el-carousel-item v-for="(item, index) in lunboList" :key="index">
            <!-- <h3 class="small">{{ item }}</h3> -->
            <img class="banner-img" :src="item.url" />
            <router-link v-if="item.link_url != ''" :to="item.link_url" class="linkLun"></router-link>
          </el-carousel-item>
        </el-carousel>
      </div>

      <!-- 新版布局 -->
      <!-- 1f 导航卡 -->
      <div class="topOne" style="justify-content: space-around;">
        <div @click="navigation(index)" class="box" v-for="(item, index) in this.card" :key="index">
          <img :src="item.src" alt="" />
          <div class="boxMessage">
            <h3>{{ item.title }}</h3>
            <p>{{ item.introduce }}</p>
          </div>
        </div>
      </div>

      <!-- 2f 数据楼 -->
      <div class="topOne">
        <div class="left">
          <h4>综合服务</h4>
          <div class="left-con">
            <div class="con-item le">
              <div class="con-nav" v-for="(item, index) in sort" :key="index" @click="gofuwu(index)">
                <div style="width: 100%;
    display: flex;
    height: 100%;
    justify-content: center;align-items:center" :style="{'background-color':item.bgcolor}">
                  <div class="con-it">
                  <img :src="item.src" alt="" />
                  <p>{{ item.title }}</p>
                </div>
                </div>
              </div>
            </div>
            <div class="con-item ri">
              <div class="con-nav" v-for="(item, index) in sort1" :key="index" @click="gofuwu2(index)">
                <div style="width: 100%;
    display: flex;
    height: 100%;
    justify-content: center;align-items:center" :style="{'background-color':item.bgcolor}">
                  <div class="con-it">
                  <img :src="item.src" alt="" />
                  <p>{{ item.title }}</p>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="top" style="display: flex;height: 49%;justify-content: space-between;">
            <div class="price" style="width: 49.5%;background-color: #fff;">
            <h4>行情 <span @click="toMore(4)" style="margin-left: 77%;cursor: pointer;">更多<i class="el-icon-arrow-right"></i></span></h4>
            <div class="priceList">
              <div  v-for="(item,index) in priceList" :key="index" style="padding-left: 17px;">
                <div @click="toDetail(item)" class="hang"><span style="font-weight: bold;">·</span><span style="margin-left: 10px;">{{ item.title }}</span></div>
            </div>
            </div>
          </div>
          <div class="price" style="width: 49.5%;background-color: #fff;">
            <h4>品种 <span @click="toMore(1)" style="margin-left: 77%;cursor: pointer;">更多<i class="el-icon-arrow-right"></i></span></h4>
            <div class="priceList">
              <div  v-for="(item,index) in varietyList" :key="index" style="padding-left: 17px;">
                <div @click="toDetail(item)" class="hang"><span style="font-weight: bold;">·</span><span style="margin-left: 10px;">{{ item.title }}</span></div>
            </div>
            </div>
          </div>
          </div>
          <div class="bottom" style="display: flex;height: 49%;justify-content: space-between;">
            <div class="codex" style="width: 49.5%;background-color: #fff;">
            <h4>药典<span @click="toMoreCodex('codex')" style="margin-left: 77%;cursor: pointer;">更多<i class="el-icon-arrow-right"></i></span></h4>
            <div class="priceList">
              <div style="display:flex;flex-wrap: wrap;margin-left: 10px;">
                <div class="codexList"  v-for="(item,index) in codexList" :key="index" style="">
                <div @click="toDetail(item,'coden')" class="hang"><span style="font-weight: bold;">·</span><span style="margin-left: 10px;">{{ item.title }}</span></div>
            </div>
              </div>
            </div>
          </div>
          <div class="market" style="width: 49.5%;background-color: #fff;">
            <h4>市场价格 <span @click="toMore3()" style="margin-left: 70%;cursor: pointer;">更多<i class="el-icon-arrow-right"></i></span></h4>
            <div class="left-con">
            <div class="list-module">
              <el-table class="xh-table" :header-row-style="{'height':'30px'}" :row-style="{'height':'28px'}" :data="tableData" height="204px" style="width: 100%" stripe align="center">
                <el-table-column prop="mname" label="品名" align="center" width="60"></el-table-column>
                <el-table-column prop="mspec" label="规格" align="center" width="60"></el-table-column>
                <el-table-column prop="maddr" label="产地" width="60" align="center"></el-table-column>
                <el-table-column width="80" prop="currentPrice" align="center" label="价格"></el-table-column>
                <el-table-column width="80"  prop="market" label="市场" align="center"></el-table-column>
                <el-table-column width="90" align="center" prop="dayrange" label="日涨幅" sortable>
                  <template slot-scope="scoped">
                    <template v-if="
                        parseFloat(scoped.row.dayrange * 100).toFixed(2) < 0
                      ">
                      <div class="red el-icon-bottom">
                        {{
                          parseFloat(scoped.row.dayrange * 100).toFixed(2)
                        }}%
                      </div>
                    </template>
                    <template v-else-if="
                        parseFloat(scoped.row.dayrange * 100).toFixed(2) > 0
                      ">
                      <div class="green el-icon-top">
                        {{
                          parseFloat(scoped.row.dayrange * 100).toFixed(2)
                        }}%
                      </div>
                    </template>
                    <template v-else>
                      <div>
                        {{
                          parseFloat(scoped.row.dayrange * 100).toFixed(2)
                        }}%
                      </div>
                    </template>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          </div>
          </div>
        </div>
        
      </div>
      <div class="topOne" style="display: flex;">
          <div class="content" style="width: 49%;height: 500px;">
            <h4>供应信息<span @click="toMore2('first')" style="margin-left: 77%;cursor: pointer;">更多<i class="el-icon-arrow-right"></i></span></h4>
            <el-table class="xh-table" :data="dataList" height="400px" style="width: 100%" stripe align="center">
                <el-table-column prop="name" label="品名" align="center" width="100"></el-table-column>
                <el-table-column prop="spec" label="规格" align="center" width="80"></el-table-column>
                <el-table-column prop="addr" label="产地" align="center"></el-table-column>
                <el-table-column width="70" prop="price" align="center" label="价格"></el-table-column>
                <el-table-column width="100" prop="stockcount" label="数量" align="center"></el-table-column>
                <el-table-column width="115" prop="contacttel" label="电话" align="center"></el-table-column>
              </el-table>
            <!-- <div class="priceList" v-for="(item,index) in dataList" :key="index" style="padding-left: 17px;">
                <div @click="toDetail(item)" class="hang"><span style="font-weight: bold;">·</span><span style="margin-left: 10px;">{{ item.title }}</span></div>
            </div> -->
          </div>
          <div class="content" style="width: 49%;height: 500px;">
            <h4>求购信息<span @click="toMore2('second')" style="margin-left: 77%;cursor: pointer;">更多<i class="el-icon-arrow-right"></i></span></h4>
            <el-table class="xh-table" :data="dataList2" height="400px" style="width: 100%" stripe align="center">
                <el-table-column prop="name" label="品名" align="center" width="100"></el-table-column>
                <el-table-column prop="spec" label="规格" align="center" width="80"></el-table-column>
                <el-table-column prop="addr" label="产地" align="center"></el-table-column>
                <el-table-column width="70" prop="price" align="center" label="价格"></el-table-column>
                <el-table-column width="100" prop="stockcount" label="数量" align="center"></el-table-column>
                <el-table-column width="115" prop="contacttel" label="电话" align="center"></el-table-column>
              </el-table>
            <!-- <div class="priceList" v-for="(item,index) in dataList2" :key="index" style="padding-left: 17px;">
                <div @click="toDetail(item)" class="hang"><span style="font-weight: bold;">·</span><span style="margin-left: 10px;">{{ item.title }}</span></div>
            </div> -->
          </div>
        </div>
        <div class="" style="text-align: center;">
          <img src="@/assets/imgs/head.png" alt="" style="width: 1190px;height: 90px;">
        </div>
      <!-- 3f -->
      <div class="topOne">
        <div class="content">
          <div class="heads">
            <h4>招采投标</h4>
          </div>
          <div class="bodies">
            <div class="bodiesLeft">
              <div class="row-main">
                <ul class="row-title dflex">
                  <li class="ritem1">企业名称</li>
                  <li class="ritem2">品种</li>
                  <li class="ritem3">采购状态</li>
                  <li class="ritem4">报价剩余时间</li>
                  <li class="ritem5">操作</li>
                </ul>
                <!-- @mouseenter="mEnter" @mouseleave="mLeave" -->
                <div class="scroll_parent_box" @mouseenter="mEnter" @mouseleave="mLeave">
                  <div class="scroll_list" :style="{ transform: `translate(0px,-${scrollTop}px)` }">
                    <div ref="scrollItemBox">
                      <div class="scroll_item" v-for="(item, index) in toubiaoList" :key="index">
                        <div class="item-row item-row1">
                          <div class="ritem1">{{ item.cname }}</div>
                          <div class="ritem2">
                            {{ item.mname }}、阿胶、平地、阿胶、平地
                          </div>
                          <div class="ritem3">
                            {{ bidStatusWatch(item.bidStatus) }}
                          </div>
                          <div class="ritem4">
                            {{ countdownValueArr[index] }}
                          </div>
                          <div class="ritem5">
                            <el-button type="primary" size="small" plain @click="detailBtn(item.inquiryid)"
                              class="btnhover">
                              报价
                            </el-button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- fz s -->
                    <div ref="scrollItemBox">
                      <div class="scroll_item" v-for="(item, index) in toubiaoList" :key="index">
                        <div class="item-row item-row1">
                          <div class="ritem1">{{ item.cname }}</div>
                          <div class="ritem2">
                            {{ item.mname }}、阿胶、平地、阿胶、平地
                          </div>
                          <div class="ritem3">
                            {{ bidStatusWatch(item.bidStatus) }}
                          </div>
                          <div class="ritem4">
                            {{ countdownValueArr[index] }}
                          </div>
                          <div class="ritem5">
                            <el-button type="primary" size="small" plain @click="detailBtn(item.inquiryid)"
                              class="btnhover">
                              报价
                            </el-button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- fz e -->
                    <!-- <div v-html="copyHtml" class="fuzhi"></div> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="bodiesRight">
              <div class="row-main">
                <p class="row-title">最新中标状态</p>
                <!-- @mouseenter="mEnter2" @mouseleave="mLeave2" -->
                <div class="scroll_parent_box" @mouseenter="mEnter2" @mouseleave="mLeave2">
                  <div class="scroll_list" :style="{ transform: `translate(0px,-${scrollTop2}px)` }">
                    <div ref="scrollItemBox2">
                      <div class="scroll_item" v-for="(item, index) in listData" :key="index">
                        <div class="item-row item-row1">
                          <div class="ritem1">{{ item.cname }}</div>
                          <div class="ritem2">中标</div>
                          <div class="ritem3">{{ item.mname }}</div>
                        </div>
                        <div class="item-row item-row2">
                          <div class="ritem4">{{ item.uname }}</div>
                          <div class="ritem5">{{ item.zbDate }}</div>
                        </div>
                      </div>
                    </div>
                    <div v-html="copyHtml2" class="fuzhi2"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 4f -->
      <div class="topOne" style="display: flex;">
          <div class="content" style="width: 32%;height: 300px;">
            <h4>种植技术<span @click="toMore(11)" style="margin-left: 67%;cursor: pointer;">更多<i class="el-icon-arrow-right"></i></span></h4>
            <div class="priceList" v-for="(item,index) in plantTecList" :key="index" style="padding-left: 17px;">
                <div @click="toDetail(item)" class="hang"><span style="font-weight: bold;">·</span><span style="margin-left: 10px;">{{ item.title }}</span></div>
            </div>
          </div>
          <div class="content" style="width: 32%;height: 300px;">
            <h4>真伪鉴别<span @click="toMore(12)" style="margin-left: 67%;cursor: pointer;">更多<i class="el-icon-arrow-right"></i></span></h4>
            <div class="priceList" v-for="(item,index) in identificationList" :key="index" style="padding-left: 17px;">
                <div @click="toDetail(item)" class="hang"><span style="font-weight: bold;">·</span><span style="margin-left: 10px;">{{ item.title }}</span></div>
            </div>
          </div>
          <div class="content" style="width: 32%;height: 300px;">
            <h4>新闻资讯<span @click="toMore(13)" style="margin-left: 67%;cursor: pointer;">更多<i class="el-icon-arrow-right"></i></span></h4>
            <div class="priceList">
              <div  v-for="(item,index) in newsList" :key="index" style="padding-left: 17px;">
                <div @click="toDetail(item)" class="hang"><span style="font-weight: bold;">·</span><span style="margin-left: 10px;">{{ item.title }}</span></div>
            </div>
            </div>
          </div>
        </div>
        <div class="" style="text-align: center;margin-bottom: 20px;">
          <img src="@/assets/imgs/head.png" alt="" style="width: 1190px;height: 90px;">
        </div>
      <div class="topOne" v-if="show">
        <div class="content">
          <div class="heads">
            <h4>中药材产业链</h4>
          </div>
          <div class="bodies bodiesItem">
            <div class="item top">
              <div class="item-con" v-for="item in chanList" :key="item">
                <h3>{{ item }}</h3>
                <p>提供最新与优质的种子种苗</p>
                <p>采购供应商，为您提供在线询价</p>
              </div>
            </div>
            <!-- <div class="item bottom">
                        <div class="item-con" v-for="item in 4" :key="item"></div>
                    </div> -->
          </div>
        </div>
      </div>
      <div class="weichat">
        <ul class="weilist flex">
          <li class="flex" v-for="(item, index) in qList" :key="index">
            <div class="imgdiv"><img :src="item.url" alt="" /></div>
            <div class="weir">
              <div>{{ item.cname }}</div>
              <div>
                电话：<span>{{ item.tel }}</span>
              </div>
              <div>
                邮箱：<span>{{ item.eMail }}</span>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
  let timer1 = null
  let timer2 = null
  import {
    getprices,
    getRiseData
  } from '@/api/price'
  import {
    B2B2C_URL,
    B2B_URL
  } from '@/config/globalConfig'
  import {
    getLunbotu,
    getAd,
    saveView,
    getUserInfo
  } from '@/api'
  import {
    getInquiryUser,
    getInqueryMaterialsList
  } from '@/api/bid'
  import {
    getList,
    getSeekList
  } from '@/api/gq'
  import {zhishuList,getTopN} from '@/api/price'

import { BackgroundStyle } from 'quill'
  export default {
    components: {
      
    },
    data() {
      return {
        toubiaoList: [],
        listData: [],
        timer: [],
        countdownValue: '',
        countdownValueArr: [],
        priceList:[
          {
            name:'第一条',
            id:1
          },
          {
            name:'第二条',
            id:2
          },
          {
            name:'第三条',
            id:3
          },
          {
            name:'第三条',
            id:4
          },
          {
            name:'第三条',
            id:5
          },
          {
            name:'第三条',
            id:6
          },
           {
            name:'第三条',
            id:6
          },
            {
            name:'第三条',
            id:6
          },
            {
            name:'第三条',
            id:6
          },
        ],
        varietyList:[],
        codexList:[],
        plantTecList:[],
        identificationList:[],
        newsList:[],
        isLogon: true,
        entryType: '',
        checkFlag: '',
        userInfo: '',
        show: false,
        bidStatusObj: [{
            value: 0,
            label: '投标中'
          },
          {
            value: 1,
            label: '已开标'
          },
          {
            value: 2,
            label: '议价中'
          },
          {
            value: 3,
            label: '议价结束'
          },
          {
            value: 4,
            label: '中标'
          },
          {
            value: 5,
            label: '结束'
          },
        ],
        scrollTop: 0, //列表滚动高度
        speed: 25, //滚动的速度
        scrollTop2: 0, //列表滚动高度
        speed2: 25, //滚动的速度
        copyHtml: '',
        copyHtml2: '',
        chanList: [
          '种子种苗',
          '中药材种植',
          '中药材初加工',
          '中药制药',
          '供应链金融',
          '中药安全溯源',
          '中药大健康',
          '药品流通',
        ],
        // 1f
        card: [{
            title: '招标投标',
            introduce: '实现信息资源共享',
            src: require('@/assets/imgs/zbtb.svg'),
          },
          // {
          //   title: '供应链金融',
          //   introduce: '专业 安全 诚信',
          //   src: require('@/assets/imgs/zbtb.svg'),
          // },
          {
            title: '中药产业商城',
            introduce: '专业的中药材交易平台',
            src: require('@/assets/imgs/yunsou.svg'),
          },
          {
            title: '大健康商城',
            introduce: '零差价健康产业链',
            src: require('@/assets/imgs/shangdian.svg'),
          },
        ],
        // 2f
        sort: [{
            title: '花茶企业',
            src: require('@/assets/imgs/chabei.svg'),
            bgcolor:'#3BAAF1'
          },
          {
            title: '机械设备',
            src: require('@/assets/imgs/jixie.svg'),
            bgcolor:'#FFC107'
          },
          {
            title: '包装材料',
            src: require('@/assets/imgs/hezi.svg'),
            bgcolor:'#01BBD4'
          },
        ],
        sort1: [{
            title: '传媒设计',
            src: require('@/assets/imgs/shexiang.svg'),
            bgcolor:'#2CCA2F'
          },
          {
            title: '金融服务',
            src: require('@/assets/imgs/jinrong.svg'),
            bgcolor:'#4371C6'
          },
          {
            title: '快运物流',
            src: require('@/assets/imgs/wuliu2.svg'),
            bgcolor:'#F54337'
          },
        ],
        tableData: [],
        form: {
          keyword: '',
          type: 0,
          dateFlag: 0,
          pageIndex: 1,
          pageSize: 10,
          name: '',
          name2: '',
          qstatus: 2,
          payment: 2,
        },
        bannerImg: require('@/assets/imgs/ban1.jpg'),
        img1: require('@/assets/imgs/img1.jpg'),
        img2: require('@/assets/imgs/img2.png'),
        img3: require('@/assets/imgs/img3.jpg'),
        img4: require('@/assets/imgs/img4.jpg'),
        img5: require('@/assets/imgs/img5.png'),
        img6: require('@/assets/imgs/img6.png'),
        img7: require('@/assets/imgs/img7.png'),
        imgQr: require('@/assets/imgs/qr.png'),
        isShow: true,
        adList: [],
        lunboList: [],
        dataList: [],
        dataList2: [],
        qList: [{
            url: require('@/assets/imgs/qiwei.png'),
            cname: '安徽亳泰中药科技有限公司',
            tel: '0558-8922888',
            eMail: 'btzy@bzcjhg.com',
          },
          {
            url: require('@/assets/imgs/qiwei.png'),
            cname: '安徽昊祥药业有限公司',
            tel: '0558-5124777',
            eMail: 'hxyy@bzcjhg.com',
          },
          {
            url: require('@/assets/imgs/qiwei.png'),
            cname: '亳州兴禾农业发展有限公司',
            tel: '0558-5625715',
            eMail: 'bzxh@bzcjhg.com',
          },
          {
            url: require('@/assets/imgs/qiwei.png'),
            cname: '安徽省亳州中医药集团有限公司',
            tel: '0558-5588506',
            eMail: 'bzxh@bzcjhg.com',
          },
          {
            url: require('@/assets/imgs/qiwei.png'),
            cname: '安徽世仁医药有限公司',
            tel: '13866167196',
            eMail: 'shirenyy@bzcjhg.com',
          },
          {
            url: require('@/assets/imgs/qiwei.png'),
            cname: '安徽元三健康产业发展有限公司',
            tel: '0558-8929888',
            eMail: 'ysjk@bzcjhg.com',
          },
          {
            url: require('@/assets/imgs/qiwei.png'),
            cname: '亳州城建汉广中医药初加工有限公司',
            tel: '0558-5660609',
            eMail: 'cjhg@bzcjhg.com',
          },
        ],
        page:1,
        size:6,
        total:0
      }
    },
    beforeDestroy() {
      clearInterval(timer1)
      clearInterval(timer2)
    },
    created() {
      this.getprices()
      this.getLunbotu()
      this.getAd()
      this.getInqueryMaterialsList()
      this.initScroll()
      this.initScroll2()
      this.getUserInfo()
      this.getInquiryUser()
      this.getPriceList()
      this.getGqlist()
    },
    computed: {
      bidStatusWatch() {
        return function (bidStatus) {
          if (bidStatus == null) {
            return ''
          }
          if (bidStatus == 7) {
            return ''
          }
          return this.bidStatusObj[bidStatus]['label']
        }
      },
    },
    methods: {
      toMore (type) {
        this.$router.push({
        name: "listMore",
        query: {
          type: type,
        },
      });
    },
    toMoreCodex (type) {
        this.$router.push({
        name: "listMore",
        query: {
          type: type,
        },
      });
    },
    toMore2(val){
        this.$router.push({
        name: 'gq',
        query:{param:val}
      })
    },
    toMore3(){
        this.$router.push({
        name: 'price',
      })
    },
      getPriceList () {
      zhishuList({
        newstype: 4,
        page: this.page,
        limit: this.size,
      }).then((res) => {
        this.priceList = res.data.content || [];
        // this.total = res.data.totalElements;
      });
      zhishuList({
        newstype: 1,
        page: this.page,
        limit: this.size,
      }).then((res) => {
        this.varietyList = res.data.content || [];
      });
      getTopN({topN:12}).then(res=>{
        this.codexList = res.data
      })
      zhishuList({
        newstype: 11,
        page: this.page,
        limit: this.size,
      }).then((res) => {
        this.plantTecList = res.data.content || [];
      });
      zhishuList({
        newstype: 12,
        page: this.page,
        limit: this.size,
      }).then((res) => {
        this.identificationList = res.data.content || [];
      });
      zhishuList({
        newstype: 13,
        page: this.page,
        limit: this.size,
      }).then((res) => {
        this.newsList = res.data.content || [];
      });
    },
    getGqlist(){
      getList(
        {
        page: 1,
        pageSize: 20,
      }).then((res) => {
        if (res.code == 200) {
          this.dataList = res.data.data;
      
        }
      });
      getSeekList({
        page: 1,
        pageSize: 20,
      }).then(res=>{
        if (res.code == 200) {
          this.dataList2 = res.data.data;
        }
      })
    },
    toDetail (row,type) {
     if(type!='coden'){
       type = 'news'
     }
      this.$router.push({
        name: "newsDetail",
        query: {
          id: row.id,
          type: type
        },
      });
    },
      // 综合服务跳转
      gofuwu(index) {
        // 花茶企业
        if (index == 0) {
          this.$router.push({
            path: '/informationSearch?keywords=&catId=1',
          })
        }
        // 机械设备
        if (index == 1) {
          this.$router.push({
            path: '/informationSearch?keywords=&catId=8',
          })
        }
        // 包装材料
        if (index == 2) {
          this.$router.push({
            path: '/informationSearch?keywords=&catId=5',
          })
        }
      },
      gofuwu2(index) {
        // 传媒设计
        if (index == 0) {
          this.$router.push({
            path: '/informationSearch?keywords=&catId=3',
          })
        }
        // 金融服务
        if (index == 1) {
          this.$router.push({
            path: '/informationSearch?keywords=&catId=11',
          })
        }
        // 快运物流
        if (index == 2) {
          this.$router.push({
            path: '/informationSearch?keywords=&catId=7',
          })
        }
      },
      open() {
        this.entryType = ''
        this.$router.push('/login')
      },
      getUserInfo() {
        getUserInfo().then((res) => {
          this.$store.commit('userInfo', res.data || {})
        })
      },
      lookBtn(row) {
        getUserInfo().then((res) => {
          if (res.code == 200) {
            this.$router.push({
              name: "priceDetail",
              query: {
                id: row.id,
                mname: row.mname,
                mspec: row.mspec,
                maddr: row.maddr,
                market: row.market,
              },
            });
          } else {
            this.$notify({
              title: '信息',
              message: '请先登录以获取更多信息',
              type: 'info',
              offset: 100,
              duration: 2600
            });
          }
        })
      },
      // 招投标
      getInqueryMaterialsList() {
        let that = this
        getInqueryMaterialsList({
          cname: that.form.name2,
          mname: that.form.name,
          type: that.form.type,
          qstatus: that.form.qstatus,
          payment: that.form.payment,
          pageIndex: that.form.pageIndex,
          pageSize: that.form.pageSize,
        }).then((res) => {
          this.clearCurTimer()
          let datas = res.data.content
          let vals = []
          for (var i = 0; i < datas.length; i++) {
            if (datas[i].pics != null) {
              let picArr = datas[i].pics.split(',')
              let r = picArr.filter(function (s) {
                return s && s.trim()
              })
              datas[i].pics = r
            } else {
              datas[i].pics = []
            }
            vals.push(datas[i].deadline)
          }
          that.countdown(vals)
          that.toubiaoList = datas
          this.$nextTick(() => {
            this.copyHtml = this.$refs.scrollItemBox.innerHTML
          })
        })
      },
      // 倒计时
      countdown(vals) {
        let that = this
        vals.forEach((v, i) => {
          that.timer[i] = setInterval(() => {
            this.transferTime(v, i)
          }, 1000)
        })
      },
      transferTime(dateValue, index) {
        let nowTime = new Date()
        let targetTime = new Date(Date.parse(dateValue.replace(/-/g, '/')))
        //得到时间戳
        let timeStamp = targetTime - nowTime
        //天数
        let day = parseInt(timeStamp / (1000 * 60 * 60 * 24))
        //小时
        let hour = parseInt(
          (timeStamp % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        )
        //分钟
        let minute = parseInt((timeStamp % (1000 * 60 * 60)) / (1000 * 60))
        //秒
        let second = parseInt((timeStamp % (1000 * 60)) / 1000)
        // var date = document.getElementById('info');
        //向info盒子中添加倒计时
        if (timeStamp < 0) {
          this.$set(this.countdownValueArr, index, '已截止')
        } else {
          this.$set(
            this.countdownValueArr,
            index,
            day + '天' + hour + '小时' + minute + '分钟' + second + '秒'
          )
        }
        //this.countdownValueArr[index] =  day + '天' + hour + '小时' + minute + '分钟' + second + '秒'
      },
      clearCurTimer() {
        this.timer.forEach((v) => {
          clearInterval(v)
        })
      },
      getprices() {
        let that = this
        getRiseData({
          page: 1,
          pageSize: 3,
          timeType: 0,
        }).then((res) => {
          that.tableData = res.data
        })
      },
      // 最新中标动态
      getInquiryUser() {
        let that = this
        getInquiryUser(20).then((res) => {
          that.listData = res.data
          this.$nextTick(() => {
            this.copyHtml2 = this.$refs.scrollItemBox2.innerHTML
          })
        })
      },
      // 报价
      detailBtn(inquiryId) {
        if (!this.userInfo.nickname) {
          this.$router.push('/login')
        } else {
          if (this.userInfo.checkFlag == -1) {
            this.$message.warning('您还没有认证资质，请认证资质哦！')
            setTimeout(() => {
              this.$router.push({
                name: 'qualifications',
              })
            }, 1000)
            return
          } else if (this.userInfo.checkFlag == 0) {
            this.$message.warning('您的资质正在审核中，请等候！')
            setTimeout(() => {
              this.$router.push({
                name: 'qualifications',
              })
            }, 1000)
            return
          } else if (this.userInfo.checkFlag == 2) {
            this.$message.warning('您的资质认证未通过，请重新认证！')
            setTimeout(() => {
              this.$router.push({
                name: 'qualifications',
              })
            }, 1000)
            return
          }
          this.$router.push({
            name: 'bidDetail',
            query: {
              id: inquiryId,
            },
          })
        }
      },
      initScroll() {
        this.$nextTick(() => {
          this.startScroll()
        })
      },
      initScroll2() {
        this.$nextTick(() => {
          this.startScroll2()
        })
      },
      // 鼠标移入停止滚动
      mEnter() {
        clearInterval(timer1)
      },
      mEnter2() {
        clearInterval(timer2)
      },

      // 鼠标移出继续滚动
      mLeave() {
        this.startScroll()
      },
      mLeave2() {
        this.startScroll2()
      },

      // 开始滚动
      startScroll() {
        timer1 = setInterval(this.scroll, this.speed)
      },
      startScroll2() {
        timer2 = setInterval(this.scroll2, this.speed2)
      },
      // 滚动处理方法
      scroll() {
        this.scrollTop++
        // 获取需要滚动的盒子的高度
        this.$nextTick(() => {
          let scrollItemBox = this.$refs.scrollItemBox.offsetHeight
          if (this.scrollTop >= scrollItemBox) {
            this.scrollTop = 0
          }
        })
      },
      scroll2() {
        this.scrollTop2++
        // 获取需要滚动的盒子的高度
        this.$nextTick(() => {
          let scrollItemBox2 = this.$refs.scrollItemBox2.offsetHeight
          if (this.scrollTop2 >= scrollItemBox2) {
            this.scrollTop2 = 0
          }
        })
      },
      navigation(i) {
        switch (i) {
          case 0:
            this.$router.push({
              name: 'bid',
            })
            break
          // case 1:
          //   this.$router.push({
          //     name: 'finance',
          //   })
          //   break
          case 1:
            window.open(B2B_URL, '_blank')
            break
          case 2:
            window.open(B2B2C_URL, '_blank')
            break
        }
      },
      // 获取悬浮广告
      getAd() {
        getAd('5').then((res) => {
          if (res.code === 200) {
            this.adList = res.data
          }
        })
      },
      goUrl(name) {
        if (name == 'b2b') {
          window.open(B2B2C_URL, '_blank')
          return
        }
        if (name == 'ddyc') {
          this.$message.warning('项目开发中')
          return
        }
        if (name === 'b2b2c') {
          window.open(B2B_URL, '_blank')
          return
        }
        this.$router.push({
          name: name,
        })
      },
      closeAd() {
        this.isShow = false
      },
      Ad() {
        window.open(this.adUrl, '_blank')
      },
      // 轮播图
      getLunbotu() {
        getLunbotu({
          limitN: 3,
          location: 4
        }).then((res) => {
          if (res.code == 200) {
            this.lunboList = res.data
          }
        })
      },
    },
    watch: {
      $route: {
        handler(val) {
          this.activeValue = val.name
        },
        immediate: true,
        deep: true,
      },
      '$store.state.userInfo': {
        handler(val) {
          this.userInfo = val
        },
        deep: true,
      },
    },
  }
</script>

<style lang="less" scoped>
  @import url('../../assets/common.less');
  .el-main{
      line-height: 30px !important;
    }
  .block {
    width: 100%;
    min-width: 1190px;
    margin: 0 auto;
    height: 500px;
    text-align: center;
  }

  .ad {
    width: 150px;
    // height: 348px;
    position: fixed;
    left: 10px;
    top: 230px;
    z-index: 999;

    a {
      display: block;
      width: 100%;
      height: 90px;
      margin-bottom: 2px;
    }

    img {
      width: 100%;
      height: 500px;
      cursor: pointer;
    }

    .el-icon-close {
      position: absolute;
      right: -11px;
      top: -23px;
      cursor: pointer;
      z-index: 999;
    }
  }

  .home {
    background-color: rgb(238, 238, 238);

    .topOne {
      width: 1190px;
      margin: 20px auto;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      // justify-content: space-around;
      .box {
        width: 23%;
        height: 120px;
        background-color: #fff;
        border: 1px solid #ddd;
        box-shadow: 0 0 2px 2px rgb(235, 235, 235);
        border-radius: 3px;
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;

        img {
          margin-left: 20px;
          margin-right: 8px;
          width: 80px;
          height: 60px;
        }

        .boxMessage {
          h3 {
            font-size: 18px;
          }

          p {
            margin-top: 5px;
            font-size: 14px;
            color: #8b8b8b;
          }
        }
      }

      .left {
        width: 26%;
        height: 500px;
        border-top: 3px solid rgb(136, 201, 198);
        background-color: #fff;

        .left-con {
          width: 100%;
          height: 453px;
          color: #fff;
          background-color: #fff;
          display: flex;
          flex-direction: row;
          justify-content: space-around;

          .le {
            margin-left: 8px !important;
          }

          .ri {
            margin-right: 8px !important;
          }

          .con-item {
            margin: 15px 0;
            width: 140px;
            height: 428px;
            // background-color: #eee;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .con-nav {
              margin: 0 auto;
              width: 130px;
              height: 133px;
              background-color: rgb(210, 235, 234);
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: space-around;
              cursor: pointer;

              &:hover {
                background-color: rgb(202, 255, 252);
                box-shadow: 0 0 4px 2px rgb(219, 255, 254);
              }

              .con-it {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-around;

                img {
                  width: 50px;
                  height: 50px;
                }

                p {
                  font-size: 16px;
                  font-weight: bold;
                  margin-top: 10px;
                }
              }
            }
          }

          .list-module {
            padding: 10px 15px;

            .el-table {
              font-size: 12px;
              color: #666;
            }

            .xh-table {
              /deep/ th {
                background-color: #f1f1f1;
                color: #666;
                height: 40px;
                font-size: 12px;
                padding-top: 5px;
                padding-bottom: 5px;
              }
              // /deep/ .el-table_header {
              //   height: 30px;
              // }
            }
          }
        }
      }

      h4 {
        font-size: 16px;
        margin: 10px 5px;
        padding-left: 10px;
        border-left: 3px solid rgb(136, 201, 198);
      }
      .priceList{
          max-height: 188px;
          overflow: auto;
          .codexList{
            // padding-left: 17px;
            width: 50%;
          }
          .hang{
            width: 50%;
            margin-bottom: 10px;
            cursor: pointer;
          }
        }

      .right {
        width: 73%;
        height: 500px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        // background-color: #fff;
        // border-top: 3px solid rgb(136, 201, 198);
      }
      .price{
        border-top: 3px solid rgb(136, 201, 198);
      }
      .content {
        width: 100%;
        // height: 500px;
        border-top: 3px solid rgb(46, 154, 255);
        background-color: #fff;
      
        .heads {
          width: 100%;
          height: 40px;
          background-color: #fff;
        }

        .bodies {
          margin-top: 10px;
          width: 100%;
          // height: 450px;
          background-color: #fff;
          display: flex;
          // flex-direction: row;
          padding-bottom: 20px;

          .bodiesLeft {
            width: 69%;
            height: 100%;
            background-color: #eee;
          }
        }

        .bodiesItem {
          flex-direction: column;
          align-content: space-around;
          padding: 10px;
          box-sizing: border-box;
          padding-bottom: 20px;

          .item {
            margin-top: 15px;
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            flex-wrap: wrap;

            .item-con {
              width: 22%;
              height: 100%;
              border: 1px solid #f1f1f1;
              height: 232px;
              text-align: center;
              padding-top: 130px;
              box-sizing: border-box;

              h3 {
                color: #656565;
                margin-bottom: 15px;
              }

              p {
                color: #9e9e9e;
              }
            }

            .item-con:nth-child(1),
            .item-con:nth-child(2),
            .item-con:nth-child(3),
            .item-con:nth-child(4) {
              margin-bottom: 38px;
            }
          }
        }
      }
    }
    .gqqg{
      width: 1190px;
      margin: 20px auto;
    }
    .el-carousel__item h3 {
      color: #475669;
      font-size: 14px;
      opacity: 0.75;
      line-height: 150px;
      margin: 0;
    }

    .el-carousel__item:nth-child(2n) {
      background-color: #eeeeee;
    }
    
    .el-carousel__item:nth-child(2n + 1) {
      background-color: #eeeeee;
    }

    .banner-img {
      // width: 1980px;
      height: 500px;
    }

    .footer {
      background-color: #222222;
      color: #fff;
      height: 200px;
      text-align: center;

      div {
        font-size: 12px;
        font-family: 微软雅黑;
      }
    }

    .introduce {
      max-width: 1190px;
      margin: auto;
      padding: 100px 0 30px;

      &-tit {
        color: rgb(51, 51, 51);
        font-size: 28px;
        font-weight: bold;
        margin-bottom: 6px;
        text-align: center;
      }

      &-subtit {
        color: @hui;
        font-size: 16px;
        text-align: center;
        font-family: 微软雅黑;
      }

      &-con {
        line-height: 30px;
        color: @hui;
        font-size: 16px;
        line-height: 30px;
        display: flex;
        //align-items: center;
        justify-content: space-between;
        margin-top: 50px;

        &-value,
        &-img {
          width: 560px;
        }

        &-value {
          padding-top: 20px;

          span {
            font-size: 30px;
            margin-bottom: 20px;
            font-family: 微软雅黑;
            color: #000;
          }

          div {
            font-size: 18px;
          }

          .el-button {
            float: right;
            padding: 15px 55px;
            border-radius: 10px;
            font-size: 18px;
            background-color: @primary;
            margin-top: 160px;
          }
        }

        &-img {
          width: 585px;
          height: 354px;
        }
      }
    }

    .card {
      margin: auto;

      .card-tit {
        font-size: 30px;
        font-weight: bold;
        text-align: center;
        margin-bottom: 20px;
      }

      .card-subtit {
        font-size: 18px;
        color: #999;
        text-align: center;
      }

      .card-con {
        display: flex;
        margin-top: 100px;
      }

      .card-img {
        width: 500px;
      }

      .card-value {
        flex: 1;
        margin-left: 80px;
        font-size: 18px;
        position: relative;
      }

      .card-value1 {
        margin-right: 80px;
        margin-left: 0;
      }

      .btn-item {
        position: absolute;
        right: 0;
        margin-top: 150px;
      }
    }

    .card1 {
      padding: 100px calc((100% - 1100px) / 2) 30px;
      background: rgba(245, 245, 245, 1);

      .sub-title {
        font-size: 28px;
        line-height: 56px;
      }
    }
  }

  .linkLun {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }

  .bodiesLeft {
    .scroll_parent_box {
      overflow: hidden;
      box-sizing: border-box;
    }

    .scroll_list {
      transition: all 0ms ease-in 0s;
    }

    .scroll_item {
      border-bottom: 1px dashed #dcdfe6;
      font-size: 14px;
      height: 57px;
      line-height: 57px;
      box-sizing: border-box;
    }

    .row-main {
      font-size: 14px;
      box-sizing: border-box;
      background-color: #ffffff;
      height: 450px;
      overflow: hidden;

      .row-title {
        font-size: 16px;
        background-color: #f9f9f9;

        li {
          height: 40px;
          line-height: 40px;
          text-align: center;
        }

        .ritem1 {
          width: 58%;
        }

        .ritem2 {
          width: 55%;
          margin-left: 8px;
        }

        .ritem3 {
          width: 29.1%;
        }

        .ritem4 {
          width: 45%;
        }

        .ritem5 {
          width: 25%;
        }
      }

      .item-row {
        display: flex;
        text-align: center;

        .ritem1 {
          width: 58%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          vertical-align: middle;
        }

        .ritem2 {
          width: 55%;
          // margin-left: 8.5%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          vertical-align: middle;
          margin-left: 8px;
        }

        .ritem3 {
          width: 29.1%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          text-align: center;
          vertical-align: middle;
        }

        .ritem4 {
          width: 45%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          vertical-align: middle;
        }

        .ritem5 {
          text-align: center;
          width: 25%;
        }
      }
    }

    .scroll_item:nth-child(2n) {
      background-color: #fafafa;
    }
  }

  .weichat {
    min-width: 1210px;
    background: #fff;
    padding-top: 30px;

    .weilist {
      margin: 0 auto;
      max-width: 1200px;
      flex-wrap: wrap;

      li {
        width: 25%;
        color: #656565;
        font-size: 14px;
        margin-bottom: 30px;

        .imgdiv {
          width: 90px;
          height: 90px;
          margin-right: 10px;
        }

        img {
          width: 90px;
          height: 90px;
        }

        .weir {
          line-height: 2;
          padding-top: 5px;
          box-sizing: border-box;
        }
      }

      li:nth-child(4),
      li:nth-child(7) {
        div {
          white-space: nowrap;
        }
      }
    }
  }

  .bodiesRight {
    margin-left: 2%;
    width: 30%;
    height: 100%;

    .scroll_parent_box {
      overflow: hidden;
      box-sizing: border-box;
      height: 387px;
    }

    .scroll_list {
      transition: all 0ms ease-in 0s;
    }

    .scroll_item {
      border-bottom: 1px solid #dcdfe6;
      font-size: 14px;
      padding: 10px 0;
      box-sizing: border-box;
    }

    .row-main {
      width: 330px;
      height: 450px;
      padding: 10px;
      font-size: 14px;
      border: 1px dashed @bordercolor;
      box-sizing: border-box;
      background-color: #f9f9f9;
      overflow: hidden;

      .row-title {
        font-size: 18px;
        padding-bottom: 10px;
      }

      .item-row {
        display: flex;

        .ritem1 {
          width: 58%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          vertical-align: middle;
        }

        .ritem2 {
          width: 29.1%;
          margin-left: 8.5%;
          vertical-align: middle;
        }

        .ritem3 {
          color: @primary;
          width: 29.1%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          text-align: right;
          vertical-align: middle;
        }

        .ritem4 {
          width: 50%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          vertical-align: middle;
        }

        .ritem5 {
          text-align: right;
          width: 50%;
        }
      }

      .item-row2 {
        padding-top: 10px;
      }
    }


  }

  .red {
    color: red;
  }

  .green {
    color: green;
  }
</style>
