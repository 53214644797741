<template>
  <div class="gh-detail">
    <div class="search-module">
      <div class="select-item tp">
        <div>产地：</div>
        <el-input type="text" style="width:300px" placeholder="全部" @blur="handleSearch"></el-input>
      </div>
      <div class="select-item tp">
        <div>规格：</div>
        <div v-for="(item,index) in typeOption" :key="index" :class="{'active': timeActive == item.value}"
          @click="timeActive = item.value">{{item.label}}</div>
      </div>
      <div class="select-item">
        <div>排序：</div>
        <div v-for="(item,index) in typeOption" :key="index" :class="{'active': timeActive == item.value}"
          @click="timeActive = item.value">{{item.label}}</div>
      </div>
    </div>
    <div class="content-module">
      <div v-for="(item,index) in dataList" :key="index" class="content-div">
        <div class="img">
          <div class="type">{{item.type}}</div>
          <el-image style="width:100%;height:100%"></el-image>
        </div>
        <div class="line">
          <div class="text">品名：{{item.name}}</div>
          <div class="text">数量：{{item.num}}</div>
          <div class="text">发布：{{item.time}}</div>
          <div class="text">联系人：{{item.lianxi}}</div>
          <div class="text">地址：{{item.address}}</div>
        </div>
        <div class="line">
          <div class="text">规格：{{item.guige}}</div>
          <div class="text">产地：{{item.area}}</div>
          <div class="text">有效期：{{item.dateLine}}</div>
          <div class="text">手机：{{item.phone}}</div>
        </div>
        <div class="line">
          <div class="text">单价：{{item.price}}</div>
          <div class="text">存货地：{{item.source}}</div>
          <div class="text">公司：{{item.company}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ghDetail',
    data() {
      return {
        areaOption: [{
            label: "全部",
            value: "",
          },
          {
            label: "亳州市场",
            value: "亳州",
          },
          {
            label: "安国市场",
            value: "安国",
          },
          {
            label: "成都市场",
            value: "成都",
          },
          {
            label: "玉林市场",
            value: "玉林",
          },
        ],
        typeOption: [{
            label: "全部",
            value: 0,
          },
          {
            label: "今日变动",
            value: 1,
          },
          {
            label: "动物类",
            value: 2,
          },
          {
            label: "植物类",
            value: 3,
          },
        ],
        typeActive: 0,
        timeActive: 0,
        dataList: [{
          image: 'xxx',
          type: '1',
          name: '品名111',
          guige: '规格',
          price: '价格',
          num: '数量',
          area: '产地',
          source: '存货地',
          time: '发布时间',
          dateLine: '有效期',
          lianxi: '联系人',
          phone: '手机',
          address: '地址',
          company: '公司'
        }, {
          image: 'xxx',
          type: '1',
          name: '品名111',
          guige: '规格',
          price: '价格',
          num: '数量',
          area: '产地',
          source: '存货地',
          time: '发布时间',
          dateLine: '有效期',
          lianxi: '联系人',
          phone: '手机',
          address: '地址',
          company: '公司'
        }]
      }

    }
  }
</script>

<style lang="less" scoped>
  .gh-detail {
    max-width: 1190px;
    margin: 20px auto 0;
    padding-bottom: 40px;

    .search-module {
      width: 100%;
      text-align: center;
      background: #fff;
      padding: 30px 25px;
      box-sizing: border-box;

      /deep/ .el-input__inner {
        border: none;
        -webkit-appearance: none;
        background-color: #fff;
        background-image: none;
        border-radius: 0;
        border: 1px solid #d5d5d5;
        box-sizing: border-box;
        color: #606266;
        display: inline-block;
        font-size: inherit;
        height: 40px;
        line-height: 40px;
        outline: 0;
        padding: 0 15px;
        width: 100%;
      }

      .el-icon-search {
        width: 90px;
        margin-left: 39px;
      }

      .el-button {
        border-radius: 0;
      }
    }

    .tp {
      border-bottom: none !important;
    }

    .select {
      margin: auto;
      height: 22px;

      &-item {
        display: flex;
        align-items: center;
        line-height: 42px;
        border: 1px solid #eee;
        padding: 10px 0;

        div {
          font-size: 16px;
          padding: 0px 10px;
          margin-right: 14px;
          cursor: pointer;
        }

        div:first-child {
          margin-right: 0;
        }

        .active {
          background: rgb(20, 99, 158);
          border-radius: 4px;
          color: #fff;
        }
      }
    }

    .content-module {
      padding-left: 25px;

      .content-div {
        width: 100%;
        height: 215px;
        display: flex;
        border-bottom: 1px dashed #eee;
        justify-content: space-between;
        margin-bottom: 20px;
        padding-bottom: 10px;

        .img {
          width: 20%;
          height: 100%;
          position: relative;
          margin-right: 40px;

          .type {
            position: absolute;
            top: 0;
            left: 0;
            width: 30px;
            height: 30px;
            z-index: 999;
          }
        }

        .line {
          width: 26%;
          padding-top: 10px;

          .text {
            text-align: left;
            margin-bottom: 20px;
          }
        }
      }
    }
  }
</style>