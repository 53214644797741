/*
 * @Author: guojiren 7755@123.com
 * @Date: 2023-02-03 09:45:44
 * @LastEditors: guojiren 7755@123.com
 * @LastEditTime: 2023-03-02 16:13:13
 * @FilePath: \xinghe-xinxi-pc\src\utils\fetch.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import axios from 'axios'
import {axgInfo, infoContext} from '@/utils/fetch2'
import Cookies from 'js-cookie'
// import download from 'downloadjs';
import store from '../store'
import qs from 'qs'

let baseUrl = infoContext
// 创建axios实例
const service = axios.create({
  baseURL: baseUrl,
})

// request拦截器
service.interceptors.request.use(
  (config) => {
    let baseUrl = infoContext
    if (config.url.startsWith(axgInfo)) {
      baseUrl = "/"
    }
    config.baseURL = baseUrl
    let token = Cookies.get('token')
    if (token == undefined || token == null || token == '') {
      token = localStorage.getItem('token')
    }
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token // 让每个请求携带token--['X-Token']为自定义key 请根据实际情况自行修改
    }
    // console.log('request config----', config)
    return config
  },
  (error) => {
    // Do something with request error
    Promise.reject(error)
  }
)

// respone拦截器
service.interceptors.response.use(
  async (res) => {
    // console.log("response----",res)
    if (res.data.code == 301 || res.data.code == 302) {
      Cookies.remove('token')
      store.commit('userInfo', {})
    }
    // if (res.data.code === 401) {
    //   store.dispatch('logout');
    //   return Promise.reject(res);
    // }
    // if (
    //   res.headers['content-type'] === 'text/csv' ||
    //   res.config.responseType == 'blob'
    // ) {
    //   if (res.headers['content-disposition']) {
    //     let title = res.headers['content-disposition'].split('=')[1];
    //     download(res.data, title || '1.text');
    //     return { code: 200 };
    //   }
    //   res.data = JSON.parse(await res.data.text());

    // }
    // if (res.data.code === 123 && res.data.msg) {
    //   return Promise.reject(res.data);
    // }
    // if (res.data.status !==200) {
    //   if (res.data.msg && res.data.msg !== '数据不存在') {
    //     Message({
    //       message: res.data.msg,
    //       type: 'warning',
    //       duration: 2000,
    //     });
    //   }
    //   return Promise.reject(res.data);
    // }
    return res.data
  },
  (error) => {
    // console.log('err' + error); // for debug
    // Message({
    //   message:
    //     (error && error.data && error.data.msg) ||
    //     '网络错误或获取失败，请刷新后重试',
    //   type: 'error',
    //   duration: 5 * 1000,
    // });
    return Promise.reject(error)
  }
)

function get(url, params) {
  return service.get(url, { params: params }).then((res) => res)
}
function json(url, params) {
  return service.post(url, params ).then((res) => res)
}
function post(url, params) {
  return service.post(url, qs.stringify(params)).then((res) => res)
}
function delet(url, params) {
  return service.delete(url, params).then((res) => res)
}
function put(url, params) {
  return service.put(url, params).then((res) => res)
}
function patch(url, params) {
  return service.patch(url, params)
}
export { get, json,post, delet, put, patch }

export default service
