<template>
  <div class="information">
    <div class="banxin1">
      <div class="bannercai">
        <div class="carouse">
          <el-carousel trigger="click" height="450px">
            <el-carousel-item v-for="item in lunboList" :key="item.url">
              <!-- <h3 class="small">{{ item }}</h3> -->
              <img class="banner-img" :src="item.url" />
              <router-link v-if="item.link_url != ''" :to="item.link_url" class="linkLun"></router-link>
            </el-carousel-item>
          </el-carousel>
        </div>

        <div class="banxin">
          <div class="condisplay">
            <!-- <div class="header-classify">
                  <div class="header-classify-maintit">全部
                    <i class="el-icon-arrow-down dowm-icon"></i>
                    </div>
                </div> -->
            <div class="container">
              <div class="nav-module">
                <!-- @mouseleave="mouseleaveBtn" -->
                <div class="group-list" @mouseleave="mouseleaveBtn">
                  <ul class="classify">
                    <li class="classify-item" v-for="(item, index) in classifyOption" :key="item.id"
                      :class="{ 'active-class': activeIndex === index }" @mouseenter="mouseenterbtn(index)">
                      <div class="classify-item-top">
                        <span v-if="index === 0" class="icon">
                          <img src="@/assets/imgs/tea.svg" alt="">
                        </span>
                        <span v-if="index === 1" class="icon1">
                          <img src="@/assets/imgs/yinhang.svg" alt="">
                        </span>
                        <span v-if="index === 2" class="icon2">
                          <img src="@/assets/imgs/qita.svg" alt="">
                        </span>
                        <span v-if="index === 3" class="icon3">
                          <img src="@/assets/imgs/chuanmei.svg" alt="">
                        </span>
                        <span v-if="index === 4" class="icon4">
                          <img src="@/assets/imgs/baozhuang.svg" alt="">
                        </span>
                        <span v-if="index === 5" class="icon1">
                          <img src="@/assets/imgs/wuliu.svg" alt="">
                        </span>
                        <span v-if="index === 6" class="icon4">
                          <img src="@/assets/imgs/shebei.svg" alt="">
                        </span>
                        <div class="classify-label">{{ item.name }}</div>

                      </div>
                      <!-- <div class="line"></div> -->
                    </li>
                  </ul>
                  <ul class="classify-one" v-if="activeIndex != null">
                    <li class="classify-one-item" v-for="item in dataList" :key="item.id" @click="lookDetail(item.id)">
                      <div>{{ item.name }}</div>
                    </li>
                  </ul>
                </div>
                <!-- <div style="width:calc(100% - 240px);margin:auto;height:100%">
                            <el-carousel height="460px">
                                <el-carousel-item  style="height:460px" v-for="(item,index) in imgList" :key="index">
                                    <el-image style="width: 100%; height: 100%" :src="baseUrl+item.url"></el-image>
                                </el-carousel-item>
                            </el-carousel>
                        </div> -->
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="banxin2" @click="toAny">
                    <img src="@/assets/imgs/ad.jpg" alt="">
                </div> -->
      </div>
    </div>
    <div class="banxin">

      <div class="infomain">

        <!-- 热门服务 -->
        <div class="info" v-for="(item, index) in recCompany" :key="index">
          <div class="recommend">
            <div class="recommend-h2">
              <span>推荐企业</span>
            </div>
            <span class="recommend-lookmore" @click="lookMore('')">查看更多 &nbsp;></span>
          </div>
          <ul class="recommend-list">
            <li v-for="item1 in companyList2" :key="item1.id">
              <div class="card">
                <div class="card-server" @click="lookDetail(item1.id)">
                  <img class="card-two-item-img" :src="baseUrl + item1.pic" />
                  <div class="card-server-tit">
                    {{ item1.name }}
                  </div>
                  <div class="card-two-item-right-addr">
                    <i class="el-icon-location-outline icon-addr"></i>{{ item1.addr }}
                  </div>
                </div>
              </div>
            </li>
            <li v-for="(item2, index2) in 5-(companyList2.length<5?companyList2.length:5)" :key="index2">
              <div class="card">
                <div class="card-server" style="padding: 20px">
                  <el-empty style="padding: 0;" description="虚位以待" :image-size=80></el-empty>
                </div>
              </div>
            </li>
          </ul>

        </div>
        <!-- 优质服务商 -->
        <div class="info" v-for="item in categorys" :key="item.id">
          <div class="recommend">
            <div class="recommend-h2">
              <span>{{ item.name }}</span>
            </div>
            <span class="recommend-lookmore" @click="lookMore('', item.id)">查看更多 &nbsp;></span>
          </div>
          <ul class="recommend-list">
            <li v-for="item1 in item.companyList.slice(0,5)" :key="item1.id">
              <div class="card">
                <div class="card-server" @click="lookDetail(item1.id)">
                  <img class="card-two-item-img" :src="baseUrl + item1.pic" />
                  <div class="card-server-tit">
                    {{ item1.name }}
                  </div>
                  <div class="card-two-item-right-addr">
                    <i class="el-icon-location-outline icon-addr"></i>{{ item1.addr }}
                  </div>
                </div>
              </div>
            </li>
            <template v-if="item.companyList.length<5">
              <li v-for="(item2, index2) in 5-item.companyList.length" :key="index2">
                <div class="card">
                  <div class="card-server" style="padding: 20px">
                    <el-empty style="padding: 0;" description="虚位以待" :image-size=80></el-empty>
                  </div>
                </div>
              </li>
            </template>
          </ul>
        </div>

        <div style="height:30px"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCategorys, getRecService, getSwipers, getHotService, getTopCategorys } from '@/api/information'
import { INFO_URL } from '@/config/globalConfig'
import { getLunbotu } from '@/api'
export default {
  components: { },
  data() {
    return {
      isCollapse: true,
      isLogon: true,
      baseUrl: INFO_URL,
      activeId: 1,
      pagination: {
        total: 4,
        size: 10,
        page: 1
      },
      classifyOption: [],
      categorys: [],
      companyList2: [],
      dataList: [],
      // 控制动画
      animateUp: false,
      // 计时器
      intNum: null,
      imgList: [],
      activeIndex: null,
      hotServer: [],
      lunboList: [],
      recCompany: []
      // list: false
    }
  },
  created() {
    this.getCategoryData()
    this.getSwipers()
    this.getHotService()
    this.getTopCategorys()
    this.getLunbotu()
    this.getRecService()
  },
  mounted() {
    // this.ScrollUp()
  },
  destroyed() {
    clearInterval(this.intNum)
  },
  methods: {
    ScrollUp() {
      if (this.recCompany[0].companyList.length > 4) {
        this.intNum = setInterval(() => {
          this.animateUp = true // 向上滚动的时候需要添加css3过渡动画
          setTimeout(() => {
            this.recCompany[0].companyList.push(this.recCompany[0].companyList[0]) // 将数组的第一个元素添加到数组的
            this.recCompany[0].companyList.shift() // 删除数组的第一个元素
            this.animateUp = false
          }, 500)
        }, 1000)
      }
    },
    // 鼠标移上去停止
    Stop() {
      clearInterval(this.intNum)
    },
    // 鼠标离开继续滚动
    Up() {
      this.ScrollUp()
    },
    toAny() {},
    // Banner图
    getLunbotu() {
      getLunbotu({ limitN: 3, location: 6 }).then((res) => {
        if (res.code == 200) {
          this.lunboList = res.data
        }
      })
    },
    lookDetail(id) {
      this.$router.push({
        path: '/comDesc',
        query: {
          id: id
        }
      })
    },
    mouseenterbtn(index) {
      this.activeIndex = index
      this.dataList = this.classifyOption[index].companyList || []
    },
    mouseleaveBtn() {
      this.activeIndex = null
      this.dataList = []
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath)
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath)
    },
    choose(val) {
      this.activeId = val
    },
    handleSizeChange(val) {
      this.pagination.size = val
      this.pagination.page = 1
    },
    handleCurrentChange(val) {
      this.pagination.page = val
    },
    getCategoryData() {
      let that = this
      getCategorys().then((res) => {
        that.classifyOption = res.data
      })
    },
    getSwipers() {
      let that = this
      getSwipers().then((res) => {
        that.imgList = res.data
      })
    },
    getHotService() {
      let that = this
      getHotService().then((res) => {
        that.hotServer = res.data
      })
    },
    getRecService() {
      getRecService().then((res) => {
        this.recCompany = res.data
        this.companyList2 = [...res.data[0].companyList]
        if (this.companyList2.length > 5) {
          this.companyList2.splice(5, this.companyList2.length - 5)
        }
        if (this.recCompany[0].companyList.length > 4) {
          this.intNum = setInterval(() => {
            this.animateUp = true // 向上滚动的时候需要添加css3过渡动画
            setTimeout(() => {
              this.recCompany[0].companyList.push(this.recCompany[0].companyList[0]) // 将数组的第一个元素添加到数组的
              this.recCompany[0].companyList.shift() // 删除数组的第一个元素
              this.animateUp = false
            }, 500)
          }, 1000)
        }
      })
    },
    getTopCategorys() {
      // let that = this;
      getTopCategorys().then((res) => {
        this.categorys = res.data
        // this.intNum = setInterval(() => {
        //     this.animateUp = true// 向上滚动的时候需要添加css3过渡动画
        //     setTimeout(() => {
        //     this.categorys[0].keywordsList.push(this.categorys[0].keywordsList[0])// 将数组的第一个元素添加到数组的
        //     this.categorys[0].keywordsList.shift() // 删除数组的第一个元素
        //     this.animateUp = false
        //     }, 500)
        // }, 1000)
        //    debugger
      })
    },
    // 查看更多
    lookMore(keyword, catId) {
      this.$router.push({
        name: 'InformationSearch',
        query: {
          keywords: keyword,
          catId: catId
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import url('../../assets/common.less');

.recommend {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  line-height: 20px;
  padding-bottom: 12px;
  border-bottom: 1px solid #f1f1f1;
  .recommend-h2 {
    font-size: 16px;
    color: #666;
  }
  .recommend-lookmore {
    position: absolute;
    top: 0;
    right: 30px;
    color: #666;
    font-size: 12px;
    cursor: pointer;
  }
}

.recommend-list {
  background-color: #b4d2d3;
  padding: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
  .card {
    overflow: hidden;
    border: 1px solid #f1f1f1;
    border-radius: 20px;
    background-color: #fff;
  }
  .card-server {
    padding: 0;
  }
  .card-two-item-img {
    height: 120px;
    width: 100%;
    margin-bottom: 0;
  }
  .card-two-item-right-addr {
    padding: 0 5px;
  }
}

.animate-up {
  // animation: myMove 5s linear infinite;
  // animation-fill-mode:forwards;
  // transition: all 0.5s ease-in-out;
  // transform: translateY(-20px);
  -webkit-animation: rowup 5s linear infinite normal;
  animation: rowup 5s linear infinite normal;
  position: relative;
}
@keyframes rowup {
  //   0% {
  //     transform: translateY(0);
  // }
  // 100% {
  //     transform: translateY(-140px);
  // }
}
.banxin1 {
  width: 1190px;
  margin: 0 auto;
  position: relative;
}

.banxin {
  max-width: 1190px;
  margin: 0 auto;
  position: relative;
}

.information {
  position: relative;
  background: @beijingse;
  padding-bottom: 30px;

  .search-module {
    display: flex;
    text-align: center;
    padding-top: 20px;
    margin-left: 21px;
    width: 100%;

    /deep/ .el-input__inner {
      border: none;
      -webkit-appearance: none;
      background-color: #fff;
      background-image: none;
      border-radius: 4px;
      border: 1px solid @primary;
      box-sizing: border-box;
      color: #606266;
      display: inline-block;
      font-size: inherit;
      height: 40px;
      line-height: 40px;
      outline: 0;
      padding: 0 15px;
      width: 100%;
    }

    .search-btn {
      width: 100px;
      margin-left: 30px;
    }
  }

  .container {
    position: relative;
    width: 202px;
    height: 461px;
    // background-color: #edeeef;

    .nav-module {
      display: flex;
      width: 1190px;
      height: 461px;
      justify-content: space-between;
      margin: 0 auto;

      .group-list {
        position: relative;
        height: 448px;
        margin-top: 2px;
        // background: rgba(56, 131, 229, 0.534);

        .classify {
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: 185px;
          z-index: 2;
          height: 450px;
          overflow-y: auto;
          background: rgb(255, 255, 255);
          border: 1px solid #dadada;
          border-top: none;
          border-bottom: none;

          // padding-top: 10px;

          // li{
          //      background-color: rgba(34, 34, 34, 0.7);
          // }
          li:hover {
            border-bottom: 1px solid rgb(111, 207, 252);
            // border-right: none;
            font-weight: bold;
          }

          &-item {
            position: relative;
            font-size: 16px;
            color: rgb(53, 53, 53);
            // padding: 11.11px 20px 11.11px 45px;
            flex: 1;
            width: 100%;
            display: flex;
            // text-align: center;
            align-items: center;
            // align-content: stretch;
            // flex-direction: column;
            box-sizing: border-box;
            border-bottom: 1px solid #dadada;

            &-top {
              display: flex;
              // margin: auto;
              margin-left: 30px;
              align-items: center;
              // justify-content: center;

              .icon {
                width: 35px;
                height: 35px;

                img {
                  width: 35px;
                  height: 35px;
                }
              }

              .icon1 {
                width: 25px;
                height: 25px;
                margin-left: 5px;
                margin-right: 5px;

                img {
                  // margin-right: 6px;
                  width: 25px;
                  height: 25px;
                }
              }

              .icon2 {
                width: 20px;
                height: 20px;
                margin-left: 8px;
                margin-right: 6px;

                img {
                  // margin-right: 6px;
                  width: 20px;
                  height: 20px;
                }
              }

              .icon3 {
                width: 27px;
                height: 27px;
                margin-left: 5px;
                margin-right: 2px;

                img {
                  // margin-right: 6px;
                  width: 27px;
                  height: 27px;
                }
              }

              .icon4 {
                width: 23px;
                height: 23px;
                margin-left: 5px;
                margin-right: 6px;

                img {
                  // margin-right: 6px;
                  width: 23px;
                  height: 23px;
                }
              }

              .classify-label {
                flex: 1;
                cursor: pointer;
              }
            }

            .line {
              width: 80%;
              position: absolute;
              bottom: -1px;
              border-bottom: 1px dashed @bordercolor;
            }
          }

          &-item:last-child {
            // border-bottom: none;
          }

          &-item:hover {
            // color: @primary;
            // color: #fff;
            // background-color: #fff;

            .classify-one {
              display: flex;
            }
          }

          .active-class {
            // color: #fff;
            // background-color: rgba(56, 131, 229);
            border-bottom: 1px solid rgb(111, 207, 252);
            // border-right: none;
          }
        }

        .classify-one {
          width: 973px;
          position: absolute;
          left: 185px;
          top: -1px;
          bottom: 0;
          background-color: rgb(255, 255, 255);
          border: 1px solid rgb(111, 207, 252);
          // border-top: none;
          z-index: 2;
          padding: 8px 16px 0 16px;
          display: flex;
          flex-wrap: wrap;
          align-items: flex-start;
          align-content: flex-start;
          height: 441px;
          overflow-x: hidden;

          &-item {
            height: 28px;
            line-height: 28px;
            color: rgb(53, 53, 53);
            font-size: 13px;
            padding-right: 32px;
            padding-left: 20px;

            // border-bottom: 1px dashed @bordercolor;
            div {
              cursor: pointer;
            }
          }

          &-item:hover {
            // color: #ffee04;
            color: rgba(56, 131, 229);
            text-decoration: underline;
          }
        }
      }
    }
  }

  .info-type {
    width: 476px;
    margin: 0 auto 40px;

    .label {
      width: 60px;
      margin: 0 10px;
      text-align: center;
      padding: 6px 14px;
      cursor: pointer;
    }

    .active {
      background-color: @primary;
      color: #fff;
      border-radius: 8px;
    }
  }

  .info {
    // margin-top: 50px;
    padding-top: 18px;
    // box-shadow: 0 0 3px 3px rgb(235, 235, 235);

    .info-title {
      font-size: 16px;
      line-height: 19px;
      padding-bottom: 12px;
      color: @hui;
      padding-left: 6px;
      border-bottom: 1px solid @bordercolor;

      .l-style {
        display: inline-block;
        width: 2px;
        height: 18px;
        background: @primary;
        margin-right: 6px;
      }

      .lookmore {
        color: @hui;
        font-size: 12px;
        padding-right: 30px;
        flex: 1;
        text-align: right;
        cursor: pointer;
      }
    }

    .el-row {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .card {
      height: 200px;
      position: relative;
      border-right: 1px solid @bordercolor;
      border-bottom: 1px solid @bordercolor;
      cursor: pointer;

      &-server {
        text-align: center;
        padding: 20px 5px;
        padding-top: 30px;
        box-sizing: border-box;

        &-tit {
          font-size: 12px;
          color: @hui;
          white-space: nowrap;
          margin-bottom: 6px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &-con {
          color: @hui;
          font-size: 14px;
        }

        &-btn {
          position: absolute;
          bottom: 20px;
          right: 20px;
          font-size: 14px;
          width: 76px;
          margin-top: 26px;
          text-align: center;
          padding: 4px 0;
          color: @primary;
          border-radius: 2px;
          margin-right: 0;
          margin-left: auto;
        }
      }

      .info-title {
        font-size: 18px;
      }
    }

    .el-col-6 {
      width: 16.66%;
    }

    .el-col:nth-child(6) .card {
      border-right: none;
    }

    .card-two {
      // box-shadow: 0 0 16px #d8d8d8;
      // padding:0 20px;
      &-item {
        text-align: center;
        // display: flex;
        // align-items: center;
        // border-bottom: 1px dashed #ddd;
        padding: 20px 5px;
        padding-top: 30px;

        &-img {
          width: 60px;
          height: 60px;
          // border-radius: 50%;
          margin-bottom: 16px;
        }

        &-right {
          flex: 1;

          &-title {
            font-size: 12px;
            color: @hui;
            margin-bottom: 6px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          &-addr {
            color: #9f9f9f;
            font-size: 12px;
            color: #9f9f9f;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;

            .icon-addr {
              margin-right: 4px;
              font-size: 16px;
            }
          }
        }

        &-value {
          width: 100%;
          font-size: 12px;
        }
      }

      &-item:last-child {
        border-bottom: none;
      }
    }

    .card:hover {
      box-shadow: 0 0 16px #aaa;
    }

    .hotcard {
      background: @primary;
      height: 200px;
      color: #fff;
      font-size: 16px;
      padding: 26px 26px;
      box-sizing: border-box;

      .hotxt {
        font-size: 12px;
        margin-top: 18px;
        padding-left: 20px;
        box-sizing: border-box;
        flex-direction: row;
        flex-wrap: wrap;
        // .hotxt2{
        //     padding-left: 0px !important;
        //     flex-direction: column !important;
        // }
        .name {
          margin-right: 10px;
          line-height: 30px;
          cursor: pointer;
          width: 116%;
        }
        div {
          margin-right: 10px;
          line-height: 30px;
          cursor: pointer;
          width: 44%;
        }

        div:nth-child(2n) {
          margin-right: 0;
        }
      }
    }
  }

  .pagination-style {
    text-align: right;
    margin-bottom: 20px;
  }
}

.header-classify {
  display: flex;
  align-items: center;
}

.header-classify-maintit {
  width: 214px;
  background-color: rgba(34, 34, 34, 0.8);
  color: #fff;
  padding: 8px 0;
  font-size: 14px;
  padding-left: 45px;
  box-sizing: border-box;
}

.condisplay {
  position: absolute;
  left: 0;
  top: -451px;
}

.bannercai {
  width: 100%;
  height: 450px;
  position: relative;
  margin-top: 10px;

  .carouse {
    margin-left: 195px;
    width: 995px;
    height: 100%;

    img {
      // margin-right: 202px;
      width: 995px;
      height: 100%;
    }
  }

  .banxin2 {
    width: 220px;
    height: 450px;
    background-color: #fff;
    // margin: 0 auto;
    position: absolute;
    right: 0;
    top: 0;

    img {
      width: 220px;
      height: 450px;
    }
  }
}

.infomain {
  background: #fff;
  margin-top: 40px;
  box-shadow: 0 0 3px 3px rgb(235, 235, 235);
  // width:86%;
  // margin-left:7%;
}
.group_6 {
  background-image: url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/39307ff177a14dfeb5f40570ac9e15e1_mergeImage.png);
  position: absolute;
  left: -85px;
  top: 22px;
  width: 304px;
  height: 107px;
}
.linkLun {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
</style>
