<template>
  <div class="w">
    <div class="com-desc">
      <el-row>
        <el-col :span="16">
          <section class="com-sec">
            <div class="com-sec-hd">
              <div class="h2-box">
                <!-- <img referrerpolicy="no-referrer"
                  src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng9f0924056986b6c9fb7451131964e5e769f444a1eecac30042c39eac0e3d6a33" /> -->
                <h2>公司简介</h2>
                <!-- <img referrerpolicy="no-referrer"
                  src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng753270a6fd442eec7c7de7ae8a3a2ecc8b057ca5d7e52609ab8556e6a2b62f11" /> -->
              </div>

            </div>
            <div class="com-intro">
              <div v-html="comMsg.decriptions"></div>
            </div>

          </section>
          <section class="com-sec">
            <div class="com-sec-hd">
              <div class="h2-box">
                <!-- <img referrerpolicy="no-referrer"
                  src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng9f0924056986b6c9fb7451131964e5e769f444a1eecac30042c39eac0e3d6a33" /> -->
                <h2>公司相册</h2>
                <!-- <img referrerpolicy="no-referrer"
                  src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng753270a6fd442eec7c7de7ae8a3a2ecc8b057ca5d7e52609ab8556e6a2b62f11" /> -->
              </div>
              <div class="look-more" @click="toComPhotos">查看更多</div>
            </div>
            <div class="com-photos">
              <el-carousel :interval="4000" type="card" height="300px" indicator-position="none">
                <el-carousel-item v-for="item in comPhotos" :key="item">
                  <img :src="'/info-admin' + item">
                </el-carousel-item>
              </el-carousel>
            </div>

          </section>
          <section class="com-sec">
            <div class="com-sec-hd">
              <div class="h2-box">
                <!-- <img referrerpolicy="no-referrer"
                  src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng9f0924056986b6c9fb7451131964e5e769f444a1eecac30042c39eac0e3d6a33" /> -->
                <h2>产品中心</h2>
                <!-- <img referrerpolicy="no-referrer"
                  src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng753270a6fd442eec7c7de7ae8a3a2ecc8b057ca5d7e52609ab8556e6a2b62f11" /> -->
              </div>
              <div class="look-more" @click="toProCenter">查看更多</div>
            </div>
            <div class="pro-bg">
              <el-carousel :interval="4000" type="card" height="300px">
                <el-carousel-item v-for="item in productList" :key="item.id">
                  <img :src="'/info-admin' + item.mainpic" class="pro-img"
                    @click="goDetail(item.id)">
                </el-carousel-item>
              </el-carousel>
            </div>

          </section>

        </el-col>
        <el-col :span="8">
          <el-row style="margin-top: 65px">
            <el-col :span="8">
              <div class="com-logo">
                <img :src="'/info-admin' + comMsg.logopic">
              </div>
              <div class="ren-zheng" v-if="statusName==='已认证'">
                <img src="@/assets/imgs/renzheng.png">
              </div>
            </el-col>
            <el-col :span="16">
              <div class="item">
                <span class="label">公司名称</span>
                <span class="value">{{comMsg.name}}</span>
              </div>
              <div class="item">
                <span class="label">认证标识</span>
                <span class="value">{{statusName}}</span>
              </div>
              <div class="item" style="display: flex">
                <span class="label" style="width: 56px">公司地址</span>
                <span class="value" style="flex: 1">{{comMsg.addr}}</span>
              </div>

              <div class="item">
                <span class="label">联系人</span>
                <span class="value">{{comMsg.contacter}}</span>
              </div>
              <div class="item">
                <span class="label">联系电话</span>
                <span class="value">{{comMsg.contacttel}}</span>
              </div>
              <div class="item">
                <span class="label">公司网站</span>
                <span class="value">{{comMsg.homepageUrl}}</span>
              </div>
            </el-col>
          </el-row>

          <div class="com-sec-hd">
            <div class="h2-box" style="margin-top: 20px">
              <!-- <img referrerpolicy="no-referrer"
                src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng9f0924056986b6c9fb7451131964e5e769f444a1eecac30042c39eac0e3d6a33" /> -->
              <h2>公司资质</h2>
              <!-- <img referrerpolicy="no-referrer"
                src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng753270a6fd442eec7c7de7ae8a3a2ecc8b057ca5d7e52609ab8556e6a2b62f11" /> -->
            </div>
          </div>

          <div>
            <img v-for="item in qualitypics" class="zizhi-img" :src="'/info-admin' + item"
              :key="item">
          </div>

        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { getCompany, getProductList, getCompanyByUser } from '@/api/information'
export default {
  name: 'comDesc',
  data() {
    return {
      comMsg: {
        decriptions: '',
        logopic: '',
        name: '',
        addr: '',
        homepageUrl: '',
        contacter: '',
        contacttel: ''
      },
      currentId: null,
      productList: [],
      comPhotos: [],
      qualitypics: [],
      statusName: ''
    }
  },
  created() {
    this.currentId = this.$route.query.id
    this.getStatus()
    this.getCompany(this.currentId)
    this.getProduct(this.currentId)
  },
  methods: {
    async getStatus() {
      const res = await getCompanyByUser()
      const { status } = res.data
      if (status === 2) {
        this.statusName = '已认证'
      } else {
        this.statusName = '未认证'
      }
    },
    getCompany(id) {
      let that = this
      getCompany(id).then((res) => {
        that.comMsg = res.data
        if (res.data.pic) {
          this.comPhotos = res.data.pic.split(',').slice(0, 4)
        }
        if (res.data.qualitypics) {
          this.qualitypics = res.data.qualitypics.split(',')
        }
      })
    },
    getProduct(id) {
      let that = this
      let params = {
        pageIndex: 1, // 传入页码
        pageSize: 4, // 传入每页条数
        companyid: id,
        approveStatus: 2
      }
      getProductList(params).then((res) => {
        that.productList = res.data.content
      })
    },
    toComPhotos() {
      this.$router.push({
        path: '/comPhotos',
        query: {
          id: this.currentId
        }
      })
    },
    toProCenter() {
      this.$router.push({
        path: '/production',
        query: {
          id: this.currentId
        }
      })
    },
    goDetail(id) {
      this.$router.push({
        name: 'proDetail',
        query: {
          id
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.w {
  background-color: #f2f2f2;
  padding-top: 20px;
  padding-bottom: 20px;
}
.com-desc {
  max-width: 1100px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  .h2-box {
    display: flex;
    align-items: center;
    img {
      width: 28px;
      height: 22px;
    }
  }
  h2 {
    color: #16191c;
    margin: 0 40px;
    font-size: 20px;
    font-family: PingFangSC-Semibold;
    font-weight: 600;
    line-height: 45px;
  }
  .look-more {
    color: #1b86fb;
    font-size: 14px;
    white-space: nowrap;
    cursor: pointer;
  }
  .item {
    padding: 5px;
    margin-bottom: 5px;
  }
  .label {
    margin-right: 10px;
    color: #666;
    font-size: 14px;
    display: inline-block;
    width: 60px;
  }
  .value {
    color: #000;
    font-size: 15px;
  }
}
.com-intro {
  border: 1px solid #b4d2d3;
  padding: 10px;
}
.com-sec {
  margin-bottom: 20px;
  padding-right: 20px;
}
.com-sec-hd {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.com-logo {
  padding-right: 20px;
  img {
    width: 100%;
  }
}
.ren-zheng {
  padding-right: 20px;
  text-align: center;
  margin-top: 10px;
  img {
    width: 50%;
  }
}
.pro-bg {
  padding: 20px;
  background-color: #b4d2d3;
  .el-carousel__item--card {
    border-radius: 20px;
    overflow: hidden;
  }
}
.pro-img {
  width: 100%;
  height: 100%;
}
.com-photos {
  padding: 20px;
  background-color: #b4d2d3;
  .el-carousel__item--card {
    border-radius: 20px;
    overflow: hidden;
    background-color: #fff;
  }
  img {
    width: 100%;
    height: 100%;
  }
}

.zizhi-img {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
}
</style>