<template>
  <el-dialog
      :close-on-click-modal="false"
      :visible.sync="visibleDetail"
      class="quoted-price"
      title="招标说明"
      width="1190px"
  >
    <!-- <h2>招标说明：</h2> -->
    <div v-html="detailObj.content"></div>
    <template v-if="detailObj.file">
      <h2>投标附件：</h2>
      <a :href="`${infoUrl}${detailObj.file}`">下载附件</a>
    </template>

  </el-dialog>
</template>

<script>
import {getInqueryDetail, getInqueryMU} from '@/api/bid'
import {INFO_URL} from '@/config/globalConfig'
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    id: {
      // type: String,
      default: '',
    },
    pid: {
      // type: String,
      default: '',
    },
    obj: {
      type: Object,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: {
        mname: '',
        maddr: '',
        mcount: '',
        munit: '',
        mstandard: '',
        mspecremark: ''
      }
    }
  },
  computed: {
    visibleDetail: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit('update:visible', val);
      },
    },
  },

//   watch: {
//     visible(val) {
//       if (val) {
//       }
//     },
//   },
  name: 'showdetail',
  data() {
    return {
      infoUrl:INFO_URL,
      dialogImageUrl: '',
      dialogVisible: false,
      detailObj: {}
    };
  },
  methods: {
    detail(id) {
      const that = this;
      getInqueryDetail(id).then(res => {
        if (res.code == 200) {
          this.detailObj = res.data
          that.$emit("openq")
        }
        if (res.code == 301) {
          // that.$message.warning("请先登录")
          this.$alert('请先登录', '提示', {
            confirmButtonText: '确定',
          });
        }
      })
    }
  },
};
</script>

<style lang="less" scoped>
.quoted-price {
  .form {
    padding: 0 20px;

    &-tit {
      font-size: 18px;
      font-weight: bold;
      border-left: 4px solid rgb(20, 99, 158);
      padding-left: 10px;
      line-height: 20px;
    }

    &-info {
      margin: 20px 0;
      display: flex;
      flex-wrap: wrap;
      border-bottom: 1px solid #eee;

      li {
        width: 50%;
        margin-bottom: 18px;
      }
    }

    // .upload-style{
    //   width: 50px;
    //   height: 50px;
    // }
    /deep/ .el-upload--picture-card {
      background-color: #fbfdff;
      border: 1px dashed #c0ccda;
      border-radius: 6px;
      box-sizing: border-box;
      width: 80px;
      height: 80px;
      cursor: pointer;
      line-height: 80px;
      vertical-align: top;
    }

    .el-upload--picture-card i {
      font-size: 24px;
      color: #8c939d;
    }
  }

  .flex-jac {
    text-align: center;
  }

  .price-module {
    display: flex;

    .el-input {
      width: 93%;
    }

    /deep/ .el-input__inner {
      border: none;
      border-bottom: 1px solid #DCDFE6;
      border-radius: 0;
    }
  }

  /deep/ .el-dialog__body {
    padding: 20px;
    line-height: 1.8;
    min-height: 100px;
    max-height: 500px;
    overflow-x: hidden;
  }
}
/deep/ .el-dialog__header{
  text-align: center;
}
/deep/ .el-dialog{
  left:-9px;
}
</style>
