<template>
  <div v-loading="msgLoading">
    <el-form label-width="150px" disabled>
      <el-form-item label="企业全称">
        <el-input v-model="form.name" placeholder="请输入企业全称" disabled></el-input>
      </el-form-item>
      <el-row>
        <el-col :span="12">
          <el-form-item label="企业地址">
            <el-input v-model="form.addr" @input="$forceUpdate()" disabled
              placeholder="请输入企业地址"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="企业类型">
            <el-select v-model="form.companytype" style="width: 33%" disabled>
              <el-option v-for="item in comTypes" :key="item.value" :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="企业法人">
            <el-input v-model="form.contacter" disabled placeholder="请输入企业法人"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="法人电话">
            <el-input v-model="form.contacttel" disabled placeholder="请输入法人电话"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="联系邮箱">
            <el-input v-model="form.email" placeholder="请输入联系邮箱" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="官网地址">
            <el-input v-model="form.homepageUrl" placeholder="请输入官网地址" disabled></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-form-item label="微信号">
        <el-input v-model="form.wechatNumber" placeholder="请输入微信号" disabled></el-input>
      </el-form-item>
      <el-row>
        <el-col :span="12">
          <el-form-item label="公司LOGO">
            <el-upload class="disabled" :action="uploadOneImgUrl" :limit="1"
              list-type="picture-card" :file-list="fileListObj.logopic"
              :on-preview="handlePictureCardPreview">
              <i class="el-icon-plus"></i>
            </el-upload>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="企业图片">
            <el-upload class="disabled" :action="uploadOneImgUrl" :limit="1"
              list-type="picture-card" :on-preview="handlePictureCardPreview"
              :file-list="fileListObj.pic">
              <i class="el-icon-plus"></i>
            </el-upload>
          </el-form-item>
        </el-col>
      </el-row>

      <el-form-item label="企业资质">
        <el-upload class="disabled" :action="uploadOneImgUrl" :limit="3" list-type="picture-card"
          :on-preview="handlePictureCardPreview" :file-list="fileListObj.qualitypics">
          <i class="el-icon-plus"></i>
        </el-upload>
      </el-form-item>
      <el-row>
        <el-col :span="12">
          <el-form-item label="营业执照">

            <el-upload class="disabled" :action="uploadOneImgUrl" :limit="1"
              list-type="picture-card" :on-preview="handlePictureCardPreview"
              :file-list="fileListObj.yyzzPic">
              <i class="el-icon-plus"></i>
            </el-upload>

          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="企业视频">

            <video v-if="form.videourl != '' && !videoFlag" v-bind:src="videoPreview"
              class="avatar video-avatar" controls="controls">
              您的浏览器不支持视频播放
            </video>

          </el-form-item>
        </el-col>
      </el-row>

      <el-form-item label="协议图片">
        <el-upload class="disabled" :action="uploadOneImgUrl" :limit="1" list-type="picture-card"
          :on-preview="handlePictureCardPreview" :file-list="fileListObj.agreementPic">
          <i class="el-icon-plus"></i>
        </el-upload>
      </el-form-item>

      <el-form-item label="企业介绍">
        <vue-html5-editor @change="updateData" :content="spForm.decriptions" :height="500"
          :z-index="1000" :auto-height="true" :show-module-name="false"></vue-html5-editor>
      </el-form-item>
    </el-form>
    <el-button style="margin-left: 80px;margin-bottom: 40px" type="primary"
      @click="save">保存</el-button>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>
</template>

<script>
import { getUserInfo } from '@/api'
import { getComTypes, getCompanyByUser, editDesc } from '@/api/information'
import { INFO_URL } from '@/config/globalConfig'

export default {
  name: 'companyProfile',
  data() {
    return {
      baseUrl: INFO_URL,
      msgLoading: false,
      comTypes: [],
      form: {
        id: '',
        name: '',
        contacter: '',
        contacttel: '',
        companytype: '',
        email: '',
        homepageUrl: '',
        wechatNumber: '',
        decriptions: '',
        logopic: '',
        qualitypics: '',
        yyzzPic: '',
        pic: '',
        videourl: ''
      },
      spForm: {
        decriptions: '',
        id: ''
      },
      headers: '',
      fileListObj: {
        logopic: [],
        pic: [],
        qualitypics: [],
        yyzzPic: [],
        agreementPic: []
      },
      qualitypics: [],
      // 企业视频
      videoFlag: false,
      videoUploadPercent: '',
      isShowUploadVideo: false,
      dialogVisible: false,
      dialogImageUrl: '',
      uploadOneImgUrl: '',
      uploadZiZhiUrl: '',
      uploadAviUrl: '',
      videoPreview: '',
      gysType: ''
    }
  },
  created() {
    // 首先获取资质信息
    this.firstGetMyInfo()
    this.getComTypes()
  },
  methods: {
    async firstGetMyInfo() {
      this.msgLoading = true
      try {
        const res = await getUserInfo()
        if (res.code !== 200) {
          this.$message.error(res.msg)
          return
        }
        const { gysType } = res.data
        this.gysType = gysType
        if (gysType === '4') {
          // 若资质是个人，默认所有字段由用户手动输入
        } else {
          // 企业维护界面加载逻辑：若资质是企业，则企业名称、企业地址、企业法人、法人电话、营业执照5个字段由资质带入，且不能修改

          const { companyName, legal, contactTel, yyzzPic, fpdwAddr } = res.data
          this.form.name = companyName // 企业全称
          this.form.contacter = legal // 企业法人
          this.form.contacttel = contactTel // 法人电话
          this.form.addr = fpdwAddr // 企业地址
          this.fileListObj.yyzzPic = [
            { name: '', url:  this.baseUrl + yyzzPic, path: yyzzPic }
          ]
        }
        // 然后获取我的公司信息
        this.getMyCom()
      } catch (error) {
        console.log(error)
      } finally {
        this.msgLoading = false
      }
    },
    async getMyCom() {
      const res = await getCompanyByUser()
      if (this.gysType === '4') {
        // 个人类型
        const { name, contacter, contacttel, yyzzPic, addr } = res.data
        this.form.name = name // 企业全称
        this.form.contacter = contacter // 企业法人
        this.form.contacttel = contacttel // 法人电话
        this.form.addr = addr // 企业地址
        this.fileListObj.yyzzPic = [{ name: '', url: this.baseUrl + yyzzPic }]
      }
      // 对于企业类型，填充剩余字段即可
      const {
        id,
        addr,
        companytype,
        email,
        homepageUrl,
        wechatNumber,
        logopic,
        pic,
        qualitypics,
        videourl,
        decriptions,
        yyzzPic,
        agreementPic
      } = res.data
      this.spForm.id = id
      this.form.addr = addr
      this.form.id = id
      this.form.companytype = companytype
      this.form.email = email
      this.form.homepageUrl = homepageUrl
      this.form.wechatNumber = wechatNumber
      if (logopic) {
        this.form.logopic = logopic
        this.fileListObj.logopic = [
          { name: '', url: this.baseUrl + logopic, path: logopic }
        ]
      } else {
        this.fileListObj.logopic = []
      }
      if (pic) {
        this.form.pic = pic
        this.fileListObj.pic = [{ name: '', url: this.baseUrl + pic, path: pic }]
      } else {
        this.fileListObj.pic = []
      }
      if (qualitypics) {
        this.fileListObj.qualitypics = qualitypics.split(',').map((item) => {
          return {
            name: '',
            url: this.baseUrl + item,
            path: item
          }
        })
        this.form.qualitypics = qualitypics
      } else {
        this.fileListObj.qualitypics = []
      }
      if (videourl) {
        this.form.videourl = videourl
        this.videoPreview = this.baseUrl + videourl
      } else {
        this.form.videourl = ''
      }
      if (agreementPic) {
        this.form.agreementPic = agreementPic
        this.fileListObj.agreementPic = [
          { name: '', url: this.baseUrl + agreementPic, path: agreementPic }
        ]
      } else {
        this.form.agreementPic = ''
        this.fileListObj.agreementPic = []
      }
      if (yyzzPic) {
        this.form.yyzzPic = yyzzPic
        this.fileListObj.yyzzPic = [
          { name: '', url: this.baseUrl + yyzzPic, path: yyzzPic }
        ]
      } else {
        this.fileListObj.yyzzPic = []
      }

      if (decriptions) {
        this.spForm.decriptions = decriptions
      } else {
        this.spForm.decriptions = ''
      }
    },

    // 企业类型
    async getComTypes() {
      const res = await getComTypes()
      const obj = res.data
      let arr = []
      for (let key in obj) {
        arr.push({
          label: obj[key],
          value: +key
        })
      }
      this.comTypes = arr
    },

    // 企业介绍
    updateData(val) {
      this.spForm.decriptions = val
    },
    // 保存
    async save() {
      const res = await editDesc(this.spForm)
      if (res.code !== 200) {
        this.$message.error(res.msg)
        return
      }
      this.$message.success(res.msg)
      this.getMyCom()
    },

    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    }
  }
}
</script>

<style scoped>
.pic-card {
  width: 148px;
  height: 148px;
}
.avatar-uploader-icon {
  border: 1px dashed #d9d9d9 !important;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9 !important;
  border-radius: 6px !important;
  position: relative !important;
  overflow: hidden !important;
}
.avatar-uploader .el-upload:hover {
  border: 1px dashed #d9d9d9 !important;
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 300px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 300px;
  height: 178px;
  display: block;
}
</style>

<style>
.disabled .el-upload--picture-card {
  display: none;
}
</style>