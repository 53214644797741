<template>
  <div class="price-detail">
    <div class="price-detail-card">
      <div class="card-item">
        今日参考价格：
        <span class="card-price">{{ priceObj.price }}</span>
        <span class="unit">(元/公斤)</span>
        <span class="card-grade">{{ mname }}-{{ priceObj.mspec }}</span>
      </div>
      <ul class="card-list">
        <li class="card-list-item">
          <div class="card-list-item-value">
            {{ parseFloat(priceObj.week_range * 100).toFixed(2) }}%
          </div>
          <div class="card-list-item-label">较上周</div>
        </li>
        <li class="card-list-item">
          <div class="card-list-item-value">
            {{ parseFloat(priceObj.month_range * 100).toFixed(2) }}%
          </div>
          <div class="card-list-item-label">较上月</div>
        </li>
        <li class="card-list-item">
          <div class="card-list-item-value">
            {{ parseFloat(priceObj.quarter_range * 100).toFixed(2) }}%
          </div>
          <div class="card-list-item-label">较上季</div>
        </li>
        <li class="card-list-item">
          <div class="card-list-item-value">
            {{ parseFloat(priceObj.yearr_range * 100).toFixed(2) }}%
          </div>
          <div class="card-list-item-label">较上年</div>
        </li>
      </ul>
    </div>
    <div class="list-module">
      <div class="search-module">
        <div class="select-item tp">
          <div>市场：</div>
          <div
            v-for="(item, index) in scOption"
            :key="index"
            :class="{ active: scActive == item.value }"
            @click="
              scActive = item.value
              handleSearch(1)
            "
          >
            {{ item.label }}
          </div>
        </div>
        <div class="select-item tp">
          <div>产地：</div>
          <div
            v-for="(item, index) in cdOption"
            :key="index"
            :class="{ active: cdActive == item }"
            @click="
              cdActive = item
              handleSearch(1)
            "
          >
            {{ item }}
          </div>
        </div>
        <div class="select-item">
          <div>规格：</div>
          <div
            v-for="(item, index) in ggOption"
            :key="index"
            :class="{ active: ggActive == item }"
            @click="
              ggActive = item
              handleSearch(1)
            "
          >
            {{ item }}
          </div>
        </div>
        <div class="select-item">
          <div>时间区间：</div>
          <div
            v-for="(item, index) in sjOption"
            :key="index"
            :class="{ active: sjActive == item }"
            @click="
              sjActive = item
              handleSearch(2)
            "
          >
            {{ item }}
          </div>
        </div>
      </div>
      <div class="list-module-tit">
        <div class="dflex">
          <span class="l-style"></span>
          <span>历史价格图表</span>
        </div>

        <!-- <span>{{mname}}-{{priceObj.mspec}}</span> -->
        <span class="more" @click="moreInfo"
          >更多 <i class="el-icon-arrow-right"></i
        ></span>
      </div>
      <div
        id="container"
        class="container"
        style="width: 1150px; height: 420px"
      ></div>
    </div>
    <div class="list-module">
      <div class="list-module-tit">
        <div class="dflex">
          <span class="l-style"></span>
          <span>历史价格</span>
        </div>
        <!-- <span>{{mname}}-{{priceObj.mspec}}</span> -->
        <span class="more" @click="moreInfo"
          >更多 <i class="el-icon-arrow-right"></i
        ></span>
      </div>
      <div class="table-box">
        <div class="h-table">
          <div
            class="h-tr h-tr-3 h-thead border-line"
            v-for="(table, ind) in tableData"
            :key="ind"
          >
            <div class="h-td" v-for="(item, ind) in table" :key="ind">
              <span v-if="isNumeric(item)" style="color: #ff7436">{{
                item
              }}</span>
              <span v-else>{{ item }}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- <el-table class="xh-table" :data="tableData" style="width: 100%">
        <el-table-column
          prop="name"
          label="月/年"
          width="180"
        ></el-table-column>
        <el-table-column
          prop="guige"
          label="2018"
          width="180"
        ></el-table-column>
        <el-table-column prop="addr" label="2019"></el-table-column>
        <el-table-column prop="jg" label="2020"></el-table-column>
        <el-table-column prop="zdfd" label="2021"></el-table-column>
        <el-table-column prop="action" label="2022"></el-table-column>
      </el-table> -->
    </div>
    <!-- <div class="look-gy" @click="lookGy">查看供应</div> -->
  </div>
</template>

<script>
import {
  getPriceDetail, getPricePcDetail
} from '@/api/price'
import { getAddress } from "@/api/gq";
export default {
  data () {
    return {
      tableData: [],
      priceObj: {},
      currentId: 0,
      mname: '',
      loading: {},
      scActive: 0,
      ggActive: '',
      sjActive: '近四年',
      cdActive: '',
      cdOption: [],
      ggOption: [],
      scOption: [{
        label: '亳州市场',
        value: '亳州市场'
      },
      {
        label: '安国市场',
        value: '安国市场'
      },
      {
        label: '成都市场',
        value: '成都市场'
      },
      {
        label: '玉林市场',
        value: '玉林市场'
      },
      ],
      sjOption: ['全部', '近四年'],
      line: {
        tooltip: {
          trigger: "axis",
        },
        xAxis: [{
          boundaryGap: true,
          type: 'category',
          data: []
        }],
        yAxis: [{
          type: 'value'
        }],
        series: [{
          name: '价格',
          type: 'line',
          stack: '总量',
          symbol: 'circle', //折线上面的点形状
          smooth: true,
          itemStyle: {
            normal: {
              color: '#439CF6',
              areaStyle: {
                type: 'default',
                color: this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{ //渐变颜色
                  offset: 0,
                  color: '#439CF6'
                }, {
                  offset: 1,
                  color: '#FFF'
                }])
              },
              lineStyle: {
                color: '#439CF6'
              }
            }
          },
          data: []
        }]
      }
    }
  },
  created () {
    this.openScreen();
    this.currentId = this.$route.query.id;
    this.mname = this.$route.query.mname;
    this.ggActive = this.$route.query.mspec;
    this.cdActive = this.$route.query.maddr;
    this.scActive = this.$route.query.market;
    this.getPriceDetail();
    this.getPricePcDetail();
    this.getcd()
  },
  mounted () {
    //this.init();
  },
  methods: {
    moreInfo () {
      this.$notify({
        title: '信息',
        message: '获得更多价格信息，请联系客服',
        type: 'info',
        offset: 100,
        duration: 2600
      });
      return
    },
    getcd () {
      // getAllMaddrByName({
      //   mName: this.mname
      // }).then(res => {
      //   this.cdOption = this.cdOption.concat(res.data || [])
      // })
      // getAllMspecByName({
      //   mName: this.mname
      // }).then(res => {
      //   this.ggOption = this.ggOption.concat(res.data || [])
      // })
      getAddress(this.mname).then(res => {
        this.cdOption = this.cdOption.concat(res.data.address || [])
        this.ggOption = this.ggOption.concat(res.data.spec || [])
      })
    },
    handleSearch (arg) {
      if (arg == 2 && this.sjActive == '全部') {
        this.sjActive = '近四年'
        this.moreInfo()
        return
      }
      let that = this;
      getPriceDetail({
        mname: this.mname,
        mspec: this.ggActive,
        maddr: this.cdActive,
        market: this.scActive,
        time: ''
      }).then(res => {
        that.priceObj = res.data.priceObj
        that.line.xAxis[0].data = res.data.jsonArrX
        that.line.series[0].data = res.data.jsonArrY
        console.log(res.data.jsonArrX, res.data.jsonArrY)
        that.init()
      })
      getPricePcDetail({
        mname: this.mname,
        mspec: this.ggActive,
        maddr: this.cdActive,
        market: this.scActive,
        time: ''
      }).then(res => {
        that.tableData = res.data.tablelist
        that.closeScreen();
      })
    },
    openScreen () {
      this.loading = this.$loading({
        lock: true,
        text: '加载中',
        spinner: 'el-icon-loading',
        background: '#fff'
      });
    },
    closeScreen () {
      this.loading.close();
    },
    lookGy () {
      this.$router.push({
        path: '/price',
        query: {
          type: 'ckgy'
        }
      })
    },
    init () {
      let chartDom = document.getElementById('container'); //获取要初始化的htmlyuansu
      let lschart = this.$echarts.init(chartDom); //转化为echarts对象
      this.line && lschart.setOption(this.line); //初始化图表
    },
    getPriceDetail () {
      let that = this;
      getPriceDetail({
        id: that.currentId
      }).then(res => {
        that.priceObj = res.data.priceObj
        that.line.xAxis[0].data = res.data.jsonArrX
        that.line.series[0].data = res.data.jsonArrY
        console.log(res.data.jsonArrX, res.data.jsonArrY)
        that.init()
        that.closeScreen();
      })
    },
    getPricePcDetail () {
      let that = this;
      getPricePcDetail({
        id: that.currentId
      }).then(res => {
        that.tableData = res.data.tablelist
        that.closeScreen();
      })
    },
    isNumeric (str) {
      var reg = /^[0-9]+([.][0-9]+)?$/;
      return reg.test(str);
    },
  }
}
</script>

<style lang="less" scoped>
@import url('../../assets/common.less');

.price-detail {
  max-width: 1190px;
  margin: 20px auto 0;

  &-card {
    box-shadow: 0 0 16px #ebeaea;
    border-radius: 4px;

    .card-item {
      padding: 20px;

      // display: flex;
      .card-price {
        color: red;
        font-size: 18px;
        margin: 0 4px 0 20px;
      }

      .unit {
        font-size: 14px;
      }

      .card-grade {
        margin-left: auto;
        float: right;
        color: red;
        font-size: 20px;
      }
    }

    .card-list {
      display: flex;
      align-items: center;
      padding: 20px 20px 30px;

      &-item {
        flex: 1;
        text-align: center;
        border-right: 1px solid #ddd;

        &-value {
          color: red;
          font-size: 20px;
          margin-bottom: 10px;
        }

        &-label {
        }
      }

      &-item:last-child {
        border-right: 0;
      }
    }
  }

  .search-module {
    width: 100%;
    text-align: center;
    background: #fff;
    padding: 30px 25px;
    box-sizing: border-box;

    .select-item {
      flex-wrap: wrap;
    }

    /deep/ .el-input__inner {
      border: none;
      -webkit-appearance: none;
      background-color: #fff;
      background-image: none;
      border-radius: 0;
      border: 1px solid #d5d5d5;
      box-sizing: border-box;
      color: #606266;
      display: inline-block;
      font-size: inherit;
      height: 40px;
      line-height: 40px;
      outline: 0;
      padding: 0 15px;
      width: 100%;
    }

    .el-icon-search {
      width: 90px;
      margin-left: 39px;
    }

    .el-button {
      border-radius: 0;
    }
  }

  .tp {
    border-bottom: none !important;
  }

  .select {
    margin: auto;
    height: 22px;

    &-item {
      display: flex;
      align-items: center;
      line-height: 42px;
      border: 1px solid #eee;
      padding: 10px 0;

      div {
        font-size: 16px;
        padding: 0px 10px;
        margin-right: 14px;
        cursor: pointer;
      }

      div:first-child {
        margin-right: 0;
      }

      .active {
        background: rgb(20, 99, 158);
        border-radius: 4px;
        color: #fff;
      }
    }
  }

  .list-module {
    padding: 16px 20px;
    box-shadow: 0 0 10px #eee;
    margin-top: 40px;

    .l-style {
      display: inline-block;
      width: 2px;
      height: 18px;
      background: @primary;
      margin-right: 6px;
      margin-left: 0 !important;
    }

    &-tit {
      font-size: 17px;
      margin-bottom: 20px;
      // border-left: 4px solid rgb(20, 99, 158);
      line-height: 20px;

      span:first-child {
        color: red;
        margin-left: 20px;
      }

      .more {
        float: right;
        cursor: pointer;
        color: @primary;
      }
    }

    .xh-table {
      /deep/ th {
        background-color: #eee;
        color: #333;
      }
    }
  }

  .look-gy {
    position: fixed;
    right: 5%;
    top: 50%;
    /* left: 1190px; */
    border-radius: 50%;
    width: 34px;
    height: 50px;
    background-color: rgba(20, 99, 158, 0.6);
    color: #fff;
    padding: 10px 18px;
    box-shadow: 0 0 10px rgb(20 99 158 / 60%);
    cursor: pointer;
  }

  /*表格*/
  .table-box {
    margin-bottom: 20rpx;

    .h-table {
      /* 行 */
      .h-tr {
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: stretch;
        align-content: center;

        // border-color: #ccc;
        // border-style: solid;
        // border-width: 0;
        // border-top-width: 1px;
        // border-left-width: 1px;
        // border-bottom-width: 1px;
        color: #333;

        /* 等比分列，1-6列 */
        // @for $i from 1 through 6
        // {
        // 	&-#{$i}{
        // 		>.h-td{
        // 			width:(100% / $i);
        // 		}
        // 	}
        // }

        + .h-tr {
          border-top-style: none;
        }
      }

      /* 单元格 */
      .h-td {
        border: 1px solid #dedede;
        box-sizing: border-box;
        padding: 10px 6px;
        word-break: break-all;
        width: 16%;
        font-size: 18px;
        // border-color: #ccc;
        // border-style: solid;
        // border-width: 0;
        // border-right-width: 1px;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        align-content: center;
        min-height: 64rpx;

        /* 跨列 */
        &-colspan {
          flex-grow: 1;
          width: 0;
        }

        &-rowspan {
          // border: none;
          border-right-width: 0;
          padding: 0 !important;
          flex-wrap: wrap !important;

          > .h-tr {
            width: 100%;
            border-width: 0;

            .h-td {
              border-right-width: 1px;
            }

            & + .h-tr {
              border-top-width: 1px;
              border-top-style: solid;
            }
          }
        }

        /* 内容顶部对齐 */
        &-top {
          align-items: flex-start;
          align-content: flex-start;
        }

        /* 内容底部对齐 */
        &-bottom {
          align-items: flex-end;
          align-content: flex-end;
        }

        /* 内容左边对齐 */
        &-left {
          justify-content: flex-start;
        }

        /* 内容右边对齐 */
        &-right {
          justify-content: flex-end;
        }
      }

      /* 表头 */
      .h-thead {
        background-color: #fff;
      }
    }
  }
}
</style>