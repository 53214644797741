<template>
  <div class="qy-detail">
    <div class="tab-banxin">
      <div class="tabs-content">
        <h3>{{ formData.company }}</h3>
        <div class="qy-time">
          <span>浏览：</span><span>时间：{{ formData.publishdatetime }}</span>
        </div>
        <div class="qy-desc">
          <ul>
            <li>{{ formData.company }}</li>
            <li>发布时间：{{ formData.publishdatetime }}</li>
            <li>剩余时间：{{ formData.deadline }}</li>
            <li>联系人：{{ formData.contacter }}</li>
            <li>电话：{{ formData.contacttel }}</li>
            <li>招标说明：{{ formData.descriptions }}</li>
          </ul>
        </div>
        <div class="qy-pics">
          <el-image
            v-if="formData.pics"
            :src="'/info-admin' + formData.pics"
            style="width: 100%; height: 100%"
          ></el-image>
        </div>
      </div>
      <rank :hotShop="hotShop" :hotMaterial="hotMaterial" />
    </div>
  </div>
</template>

<script>
import { getHotShop, getHotMaterial, getCompanyPurchaseInfo } from "@/api/gq";
import Rank from './conponents/rank.vue';
export default {
  components: {
    Rank
  },
  data () {
    return {
      formData: [],
      hotShop: [],
      hotMaterial: [],
    };
  },
  created () {
    this.getInfo();
    this.getHotShop() //店铺排行榜
    this.getHotMaterial() //药品排行榜
  },
  methods: {
    getInfo () {
      getCompanyPurchaseInfo(this.$route.query.id).then(res => {
        if (res.code == 200) {
          this.formData = res.data
        }
      })
    },
    getHotShop () {
      getHotShop().then(res => {
        this.hotShop = res.data
      })
    },
    getHotMaterial () {
      getHotMaterial().then(res => {
        this.hotMaterial = res.data

      })
    },
  },
};
</script>

<style lang="less" scoped>
@import url('../../assets/common.less');

.qy-detail {
  padding-bottom: 20px;
  max-width: 1199px;
  margin: 40px auto 0;
}
.tab-banxin {
  display: flex;
  width: 100%;
}
.tabs-content {
  width: 882px;
  padding: 40px 15px 10px;
  font-family: PingFangSC-Semibold, PingFang SC;
  h3 {
    text-align: center;
    height: 33px;
    font-size: 24px;
    font-weight: 600;
    color: #333333;
    line-height: 33px;
  }
  .qy-time {
    font-size: 18px;
    font-weight: 400;
    color: #999999;
    line-height: 25px;
    margin-top: 29px;
    padding-bottom: 6px;
    border-bottom: 1px solid #cfcfcf;
  }
  .qy-desc {
    padding: 40px 50px 0;
    li {
      font-weight: 600;
      font-size: 18px;
      color: #333333;
      line-height: 40px;
    }
  }
  .qy-pics {
    padding: 25px;
  }
}

.content-module {
}
</style>