<template>
  <div class="gq">
    <div class="banxin1">
      <el-carousel trigger="click" height="500px">
        <el-carousel-item v-for="(item, index) in lunboList" :key="index">
          <img class="banner-img" :src="item.url" />
          <router-link
            v-if="item.link_url != ''"
            :to="item.link_url"
            class="linkLun"
          ></router-link>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="tab-banxin">
      <el-tabs
        class="tabs-content"
        v-model="activeName"
        @tab-click="handleClick"
        type="border-card"
      >
        <el-tab-pane label="供应信息" name="first">
          <div class="banxin">
            <div class="search-module">
              <div class="select-item tp">
                <div>品名：</div>
                <el-input
                  type="text"
                  v-model="form.name"
                  style="width: 125px; padding-left: 0"
                  placeholder="请输入"
                >
                </el-input>
                <div>产地：</div>
                <el-input
                  type="text"
                  v-model="form.addr"
                  style="width: 125px; padding-left: 0"
                  placeholder="请输入"
                >
                </el-input>
                <div>规格：</div>
                <el-input
                  type="text"
                  v-model="form.spec"
                  style="width: 125px; padding-left: 0"
                  placeholder="请输入"
                >
                </el-input>
                <el-button
                  style="width: 65px; color: #fff"
                  @click="getList"
                  type="primary"
                  >查询</el-button
                >
                <el-button
                  style="width: 65px; background: #ff5722; color: #fff"
                  @click="resetOne"
                  >重置</el-button
                >
              </div>
              <!-- <div class="select-item tp">
                <div>产地：</div>
                <el-input
                  type="text"
                  v-model="form.addr"
                  style="width: 300px; padding-left: 0"
                  placeholder="请输入"
                >
                </el-input>
                <el-button
                  style="width: 100px; background: #ff5722; color: #fff"
                  @click="getList"
                  >选择</el-button
                >
              </div>
              <div class="select-item tp">
                <div>规格：</div>
                <el-input
                  type="text"
                  v-model="form.spec"
                  style="width: 300px; padding-left: 0"
                  placeholder="请输入"
                >
                </el-input>
                <el-button
                  style="width: 100px; background: #ff5722; color: #fff"
                  @click="getList"
                  >选择</el-button
                >
              </div> -->
              <div class="select-item">
                <div>排序：</div>
                <div
                  v-for="(item, index) in pxOption"
                  :key="index"
                  :class="{ active: pxActive == item.value }"
                  @click="handleToSearch(item)"
                >
                  {{ item.label }}
                </div>
              </div>
            </div>
            <div style="height: 25px;color: #f50">
              温馨提示:本平台是公开发布平台，请注意甄别供求信息的真伪，交易风险需自行把控。
            </div>
            <div class="content-module">
              <div
                v-for="(item, index) in dataList"
                :key="index"
                class="content-div"
              >
                <div class="img" @click="toDetail(item, 0)">
                  <div v-if="item.mtop == 2" class="type showTj"></div>
                  <div v-if="item.mtop == 1" class="type showJs"></div>
                  <div v-if="item.zjState == 1" class="type showZj"></div>
                  <el-image
                    v-if="item.picsurl"
                    :src="'/info-admin' + item.picsurl[0]"
                    style="width: 100%; height: 100%"
                  ></el-image>
                </div>
                <div class="cont-info">
                  <el-row class="mb10">
                    <el-col :span="8">
                      品名：<span class="proName" @click="toSeekDetail(item, 0)">{{ item.name }}</span>
                    </el-col>
                    <el-col :span="8">规格：{{ item.spec }} </el-col>
                    <el-col :span="8">单价：{{ item.price }} </el-col>
                  </el-row>
                  <el-row class="mb10">
                    <el-col :span="8"> 数量：{{ item.stockcount }}{{item.unit}} </el-col>
                    <el-col :span="8">产地：{{ item.addr }} </el-col>
                    <el-col :span="8">货源所在地：{{ item.stockaddr }} </el-col>
                  </el-row>
                  <el-row class="mb10">
                    <el-col :span="8">
                      发布：{{ item.publishdatetime ? item.publishdatetime.split(' ')[0] : '' }}</el-col
                    >
                    <el-col :span="8"
                      >有效期：剩余{{ item.deadline }}天
                    </el-col>
                    <el-col :span="8" class="colorRed">
                      <div class="smrz">
                        <div>实名认证：</div>
                        <img
                          style="height: 35px; text-align: center"
                          src="@/assets/imgs/smrz.png"
                          alt=""
                        />
                      </div>
                    </el-col>
                  </el-row>
                  <el-row class="mb10">
                    <el-col :span="8" class="colorRed">
                      联系人：{{ item.contacter }}</el-col
                    >
                    <el-col :span="16" class="colorRed"
                      >手机：{{ item.contacttel }}
                    </el-col>
                  </el-row>
                  <el-row class="mb10">
                    <el-col :span="24" class="colorRed">
                      地址：{{ item.idcadeno }}</el-col
                    >
                  </el-row>
                </div>
              </div>
            </div>
            <el-pagination
              class="pagination-style"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="pagination.page"
              :page-sizes="[1, 10, 20, 50, 100]"
              :page-size="pagination.size"
              layout="total, sizes, prev, pager, next, jumper"
              :total="this.total"
            >
            </el-pagination>
          </div>
        </el-tab-pane>
        <el-tab-pane label="求购信息" name="second">
          <div class="banxin">
            <div class="search-module">
              <div class="select-item tp">
                <div>品名：</div>
                <el-input
                  type="text"
                  v-model="seekForm.name"
                  style="width: 125px; padding-left: 0"
                  placeholder="请输入"
                >
                </el-input>
                <div>产地：</div>
                <el-input
                  type="text"
                  v-model="seekForm.addr"
                  style="width: 125px; padding-left: 0"
                  placeholder="请输入"
                >
                </el-input>
                <div>类型：</div>
                <el-select v-model="seekForm.type" clearable placeholder="请选择" style="width: 125px; padding-left: 0">
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
                <el-button
                  style="width: 65px; color: #fff"
                  type="primary"
                  @click="changeSeekName"
                  >查询</el-button
                >
                <el-button
                  style="width: 65px; background: #ff5722; color: #fff"
                  @click="resetTwo"
                  >重置</el-button
                >
              </div>
              <!-- <div class="select-item">
                <div>产地：</div>
                <el-input
                  type="text"
                  v-model="seekForm.addr"
                  style="width: 300px; padding-left: 0"
                  placeholder="请输入"
                >
                </el-input>
                <el-button
                  style="width: 100px; background: #ff5722; color: #fff"
                  @click="changeSeekName"
                  >选择</el-button
                >
              </div> -->
              <div class="select-item">
                <div>排序：</div>
                <div
                  v-for="(item, index) in qgOption"
                  :key="index"
                  :class="{ active: qgActive == item.value }"
                  @click="handleToSearchQg(item)"
                >
                  {{ item.label }}
                </div>
              </div>
            </div>
            <div style="height: 25px;color: #f50">
              温馨提示:本平台是公开发布平台，请注意甄别供求信息的真伪，交易风险需自行把控。
            </div>
            <div class="content-module">
              <div
                v-for="(item, index) in seekData"
                :key="index"
                class="content-div"
              >
                <div class="img" @click="toSeekDetail(item, 1)">
                  <div v-if="item.type == 1" class="typeOther showGr"></div>
                  <div v-if="item.type == 2" class="typeOther showQy"></div>
                  <el-image
                    v-if="item.pics"
                    :src="'/info-admin' + item.pics[0]"
                    style="width: 100%; height: 100%"
                  ></el-image>
                </div>
                <div class="cont-info">
                  <el-row class="mb10">
                    <el-col :span="8">
                      品名：<span class="proName" @click="toSeekDetail(item, 1)">{{ item.name }}</span>
                    </el-col>
                    <el-col :span="8">规格：{{ item.spec }} </el-col>
                    <el-col :span="8">单价：{{ item.price }} </el-col>
                  </el-row>
                  <el-row class="mb10">
                    <el-col :span="8"> 数量：{{ item.buycount }}{{item.unit}} </el-col>
                    <el-col :span="8">产地：{{ item.addr }} </el-col>
                    <el-col :span="8">货源所在地：{{ item.addr }} </el-col>
                  </el-row>
                  <el-row class="mb10">
                    <el-col :span="8">
                      发布：{{ item.publishdatetime ? item.publishdatetime.split(' ')[0] : '' }}</el-col
                    >
                    <el-col :span="8"
                      >有效期：剩余{{ item.deadline }}天
                    </el-col>
                    <el-col :span="8" class="colorRed">
                      <div class="smrz">
                        <div>实名认证：</div>
                        <img
                          style="height: 35px; text-align: center"
                          src="@/assets/imgs/smrz.png"
                          alt=""
                        />
                      </div>
                    </el-col>
                  </el-row>
                  <el-row class="mb10">
                    <el-col :span="8" class="colorRed">
                      联系人：{{ item.contacter }}</el-col
                    >
                    <el-col :span="8" class="colorRed"
                      >手机：{{ item.contacttel }}
                    </el-col>
                    <el-col :span="8">
                      <div class="xjly" @click="getComment(item)">询价留言</div>
                    </el-col>
                  </el-row>
                  <el-row class="mb10">
                    <el-col :span="24" class="colorRed">
                      地址：{{ item.deliveryaddr }}</el-col
                    >
                  </el-row>
                </div>
              </div>
            </div>
            <el-pagination
              class="pagination-style"
              @size-change="handleSizeChange1"
              @current-change="handleCurrentChange1"
              :current-page="pagination1.page"
              :page-sizes="[1, 10, 20, 50, 100]"
              :page-size="pagination1.size"
              layout="total, sizes, prev, pager, next, jumper"
              :total="this.seekTotal"
            >
            </el-pagination>
          </div>
        </el-tab-pane>

        <!-- <el-tab-pane label="企业求购" name="third">
          <div class="banxin">
            <div class="content-module">
              <div
                v-for="(item, index) in qyData"
                :key="index"
                class="content-qy"
                @click="toQyDetail(item)"
              >
                <div class="qy-title">
                  {{ item.addr
                  }}<img
                    style="
                      height: 28px;
                      vertical-align: middle;
                      margin-left: 10px;
                    "
                    src="@/assets/imgs/document.png"
                    alt=""
                  />
                </div>
                <div class="qy-time">{{ item.publishdatetime }}</div>
              </div>
            </div>
            <el-pagination
              class="pagination-style"
              @size-change="handleSizeChangeQy"
              @current-change="handleCurrentChangeQy"
              :current-page="paginationQy.pageIndex"
              :page-sizes="[1, 10, 20, 50, 100]"
              :page-size="paginationQy.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="this.qyTotal"
            >
            </el-pagination>
          </div>
        </el-tab-pane> -->
      </el-tabs>

      <rank :hotShop="hotShop" :hotMaterial="hotMaterial" />
    </div>

    <el-dialog
      title="询价留言"
      :visible.sync="dialogFormVisible"
      :show-close="false"
    >
      <el-form
        :model="messageForm"
        :rules="rules"
        ref="messageForm"
        label-width="120px"
      >
        <el-row>
          <el-col :span="22">
            <el-form-item label="标题" prop="title">
              <el-input
                class="dialog-input"
                v-model="messageForm.title"
                autocomplete="off"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="22">
            <el-form-item label="内容" prop="content">
              <el-input
                v-model="messageForm.content"
                type="textarea"
                :rows="5"
                autocomplete="off"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="22">
            <el-form-item label="联系方式" prop="phoneNum">
              <el-input
                class="dialog-input"
                v-model="messageForm.phoneNum"
                autocomplete="off"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <!-- <el-button type="primary" @click="submitComment">确 定</el-button>
        <el-button @click="dialogFormVisible = false">取 消</el-button> -->
        <el-button class="footer-sub" @click="submitComment" :disabled="isDisable">确认</el-button>
        <el-button class="footer-del" @click="dialogFormVisible = false">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getList, getHotShop, getHotMaterial, getSeekList, getAddress, messageSave, getListCompanyPurchase } from "@/api/gq";
import { saveView } from "@/api/index";
import FromRules from '@/utils/fromRules'
import { getSwiperLimitN } from "@/api/comment";
import Rank from "./conponents/rank.vue";
export default {
  components: {
    Rank
  },
  data () {
    return {
      isDisable: false, //禁用确认按钮
      activeName: this.$route.query.param||'first',
      lunboList: [],
      keyword: "",
      pagination: {
        size: 10,
        page: 1,
      },
      total: 0,
      seekTotal: 0,
      pagination1: {
        size: 10,
        page: 1,
      },
      paginationQy: {
        pageIndex: 1,
        pageSize: 10,
      },
      qyTotal: 0,

      dataList: [],
      seekData: [],
      qyData: [],
      cdOption: ['全部'],
      ggOption: ['全部'],
      pxOption: [
        {
          label: '急售',
          value: '1'
        },
        {
          label: '推荐',
          value: '2'
        },
        {
          label: '质检',
          value: '3'
        },
        {
          label: '数量',
          value: '4'
        },
        {
          label: '价格',
          value: '5'
        }
      ], qgOption: [{
        label: '按剩余时间',
        value: 1
      }, {
        label: '按最新输入',
        value: 0
      },
      {
        label: '按数量排序',
        value: 2
      }],
      cdActive: 0, //产地
      ggActive: 0, //规格
      pxActive: undefined, //排序
      qgActive: undefined, //求购
      form: {
        name: "", //药品名
        addr: undefined, //产地
        spec: undefined, //规格
        mtop: undefined, //状态排序 1急售 2推荐
        qualityState: undefined, //质检排序 0不按质检排序 1质检在前
        stockcount: undefined,
        price: undefined,
        page: 1,
        pageSize: 10,
      },
      seekForm: {
        name: "", //药品名
        addr: '', // 产地
        type: '', // 类型
        order: undefined, //排序 0根据创建时间倒序 1根据剩余时间升序      
        page: 1,
        pageSize: 10,
      },
      options: [
        {value: 1, label: '个人求购'},
        {value: 2, label: '企业求购'},
      ],
      hotShop: [],
      hotMaterial: [],
      dialogFormVisible: false,
      commentId: undefined,
      messageForm: {
        title: '',
        content: '',
        phoneNum: '',
      },
      rules: {
        title: [
          { required: true, message: '请输入标题', trigger: 'blur' },
        ],
        content: [
          { required: true, message: '请输入内容', trigger: 'blur' },
        ],
        phoneNum: [
          { required: true, message: '请输入联系方式', trigger: 'change' },
          { validator: FromRules.validatePhone, trigger: 'change' },
        ],
      },
    };
  },
  created () {
    this.getInfo();
    this.getHotShop() //店铺排行榜
    this.getHotMaterial() //药品排行榜
    this.getLunbotu();

  },
  mounted () {
  },
  methods: {
    // Banner图
    getLunbotu () {
      getSwiperLimitN({
        limitN: 3,
        location: 12,
      }).then((res) => {
        if (res.code == 200) {
          this.lunboList = res.data;
          console.log("lunbotu", res);
        }
      });
    },
    getHotShop () {
      getHotShop().then(res => {
        this.hotShop = res.data
      })
    },
    getHotMaterial () {
      getHotMaterial().then(res => {
        this.hotMaterial = res.data

      })
    },
    getInfo () {
      if (this.$route.query.mname) {
        this.form.name = this.$route.query.mname
        this.form.addr = this.$route.query.maddr
        this.form.spec = this.$route.query.mspec
        this.getList()
        // this.getAllByName()
      } else {
        this.getList()
        this.getSeek()
      }
    },
    // changeName () {
    //   this.cdOption = ['全部']
    //   this.ggOption = ['全部']
    //   this.form.addr = ''
    //   this.form.spec = ''
    //   this.cdActive = 0
    //   this.ggActive = 0
    //   this.getAllByName()
    // },
    changeSeekName () {
      this.qgActive = undefined
      this.form.order = undefined
      this.form.stockcount = undefined
      this.getSeek()
    },
    resetOne() {
      this.form.name = undefined
      this.form.addr = undefined
      this.form.spec = undefined
      this.getList()
    },
    resetTwo() {
      this.seekForm.name = undefined
      this.seekForm.addr = undefined
      this.seekForm.type = undefined
      this.changeSeekName()
    },
    //获取对应品名的产地和规格
    // getAllByName () {
    //   getAddress(this.form.name).then(res => {
    //     this.cdOption = this.cdOption.concat(res.data.address || [])
    //     this.cdActive = this.cdOption.findIndex(item => { return item === this.form.addr })
    //     this.ggOption = this.ggOption.concat(res.data.spec || [])
    //     this.ggActive = this.ggOption.findIndex(item => { return item === this.form.spec })
    //   })
    //   this.getList()
    // },
    handleToSearch (item) {
      console.log(item)
      console.log(this.pxActive)
      this.form.mtop = undefined
      this.form.qualityState = undefined
      this.form.stockcount = undefined
      this.form.price = undefined
      if (this.pxActive == item.value) {
        this.pxActive = undefined
      } else {
        this.pxActive = item.value
      }
      this.getList()
    },
    handleToSearchQg (item) {
      this.seekForm.stockcount = undefined
      this.seekForm.order = undefined
      if (this.qgActive == item.value) {
        this.qgActive = undefined
      } else {
        this.qgActive = item.value
      }
      this.getSeek()
    },

    handleClick () {
      if (this.activeName == 'first') {
        this.getList()
      } else if (this.activeName == 'second') {
        this.getSeek()
      } else {
        this.getQyList()
      }
    },
    getList () {
      // if (this.form.addr == '全部') {
      //   this.form.addr = ''
      // }
      // if (this.form.spec == '全部') {
      //   this.form.spec = ''
      // }
      if (this.pxActive == '1') {
        this.form.mtop = '1'
        this.form.qualityState = undefined
        this.form.stockcount = undefined
        this.form.price = undefined
      }
      if (this.pxActive == '2') {
        this.form.mtop = '2'
        this.form.qualityState = undefined
        this.form.stockcount = undefined
        this.form.price = undefined
      }
      if (this.pxActive == '3') {
        this.form.qualityState = '1'
        this.form.stockcount = undefined
        this.form.price = undefined
        this.form.mtop = undefined
      }
      if (this.pxActive == '4') {
        this.form.stockcount = '1'
        this.form.mtop = undefined
        this.form.qualityState = undefined
        this.form.price = undefined
      }
      if (this.pxActive == '5') {
        this.form.price = '1'
        this.form.mtop = undefined
        this.form.qualityState = undefined
        this.form.stockcount = undefined
      }
      getList({
        name: this.form.name,
        addr: this.form.addr,
        spec: this.form.spec,
        mtop: this.form.mtop,
        qualityState: this.form.qualityState,
        stockcount: this.form.stockcount,
        price: this.form.price,
        page: this.form.page,
        pageSize: this.form.pageSize,
      }).then((res) => {
        if (res.code == 200) {
          this.dataList = res.data.data;
          this.dataList.forEach(e => {
            if (e.picsurl && e.picsurl.length >0) {
              e.picsurl = e.picsurl.split(',')
            }
          });
          this.total = res.data.total;
        }
      });
    },
    getSeek () {
      if (this.qgActive == 2) {
        this.seekForm.stockcount = 1
        this.seekForm.order = undefined
      } else {
        this.seekForm.stockcount = undefined
        this.seekForm.order = this.qgActive
      }
      getSeekList(this.seekForm).then(res => {
        if (res.code == 200) {
          this.seekData = res.data.data
          this.seekData.forEach(e => {
            if (e.pics && e.pics.length >0) {
              e.pics = e.pics.split(',')
            }
          });
          this.seekTotal = res.data.total
        }
      })
    },
    getQyList () {
      getListCompanyPurchase(this.paginationQy).then(res => {
        this.qyData = res.data.data
        this.qyTotal = res.data.total
      })
    },
    handleSizeChange (val) {
      this.pagination.size = val;
      this.pagination.page = 1;
      this.form.size = val;
      this.form.page = 1;
      this.getList();
    },
    handleCurrentChange (val) {
      this.pagination.page = val;
      this.form.page = val;
      this.getList();
    },
    handleSizeChange1 (val) {
      this.pagination1.size = val;
      this.pagination1.page = 1;
      this.seekForm.size = val;
      this.seekForm.page = 1;
      this.getList();
    },
    handleCurrentChange1 (val) {
      this.pagination1.page = val;
      this.seekForm.page = val;
      // this.getList();
       this.getSeek()
    },
    handleSizeChangeQy (val) {
      this.paginationQy.pageSize = val;
      this.paginationQy.pageIndex = 1;
      this.getList();
    },
    handleCurrentChangeQy (val) {
      this.paginationQy.pageIndex = val;
      this.getQyList();
    },

    toDetail (item, type) {
      saveView({
        viewType: 0,
        relateId: item.id,
        type: type
      }).then((res) => { });
      this.$router.push({
        name: "gqDetail",
        query: {
          id: item.id,
          type: type
        },
      });
    },
    toSeekDetail (item, type) {
      saveView({
        viewType: 3,
        relateId: item.id,
        type: type
      }).then((res) => { });
      this.$router.push({
        name: "gqDetail",
        query: {
          id: item.id,
          type: type,
          purchaseType: item.type // 区分个人企业求购
        },
      });
    },

    toQyDetail (item) {
      this.$router.push({
        name: "qyDetail",
        query: {
          id: item.id
        },
      });
    },

    getComment (item) {
      this.commentId = item.id
      this.dialogFormVisible = true
      this.isDisable = true
    },
    submitComment () {
      const query = {
        title: this.messageForm.title,
        content: this.messageForm.content,
        phoneNum: this.messageForm.phoneNum,
        pId: this.commentId,
        type: 1
      }
      this.$refs.messageForm.validate((valid) => {
        if (valid) {
          messageSave(query).then(res => {
            if (res.code == 200) {
              this.dialogFormVisible = false
              this.$message.success('留言成功')
              this.isDisable = true
            }
          })
        }
      })
    },
  },
};
</script>

<style lang="less" scoped>
@import url('../../assets/common.less');
/deep/ .el-table--enable-row-hover .el-table__body tr > td.el-table__cell {
  background: #fff;
  padding-left: 15px;
}

/deep/ .el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border-bottom: 1px dashed #f1f1f1;
}

/deep/ .el-table th.el-table__cell > .cell {
  padding-left: 25px;
  padding-right: 20px;
}

/deep/.el-pagination .el-select .el-input {
  border: 1px solid #f4f4f5;
}

/deep/ .el-pagination__editor.el-input {
  border: 1px solid #f4f4f5;
}

/deep/ .el-pager li.active {
  color: #fff;
  background: #3883e5;
}

/deep/ .el-pager li {
  background: #f4f4f5;
  margin: 0 2px;
  color: #686a6e;
}

/deep/ .el-button--primary.is-plain:hover {
  background: #3883e5;
}

/deep/ .el-tabs__item {
  font-size: 17px;
}

/deep/ .el-range-editor.is-disabled input {
  color: #000000;
  font-size: 16px;
}

::v-deep .el-form-item__label {
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 28px;
}

::v-deep .el-dialog {
  border-radius: 10px;
}
::v-deep .el-dialog__header {
  text-align: center;
  height: 76px;
  background: #1b86fb;
  padding: 0;
  line-height: 76px;
  margin: 0 auto;
}
::v-deep .el-dialog__body {
  background: #f9f8f8;
}
::v-deep .el-dialog__footer {
  text-align: center;
  background: #f9f8f8;
}

::v-deep .el-dialog__title {
  font-size: 30px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
}
.dialog-footer {
  display: flex;
  justify-content: center;
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  line-height: 60px;
  .footer-sub {
    width: 144px;
    height: 60px;
    background: #1b86fb;
    border-radius: 6px;
    color: #ffffff;
    border: 2px solid #1b86fb;
    margin-right: 185px;
  }
  .footer-del {
    width: 144px;
    height: 60px;
    border-radius: 6px;
    border: 2px solid #1b86fb;
    color: #1b86fb;
  }
  .dialog-input input {
    height: 45px;
    background: #ffffff;
    border: 1px solid #dedede;
  }
}
.gq {
  background: @beijingse;
  padding-bottom: 20px;
  .select-item {
    flex-wrap: wrap;
  }
  .proName {
    cursor: pointer;
    &:hover {
      outline: 0;
      color: #f50;
      text-decoration: underline;
    }
  }
  .s-txt {
    font-size: 12px;
    color: #666666;
    line-height: 40px;
    margin-right: 10px;
  }

  .search-module {
    width: 100%;
    text-align: center;
    background: #fff;
    padding: 30px 0 20px;
    box-sizing: border-box;
    padding-top: 10px;

    /deep/ .el-input__inner {
      border: none;
      -webkit-appearance: none;
      background-color: #fff;
      background-image: none;
      // border-radius: 0;
      border: 1px solid #d5d5d5;
      box-sizing: border-box;
      color: #606266;
      display: inline-block;
      font-size: inherit;
      height: 42px;
      line-height: 40px;
      outline: 0;
      padding: 0 15px;
      width: 100%;
    }

    .el-icon-search {
      width: 90px;
      margin-left: 39px;
    }

    .el-button {
      border-radius: 0;
    }
  }

  .tp {
    border-bottom: none !important;
  }

  .select {
    margin: auto;
    height: 22px;

    &-item {
      display: flex;
      align-items: center;
      line-height: 42px;
      border: 1px solid #eee;
      padding: 10px 0;

      div {
        font-size: 16px;
        padding: 0px 10px;
        margin-right: 14px;
        cursor: pointer;
      }

      div:first-child {
        margin-right: 0;
      }

      .active {
        background: rgb(20, 99, 158);
        border-radius: 4px;
        color: #fff;
      }
    }
  }

  .list-module {
    // padding-left:40px;
    // background:#fff;
    .el-table {
      font-size: 12px;
      color: #666;
    }

    .xh-table {
      /deep/ th {
        background-color: #f1f1f1;
        color: #666;
        height: 40px;
        font-size: 12px;
        padding-top: 5px;
        padding-bottom: 5px;
      }
    }
  }

  .pagination-style {
    text-align: right;
    margin-bottom: 20px;
    background: #fff;
    padding: 16px 0;
    padding-right: 20px;
    // border-bottom-left-radius:5px;
    //  border-bottom-right-radius:5px;
  }
}

.tab-banxin {
  display: flex;
  max-width: 1208px;
  margin: 40px auto 0;
}
.tabs-content {
  width: 882px;
  height: 800px;
  overflow: auto;
}

.banxin1 {
  width: 100%;
  text-align: center;
}
.linkLun {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
.content-module {
  .content-div {
    width: 100%;
    height: 205px;
    display: flex;
    border-bottom: 1px solid #cfcfcf;
    justify-content: space-between;
    margin-top: 20px;
    padding-bottom: 20px;

    .img {
      width: 204px;
      height: 205px;
      position: relative;
      margin-right: 20px;
      cursor: pointer;
      .showTj {
        left: 0;
        background: url(../../assets/imgs/tuijian.png) no-repeat;
      }
      .showJs {
        left: 0;
        background: url(../../assets/imgs/jishou.png) no-repeat;
      }
      .showZj {
        right: 0;
        background: url(../../assets/imgs/jian.png) no-repeat;
      }
      .showYx {
        left: 0;
        background: url(../../assets/imgs/youxian.png) no-repeat;
      }
      .showGr {
        right: 0;
        background: url(../../assets/imgs/grqg.png) no-repeat;
      }
      .showQy {
        right: 0;
        background: url(../../assets/imgs/qyqg.png) no-repeat;
      }
      .type {
        position: absolute;
        top: 0;
        width: 46px;
        height: 46px;
        z-index: 999;
      }
      .typeOther {
        position: absolute;
        top: 0;
        width: 118px;
        height: 65px;
        z-index: 999;
      }
    }
    .cont-info {
      width: 628px;
      .smrz {
        width: 100%;
        height: 35px;
        line-height: 35px;
        display: flex;
      }
      .xjly {
        text-align: center;
        width: 102px;
        height: 35px;
        background: #eeeeee;
        border-radius: 2px;
        border: 1px solid #cfcfcf;

        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 35px;
      }
      .el-row {
        height: 35px;
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #4f5156;
        line-height: 35px;
      }
      .mb10 {
        margin-bottom: 10px;
      }
      .colorRed {
        color: red;
      }
      .text {
        text-align: left;
        margin-bottom: 20px;
        overflow: hidden;
      }
    }
  }
  .content-qy {
    width: 100%;
    border-bottom: 1px solid #cfcfcf;
    padding: 40px 0 20px;
    cursor: pointer;
    .qy-title {
      height: 28px;
      font-size: 20px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #4f5156;
      line-height: 28px;
    }
    .qy-time {
      text-align: right;
      margin-top: 20px;
      height: 25px;
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #4f5156;
      line-height: 25px;
    }
  }
}
</style>