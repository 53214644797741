<template>
  <div class="zsDetail">
    <div
      class="goback"
      @click="goback"
    >
      <el-button
        type="primary"
        style="width: 100px;"
        @click="goback"
        >返回</el-button
      >
    </div>
    <div class="zs-title">
      {{ title }}
    </div>
    <div class="zs-subin">
      来源：安徽亳州中医药集团&nbsp;&nbsp;浏览：{{ liulan }}&nbsp;&nbsp;时间：{{
        time
      }}
    </div>
    <div class="zs-html" v-html="content"></div>
  </div>
</template>

<script>
  import {
    zhishuDetail,
    codeDetail,
    getViewCount
  } from "@/api/price";
  export default {
    name: "ZsDetail",
    data() {
      return {
        id: null,
        liulan: "",
        time: "",
        title: "",
        content: "",
        type: "",
      };
    },
    created() {
      this.id = this.$route.query.id;
      this.type = this.$route.query.type;
      this.getDetail();
    },
    methods: {
      goback() {
        this.$router.push({
          name: 'price',
          query: {
            activeName: 'third',
          },
        })
      },
      getDetail() {
        getViewCount({
          viewType: 2,
          relateId: this.id
        }).then(res => {
          this.liulan = res.data;
        })
        if(this.type=='coden'){
          codeDetail({
          id: this.id,
        }).then((res) => {
          this.time = res.data.pubDate || "";
          this.title = res.data.title || "";
          this.content = res.data.content || "";
        });
        }else{
          zhishuDetail({
          id: this.id,
        }).then((res) => {
          this.time = res.data.pubDate || "";
          this.title = res.data.title || "";
          this.content = res.data.content || "";
        });
        }
      },
    },
  };
</script>

<style lang="less" scoped>
  .zsDetail {
    max-width: 1190px;
    margin: 40px auto 0;

    .goback {
      padding: 10px;
    }

    .zs-title {
      width: 100%;
      text-align: center;
      font-size: 34px;
    }

    .zs-subin {
      padding: 20px 10px;
      color: #888;
      border-bottom: 1px dashed #ddd;
    }

    .zs-html {
      padding: 20px 10px;
      letter-spacing: 1px;
      line-height: 30px;
    }
  }
</style>
