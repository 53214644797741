<template>
  <el-dialog
    title="我要贷款"
    :visible.sync="visibleDetail"
    width="500px"
    class="finance-detail"
    :close-on-click-modal="false"
    label-width="130px"
  >
    <div class="form">
      <el-form size="small">
        <!-- <el-col :span="14"> -->
        <el-form-item label="姓名" required>
          <div class="price-module">
            <el-input
              placeholder="请输入姓名"
              size="small"
              v-model="form.name"
            ></el-input>
          </div>
        </el-form-item>
        <el-form-item label="联系方式" required>
          <div class="price-module">
            <el-input
              type="number"
              placeholder="请输入联系方式"
              size="small"
              v-model="form.phone"
            ></el-input>
          </div>
        </el-form-item>
        <el-form-item label="身份证号码" required>
          <div class="price-module">
            <el-input
              type="number"
              placeholder="请输入身份证号码"
              size="small"
              v-model="form.codeNum"
            ></el-input>
          </div>
        </el-form-item>
        <el-form-item label="上传身份证正反面">
          <el-upload
            action=""
            list-type="picture-card"
            :on-preview="handlePictureCardPreview"
            :on-remove="handleRemove"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
          <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" alt="" />
          </el-dialog>
        </el-form-item>
      </el-form>
    </div>
    <div slot="footer" class="flex-jac">
      <el-button size="small" @click="visibleDetail = false">取消</el-button>
      <el-button size="small" type="primary" @click="visibleDetail = false"
        >确认</el-button
      >
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },

  },
  computed: {
    visibleDetail: {
      get () {
        return this.visible;
      },
      set (val) {
        this.$emit('update:visible', val);
      },
    },
  },

  name: 'financeDetail',
  data () {
    return {
      form: {
        codeNum: '',
        name: '',
        phone: ''
      },
      dialogImageUrl: '',
      dialogVisible: false,
      fileList: []
    };
  },
  methods: {
    handleRemove (file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview (file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    }
  },
};
</script>

<style lang="less" scoped>
.finance-detail {
  .form {
    padding: 0 20px;

    // .upload-style{
    //   width: 50px;
    //   height: 50px;
    // }
    /deep/ .el-upload--picture-card {
      background-color: #fbfdff;
      border: 1px dashed #c0ccda;
      border-radius: 6px;
      box-sizing: border-box;
      width: 80px;
      height: 80px;
      cursor: pointer;
      line-height: 80px;
      vertical-align: top;
    }
    .el-upload--picture-card i {
      font-size: 24px;
      color: #8c939d;
    }
    .price-module {
      display: inline-block;
    }
  }
  .flex-jac {
    text-align: center;
  }

  /deep/.el-dialog__body {
    padding: 20px;
  }
  /deep/.el-form-item__label {
    width: 130px;
  }
}
</style>
