/*
 * @Author: guojiren 7755@123.com
 * @Date: 2022-09-05 16:48:00
 * @LastEditors: guojiren 7755@123.com
 * @LastEditTime: 2022-09-22 15:17:26
 * @FilePath: \xinghe-xinxi-pc\src\main.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'element-ui/lib/theme-chalk/index.css'
import 'font-awesome/css/font-awesome.css'
import '@/assets/common.less'
import '@/assets/base.less'
import Element from 'element-ui'
import { Loading } from 'element-ui'
import { recover } from '@/utils/index'

import VueHtml5Editor from 'vue-html5-editor'

import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts

Vue.prototype.recover = recover

Vue.prototype.$store = store

Vue.use(Element)
Vue.use(VueHtml5Editor, {
  icons: {
    text: "fa fa-pencil",
    color: "fa fa-paint-brush",
    font: "fa fa-font",
    align: "fa fa-align-justify",
    list: "fa fa-list",
    link: "fa fa-chain",
    unlink: "fa fa-chain-broken",
    tabulation: "fa fa-table",
    image: "fa fa-file-image-o",
    hr: "fa fa-minus",
    eraser: "fa fa-eraser",
    undo: "fa-undo fa",
    "full-screen": "fa fa-arrows-alt",
    info: "fa fa-info",
  },
  language: "zh-cn"
});

Vue.config.productionTip = false
Vue.prototype.openLoading = function () {
  const loading = this.$loading({
    // 声明一个loading对象
    fullscreen: true,
    lock: true, // 是否锁屏
    text: '加载中', // 加载动画的文字
    spinner: 'el-icon-loading', // 引入的loading图标
    background: 'rgba(190,189,189,0.8)', // 背景颜色
    // target: '.el-table, .table-flex, .region',       // **需要遮罩的区域，这里写要添加loading的选择器**
    // fullscreen: false,
    customClass: 'loadingclass', // **遮罩层新增类名，如果需要修改loading的样式**
  })
  setTimeout(function () {
    // 设定定时器，超时3S后自动关闭遮罩层，避免请求失败时，遮罩层一直存在的问题
    loading.close() // 关闭遮罩层
  }, 3000)
  return loading
}
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
