<template>
  <div class="bid-detail">
    <div class="back_icons" @click="toBack()">
      <i class="el-icon-back"></i>
    </div>
    <div class="bgan" @click="showBaojia">
      <img src="@/assets/imgs/plbj.png" alt="" />
    </div>
    <div class="contents">
      <div class="companyName" >
        <h1 style="text-align: center;">{{ caiMain.conpanyname }}</h1>
        <div v-html="caiMain.content" style="margin-top: 10px"></div>
        <div class="fujian" v-if="caiMain.file">
          <a :href="infoUrl + caiMain.file" download target="_blank"
            ><img src="@/assets/imgs/fujian.svg" alt="" />{{
              caiMain.filename
            }}</a
          >
        </div>
      </div>
      <div class="breedList">
        <div class="breedMess" v-for="(item, index) in caigouList" :key="index">
          <el-row :gutter="20" class="rows">
            <el-col :span="6"
              >品名：<span style="color: #1b86fb">{{
                item.materialname ? item.materialname : ''
              }}</span></el-col
            >
            <el-col :span="6"
              >规格：<span>{{
                item.specremark ? item.specremark : ''
              }}</span></el-col
            >
            <el-col :span="6"
              >数量：<span
                >{{ item.count ? item.count : ''
                }}{{ item.unit ? item.unit : '' }}</span
              ></el-col
            >
            <el-col :span="6"
              >产地：<span>{{ item.addr ? item.addr : '' }}</span></el-col
            >
          </el-row>
          <el-row :gutter="20" class="rows">
            <el-col
              >质量标准：<span>{{
                item.standard ? item.standard : ''
              }}</span></el-col
            >
          </el-row>
        </div>
      </div>
      <!-- <div class="fyq">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage4"
          :page-sizes="[5, 10, 15, 20]"
          :page-size="100"
          layout="total, sizes, prev, pager, next, jumper"
          :total="400">
        </el-pagination>
      </div> -->
      <!-- <el-row
        :gutter="20"
        class="right"
        v-if="show && (caiMain.bidStatus == 2 || caiMain.bidStatus == 7)"
      >
        <el-col :span="8" class="bonds"
          ><span style="font-size: 14px"
            ><i style="color: #e82828">*</i> 上传保证金凭证</span
          >
          <el-upload
            class="avatar-uploader"
            action="#"
            :show-file-list="false"
            :before-upload="beforeAvatarUpload"
            :http-request="handleYyzzFileUpload1"
          >
            <img v-if="depositPic" :src="infoUrl + depositPic" class="avatar" />
            <div v-else class="avatar-uploader-icon">
              <i class="el-icon-plus icons"></i>
            </div>
          </el-upload></el-col
        >
        <el-col :span="6" class="bonds"
          ><span style="font-size: 14px"
            ><i style="color: #e82828">*</i> 资料凭证</span
          >
          <el-upload
            class="avatar-uploader"
            action="#"
            :show-file-list="false"
            :before-upload="beforeAvatarUpload"
            :http-request="handleYyzzFileUpload2"
          >
            <div class="avatar-uploader-icon first">
              <i class="el-icon-plus icons"></i>
            </div>
          </el-upload></el-col
        >
        <el-col
          :span="8"
          class="imgLIst"
          :class="{ borders: samplePic.length > 0 }"
        >
          <div
            class="imgs"
            v-for="(item, index) in samplePic"
            :key="index"
            @mouseenter="nus = index"
            @mouseleave="nus = null"
          >
            <i
              class="el-icon-circle-close ics"
              v-if="nus == index"
              @click="deleteImg(index)"
            ></i>
            <img :src="item" />
          </div>
        </el-col>
      </el-row>
      <el-row
        style="margin-top: 40px"
        v-if="show && (caiMain.bidStatus == 2 || caiMain.bidStatus == 7)"
      >
        <el-col>
          <div style="width: 200px; margin: 0 auto">
            <el-button
              type="primary"
              style="border-radius: 5px; width: 200px"
              @click="updatePic"
              >提交审核</el-button
            >
          </div>
        </el-col>
      </el-row> -->
    </div>

    <quoted-price
      :id="obj.inquirymid"
      ref="quotedprice"
      :obj="obj"
      :pid="obj.inquiryid"
      :visible.sync="visiblePrice"
      @closeq="closeq"
      @openq="openq"
    ></quoted-price>
    <!-- 报价组件 -->
    <Baojia
      :id="this.currentId"
      :secondBidFlag="secondBidFlag"
      ref="showBaojia"
      :visible.sync="visibleBaojiaDlg"
      style="margin-top: 40px"
      @closeBj="closeDetail"
      @openBj="openDetail"
    ></Baojia>
  </div>
</template>

<script>
import quotedPrice from './quotedPrice.vue'
import {
  getDetailData,
  getInqueryMaterialMain,
  uploadDeposit,
  uploadSample,
  userBidInfo,
  getBzjData,
} from '@/api/bid'
import { INFO_URL } from '@/config/globalConfig'
import { uploadFile } from '@/api/upload'
import { QR_URL } from '@/config/globalConfig'
import Baojia from '@/components/baojia.vue'
import { getDictInfo, getUserInfo } from '@/api'

export default {
  components: { quotedPrice, Baojia },
  name: 'bidDetail',
  created() {
    this.currentId = this.$route.query.id
    this.getUserInfo()
    this.getDetailData()
    // this.getOtherInqueryMaterials();
    this.getInqueryMaterialMain()
    this.getPayment()
    // this.getBzjData(this.currentId)
  },
  data() {
    return {
      Visible: false,
      infoUrl: INFO_URL,
      qrurl: QR_URL,
      currentId: 0,
      obj: {},
      urls: [],
      visiblePrice: false,
      tableData: [],
      caigouList: [],
      caiMain: {},
      baojiazu: [],
      visibleBaojiaDlg: false,
      form: {
        bjcount: 50,
        sfkp: 1,
      },
      dialogVisible: false,
      itemData: {},
      depositPic: '',
      samplePic: [],
      secondBidFlag: '',
      value1: false,
      value2: false,
      steps: '',
      id: '',
      nus: null,
      payment: [],
      samplePicList: [],
      show: false,
      expressName: '',
      sampleNo: '',
    }
  },

  computed: {
    paymentWatch() {
      return function (bidStatus) {
        if (bidStatus == null) {
          return ''
        }

        let paymentFind = this.payment.find((item) => item.value == bidStatus)
        return paymentFind ? paymentFind.label : ''
      }
    },

    depositFlag() {
      if (this.value1) {
        return 1
      }
      return 0
    },
    sampleFlag() {
      if (this.value2) {
        return 1
      }
      return 0
    },
  },

  watch: {
    '$store.state.userInfo': {
      handler(val) {
        this.userInfo = val
      },
      deep: true,
    },
  },

  mounted() {
    // console.log(this.$route, 'id')
    // this.$router.afterEach(() => {
    //   window.scrollTo(0, 0)
    // })
  },
  methods: {
    getPayment() {
      getDictInfo('BID_PAY_TYPE').then((res) => {
        if (res.code == 200) {
          const map = new Map(Object.entries(res.data))
          this.payment = this.mapToObj(map)
        }
      })
    },

    // getBzjData(id) {
    //   getBzjData(id).then((res) => {
    //     if (res.data == null) {
    //       this.show = true
    //     }
    //     console.log(res, 'ssss')
    //   })
    // },

    toBack() {
      this.$router.back()
    },

    mapToObj(strMap) {
      let arr = new Array()
      for (let [k, v] of strMap) {
        let obj = Object.create(null)
        obj.value = parseInt(k)
        obj.label = v
        arr.push(obj)
      }
      return arr
    },
    // getBidInfo(id) {
    //   userBidInfo(id).then((res) => {
    //     this.steps = res.data.sampleFlag
    //     if (this.steps == 0) {
    //       this.$notify({
    //         title: '提示',
    //         message: '请选择是否上传保证金和样品单号照片',
    //         type: 'warning',
    //         duration: 5000,
    //       })
    //     }

    //     console.log(res, 'ressssss')
    //   })
    // },

    deleteImg(i) {
      this.samplePic.splice(i, 1)
    },
    handleYyzzFileUpload1(fileObject) {
      let fd = new FormData() // 新建一个FormData()对象，这就相当于你新建了一个表单
      fd.append('file', fileObject.file) // 将文件保存到formData对象中
      fd.append('index', 1)
      uploadFile(fd)
        .then((res) => {
          console.log(res, 'resres')
          this.depositPic = res.path
          console.log(res, this.depositPic, 'ssssss')
        })
        .catch((exp) => {
          console.log(exp)
        })
    },
    handleYyzzFileUpload2(fileObject) {
      let fd = new FormData() // 新建一个FormData()对象，这就相当于你新建了一个表单
      fd.append('file', fileObject.file) // 将文件保存到formData对象中
      fd.append('index', 1)
      uploadFile(fd)
        .then((res) => {
          let pic = this.infoUrl + res.path
          this.samplePicList.push(res.path)
          this.samplePic.push(pic)
          console.log(this.samplePic, 'ssssss')
        })
        .catch((exp) => {
          console.log(exp)
        })
    },

    beforeAvatarUpload(file) {
      const isJPG =
        file.type === 'image/jpg' ||
        file.type === 'image/png' ||
        file.type === 'image/jpeg'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG/PNG/JPEG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      return isJPG && isLt2M
    },

    // updatePic() {
    //   if (this.samplePicList.length > 0 && this.depositPic !== '') {
    //     let data = {
    //       certPicList: this.samplePicList,
    //       depositPic: this.depositPic,
    //       id: this.currentId,
    //     }
    //     console.log(data, '上傳圖片')
    //     uploadDeposit(data)
    //       .then((res) => {
    //         console.log(res, '上传图片')
    //         if (res.code == 200) {
    //           this.$message({
    //             message: '上传成功',
    //             type: 'success',
    //           })
    //           this.samplePic = []
    //           this.depositPic = ''
    //           location.reload()
    //         } else {
    //           this.$message({
    //             message: res.msg,
    //             type: 'warning',
    //           })
    //         }
    //       })
    //       .catch((res) => {
    //         console.log(res, 'res')
    //         this.$message({
    //           message: '上传失败',
    //           type: 'warning',
    //         })
    //       })
    //   } else {
    //     this.$message({
    //       message: '请上传保证金凭证和资料凭证',
    //       type: 'warning',
    //     })
    //   }
    // },

    getUserInfo() {
      getUserInfo().then((res) => {
        this.$store.commit('userInfo', res.data || {})
      })
    },
    closeq() {
      this.visibleBaojiaDlg = false
    },
    openq() {
      this.visibleBaojiaDlg = true
    },
    closeDetail() {
      // this.getBidInfo(this.id)
      this.visibleDetailDlg = false
    },
    openDetail() {
      this.visibleDetailDlg = true
    },
    showBaojia() {
      // debugger;
      // if (this.userInfo.checkFlag !== 1) {
      //   this.$message.warning("资质认证没有通过，请联系工作人员")
      //   return;
      // }
      this.$refs.showBaojia.getDetailPrice(this.currentId)
      this.visibleBaojiaDlg = true
    },
    // quotedPrice() {
    //   let oDate1 = new Date(this.obj.deadline)
    //   let oDate2 = new Date()
    //   if (oDate1.getTime() < oDate2.getTime()) {
    //     this.$message.warning('截止日期已过')
    //   } else {
    //     this.$refs.quotedprice.getInqueryMU(
    //       this.obj.inquiryid,
    //       this.obj.inquirymid
    //     )
    //   }
    // },
    // 招标采购 头
    getInqueryMaterialMain() {
      let that = this
      getInqueryMaterialMain({
        inquiryId: that.currentId,
      }).then((res) => {
        that.caiMain = res.data
        console.log('采购详情头', res.data, that.caiMain)
        that.caiMain.deadlinedate = that.formatDate(
          res.data.deadlinedate,
          'yyyy-MM-dd'
        )
        that.id = res.data.id
        // that.getBidInfo(that.id)
        that.secondBidFlag = res.data.secondBidFlag
        that.caiMain.pay_time = that.formatDate(res.data.pay_time, 'yyyy-MM-dd')
        if (!that.compare(that.caiMain.deadlinedate)) {
          that.deadline = true
        }
      })
    },
    // 招标采购详情
    getDetailData() {
      let that = this
      getDetailData({
        inquiryId: that.currentId,
      }).then((res) => {
        //    console.log("res1")

        that.caigouList = res.data
        that.caigouList.updateDate = that.formatDate(
          res.data.updateDate,
          'yyyy-MM-dd'
        )
        // console.log('riqi', that.caigouList.updateDate)
        if (!that.compare(that.caigouList.updateDate)) {
          that.updateDate = true
        }
        console.log('投标列表', that.caigouList)
        let datas = res.data
        // console.log('datas', datas)
        if (datas.pics != null) {
          //datas.pics = datas.pics.split(",");
          let picArr = datas.pics.split(',')
          let r = picArr.filter(function (s) {
            return s && s.trim()
          })
          datas.pics = r
        } else {
          datas.pics = []
        }
        that.baojiazu = datas
        that.obj = datas
      })
    },
    compare(date1) {
      let dates1 = new Date(this.formatDate(date1))
      let dates2 = new Date()
      if (dates1 > dates2) {
        return true
      } else {
        return false
      }
    },
    /**
     * 格式化时间显示方式
     * 用法:format="yyyy-MM-dd hh:mm:ss";
     */
    formatDate(value, format = 'yyyy-MM-dd') {
      if (!value) return ''
      if (!format) format = 'yyyy-MM-dd'
      let d = value
      if (typeof value === 'string') {
        if (value.indexOf('/Date(') > -1)
          d = new Date(
            parseInt(value.replace('/Date(', '').replace(')/', ''), 10)
          )
        else
          d = new Date(
            Date.parse(value.replace(/-/g, '/').replace('T', ' ').split('.')[0])
          ) //.split(".")[0] 用来处理出现毫秒的情况，截取掉.xxx，否则会出错
      }
      let o = {
        'M+': d.getMonth() + 1, //month
        'd+': d.getDate(), //day
        'h+': d.getHours(), //hour
        'm+': d.getMinutes(), //minute
        's+': d.getSeconds(), //second
        'q+': Math.floor((d.getMonth() + 3) / 3), //quarter
        S: d.getMilliseconds(), //millisecond
      }
      if (/(y+)/.test(format)) {
        format = format.replace(
          RegExp.$1,
          (d.getFullYear() + '').substr(4 - RegExp.$1.length)
        )
      }
      for (let k in o) {
        if (new RegExp('(' + k + ')').test(format)) {
          format = format.replace(
            RegExp.$1,
            RegExp.$1.length == 1
              ? o[k]
              : ('00' + o[k]).substr(('' + o[k]).length)
          )
        }
      }
      return format
    },
  },
}
</script>

<style lang="less" scoped>
@import url('../../assets/common.less');
\deep\.dialog {
  // height: 400px;
  position: relative;
}

.back_icons {
  padding: 10px 26px 0;
  font-size: 26px;
  width: 30px;
  cursor: pointer;
  &:hover {
    color: #409eff;
  }
}

.view {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 450px;
  background-color: #ddd;
  \deep\.el-carousel-item {
    background-color: #eee;
  }
  .icons {
    position: absolute;
    top: -26px;
    right: -26px;
    border-radius: 50%;
    color: #000;
    background-color: #bbb;
    font-size: 26px;
    &:hover {
      color: #fff;
      cursor: pointer;
    }
  }
  img {
    width: 800px;
    height: 500px;
  }
  video {
    width: 800px;
    height: 500px;
  }
}
.bgan {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  // box-shadow: 0 0 4px 2px #40a0ff2a;
  z-index: 1000;
  position: fixed;
  top: 250px;
  right: 250px;
  cursor: pointer;
  // background-color: #fff;
  img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
  }
}
.contents {
  width: 80%;
  margin: 50px auto 30px;
  padding-bottom: 50px;
  .companyName {
    h1 {
      font-size: 24px;
    }
    p {
      margin-top: 5px;
      color: #888;
      font-size: 14px;
    }
    .fujian {
      // width: 95%;
      margin: 20px auto 0;
      // margin-top: 20px;
      a {
        display: inline-block;
        line-height: 24px;
        color: #1b86fb;
        display: flex;
        align-items: center;
        img {
          width: 18px;
          height: 18px;
          margin-right: 5px !important;
          margin: auto 0;
        }
      }
    }
  }
  .breedList {
    margin-top: 40px;
    .breedMess {
      margin: 40px 0;
      font-size: 14px;
      color: #777;
      .rows {
        margin: 15px 0;
      }
      span {
        color: #333;
      }
    }
  }
  .fyq {
    width: 70%;
    margin: 20px auto;
  }
}
.dialog {
  padding: 0 15px;

  // height: ;
  // position: relative;
  .body {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .message {
    // position: absolute;
    // right: 0;
    width: 500px;

    // float: right;
    // height: 500px;
    // background-color: #eee;
    .rows {
      margin: 10px 0;

      .messageName {
        font-size: 16px;
        font-weight: 500;
      }
    }
  }
}
.inputs {
  margin: 0 auto;
  width: 62%;
}
.right {
  // display: flex;
  // flex-direction: row;
  // margin: 0 auto;
  // justify-content: center;
  .bonds:first-child {
    // margin-right: 60px;
  }
  .borders {
    border: 1px dashed #ddd;
  }
  .imgLIst {
    display: flex;
    flex-flow: wrap;
    padding: 5px 0;
    // min-height: 155px;
    .imgs {
      width: 60px;
      height: 50px;
      border: 1px dashed #ddd;
      margin: 5px;
      position: relative;
      .ics {
        position: absolute;
        width: 16px;
        height: 16px;
        // border-radius: 50%;
        top: -10px;
        right: -10px;
        color: #b1b1b1;
        // background-color: #b1b1b1;
        font-size: 17px;
        cursor: pointer;
        &:hover {
          color: #409eff;
          // background-color: #fff;
        }
      }
      img {
        width: 60px;
        height: 50px;
      }
    }
  }
  // height: 100%;
  .avatar-uploader .el-upload {
    width: 200px;
    height: 120px;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    margin-top: 10px;
    border: 1px dashed #cecece;
    // font-size: 35px;
    color: #bdbdbd;
    width: 200px;
    height: 120px;
    line-height: 120px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-content: stretch;
    position: relative;

    .icons {
      // margin: auto 0;
      position: absolute;
      display: block;
      font-weight: bold;
      font-size: 50px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .names {
      width: 100%;
      margin-top: 30px;
    }
  }
  .el-upload__text {
    // position: absolute;
    top: 0;
  }
  .avatar {
    margin-top: 20px;
    border: 1px dashed #cecece;
    width: 200px;
    height: 120px;
    display: block;
  }
}

.bid-detail {
  max-width: 1190px;
  margin: 20px auto 0;
  box-shadow: 0 0 10px #ddd;
  position: relative;
  .bid-list {
    .card2 {
      // margin: 10px 0;
      display: flex;
      flex-wrap: wrap;
      cursor: pointer;
      .card2-title {
        margin: 0 0 15px;
        width: 55%;
        margin-top: 10px;
        // height: 100%;
        // align-items: center;
        // line-height: 46px;
        // margin-right: 460px;
        // margin-bottom: 15px;
      }

      .material-name {
        width: 100%;
        font-size: 16px;
      }
    }
    .cont {
      // margin: 20px 0 !important;
      display: flex;
      flex-direction: row !important;

      width: 100% !important;
      height: 100px;
      // justify-content: space-between;
      .left {
        width: 70%;
        margin: auto 0;
      }
    }
    .card {
      position: relative;
      border-radius: 8px;
      // height: 120px;
      padding: 0 26px 10px;
      // margin-bottom: 20px;
      border-bottom: 1px solid #eee;

      .tops {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        // margin-top: 15px;
        .checkNeed {
          margin-left: 47px;
          width: 30%;
          display: flex;
          flex-direction: row;
          justify-content: center;
          &-item {
            margin-left: 15px;
            // width: ;
          }
        }
      }

      .text {
        width: 100%;
        height: 100px;
        background-color: #ddd;

        // display: flex;
        // text-align: center;
        p {
          img {
            width: 100px !important;
            height: 100px !important;
          }
        }
      }
      .titles {
        font-size: 18px;
      }

      &-title {
        font-size: 24px;
        font-weight: bold;
        display: flex;
        align-items: center;

        .l-style {
          display: inline-block;
          width: 2px;
          height: 18px;
          background: @primary;
          margin-right: 6px;
        }
      }

      .carousels {
        // margin: 15px 0;
        width: 240px;
        margin-left: 30px;
        position: relative;
        .el-carousel-item {
          border: 1px solid #ddd;
        }
        img {
          width: 238px;
          height: 138px;
          border: 1px solid #ddd;
        }
        video {
          width: 238px;
          height: 138px;
          border: 1px solid #ddd;
        }
        i {
          z-index: 2;
          position: absolute;
          top: 50%;
          left: 50%;
          width: 32px;
          height: 32px;
          border-radius: 50%;
          text-align: center;
          line-height: 32px;
          font-size: 28px;
          transform: translate(-50%, -50%);
          // border-radius: 50%;
          background-color: rgba(97, 97, 97, 0.637);
          color: rgb(236, 236, 236);
        }
      }

      .bottoms {
        margin-bottom: 15px;
      }

      &-con {
        // position: relative;
        margin: auto 0;
        display: flex;
        flex-direction: column;
        align-content: stretch;
        // align-items: center;
        font-size: 14px;
        //margin-top: 40px;
        width: 70%;

        // .rightBtn {
        //   position: absolute;
        //   float: right;
        //   right: 50px;
        //   top: 20px;
        //   margin: auto 0;
        // }

        &-item {
          // width: 80%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin: 10px 0;

          .item1 {
            width: 100%;
          }

          .item1:last-child {
            width: 200px;
          }

          .item2 {
            position: relative;

            .btns {
              position: absolute;
              font-size: 16px;
              padding: 4px 16px;
              border: 1px solid rgb(20, 99, 158);
              border-radius: 4px;
              top: -10px;
              right: 0;
              cursor: pointer;
              color: rgb(20, 99, 158);
            }

            .btns:hover {
              background-color: rgba(20, 99, 158, 0.1);
            }
          }

          .item3 {
            color: #999;
            cursor: pointer;
          }
        }
      }

      &-con-right {
        width: 20%;
        text-align: center;

        &-img {
          width: 100px;
          height: 100px;
          margin: 10px auto 0;
          display: block;
        }

        &-value {
          font-size: 12px;
        }
      }

      .card-con-imgs {
        width: 100%;
        margin-top: 20px;

        .con-imgs-item {
          width: 50px;
          height: 50px;
          margin: 0 4px;
        }
      }
    }

    .card1 {
      padding-top: 20px;

      &-title {
        font-size: 18px;
      }
    }
  }

  .images-style {
    width: 10%;
    padding: 4px;
    box-sizing: border-box;
    margin-bottom: 6px;
  }

  .xh-table {
    margin-top: 20px;

    /deep/ th {
      background-color: #fafafa;
      color: #333;
    }
  }
}

table {
  tr {
    td {
      padding: 12px 0;
      border-bottom: 1px solid #ebeef5;
      color: #606266;
      font-size: 14px;
    }
  }

  .thtou {
    td {
      background: #fafafa;
      color: #333;
    }
  }

  tr:not(.thtou):hover td {
    background: #f5f7fa;
  }
}

.formMain {
  height: 400px;
  padding: 0 26px;

  .biaoli {
    li {
      display: flex;

      > div {
        flex: 1;
      }

      line-height: 40px;
      border-bottom: 1px solid #eee;

      input {
        border: none;
      }

      font-size: 15px;
    }

    .litou {
      font-size: 18px;
      font-weight: 600;
      border-bottom: 3px solid #eee;
    }
  }

  .card-tit {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;

    // .titSpan {
    //   margin-right: 300px;
    // }
  }

  .tidiv {
    // position: absolute;
    // top: 0;
    // right: 0;
    // width: 100%;
    // display: block;
    width: 10%;
    text-align: center;
    font-size: 16px;
    margin-bottom: 15px;
    float: right;

    /deep/ .el-button--primary {
      padding: 10px 22px;
    }
  }
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

.small {
  width: 100px;
  height: 100px;
}

.fuwenben {
  padding: 20px;
  margin-top: 20px;

  /deep/ p {
    text-indent: 2em !important;
    color: red !important;
  }
}
</style>
