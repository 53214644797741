<template>
  <div class="page flex-col">

    <div class="block_15 flex-col">

      <div class="block_16 flex-col">
        <div class="image-text_7 flex-row justify-between">
          <img class="label_11" referrerpolicy="no-referrer"
            src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng26e1e413469afae3756bc254f42f83cf0222afbe22a4020d6f0f4735c6fad3d9" />
          <span class="text-group_4">企业详情</span>

        </div>
        <div class="box_2 flex-col"></div>
        <div class="image-text_8 flex-row justify-between">
          <div class="block_17 flex-col">
            <img :src="baseUrl+companyData.logopic" alt="" style="max-height:78px">
          </div>
          <div class="block_18 flex-col justify-between">
            <span class="text-group_5">{{ companyData.name }}</span>
            <div class="text-wrapper_2 flex-col">
              <span class="text_13">企业认证</span>
            </div>
          </div>
          <div class="zizhi" style="margin-left:40%;text-align: center;">
            <div class="" style="margin-top: -10px;">公司资质</div>
            <div style="display:flex; margin-top: 10px;">
              <div style="margin:0 10px" v-for="(item, index) in lunboList.slice(0,3)" :key="index">
                <img style="width:60px" :src="'/info-admin' + item" @click.self="showBigImage(baseUrl + item)" />
              </div>
            </div>
          </div>
          <span class="text-group_4"></span>
          <div class="lianxi" style="margin-left:70%;line-height: 25px;">
            <!-- <span style="color: red;
    font-size: 16px;
    margin-left: 0px; ">
                资质电话
              </span> -->
            <div class="weixin">
              微信：{{companyData.wechatNumber?companyData.wechatNumber:'暂无'}}
            </div>
            <div class="dianhua">
              电话：{{companyData.contacttel}}
            </div>
            <div class="wangzhi" style="color:blue;font-size:13px">
              公司网站：{{companyData.homepageUrl?companyData.homepageUrl:'暂无'}}
              <el-link :href="companyData.homepageUrl" target="_blank" :underline="false" v-if="companyData.homepageUrl"
                style=" font-size: 13px;">访问</el-link>
            </div>
          </div>
        </div>
      </div>
      <div class="block_19 flex-row" id="gsjs">
        <img class="label_2" referrerpolicy="no-referrer"
          src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng9f0924056986b6c9fb7451131964e5e769f444a1eecac30042c39eac0e3d6a33" />
        <span class="text_6">公司简介</span>
        <img class="label_3" referrerpolicy="no-referrer"
          src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng753270a6fd442eec7c7de7ae8a3a2ecc8b057ca5d7e52609ab8556e6a2b62f11" />
        <div class="image-text_9 flex-row justify-between">
          <!-- <span class="text-group_1" @click="goGsjs" style="cursor: pointer;">查看更多</span> -->
          <!-- <img
              class="thumbnail_1"
              referrerpolicy="no-referrer"
              src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng1421db218ed6458f41377c15f9fe79eed56fabc9ce7ae3702905a9e8874e5e7b"
            /> -->
        </div>
      </div>
      <div class="block_4 flex-col"></div>
      <div class="block_20">
        <div class="group_1 flex-col" style="margin: 0 auto">
          <img :src="baseUrl+companyData.pic" alt="" style="max-height:400px">
        </div>
        <div class="paragraph_1" style="width: 100%">
          <div v-html="companyData.decriptions"></div>
        </div>
      </div>
      <div class="block_21 flex-row" id="cpzx">
        <img class="label_4" referrerpolicy="no-referrer"
          src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng9f0924056986b6c9fb7451131964e5e769f444a1eecac30042c39eac0e3d6a33" />
        <span class="text_7">产品中心</span>
        <img class="label_5" referrerpolicy="no-referrer"
          src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng753270a6fd442eec7c7de7ae8a3a2ecc8b057ca5d7e52609ab8556e6a2b62f11" />
        <div class="image-text_10 flex-row justify-between">
          <span class="text-group_2" @click="goPro" style="cursor: pointer;">查看更多</span>
          <img class="thumbnail_2" referrerpolicy="no-referrer"
            src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng1421db218ed6458f41377c15f9fe79eed56fabc9ce7ae3702905a9e8874e5e7b" />
        </div>
      </div>
      <div class="block_7 flex-col"></div>
      <div class="grid_3 flex-row">
        <div class="list-items_1 flex-col" v-for="(item, index) in productList" :key="index">
          <img @click="goDetail(item.id)" class="image_2" referrerpolicy="no-referrer" :src="'/info-admin' + item.mainpic" />
          <span class="text_8" v-html="item.name"></span>
        </div>
      </div>
      <!-- <div class="block_22 flex-row justify-between">
          <img
            class="label_6"
            referrerpolicy="no-referrer"
            src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng9f0924056986b6c9fb7451131964e5e769f444a1eecac30042c39eac0e3d6a33"
          />
          <span class="text_9">公司资质</span>
          <img
            class="label_7"
            referrerpolicy="no-referrer"
            src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng753270a6fd442eec7c7de7ae8a3a2ecc8b057ca5d7e52609ab8556e6a2b62f11"
          />
        </div> -->
      <!-- <div class="block_9 flex-col"></div> -->
      <div class="block_23 flex-row justify-between">
        <!-- <img
            class="image_3"
            referrerpolicy="no-referrer"
            :src="baseUrl+companyData.pic"
          /> -->
        <!-- <el-carousel trigger="click" style="width: 500px;" v-if="lunboList.length>0">
              <el-carousel-item v-for="(item, index) in lunboList" :key="index">
                  <img style="margin-left: calc(50% - 205px);" class="banner-img" :src="baseUrl + item" />
              </el-carousel-item>
          </el-carousel> -->
      </div>
      <!-- <div class="block_24 flex-row justify-between">
          <img
            class="label_8"
            referrerpolicy="no-referrer"
            src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng9f0924056986b6c9fb7451131964e5e769f444a1eecac30042c39eac0e3d6a33"
          />
          <span class="text_11">联系我们</span>
          <img
            class="label_9"
            referrerpolicy="no-referrer"
            src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng753270a6fd442eec7c7de7ae8a3a2ecc8b057ca5d7e52609ab8556e6a2b62f11"
          />
        </div> -->
      <!-- <div class="block_12 flex-col"></div> -->
      <!-- <div class="block_13 flex-row justify-between">
         
          <span class="text_12">
            微信：{{ companyData.wechatNumber?companyData.wechatNumber:'暂无' }}
            电话：{{ companyData.contacttel }}
            公司网址：{{ companyData.homepageUrl?companyData.homepageUrl:'暂无' }}
            <el-link :href="companyData.homepageUrl" target="_blank" :underline="false" v-if="companyData.homepageUrl">访问</el-link>
          </span>
        
        </div> -->
    </div>
    <BigImg :visible="photoVisible" :url="bigImgUrl" @closeClick="()=>{photoVisible=false}"></BigImg>
  </div>
</template>
  <script>
import BigImg from '../big-img.vue'
import { INFO_URL } from '@/config/globalConfig'
export default {
  components: {
    BigImg
  },
  data() {
    return {
      photoVisible: false,
      bigImgUrl: '',
      baseUrl: INFO_URL,
      loopData0: [],
      // lunboList:[],
      constants: {}
    }
  },
  props: {
    companyData: {
      type: Object,
      default: {
        name: '',
        content: '',
        phone: ''
      }
    },
    productList: {
      type: Array,
      default: []
    },
    lunboList: {
      type: Array,
      default: []
    }
  },
  // mounted() {
  //  this.$nextTick(()=>{
  //   if(this.companyData.qualitypics){
  //     this.lunboList =  this.companyData.qualitypics.split(',')
  //   }
  //  })
  // },
  methods: {
    showBigImage(e) {
      //点击图片函数，点击后，把photoVisible设置成true
      if (e != '') {
        this.photoVisible = true
        this.bigImgUrl = e
      }
    },
    goDetail(id) {
      this.$emit('getDetailid', id)
    },
    goGsjs() {
      this.$emit('goGsjs')
    },
    goPro() {
      this.$emit('goPro')
    }
  }
}
</script>
  <style lang="less" scoped>
@import url('./common.less');
@import url('./index.less');
</style>
  
  