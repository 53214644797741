<template>
  <div class="content" style="display: flex;flex-direction: column;align-items: center;">
    <div class="row b-b">
      <text class="tit">联系人</text>
      <el-input class="input" :maxlength="10" type="text" v-model="userDate.contacter" placeholder="请输入姓名" placeholder-class="placeholder"
        :readonly="readOnlyFlag" />
    </div>
    <div class="row b-b">
      <text class="tit">联系电话</text>
      <el-input class="input" type="number" v-model="userDate.contacttel" placeholder="请输入联系电话" placeholder-class="placeholder"
        :readonly="readOnlyFlag" />
      <!-- <input type="hidden" v-model="userDate.captcha" style="display: none;" /> -->
    </div>
    <div class="row b-b">
      <text class="tit">企业名称</text>
      <el-input class="input" type="text" v-model="userDate.companyname" placeholder="请输入入驻企业名称" placeholder-class="placeholder"
        :readonly="readOnlyFlag" />
    </div>
    <div class="row b-b">
      <text class="tit">企业类型</text>
      <el-select @change="bindSpecChange" v-model="userDate.companytype" placeholder="请选择企业类型（选填）">
        <el-option v-for="item in companytypeArray" :key="item.id" :label="item.name" :value="item.id">
        </el-option>

      </el-select>
    </div>
    <el-button class="add-btn" @click="confirm" v-if="!readOnlyFlag">保存</el-button>
  </div>
</template>

<script>
import { getInfo, getUserInfo, getAllTypeList, doCompanyJoinApply } from '@/api/index'
// import regionPicker from "@/components/region-picker/region-picker.vue"
// import lausirCodeDialog from '@/components/lausir-codedialog/lausir-codedialog.vue';
import { mapState, mapMutations } from 'vuex'
export default {
  components: {
    // regionPicker,
    // lausirCodeDialog
  },
  data() {
    return {
      userDate: {
        companyname: '',
        nickname: '',
        contacttel: '',
        contacter: '',
        companytype: '',
        companytypeValue: '',
        default: true
      },
      companytypeArray: [
        /* {id:1,name:'花茶企业'},
        {id:2,name:'配套服务'}, */
      ],
      readOnlyFlag: false
    }
  },
  mounted() {
    this.getDetailData() //加载当前用户个人信息
  },
  created() {
    this.loadData()
  },
  computed: {
    ...mapState(['hasLogin', 'userInfo'])
  },
  methods: {
    openCodeDialog: function (obj) {
      if (!this.checkMobile(this.userDate.personalphone)) {
        this.$api.msg('手机格式不正确')
        return
      }
    },
    getDetailData(id) {
      console.log('showing')
      let that = this
      getInfo().then((res) => {
        let info = res.data
        console.log(info)
        if (info.companyname != '') {
          that.userDate.contacttel = info.contacttel
          that.userDate.contacter = info.contacter
          that.userDate.companyname = info.companyname
          that.userDate.companytype = +info.companytype
          if (that.userDate.companytype && that.companytypeArray) {
            let filter = that.companytypeArray.filter((v) => v.id == that.userDate.companytype)
            that.userDate.companytypeValue = filter.length > 0 ? filter[0].name : ''
          }

          ;(this.readOnlyFlag == info.checkFlag) == 2
        } else {
          uni.showModal({
            title: '温馨提示',
            content: '请先完成资质认证',
            success: function (res) {
              if (res.confirm) {
                uni.navigateTo({
                  url: '/pages/public/registerTwo'
                })
              } else if (res.cancel) {
                console.log('用户点击取消')
                uni.navigateBack()
              }
            }
          })
        }
      })
    },
    loadData() {
      const that = this
      getAllTypeList().then((res) => {
        let ss = this.companytypeArray
        this.companytypeArray = []

        res.data.map((item) => {
          this.companytypeArray.push({ id: parseInt(item.id), name: item.name })
        })
        console.log(this.companytypeArray)
        if (that.userDate.companytype && this.companytypeArray) {
          let filter = this.companytypeArray.filter((v) => v.id == that.userDate.companytype)
          that.userDate.companytypeValue = filter.length > 0 ? filter[0].name : ''
        }
      })
    },
    checkMobile: function (mobile) {
      return RegExp('^1[0-9]{10}$').test(mobile)
    },
    bindSpecChange: function (e) {
      this.userDate.companytypeValue = this.companytypeArray.find((item) => {
        return item.id == e
      }).name
      this.userDate.companytype = e
    },
    //提交
    confirm() {
      let data = this.userDate
      if (!data.contacter) {
        this.$message.warning('请填写联系人姓名')
        return
      }
      if (!RegExp(/^1[34578]\d{9}$/).test(data.contacttel)) {
        console.log(data.contacttel)
        this.$message.warning('请输入正确的手机号码')
        return
      }
      if (!data.companyname) {
        this.$message.warning('请输入正确的公司名称')
        return
      }
      let options = this.userDate
      const that = this
      doCompanyJoinApply({
        companyname: data.companyname,
        companytype: data.companytype,
        contacter: data.contacter,
        contacttel: data.contacttel
      }).then((res) => {
        console.log(res)
        if (res.code === 200) {
          var msg = '保存成功'
          setTimeout(() => {
            //this.$api.prePage().refreshList();
            that.$message.success('提交成功')
            this.$router.push({
              path: '/information'
              // query: {
              //     id: id
              // }
            })
          }, 800)
        } else {
          that.$message.error('')
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.page {
  background: #fff;
  padding-top: 16upx;
}

.row {
  margin: 10px 0;
  width: 500px;
  display: flex;
  align-items: center;
  position: relative;
  // padding: 0 30upx;
  // height: 110upx;
  background: #fff;
  border-bottom: 1upx solid #eee;

  .tit {
    flex-shrink: 0;
    width: 160upx;
    font-size: 30upx;
    color: #303133;
  }

  .input {
    flex: 1;
    font-size: 30upx;
    color: #303133;
  }

  .icon-shouhuodizhi {
    font-size: 36upx;
    color: #303133;
  }
}

.smsBtn {
  -webkit-flex-basis: 30%;
  flex-basis: 30%;
  ine-height: 42.55px;
  font-size: 12px;
  color: #fff !important;
  background-color: #2e74ee !important;
}

.smsBtnDisable {
  -webkit-flex-basis: 30%;
  flex-basis: 30%;
  ine-height: 42.55px;
  font-size: 12px;
  color: #000;
  background-color: #e7e7e7;
}

.default-row {
  margin-top: 16upx;

  .tit {
    flex: 1;
  }

  switch {
    transform: translateX(16upx) scale(0.9);
  }
}

.add-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 500px;
  height: 80upx;
  margin: 80upx auto 0upx auto;
  font-size: 18px;
  color: #fff;
  background-color: #14639e;
  border-radius: 40upx;
  box-shadow: 1px 2px 5px #999;
}

.del-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 690upx;
  height: 80upx;
  margin: 60upx auto;
  font-size: 20px;
  color: #fff;
  background-color: #fff;
  border-radius: 10upx;
  box-shadow: 1px 2px 5px rgba(219, 63, 96, 0.4);
}

.tab {
  padding: 20upx 0;
  font-size: 32upx;
}

.tab.active {
  color: #f00;
}

.result {
  margin-top: 100upx;
  font-size: 32upx;
}
</style>
