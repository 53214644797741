import {
  post,
  json,
  get
} from '../utils/fetch';

// 获取价格列表
export function getprices(data) {
  return json('/api/ysc/yscPrice/getAllMaterial', data);
}
export function getRiseData(data) {
  return json('/api/riseAndFall/getRiseFallData', data);
}
// 获取产地
export function getAllMaddrByName(data) {
  return get('/api/ysc/yscPrice/getAllMaddrByName', data);
}
// 获取价格详情
export function getPriceDetail(data) {
  return json('/api/ysc/yscPrice/getHisPrice', data);
}

// 获取价格详情
export function getPricePcDetail(data) {
  return json('api/ysc/yscPrice/pc/getHisPrice', data);
}

// 获取类别
export function getAllMcate(data) {
  return get('/api/ysc/yscPrice/getAllMcate', data);
}
// 获取规格
export function getAllMspecByName(data) {
  return get('/api/ysc/yscPrice/getAllMspecByName', data);
}

// 获取大盘
export function getStockList(data) {
  return get('/api/ysc/getStockList', data);
}

// 获取品种
export function getPmList(data) {
  return get('/api/ysc/getPmList', data);
}

// 获取指数
export function zhishuList(data) {
  return json('/api/mall/yshNews2/list', data);
}

export function getTopN(data) {
  return post('/api/mall/yshNews/getTopN', data);
}
export function codeDetail(data) {
  return post('/api/mall/yshNews/todetail', data);
}
export function getAllNewsList(data) {
  return post('/api/mall/yshNews/getAllNewsList', data);
}
// 获取指数详情
export function zhishuDetail(data) {
  return get('/api/mall/yshNews3/todetail', data);
}

// 获取指数浏览量
export function getViewCount(data) {
  return get('/api/pageView/getViewCount', data);
}

// 获取涨落列表
export function zlList(data) {
  return json('/api/riseAndFall/getRiseFallData', data);
}