import {get, post} from '../utils/fetch';

export function getFinances(data) {
    console.log("getFinances")
    console.log(data)
    return get('/api/pc/jr/jrFinance/list', data);
}

// 申请
export function addApplyFinance(data) {
    return post('/api/jr/jrApply/add', data);
}

export function getApplyFinance(data) {
    return post('/api/jr/jrApply/getMyApply', data);
}

export function queryIntrductionList(data) {
    return post('/api/jr/intrduction/getAllNewsList', data);
}


export function jrDetail(data){
    return post("api/jr/intrduction/detail?id="+data)
}
