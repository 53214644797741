<template>
  <div class="rank">
    <div class="rank-top">
      <h3><span>药品排行榜</span></h3>
      <div
        class="scroll_parent_box"
        @mouseenter="mEnter"
        @mouseleave="mLeave"
      >
        <div
          class="scroll_list"
          :style="{ transform: `translate(0px,-${scrollTop}px)` }"
        >
          <div ref="scrollItemBox" class="box">
            <div class="top-ul scroll_item" style="height: auto">
              <div
                v-for="(item, index) in hotMaterial"
                :key="index"
                @click="toRankDetail(item, item.viewType)"
                style="padding: 13px 0;"
              >
                <a><em @click.stop="false" v-if="index % 3 == 0" :style="Math.floor((index-1) / 3)  + 1 < 3 ?'background: #f70;':''">{{ Math.floor((index-1) / 3)  + 2 }}</em>{{ item.name }}</a>
              </div>
            </div>
          </div>
          <div ref="scrollItemBox" class="box">
            <div class="top-ul scroll_item" style="height: auto">
              <div
                v-for="(item, index) in hotMaterial"
                :key="index"
                @click="toRankDetail(item, item.viewType)"
                style="padding: 13px 0;"
              >
                <a><em @click.stop="false" v-if="index % 3 == 0" :style="Math.floor((index-1) / 3)  + 1  < 3 ?'background: #f70;':''">{{ Math.floor((index-1) / 3)  + 2 }}</em>{{ item.name }}</a>
              </div>
            </div>
          </div>
          <!-- <div v-html="copyHtml"></div> -->
        </div>
      </div>  
      <!-- <div class="top-ul" style="height: auto">
        <div
          v-for="(item, index) in hotMaterial"
          :key="index"
          @click="toRankDetail(item, item.viewType)"
          style="padding: 13px 0;"
        >
          <a>{{ item.name }}</a>
        </div>
      </div> -->
    </div>
    <div class="rank-bottom">
      <h3><span>店铺排行</span></h3>
      <div
        class="scroll_parent_box"
        @mouseenter="mEnter2"
        @mouseleave="mLeave2"
      >
        <div
          class="scroll_list"
          :style="{ transform: `translate(0px,-${scrollTop2}px)` }"
        >
          <div ref="scrollItemBox2" class="box">
            <div class="scroll_item" style="height: auto">
              <ul>
                <li
                  v-for="(item, index) in hotShop"
                  :key="index"
                  :class="index < 3 ? 'top' : ''"
                  @click="toMyShop(item)"
                >
                  <em>{{ index + 1 }}</em
                  ><span>{{ item.company_name }}</span>
               </li>
              </ul>
            </div>
          </div>
          <div ref="scrollItemBox2" class="box">
            <div class="scroll_item" style="height: auto">
              <ul>
                <li
                  v-for="(item, index) in hotShop"
                  :key="index"
                  :class="index < 3 ? 'top' : ''"
                  @click="toMyShop(item)"
                >
                  <em>{{ index + 1 }}</em
                  ><span>{{ item.company_name }}</span>
                </li>
              </ul>
            </div>
          </div>
          <!-- <div v-html="copyHtml2"></div> -->
        </div>
      </div> 
      <!-- <ul>
        <li
          v-for="(item, index) in hotShop"
          :key="index"
          :class="index < 3 ? 'top' : ''"
          @click="toMyShop(item)"
        >
          <em>{{ index + 1 }}</em
          ><span>{{ item.company_name }}</span>
        </li>
      </ul> -->
    </div>
  </div>
</template>
<script>
let timer1 = null
let timer2 = null
import { saveView } from "@/api/index";
export default {
  data() {
    return {
      scrollTop: 0, //列表滚动高度
      speed: 25, //滚动的速度
      scrollTop2: 0, //列表滚动高度
      speed2: 25, //滚动的速度
      copyHtml: '', //复制多一份防止滚动到后面出现空白
      copyHtml2: '', //复制多一份防止滚动到后面出现空白
      timer: [],
    }
  },
  props: {
    hotMaterial: {
      type: Array,
      default () {
        return []
      }
    },
    hotShop: {
      type: Array,
      default () {
        return []
      }
    }


  },
  beforeDestroy() {
    clearInterval(timer1)
    clearInterval(timer2)
  },
  created() {
    this.initScroll()
    this.initScroll2()
    this.getCopyHtml()
    this.getCopyHtml2()
  },
  methods: {
    getCopyHtml() {
      let that = this
      this.clearCurTimer()
      let vals = []
      that.countdown(vals)
      this.$nextTick(() => {
        this.copyHtml = this.$refs.scrollItemBox.innerHTML
      })
    },
    getCopyHtml2() {
      this.clearCurTimer()
      this.$nextTick(() => {
        this.copyHtml2 = this.$refs.scrollItemBox2.innerHTML
      })
    },
    toRankDetail (item, type) {
      if(item.id == null) {
        return
      }
      const viewType = type == 1 ? '3' : 0
      saveView({
        viewType: viewType,
        relateId: item.id,
        type: type
      }).then((res) => { });
      this.$router.push({
        name: "gqDetail",
        query: {
          id: item.id,
          type: type
        },
      });
    },

    toMyShop (item) {
      if(item.id == null) {
        return
      }
      saveView({
        viewType: 1,
        relateId: item.id,
      }).then((res) => { });
      this.$router.push({
        name: "myShop",
        query: {
          username: item.username
        },
      });
    },
    // 倒计时
    countdown(vals) {
      // for(var i=0; i< vals.length;i++){
      //     //定时器更新时间
      //     setInterval(()=>{
      //         this.transferTime(vals[i], i)
      //     }, 1000);
      // }
      let that = this
      vals.forEach((v, i) => {
        that.timer[i] = setInterval(() => {
          this.transferTime(v, i)
        }, 1000)
      })
    },
    transferTime(dateValue, index) {
      // console.log(dateValue,'firstAudit')
      // console.log(index)
      let nowTime = new Date()
      let targetTime = new Date(Date.parse(dateValue))
      // console.log(targetTime,'targetTime');
      //得到时间戳
      let timeStamp = targetTime - nowTime

      // this.tims = timeStamp
      //天数
      let day = parseInt(timeStamp / (1000 * 60 * 60 * 24))
      //小时
      let hour = parseInt(
        (timeStamp % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      )
      //分钟
      let minute = parseInt((timeStamp % (1000 * 60 * 60)) / (1000 * 60))
      //秒
      let second = parseInt((timeStamp % (1000 * 60)) / 1000)

      // var date = document.getElementById('info');
      //向info盒子中添加倒计时
      if (timeStamp < 0) {
        this.$set(this.countdownValueArr, index, '已截止')
      } else {
        this.$set(
          this.countdownValueArr,
          index,
          day + '天' + hour + '小时' + minute + '分钟' + second + '秒'
        )
      }
      // console.log(this.tims, 'timeStamp')
      //this.countdownValueArr[index] =  day + '天' + hour + '小时' + minute + '分钟' + second + '秒'
    },
    clearCurTimer() {
      this.timer.forEach((v) => {
        clearInterval(v)
      })
    },
    initScroll() {
      this.$nextTick(() => {
        this.startScroll()
      })
    },
    initScroll2() {
      this.$nextTick(() => {
        this.startScroll2()
      })
    },
    // 鼠标移入停止滚动
    mEnter() {
      clearInterval(timer1)
    },
    mEnter2() {
      clearInterval(timer2)
    },
    // 鼠标移出继续滚动
    mLeave() {
      this.startScroll()
    },
    mLeave2() {
      this.startScroll2()
    },
    // 开始滚动
    startScroll() {
      timer1 = setInterval(this.scroll, this.speed)
    },
    startScroll2() {
      timer2 = setInterval(this.scroll2, this.speed2)
    },
    // 滚动处理方法
    scroll() {
      this.scrollTop++
      // 获取需要滚动的盒子的高度
      this.$nextTick(() => {
        let scrollItemBox = this.$refs.scrollItemBox.offsetHeight
        if (this.scrollTop >= scrollItemBox) {
          this.scrollTop = 0
        }
      })
    },
    scroll2() {
      this.scrollTop2++
      // 获取需要滚动的盒子的高度
      this.$nextTick(() => {
        let scrollItemBox2 = this.$refs.scrollItemBox2.offsetHeight
        // 当判断滚动的高度大于等于盒子高度时，从头开始滚动
        if (this.scrollTop2 >= scrollItemBox2) {
          this.scrollTop2 = 0
        }
      })
    },
  }
}
</script>
<style lang="less" scoped>
.rank {
  width: 304px;
  background: #fff;
  padding-left: 20px;
  height: 800px;
  em {
    display: inline-block;
    font-style: normal;
    background: #908d88;
    border-radius: 3px;
    color: #fff;
    cursor: pointer;
    padding: 0 4px;
    margin-right: 10px;
  }
  h3 {
    height: 25px;
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #1b86fb;
    line-height: 25px;
    padding-bottom: 5px;
    border-bottom: 1px solid #1b86fb;
  }
  .rank-top {
    margin-top: 40px;
    .top-ul {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      // grid-column-gap: 20px;
      // grid-row-gap: 30px;
      height: 42px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #4f5156;
      line-height: 16px;
      padding: 13px 0;
    }
  }
  .rank-bottom {
    margin-top: 40px;
    ul {
      margin: 10px 0;
      .top {
        em {
          background: #f70;
        }
      }
      li {
        height: 30px;
        cursor: pointer;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #4f5156;
        line-height: 16px;
        a {
          color: #333;
          text-decoration: none;
        }
      }
    }
  }
}
.scroll_parent_box {
  overflow: hidden;
  box-sizing: border-box;
  height: 320px;
}

.scroll_list {
  transition: all 0ms ease-in 0s;
}

.scroll_item {
  border-bottom: 1px solid #dcdfe6;
  font-size: 14px;
  padding: 15px 0;
  box-sizing: border-box;
}
</style>

