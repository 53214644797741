<template>
  <div class="join-apply" v-loading="msgLoading">
    <h2 style="margin: 22px;text-align: center">入驻申请</h2>
    <el-tag v-if="tipTxt" :type="tagType" class="top-tag">{{ tipTxt }}</el-tag>

    <el-form label-width="150px" :disabled="!canSubmit" ref="joinForm" :model="form" :rules="formRules">
      <el-form-item label="企业全称" prop="name">
        <el-input v-model="form.name" placeholder="请输入企业全称" :disabled="disabledSomeFormItems"></el-input>
      </el-form-item>
      <el-row>
        <el-col :span="12">
          <el-form-item label="企业地址" prop="addr">
            <el-input v-model="form.addr" @input="$forceUpdate()" :disabled="disabledSomeFormItems" placeholder="请输入企业地址"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="企业类型" prop="companytype">
            <el-select v-model="form.companytype" style="width: 33%">
              <el-option v-for="item in comTypes" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="企业法人" prop="contacter">
            <el-input v-model="form.contacter" :disabled="disabledSomeFormItems" placeholder="请输入企业法人"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="法人电话" prop="contacttel">
            <el-input v-model="form.contacttel" :disabled="disabledSomeFormItems" placeholder="请输入法人电话"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="联系邮箱">
            <el-input v-model="form.email" placeholder="请输入联系邮箱"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="官网地址">
            <el-input v-model="form.homepageUrl" placeholder="请输入官网地址"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-form-item label="微信号">
        <el-input v-model="form.wechatNumber" placeholder="请输入微信号"></el-input>
      </el-form-item>
      <el-row>
        <el-col :span="8">
          <el-form-item label="公司LOGO" prop="logopic" ref="logopicRef">
            <el-upload :class="canSubmit&&logopicPaths.length===0?'':'disabled'" :action="uploadOneImgUrl" :limit="1" list-type="picture-card"
              :file-list="fileListObj.logopic" :on-preview="handlePictureCardPreview" :on-remove="handleLogoRemove" :on-success="handleLogoSuccess">
              <i class="el-icon-plus"></i>
            </el-upload>
          </el-form-item>
        </el-col>
        <el-col :span="16">
          <el-form-item label="企业图片">
            <el-upload :class="canSubmit&&picPaths.length<9?'':'disabled'" :action="uploadOneImgUrl" :limit="9" list-type="picture-card"
              :on-preview="handlePictureCardPreview" :on-remove="handlePicRemove" :file-list="fileListObj.pic" :on-success="handleComSuccess">
              <i class="el-icon-plus"></i>
            </el-upload>
          </el-form-item>
        </el-col>
      </el-row>

      <el-form-item label="企业资质">
        <el-upload :class="canSubmit&&qualitypicsPaths.length<3?'':'disabled'" :action="uploadOneImgUrl" :limit="3" list-type="picture-card"
          :on-preview="handlePictureCardPreview" :on-remove="handleZiZhiRemove" :file-list="fileListObj.qualitypics" :on-success="handleZiZhiSuccess">
          <i class="el-icon-plus"></i>
          <div slot="tip" class="el-upload__tip">企业资质最多支持3张图片</div>
        </el-upload>
      </el-form-item>
      <el-row>
        <el-col :span="12">
          <el-form-item label="营业执照" prop="yyzzPic" ref="yyzzPicRef">
            <el-upload :class="canSubmit&&!disabledSomeFormItems&&yyzzPicPaths.length===0?'':'disabled'" :action="uploadOneImgUrl" :limit="1"
              list-type="picture-card" :on-preview="handlePictureCardPreview" :on-remove="handleYYZHRemove" :on-success="handleZhiZhaoSuccess"
              :file-list="fileListObj.yyzzPic">
              <i class="el-icon-plus"></i>
            </el-upload>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="企业视频">
            <el-upload class="avatar-uploader" :action="uploadAviUrl" :data="{index: 1}" v-bind:on-progress="uploadVideoProcess"
              v-bind:on-success="handleVideoSuccess" v-bind:before-upload="beforeUploadVideo" v-bind:show-file-list="false">
              <video v-if="form.videourl != '' && !videoFlag" v-bind:src="videoPreview" class="avatar video-avatar" controls="controls">
                您的浏览器不支持视频播放
              </video>
              <i v-else-if="form.videourl == '' && !videoFlag && canSubmit" class="el-icon-plus avatar-uploader-icon"></i>
              <el-progress v-if="videoFlag == true" type="circle" v-bind:percentage="videoUploadPercent" style="margin-top: 7px"></el-progress>
            </el-upload>
            <el-button size="mini" type="danger" v-if="form.videourl" @click="deleteVideo">删除</el-button>
          </el-form-item>
        </el-col>
      </el-row>

      <el-form-item label="协议图片">
        <el-upload :class="canSubmit&&agreementPicPaths.length===0?'':'disabled'" :action="uploadOneImgUrl" :limit="1" list-type="picture-card"
          :on-preview="handlePictureCardPreview" :on-remove="handleXieyiRemove" :file-list="fileListObj.agreementPic"
          :on-success="handleXieYiSuccess">
          <i class="el-icon-plus"></i>
        </el-upload>
      </el-form-item>
      <el-form-item label="模板下载">
        <el-link @click="download">协议模板.docx</el-link>
      </el-form-item>

      <el-form-item label="企业介绍">
        <vue-html5-editor @change="updateData" :content="form.decriptions" :height="500" :z-index="1000" :auto-height="true"
          :show-module-name="false"></vue-html5-editor>
      </el-form-item>
      <el-form-item v-if="canSubmit">
        <el-button type="primary" @click="save">保存</el-button>
      </el-form-item>
    </el-form>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>
</template>

<script>
// import Cookies from 'js-cookie'
import { getUserInfo } from '@/api'
import { getComTypes, uploadOneImg, uploadZiZhi, uploadAvi, getCompanyByUser, editCom } from '@/api/information'
import { INFO_URL } from '@/config/globalConfig'

export default {
  name: 'companyProfile',
  data() {
    return {
      baseUrl: INFO_URL,
      msgLoading: false,
      tipTxt: '',
      canSubmit: false,
      comTypes: [],
      tagType: 'primary',
      form: {
        id: '',
        name: '',
        addr: '',
        contacter: '',
        contacttel: '',
        companytype: '',
        email: '',
        homepageUrl: '',
        wechatNumber: '',
        decriptions: '',
        logopic: '',
        qualitypics: '',
        yyzzPic: '',
        pic: '',
        videourl: ''
      },
      formRules: {
        name: [{ required: true, message: '请输入企业全称', trigger: 'blur' }],
        addr: [{ required: true, message: '请输入企业地址', trigger: 'blur' }],
        contacter: [{ required: true, message: '请输入企业法人', trigger: 'blur' }],
        contacttel: [{ required: true, message: '请输入法人电话', trigger: 'blur' }],
        companytype: [{ required: true, message: '请选择企业类型', trigger: 'change' }],
        logopic: [{ required: true, message: '请上传公司LOGO', trigger: 'change' }],
        yyzzPic: [{ required: true, message: '请上传营业执照', trigger: 'change' }]
      },
      headers: '',
      fileListObj: {
        logopic: [],
        pic: [],
        qualitypics: [],
        yyzzPic: [],
        agreementPic: []
      },
      logopicPaths: [],
      picPaths: [],
      qualitypicsPaths: [],
      yyzzPicPaths: [],
      agreementPicPaths: [],
      // 企业视频
      videoFlag: false,
      videoUploadPercent: '',
      isShowUploadVideo: false,
      dialogVisible: false,
      dialogImageUrl: '',
      uploadOneImgUrl: '',
      uploadZiZhiUrl: '',
      uploadAviUrl: '',
      videoPreview: '',
      gysType: '',
      disabledSomeFormItems: false
    }
  },
  created() {
    // 首先获取资质信息
    this.firstGetMyInfo()

    this.uploadOneImgUrl = uploadOneImg()
    this.uploadZiZhiUrl = uploadZiZhi()
    this.uploadAviUrl = uploadAvi()
    this.getComTypes()
    // this.getTokenForUpload()
  },
  methods: {
    async firstGetMyInfo() {
      this.msgLoading = true
      try {
        const res = await getUserInfo()
        if (res.code !== 200) {
          this.$message.error(res.msg)
          return
        }
        const { gysType } = res.data
        this.gysType = gysType
        if (gysType === '4') {
          // 若资质是个人，默认所有字段由用户手动输入
          this.disabledSomeFormItems = false
        } else {
          // 企业维护界面加载逻辑：若资质是企业，则企业名称、企业地址、企业法人、法人电话、营业执照5个字段由资质带入，且不能修改
          this.disabledSomeFormItems = true
          const { companyName, legal, contactTel, yyzzPic, fpdwAddr } = res.data
          this.form.name = companyName // 企业全称
          this.form.contacter = legal // 企业法人
          this.form.contacttel = contactTel // 法人电话
          this.form.addr = fpdwAddr // 企业地址
          this.form.yyzzPic = yyzzPic
          this.fileListObj.yyzzPic = [{ name: '', url: this.baseUrl + yyzzPic, path: yyzzPic }]
        }
        // 然后获取我的公司信息
        this.getMyCom()
      } catch (error) {
        this.$message.error(error.message)
      } finally {
        this.msgLoading = false
      }
    },
    async getMyCom() {
      const res = await getCompanyByUser()
      const { status, remark } = res.data
      if (status === 1) {
        // 待审核
        this.tagType = 'warning'
        this.tipTxt = '尊敬的会员，您的申请正在核实中，暂时无法编辑，请等候！'
        this.canSubmit = false
      } else if (status === 2) {
        // 审核通过
        this.tagType = 'success'
        this.tipTxt = '尊敬的会员，恭喜您，审核通过！'
        this.canSubmit = false
      } else if (status === 3) {
        // 审核不通过
        this.tagType = 'danger'
        this.tipTxt = '尊敬的会员，很遗憾审核未通过，请修改！审核不通过原因：' + remark
        this.canSubmit = true
      } else {
        this.canSubmit = true
      }
      if (this.gysType === '4') {
        // 个人类型
        const { name, contacter, contacttel, yyzzPic, addr } = res.data
        this.form.name = name // 企业全称
        this.form.contacter = contacter // 企业法人
        this.form.contacttel = contacttel // 法人电话
        this.form.addr = addr // 企业地址
        if (yyzzPic) {
          this.form.yyzzPic = yyzzPic
          this.fileListObj.yyzzPic = [{ name: '', url: this.baseUrl + yyzzPic }]
          this.yyzzPicPaths = [yyzzPic]
        }
      }
      // 对于企业类型，填充剩余字段即可
      const { id, companytype, email, homepageUrl, wechatNumber, logopic, pic, qualitypics, videourl, decriptions, agreementPic } = res.data
      this.form.id = id
      this.form.companytype = companytype
      this.form.email = email
      this.form.homepageUrl = homepageUrl
      this.form.wechatNumber = wechatNumber
      if (logopic) {
        this.form.logopic = logopic
        this.fileListObj.logopic = [{ name: '', url: this.baseUrl + logopic, path: logopic }]
        this.logopicPaths = [logopic]
      }
      if (pic) {
        this.fileListObj.pic = pic.split(',').map((item) => {
          return {
            name: '',
            url: this.baseUrl + item,
            path: item
          }
        })
        this.form.pic = pic
        this.picPaths = [pic]
      }
      if (qualitypics) {
        this.fileListObj.qualitypics = qualitypics.split(',').map((item) => {
          return {
            name: '',
            url: this.baseUrl + item,
            path: item
          }
        })
        this.form.qualitypics = qualitypics
        this.qualitypicsPaths = qualitypics.split(',')
      }
      if (videourl) {
        this.form.videourl = videourl
        this.videoPreview = this.baseUrl + videourl
      }
      if (agreementPic) {
        this.form.agreementPic = agreementPic
        this.fileListObj.agreementPic = [{ name: '', url: this.baseUrl + agreementPic, path: agreementPic }]
        this.agreementPicPaths = [agreementPic]
      }
      

      if (decriptions) {
        this.form.decriptions = decriptions
      }
      console.log(this.form.decriptions)
    },
    // 企业类型
    async getComTypes() {
      const res = await getComTypes()
      const obj = res.data
      let arr = []
      for (let key in obj) {
        arr.push({
          label: obj[key],
          value: +key
        })
      }
      this.comTypes = arr
    },
    // 公司LOGO
    handleLogoSuccess(res) {
      if (res.code !== 200) {
        this.$message.error(res.msg)
        return
      }
      this.logopicPaths = [res.data.path]
      this.$refs.logopicRef.clearValidate()
    },
    handleLogoRemove() {
      this.logopicPaths = []
      this.form.logopic = ''
    },
    // 企业图片
    handleComSuccess(res) {
      if (res.code !== 200) {
        this.$message.error(res.msg)
        return
      }
      this.picPaths.push(res.data.path)
    },
    handlePicRemove(file) {
      const index = this.picPaths.findIndex((item) => item === file.path)
      this.picPaths.splice(index, 1)
    },
    // 企业资质
    handleZiZhiSuccess(res) {
      if (res.code !== 200) {
        this.$message.error(res.msg)
        return
      }
      this.qualitypicsPaths.push(res.data.path)
    },
    handleZiZhiRemove(file) {
      const index = this.qualitypicsPaths.findIndex((item) => item === file.path)
      this.qualitypicsPaths.splice(index, 1)
    },
    // 营业执照
    handleZhiZhaoSuccess(res) {
      if (res.code !== 200) {
        this.$message.error(res.msg)
        return
      }
      this.yyzzPicPaths = [res.data.path]
      this.$refs.yyzzPicRef.clearValidate()
    },
    handleYYZHRemove() {
      this.yyzzPicPaths = []
      this.form.yyzzPic = ''
    },
    // 企业视频
    handleVideoSuccess(res) {
      this.isShowUploadVideo = true
      this.videoFlag = false
      this.videoUploadPercent = 0

      //后台上传数据
      if (res.path) {
        this.form.videourl = res.path
        this.videoPreview = this.baseUrl + res.path
      } else {
        this.$message.error('上传失败！')
      }
    },
    beforeUploadVideo(file) {
      var fileSize = file.size / 1024 / 1024 < 50 //控制大小  修改50的值即可
      if (
        ['video/mp4', 'video/ogg', 'video/flv', 'video/avi', 'video/wmv', 'video/rmvb', 'video/mov'].indexOf(file.type) == -1 //控制格式
      ) {
        this.$message.warning('请上传正确的视频格式')
        return false
      }
      if (!fileSize) {
        this.$message.warning('视频大小不能超过50MB')
        return false
      }
      this.isShowUploadVideo = false
    },
    uploadVideoProcess(event, file) {
      //注意在data中添加对应的变量名
      this.videoFlag = true
      this.videoUploadPercent = file.percentage.toFixed(0) * 1
    },
    deleteVideo() {
      this.form.videourl = ''
    },
    // 协议上传
    handleXieYiSuccess(res) {
      if (res.code !== 200) {
        this.$message.error(res.msg)
        return
      }
      this.agreementPicPaths = [res.data.path]
    },
    handleXieyiRemove() {
      this.agreementPicPaths = []
    },
    // 模板下载
    download() {
      window.open('/info-admin/upload/template/xieyimuban.docx', '_blank')
    },
    // 企业介绍
    updateData(val) {
      this.form.decriptions = val
    },
    // 保存
    async save() {
      // 组装图片
      if (this.logopicPaths.length > 0) {
        this.form.logopic = this.logopicPaths.join(',')
      }
      if (this.picPaths.length > 0) {
        this.form.pic = this.picPaths.join(',')
      }
      if (this.qualitypicsPaths.length > 0) {
        this.form.qualitypics = this.qualitypicsPaths.join(',')
      }
      if (this.yyzzPicPaths.length > 0) {
        this.form.yyzzPic = this.yyzzPicPaths.join(',')
      }
      if (this.agreementPicPaths.length > 0) {
        this.form.agreementPic = this.agreementPicPaths.join(',')
      }

      // console.log(this.form)
      // return

      this.$refs.joinForm.validate(async (pass) => {
        if (pass) {
          const res = await editCom(this.form)
          if (res.code !== 200) {
            this.$message.error(res.msg)
            return
          }
          this.$message.success(res.msg)
          this.getMyCom()
        } else {
          this.$message({
            message: '请正确填写信息',
            type: 'warning'
          })
        }
      })
    },

    handleRemove(file, fileList) {
      console.log(file, fileList)
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    }
  }
}
</script>

<style lang="less" scoped>
.join-apply {
  max-width: 1130px;
  margin: 0 auto;
  .top-tag {
    margin-bottom: 22px;
    display: block;
    height: 40px;
    line-height: 40px;
    font-size: 16px;
    text-align: center;
  }
}
.avatar-uploader-icon {
  border: 1px dashed #d9d9d9 !important;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9 !important;
  border-radius: 6px !important;
  position: relative !important;
  overflow: hidden !important;
}
.avatar-uploader .el-upload:hover {
  border: 1px dashed #d9d9d9 !important;
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 300px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 300px;
  height: 178px;
  display: block;
}
</style>

<style>
.disabled .el-upload--picture-card {
  display: none;
}
</style>