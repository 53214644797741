<template>
  <div class="bid">
    <div class="banxinfuwu">
      <div class="bannercai">
        <!-- <img :src="banSrc" alt=""> -->
        <!-- <span class="demonstration">Click 指示器触发</span> -->
        <el-carousel trigger="click" height="500px">
          <el-carousel-item v-for="(item, index) in lunboList" :key="index">
            <img class="banner-img" :src="item.url" />
            <router-link
              v-if="item.link_url != ''"
              :to="item.link_url"
              class="linkLun"
            ></router-link>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <!-- <div class="bidGl" @click="bidGlBtn"><i class="el-icon-s-management"></i>招标管理</div> -->
    <!-- search -->

    <div class="banxin bgwhite">
      <div class="headTab">
        <div
          class="titTab"
          v-for="(item, index) in tabList"
          :key="index"
          @click="checkItem(index)"
        >
          <div class="" style="margin: 0 auto; display: flex">
            <img :src="item.icon" alt="" />
            <span :class="{ active: num == index }">{{ item.title }}</span>
          </div>
          <div
            style="
              float: right;
              width: 1px;
              height: 35px;
              margin: auto 0;
              border-right: 0.5px solid #666;
              transform: scaleX(0.5);
            "
            v-if="index < 2"
          ></div>
          <!-- <img :src="item.icon" alt="" />
          <span>{{ item.title }}</span> -->
        </div>
      </div>

      <div class="new-row">
        <!-- 信息轮播 -->
        <div class="row-right" v-if="num !== 2">
          <!-- 中标信息 -->

          <div class="row-main" v-if="num == 1">
            <p class="row-title">最新中标状态</p>
            <div
              class="scroll_parent_box"
              @mouseenter="mEnter"
              @mouseleave="mLeave"
            >
              <div
                class="scroll_list"
                :style="{ transform: `translate(0px,-${scrollTop}px)` }"
              >
                <div ref="scrollItemBox" class="box">
                  <div
                    class="scroll_item"
                    v-for="(item, index) in listData"
                    :key="index"
                  >
                    <div class="item-row item-row1">
                      <div class="ritem1">{{ item.cname }}</div>
                      <div class="ritem2">中标</div>
                      <div class="ritem3">{{ item.mname }}</div>
                    </div>
                    <div class="item-row item-row2">
                      <div class="ritem4">{{ item.uname }}</div>
                      <div class="ritem5">{{ item.zbDate }}</div>
                    </div>
                  </div>
                </div>
                <div v-html="copyHtml"></div>
              </div>
            </div>
          </div>
          <!-- 采购信息 -->
          <div class="row-main" v-if="num == 0">
            <p class="row-title">最新采购动态</p>
            <!--  @mouseenter="mEnter2" @mouseleave="mLeave2" -->
            <div
              class="scroll_parent_box"
              @mouseenter="mEnter2"
              @mouseleave="mLeave2"
            >
              <div
                class="scroll_list"
                :style="{ transform: `translate(0px,-${scrollTop2}px)` }"
              >
                <div ref="scrollItemBox2" class="box">
                  <div
                    class="scroll_item"
                    v-for="(item, index) in listData2"
                    :key="index"
                  >
                    <div class="item-row item-row1">
                      <div class="ritem1">{{ item.cname }}</div>
                      <div class="ritem2">采购</div>
                      <div class="ritem3">{{ item.mname }}</div>
                    </div>
                    <div class="item-row item-row2">
                      <div class="ritem4" style="color: #888">到期</div>
                      <div class="ritem5">
                        <i class="el-icon-time" style="margin-right: 5px"></i
                        >{{ item.pubDate }}
                      </div>
                    </div>
                  </div>
                </div>
                <div v-html="copyHtml2"></div>
              </div>
            </div>
          </div>
        </div>

        <!-- 招标信息 -->
        <div class="row-left" v-if="num == 0">
          <div class="search-module">
            <el-input
              placeholder="请输入品名"
              v-model="searceValue"
              style="margin-right: 20px"
            ></el-input>
            <el-input
              placeholder="请输入标题"
              v-model="searceValue2"
            ></el-input>
            <el-button class="search-btn" type="primary" @click="search"
              >搜索</el-button
            >
            <el-button class="search-btn" @click="reset">重置</el-button>
          </div>
          <ul class="bid-list">
            <li class="card" v-for="(item, index) in dataList" :key="index">
              <div style="width: 70%">
                <div class="card-title">
                  {{ item.cname }}
                  <!-- <div class="count-down">
                  报价剩余：<span style="color: #999">{{
                    countdownValueArr[index]
                  }}</span>
                </div> -->
                </div>
                <ul class="card-con" style="width: 100%">
                  <li class="card-con-item">
                    <div class="item1 iteme mr15 dflex">
                      <div class="flex1" style="flex: 0.5">
                        <span>招标状态:</span>
                        <span style="color: #333; font-weight: bold">{{
                          bidStatusWatch(item.bidStatus)
                        }}</span>
                      </div>
                      <div class="flex1" style="flex: 0.5">
                        编号:{{ item.zbNo }}
                      </div>
                      <div class="fentxt" v-if="item.mname">
                        采购品名:{{ item.mname }}
                      </div>
                      <!-- <div class="fentxt" v-if="item.type">
                        分类：{{ typeWatch(item.type) }}
                      </div> -->
                    </div>

                    <!-- <div class="item1 iteme mr15" style="color: #777">
                      <span style="padding-right: 15px">采购品名：</span
                      >{{ item.mname }}
                    </div> -->
                  </li>
                </ul>
              </div>

              <div class="card-con-right">
                <div
                  class="fl bts"
                  v-if="
                    countdownValueArr[index] !== '已截止' &&
                    (item.bidStatus == 2 || item.bidStatus == 7)
                  "
                >
                  <div class="pr">
                    <el-button
                      type="primary"
                      size="small"
                      style="border-radius: 5px"
                      plain
                      @click="detailBtn(item.inquiryid)"
                      class="btnhover fl"
                      >立即报价
                    </el-button>
                  </div>
                  <div class="headTxt" style="font-size: 12px; color: #999">
                    报价剩余：<span style="color: #999">{{
                      countdownValueArr[index]
                    }}</span>
                  </div>
                  <!-- <div class="item1 item3" @click="showDetail(item.inquiryid)"
                                        style="margin-top:10px;">招标说明 》</div> -->
                  <!-- <div class="item1 item3" @click="detailBtn(item.inquiryid)">查看详情 》</div> -->
                </div>
                <div v-else style="float: left; top: 20px">投标截止</div>

                <div
                  class="weiimgshow fr"
                  @mouseenter="nums = index"
                  @mouseleave="nums = null"
                >
                  <div class="bigEwm" v-if="nums == index">
                    <vue-qr
                      style="display: block; width: 150px; height: 150px"
                      class="card-con-right-img"
                      :text="qrurl + '?id=' + item.inquiryid"
                    ></vue-qr>
                    <div class="card-con-right-value">扫一扫立即报价</div>
                  </div>
                  <!-- <div class="headTxt" style="font-size: 12px; color: #999">
                    报价剩余：<span style="color: #999">{{
                      countdownValueArr[index]
                    }}</span>
                  </div> -->
                  <vue-qr
                    style="display: block; width: 40px; height: 40px"
                    class="card-con-right-img"
                    :text="qrurl + '?id=' + item.inquiryid"
                  ></vue-qr>
                  <div
                    class="card-con-right-value"
                    style="font-size: 12px; color: #999"
                  >
                    扫一扫立即报价
                  </div>
                  <!-- <div class="card-con-right-value">立即报价</div> -->
                </div>
              </div>
            </li>
          </ul>
          <div class="pagination">
            <el-pagination
              class="pagination-style"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="pagination.page"
              :page-sizes="[10, 20, 50, 100]"
              :page-size="pagination.size"
              layout="total, sizes, prev, pager, next, jumper"
              :total="pagination.total"
            >
            </el-pagination>
          </div>
          <quoted-price
            ref="quotedprice"
            :visible.sync="visiblePrice"
            @closeq="closeq"
            @openq="openq"
            :id="itemDetail.inquirymid"
            :pid="itemDetail.inquiryid"
            :obj="itemDetail"
          ></quoted-price>

          <show-detail
            ref="showdetail"
            :visible.sync="visibleDetailDlg"
            @closeq="closeDetail"
            @openq="openDetail"
            :id="itemDetail.inquirymid"
            :pid="itemDetail.inquiryid"
            :obj="itemDetail"
          >
          </show-detail>
        </div>

        <!-- 中标结果 -->
        <div class="row-left" v-if="num == 1">
          <div class="search-module" style="width: 50%">
            <!-- <el-input
              placeholder="请输入品名"
              v-model="searceValue"
              style="margin-right: 20px"
            ></el-input> -->
            <el-input
              placeholder="请输入标题"
              v-model="listParam.title"
            ></el-input>
            <el-button
              class="search-btn"
              type="primary"
              @click="getFicationList"
              >搜索</el-button
            >
            <el-button class="search-btn" @click="resets">重置</el-button>
          </div>

          <!-- 列表信息 -->
          <div class="bid-list">
            <div class="cards" v-for="(item, index) in titleList" :key="index">
              <!-- <div class="card-title">
                {{ item.title }}
              </div> -->
              <div class="card_con">
                <div class="card-title">
                  {{ item.title }}
                </div>
                <div class="card_btn">
                  <el-button
                    type="primary"
                    size="small"
                    style="border-radius: 5px"
                    plain
                    @click="goDetail(item.id)"
                    class="btnhover fl"
                    >查看详情
                  </el-button>
                </div>
              </div>
            </div>
          </div>
          <div class="pagination">
            <el-pagination
              class="pagination-style"
              @size-change="handleSizeChange1"
              @current-change="handleCurrentChange1"
              :current-page="listParam.pageNum"
              :page-sizes="[10, 20, 50, 100]"
              :page-size="listParam.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>

          <quoted-price
            ref="quotedprice"
            :visible.sync="visiblePrice"
            @closeq="closeq"
            @openq="openq"
            :id="itemDetail.inquirymid"
            :pid="itemDetail.inquiryid"
            :obj="itemDetail"
          ></quoted-price>

          <show-detail
            ref="showdetail"
            :visible.sync="visibleDetailDlg"
            @closeq="closeDetail"
            @openq="openDetail"
            :id="itemDetail.inquirymid"
            :pid="itemDetail.inquiryid"
            :obj="itemDetail"
          >
          </show-detail>
        </div>

        <!-- 澄清公告 -->
        <notice v-if="num == 2"></notice>
      </div>
    </div>
    <!-- 版心结束 -->
  </div>
</template>

<script>
let timer1 = null
let timer2 = null
import vueQr from 'vue-qr'
import quotedPrice from './quotedPrice.vue'
import {
  getInquiryUser,
  getInqueryList,
  getInqueryMaterialsList,
  getFicationList,
} from '@/api/bid'
import { QR_URL } from '@/config/globalConfig'
import { getLunbotu, getUserInfo, getDictInfo } from '@/api'
import showDetail from './showDetail'
import Notice from './noticeMessage/notice'

export default {
  components: { quotedPrice, vueQr, showDetail, Notice },
  data() {
    return {
      checkFlag: '',
      userInfo: '',
      fenList: {},
      qrurl: QR_URL,
      visibleLogin: false,
      isLogon: true,
      ewmShow: false,
      entryType: '',
      // tims: null,
      dataList: [],
      titleList: [],
      pagination: {
        total: 0,
        size: 10,
        page: 1,
      },
      searceValue: '',
      searceValue2: '',
      type: '',
      num: 0,
      nums: null,
      visiblePrice: false,
      visibleDetailDlg: false,
      itemDetail: {},
      urls: [],
      countdownValue: '',
      countdownValueArr: [],
      form: {
        name: '',
        name2: '',
        type: 0,
        qstatus: 2,
        payment: 2,
        pageIndex: 1,
        pageSize: 10,
      },

      scrollTop: 0, //列表滚动高度
      speed: 25, //滚动的速度
      scrollTop2: 0, //列表滚动高度
      speed2: 25, //滚动的速度
      listData: [], // 最新中标状态数据，表示有多少个列表项
      copyHtml: '', //复制多一份防止滚动到后面出现空白
      listData2: [], //最新采购动态 表示有多少个列表项
      copyHtml2: '', //复制多一份防止滚动到后面出现空白
      lunboList: [],
      timer: [],
      bidStatusObj: [
        { value: 0, label: '已发起' },
        { value: 1, label: '开标中' },
        { value: 2, label: '二次报价' },
        { value: 3, label: '评审中' },
        { value: 4, label: '已中标' },
        { value: 5, label: '招标审核中' },
        { value: 6, label: '招标审核不通过' },
        { value: 7, label: '投标中' },
        { value: 8, label: '已结束' },
        { value: -1, label: '审核不通过' },
        { value: -2, label: '流标' },
        { value: -3, label: '废标' },
      ],
      options: [
        {
          value: '1',
          label: '',
        },
        {
          value: '2',
          label: '',
        },
      ],
      tabList: [
        {
          icon: require('@/assets/imgs/zbxx.svg'),
          title: '招标信息',
        },
        {
          icon: require('@/assets/imgs/zbjg.svg'),
          title: '中标/结果公示',
        },
        {
          icon: require('@/assets/imgs/cqgg.svg'),
          title: '澄清/变更公告',
        },
      ],
      value: '',
      listParam: {
        pageNum: 0,
        pageSize: 10,
        type: 2,
        title: '',
        zbNo: '',
      },
      total: '',
    }
  },
  computed: {
    bidStatusWatch() {
      return function (bidStatus) {
        if (bidStatus == null) {
          return ''
        }

        let bidStatusFind = this.bidStatusObj.find(
          (item) => item.value == bidStatus
        )
        return bidStatusFind ? bidStatusFind.label : ''
      }
    },
    typeWatch() {
      return function (type) {
        if (type == null) {
          return ''
        }
        return this.fenList[type]
      }
    },
  },
  beforeDestroy() {
    clearInterval(timer1)
    clearInterval(timer2)
  },
  created() {
    //this.countdown(['2022-09-09'])

    this.form.name = this.$route.query.id
    // console.log('this.form.name')
    // console.log(this.form.name)
    this.getInqueryMaterialsList()
    // this.getInqueryMaterials()
    // this.getInquiryUser()
    this.getInqueryList()
    this.getLunbotu()
    // this.initScroll()
    // if (this.num == 0) {
    this.initScroll2()
    // }

    this.getUserInfo()
    this.getDictInfo()
  },
  methods: {
    getDictInfo() {
      // console.log('resresres')
      getDictInfo('BIDTYPE').then((res) => {
        // console.log(res, 'resresres')
        if (res.code == 200) {
          // console.log('fenlei', res.data)
          this.fenList = res.data

          // this.dataList.push(res.data)
          this.options[0].label = this.fenList[1]
          this.options[1].label = this.fenList[2]
          // console.log('xindatalist', this.fenList[1])
        }
      })
    },
    getUserInfo() {
      getUserInfo().then((res) => {
        this.$store.commit('userInfo', res.data || {})
        // console.log('yonhuming', res.data)
      })
    },

    checkItem(i) {
      if (this.num == i) {
        return
      }
      this.num = i
      if (this.num == 0) {
        this.initScroll2()
        clearInterval(timer1)
        this.getInqueryList()
      } else if (this.num == 1) {
        this.initScroll()
        this.getInquiryUser()
        this.getFicationList()
        clearInterval(timer2)
      } else if (this.num == 2) {
        clearInterval(timer2)
        clearInterval(timer1)
      }
    },

    goDetail(id) {
      this.$router.push({
        path: '/noticeDetail?id=' + id,
      })
    },

    // Banner图
    getLunbotu() {
      getLunbotu({ limitN: 3, location: 5 }).then((res) => {
        if (res.code == 200) {
          this.lunboList = res.data
          // console.log('lunbotu', res.data)
        }
      })
    },

    getFicationList() {
      getFicationList(this.listParam).then((res) => {
        console.log(res, '公告列表')
        if (res.code == 200) {
          this.titleList = res.data.content
          this.total = res.data.totalElements
        } else {
          this.$message.warning(res.msg)
        }
      })
    },

    open() {
      this.visibleDetail = true
    },
    search() {
      this.form.name = this.searceValue
      this.form.name2 = this.searceValue2
      this.form.type = this.type
      this.getInqueryMaterialsList()
    },

    resets() {
      this.listParam.title = ''
      this.getFicationList()
    },

    // 重置
    reset() {
      this.form = {
        name: '',
        name2: '',
        type: 0,
        qstatus: 2,
        payment: 2,
        pageIndex: 1,
        pageSize: 10,
      }
      this.searceValue = ''
      this.searceValue2 = ''
      this.getInqueryMaterialsList()
    },
    closeq() {
      this.visiblePrice = false
    },
    openq() {
      this.visiblePrice = true
    },

    closeDetail() {
      this.visibleDetailDlg = false
    },
    openDetail() {
      this.visibleDetailDlg = true
    },
    getInqueryMaterialsList() {
      // console.log('this.form')
      // console.log(this.form)
      let that = this
      getInqueryMaterialsList({
        cname: that.form.name2,
        mname: that.form.name,
        type: that.form.type,
        qstatus: that.form.qstatus,
        // type: that.form.type,
        payment: that.form.payment,
        pageIndex: that.pagination.page,
        pageSize: that.pagination.size,
      }).then((res) => {
        //console.log(res)
        this.clearCurTimer()
        let datas = res.data.content
        console.log(datas, 'datas')
        let vals = []
        for (var i = 0; i < datas.length; i++) {
          // console.log(datas[i]);
          if (datas[i].pics != null) {
            let picArr = datas[i].pics.split(',')
            let r = picArr.filter(function (s) {
              return s && s.trim()
            })
            datas[i].pics = r
          } else {
            datas[i].pics = []
          }
          vals.push(datas[i].deadlineDate)
        }
        //console.log(vals)

        that.countdown(vals)
        //console.log("datas");
        //console.log(datas);
        that.dataList = datas
        console.log('list', that.dataList)
        that.pagination.total = res.data.totalElements
      })
    },
    getInquiryUser() {
      let that = this
      getInquiryUser(20).then((res) => {
        that.listData = res.data
        this.$nextTick(() => {
          this.copyHtml = this.$refs.scrollItemBox.innerHTML
        })
      })
    },
    getInqueryList() {
      let that = this
      getInqueryList(20).then((res) => {
        that.listData2 = res.data
        this.$nextTick(() => {
          this.copyHtml2 = this.$refs.scrollItemBox2.innerHTML
        })
      })
    },
    bidGlBtn() {
      this.$router.push({
        path: '/bidManagement',
      })
    },

    handleSizeChange1(val) {
      this.listParam.pageSize = val
      this.listParam.pageNum = 0
      // debugger
      this.getFicationList()
    },
    handleCurrentChange1(val) {
      this.listParam.pageNum = val
      this.getFicationList()
    },

    handleSizeChange(val) {
      this.pagination.size = val
      this.pagination.page = 1
      this.getInqueryMaterialsList()
    },
    handleCurrentChange(val) {
      this.pagination.page = val
      this.getInqueryMaterialsList()
    },
    detailBtn(inquiryId) {
      if (!this.userInfo.nickname) {
        this.$router.push('/login')
        // this.visibleLogin = true
        // console.log('yonh', this.userInfo.nickname)
      } else {
        if (this.userInfo.checkFlag == -1) {
          this.$message.warning('您还没有认证资质，请认证资质哦！')
          setTimeout(() => {
            this.$router.push({
              name: 'qualifications',
            })
          }, 1000)
          return
        } else if (this.userInfo.checkFlag == 0) {
          this.$message.warning('您的资质正在审核中，请等候！')
          setTimeout(() => {
            this.$router.push({
              name: 'qualifications',
            })
          }, 1000)
          return
        } else if (this.userInfo.checkFlag == 2) {
          this.$message.warning('您的资质认证未通过，请重新认证！')
          setTimeout(() => {
            this.$router.push({
              name: 'qualifications',
            })
          }, 1000)
          return
        }
        // this.visibleLogin = false
        this.$router.push({
          name: 'bidDetail',
          query: {
            id: inquiryId,
          },
        })
      }
    },
    quotedPrice(item) {
      let oDate1 = new Date(item.deadline)
      let oDate2 = new Date()
      if (oDate1.getTime() < oDate2.getTime()) {
        this.open()
        // this.$message.warning('截止日期已过')
        //     this.$alert('截止日期已过', '提示', {
        //         confirmButtonText: '确定',
        //         callback: action => {
        //           this.$message({
        //             type: 'info',
        //             message: `action: ${ action }`
        //           });
        //         }
        //  });
      } else {
        this.$refs.quotedprice.getInqueryMU(item.inquiryid, item.inquirymid)
        this.itemDetail = item
      }

      //this.visiblePrice = true
    },

    showDetail(id) {
      this.$refs.showdetail.detail(id)
    },
    // 倒计时
    countdown(vals) {
      // for(var i=0; i< vals.length;i++){
      //     //定时器更新时间
      //     setInterval(()=>{
      //         this.transferTime(vals[i], i)
      //     }, 1000);
      // }
      console.log(vals, 'vals')
      let that = this
      vals.forEach((v, i) => {
        that.timer[i] = setInterval(() => {
          this.transferTime(v, i)
        }, 1000)
      })
    },
    transferTime(dateValue, index) {
      // console.log(dateValue,'firstAudit')
      // console.log(index)
      let nowTime = new Date()
      let targetTime = new Date(Date.parse(dateValue))
      // console.log(targetTime,'targetTime');
      //得到时间戳
      let timeStamp = targetTime - nowTime

      // this.tims = timeStamp
      //天数
      let day = parseInt(timeStamp / (1000 * 60 * 60 * 24))
      //小时
      let hour = parseInt(
        (timeStamp % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      )
      //分钟
      let minute = parseInt((timeStamp % (1000 * 60 * 60)) / (1000 * 60))
      //秒
      let second = parseInt((timeStamp % (1000 * 60)) / 1000)

      // var date = document.getElementById('info');
      //向info盒子中添加倒计时
      if (timeStamp < 0) {
        this.$set(this.countdownValueArr, index, '已截止')
      } else {
        this.$set(
          this.countdownValueArr,
          index,
          day + '天' + hour + '小时' + minute + '分钟' + second + '秒'
        )
      }
      // console.log(this.tims, 'timeStamp')
      //this.countdownValueArr[index] =  day + '天' + hour + '小时' + minute + '分钟' + second + '秒'
    },
    clearCurTimer() {
      this.timer.forEach((v, i) => {
        clearInterval(v)
        console.log(i, '22')
      })
    },
    initScroll() {
      this.$nextTick(() => {
        // this.copyHtml = this.$refs.scrollItemBox.innerHTML;
        this.startScroll()
      })
    },
    initScroll2() {
      this.$nextTick(() => {
        // this.copyHtml2 = this.$refs.scrollItemBox2.innerHTML;
        this.startScroll2()
      })
    },
    // 鼠标移入停止滚动
    mEnter() {
      clearInterval(timer1)
    },
    mEnter2() {
      clearInterval(timer2)
    },
    // 鼠标移出继续滚动
    mLeave() {
      this.startScroll()
    },
    mLeave2() {
      this.startScroll2()
    },
    // 开始滚动
    startScroll() {
      timer1 = setInterval(this.scroll, this.speed)
    },
    startScroll2() {
      timer2 = setInterval(this.scroll2, this.speed2)
    },
    // 滚动处理方法
    scroll() {
      this.scrollTop++
      // 获取需要滚动的盒子的高度
      this.$nextTick(() => {
        let scrollItemBox = this.$refs.scrollItemBox.offsetHeight
        if (this.scrollTop >= scrollItemBox) {
          this.scrollTop = 0
        }
      })
    },
    scroll2() {
      this.scrollTop2++
      // 获取需要滚动的盒子的高度
      this.$nextTick(() => {
        let scrollItemBox2 = this.$refs.scrollItemBox2.offsetHeight
        // 当判断滚动的高度大于等于盒子高度时，从头开始滚动
        if (this.scrollTop2 >= scrollItemBox2) {
          this.scrollTop2 = 0
        }
      })
    },
  },
  watch: {
    $route: {
      handler(val) {
        this.activeValue = val.name
        // console.log(val, '$route')
      },
      immediate: true,
      deep: true,
    },
    '$store.state.userInfo': {
      handler(val) {
        this.userInfo = val
      },
      deep: true,
    },
  },
}
</script>

<style lang="less" scoped>
@import url('../../assets/common.less');

.banxin {
  max-width: 1190px;
  margin: 20px auto 0;
  padding-bottom: 20px;
}

.bts {
  position: absolute;
  top: 15%;
  // transform: translate(0, -50%);
}
.bgwhite {
  background: rgb(255, 255, 255);
  margin-top: 70px;
  box-shadow: 0 0 3px 3px rgb(235, 235, 235);
  padding-top: 90px;
  position: relative;
}
.headTab {
  position: absolute;
  width: 70%;
  margin: 0 auto;
  height: 100px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 0 4px 2px #eee;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .titTab {
    width: 33%;
    height: 100%;
    display: flex;
    line-height: 100px;
    text-align: center;
    align-items: center;
    cursor: pointer;
    img {
      // display: inline;
      // line-height: 100px;
      margin: auto 0;
      align-items: center;
      width: 40px;
      height: 40px;
    }
    span {
      font-size: 20px;
      font-weight: bold;
      color: #666;
    }
    .active {
      color: #1ba1fb;
    }
  }
}
.banxinfuwu {
  width: 100%;
  height: 500px;
  margin: 0 auto;
}

.card-con-right-value {
  font-size: 12px;
  color: #333;
  // background-color: #fff;
  padding-bottom: 5px;
}

.card-title {
  display: block !important;
  font-weight: bold;
  color: @primary;
  width: 90% !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.row-left {
  width: 70.8%;
}

.bid {
  min-width: 1210px;
  background: @beijingse;
  padding-bottom: 60px;

  .search-module {
    // width: 89%;
    display: flex;
    flex-direction: row;
    text-align: center;
    margin-bottom: 5px;
    padding-top: 20px;
    margin-left: 20px;

    /deep/ .el-input__inner {
      border: none;
      -webkit-appearance: none;
      background-color: #fff;
      background-image: none;
      border: 1px solid #aaa;
      box-sizing: border-box;
      color: #606266;
      display: inline-block;
      font-size: inherit;
      height: 30px;
      line-height: 30px;
      outline: 0;
      padding: 0 15px;
      width: 100%;
      border-radius: 5px;
    }
    /deep/ .el-button {
      padding: 6px 20px !important;
    }
    .search-btn {
      width: 100px;
      margin-left: 30px;
      border-radius: 5px;
    }
  }

  .bidGl {
    float: right;
    line-height: 40px;
    cursor: pointer;
  }

  .bid-list {
    padding-top: 20px;
    margin-left: 20px;
    min-height: 300px;
    border-bottom: 1px dashed @bordercolor;
    .cards {
      border-top: 1px dashed @bordercolor;
      border-right: 1px dashed @bordercolor;
      border-left: 1px dashed @bordercolor;
      padding: 15px 20px 10px;
      // border-bottom: 1px dashed #eee;
      .card-title {
        font-size: 16px;
        line-height: 34px;
        display: flex;
        align-items: center;
        width: 100%;
        // margin-bottom: 10px;
      }
      .card_con {
        margin-top: 15px;
        display: flex;
        .card_mess {
          flex: 7;
          font-size: 14px;
        }
        .card_btn {
          flex: 2;
        }
      }
    }
    .card {
      border-top: 1px dashed @bordercolor;
      border-right: 1px dashed @bordercolor;
      border-left: 1px dashed @bordercolor;
      padding: 15px 20px 10px;
      display: flex;
      flex-wrap: wrap;
      .caigoutxt {
        font-weight: bold;
        color: @primary;
      }

      &-title {
        font-size: 16px;
        display: flex;
        align-items: center;
        width: 100%;
        margin-bottom: 10px;

        .l-style {
          display: inline-block;
          width: 2px;
          height: 18px;
          background: @primary;
          margin-right: 6px;
        }

        .count-down {
          width: 33%;
          font-size: 14px;
          color: #999;
          margin-left: auto;
          text-align: right;

          span {
            color: #333;
          }
        }
      }

      &-con {
        font-size: 14px;
        width: 70%;

        &-item {
          // display: flex;
          // align-items: center;
          // justify-content: space-between;
          margin: 5px 0;
          margin-right: 10px;
          // .item1 {
          // width: calc((100% - 100px)/2);
          // width: 296px;
          // }

          // .item1:last-child {
          //     width: 200px;
          // }

          .itemgai {
            width: 140px;
          }

          .iteme {
            margin: 10px 0;
            line-height: 1.8;
          }

          .item2 {
            position: relative;

            .btns {
              position: absolute;
              font-size: 16px;
              padding: 4px 16px;
              border: 1px solid @primary;
              border-radius: 4px;
              top: -10px;
              right: 0;
              cursor: pointer;
              color: @primary;
            }

            .btns:hover {
              background-color: rgba(20, 99, 158, 0.1);
            }
          }

          .item3 {
            color: #999;
            cursor: pointer;
          }
        }
      }

      &-con-right {
        width: 29%;
        text-align: center;
        padding-top: 20px;
        position: relative;

        &-img {
          width: 80px;
          height: 80px;
          margin: 10px auto 0;
          margin-top: 0;
          display: block;
        }

        &-value {
          font-size: 12px;
        }
      }

      &-con-imgs {
        width: 100%;

        .con-imgs-item {
          width: 50px;
          height: 50px;
          margin: 0 4px;
        }
      }

      .images-style {
        width: 10%;
        padding: 4px;
        box-sizing: border-box;
        margin-bottom: 6px;
      }
    }

    .card:last-child {
      border-bottom: 1px dashed @bordercolor;
    }

    .card:nth-child(2n) {
      background: #f9f9f9;
    }
  }

  .pagination-style {
    // width: 100%;
    text-align: right;
    padding-top: 16px;
    padding-bottom: 16px;
    padding-right: 10px;
    box-sizing: border-box;
  }
  .card-con-right {
    padding-top: 0 !important;
    position: relative;
    .headTxt {
      width: 180px;
      // text-align: center;
      white-space: nowrap;
      text-align: right;
      position: absolute;
      bottom: -28px;
      left: -100px;
    }
  }
  .weiimgshow {
    // margin-top: -24px;
    position: relative;
    .bigEwm {
      position: absolute;
      z-index: 11100;
      right: -130px;
      top: -40px;
    }
  }
}
.pagination {
  display: flex;
  // align-items: center;
  // width: 70%;

  .pagination-style {
    margin: 10px auto;
  }
}
.scroll_parent_box {
  overflow: hidden;
  box-sizing: border-box;
  height: 450px;
}

.scroll_list {
  transition: all 0ms ease-in 0s;
}

.scroll_item {
  border-bottom: 1px solid #dcdfe6;
  font-size: 14px;
  padding: 15px 0;
  box-sizing: border-box;
}

.new-row {
  display: flex;
  // margin-right: 20px;
}

.row-right {
  width: 25%;
  margin-left: 30px;
}

.row-main {
  width: 297px;
  height: 500px;
  padding: 10px;
  font-size: 14px;
  border: 1px dashed #efefef;
  box-sizing: border-box;
  background-color: #f9f9f9;
  overflow: hidden;

  // box-shadow: 0 0 10px #ddd;
  // border-radius: 8px;
  .row-title {
    font-size: 16px;
    line-height: 28px;
    padding-bottom: 8px;
    color: #1ba1fb;
    border-bottom: 1px dashed #dfdfdf;
  }

  .item-row {
    display: flex;

    .ritem1 {
      width: 58%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      vertical-align: middle;
    }

    .ritem2 {
      width: 29.1%;
      margin-left: 8.5%;
      vertical-align: middle;
    }

    .ritem3 {
      color: @primary;
      width: 29.1%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-align: right;
      vertical-align: middle;
    }

    .ritem4 {
      width: 50%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      vertical-align: middle;
    }

    .ritem5 {
      text-align: right;
      width: 50%;
    }
  }

  .item-row2 {
    padding-top: 10px;
  }
}

/deep/ .el-pager li {
  color: #686a6e;
}

.bannercai {
  width: 100%;
  height: 500px;
  text-align: center;
  img {
    // width: 100%;
    height: 100%;
  }
}

.mr15 {
  margin-right: 15px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/deep/ .el-pager li.active {
  color: #fff;
  background: #3883e5;
}

/deep/ .el-pager li {
  background: #f4f4f5;
  margin: 0 2px;
  color: #686a6e;
}

/deep/ .el-pagination__editor.el-input {
  border: 1px solid #f4f4f5;
}
.linkLun {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
.fentxt {
  // margin-left: 20px;
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.flex1 {
  flex: 1.1;
}
// /deep/ .el-select {
//   margin-left: 20px;
//   width: 350px;
// }
</style>
