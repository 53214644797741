
<template>
  <div class="page flex-col">
    <div class="h2-box">
      <img referrerpolicy="no-referrer"
        src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng9f0924056986b6c9fb7451131964e5e769f444a1eecac30042c39eac0e3d6a33" />
      <h2>产品详情</h2>
      <img referrerpolicy="no-referrer"
        src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng753270a6fd442eec7c7de7ae8a3a2ecc8b057ca5d7e52609ab8556e6a2b62f11" />
    </div>
    <div class="group_1 flex-col">
      <div class="group_3 flex-col"></div>
      <div class="group_4 flex-row justify-between">
        <div class="block_1 flex-col">
          <img :src="'/info-admin' + productDetail.mainpic" alt="" style="height: 360px;">
        </div>
        <div class="block_2 flex-col">
          <div class="text-group_2 flex-col justify-between">
            <span class="text_6">{{ productDetail.name }}</span>
            <span class="text_7">产&nbsp;&nbsp;&nbsp;地：{{ origin }}</span>
          </div>
          <div class="text-wrapper_2 flex-col">
            <span class="text_8">联系厂家：{{ productDetail.companyname }}</span>
          </div>
          <div @click="goHome" class="text-wrapper_3 flex-col" style="cursor: pointer;">
            <span class="text_9">联系厂家</span>
          </div>
        </div>
      </div>
      <span class="text_10">商品介绍</span>
      <div class="group_5 flex-col"></div>
      <!-- <el-carousel trigger="click" style="width: 500px;" v-if="lunboList.length>0">
              <el-carousel-item v-for="(item, index) in lunboList" :key="index">
                  <img style="margin-left: calc(50% - 205px);" class="banner-img" :src="baseUrl + item" />
              </el-carousel-item>
          </el-carousel> -->
      <div style="display:flex;justify-content: center; flex-wrap: wrap;">
        <img v-for="(item, index) in productDetailList" :key="index" class="image_2" referrerpolicy="no-referrer" :src="'/info-admin' + item" />
      </div>
      <div class="text_11">
        <div v-html="productDetail.descriptions"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { INFO_URL } from '@/config/globalConfig'
import { getProDetail } from '@/api/information'

export default {
  data() {
    return {
      constants: {},
      baseUrl: INFO_URL,
      productDetail: null,
      productDetailList: []
    }
  },
  props: {
    // productDetail:{
    //   type: Object,
    //     default:{}
    // },
    // productDetailList:{
    //   type:Array,
    //   default:[]
    // }
  },
  computed: {
    origin() {
      const { provinceName, cityName, areaName, addr } = this.productDetail
      let str = ''
      if (provinceName) {
        str += provinceName
      }
      if (cityName) {
        str += cityName
      }
      if (areaName) {
        str += areaName
      }
      if (addr) {
        str += addr
      }
      return str
    }
  },
  created() {
    this.getDetail()
  },
  methods: {
    async getDetail() {
      const id = this.$route.query.id
      const res = await getProDetail({ id })

      if (res.code === 200) {
        this.productDetail = res.data
        this.productDetailList = res.data.picsurl.split(',')
      }
    },
    goHome() {
      this.$emit('goHome')
    },
    goPro() {
      this.$emit('goPro')
    }
  }
}
</script>
<style lang="less" scoped>
.h2-box {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  img {
    width: 28px;
    height: 22px;
  }
  h2 {
    color: #16191c;
    margin: 0 40px;
    font-size: 24px;
    font-family: PingFangSC-Semibold;
    font-weight: 600;
    line-height: 45px;
  }
}
@import url('./common.less');
@import url('./index.less');
</style>
