<template>
  <div class="bid-management">
    <ul v-if="dataList1.length == 0" class="bid-list none">
      暂无数据
    </ul>
    <ul class="bid-list" v-else>
      <li
        class="card"
        v-for="(item, index) in dataList1"
        :key="index"
        @click="detailBtn(item.id)"
      >
        <div class="card-title">
          <span class="l-style"></span><span>{{ item.conpanyname }}</span>
        </div>
        <ul class="card-con">
          <li class="card-con-item">
            <div class="item1">付款方式： {{ paymentWatch(item.payment) }}</div>
            <div class="item1">截止时间：{{ item.deadlinedate }}</div>
            <!-- <div class="item1">状态：{{ item.bidStatus }}</div> -->
            <div class="item1">交货地：{{ item.plaadder }}</div>
          </li>
          <li class="card-con-item">
            <div class="item1">招标物料：{{ item.materialnamestr }}</div>
            <div class="item1">中标品种：{{ item.zbItemNames }}</div>
            <div class="item1"></div>
          </li>
          <li class="card-con-item">
            <div class="card-con-imgs">
              <el-image
                class="images-style"
                v-for="url in item.pics"
                :key="url"
                :src="url"
                lazy
                :preview-src-list="item.pics"
              ></el-image>
            </div>
          </li>
        </ul>
      </li>
    </ul>
    <el-pagination
      class="pagination-style"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pagination.page"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pagination.size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pagination.total"
    >
    </el-pagination>
  </div>
</template>

<script>
import { getZbPageInquiry } from '@/api/bid'
import { getDictInfo } from '@/api'
export default {
  created() {
    //this.getMyInqueryMaterials()
    this.getZbPageInquiry()
  },
  data() {
    return {
      pagination: {
        total: 0,
        size: 10,
        page: 0,
      },
      form: {
        name: '',
        payment: 2,
        pageIndex: 1,
        pageSize: 10,
      },
      payment: [],
      dataList1: [],
    }
  },

  computed: {
    paymentWatch() {
      return function (bidStatus) {
        if (bidStatus == null) {
          return ''
        }

        let paymentFind = this.payment.find((item) => item.value == bidStatus)
        return paymentFind ? paymentFind.label : ''
      }
    },
  },
  mounted() {
    this.getPayment()
  },
  methods: {
    getPayment() {
      getDictInfo('BID_PAY_TYPE').then((res) => {
        if (res.code == 200) {
          const map = new Map(Object.entries(res.data))
          this.payment = this.mapToObj(map)
        }
      })
    },

    mapToObj(strMap) {
      let arr = new Array()
      for (let [k, v] of strMap) {
        let obj = Object.create(null)
        obj.value = parseInt(k)
        obj.label = v
        arr.push(obj)
      }
      return arr
    },
    detailBtn(inquiryId) {
      console.log('inquiryId')
      console.log(inquiryId)
      this.$router.push({
        name: 'bidDetail',
        query: {
          id: inquiryId,
        },
      })
    },

    getZbPageInquiry() {
      let that = this
      getZbPageInquiry({
        pageIndex: that.form.pageIndex,
        pageSize: that.form.pageSize,
      }).then((res) => {
        console.log(res, 'ressresds')
        that.dataList1 = res.data.content
        // that.dataList1.forEach((item) => {

        // })
      })
    },

    handleSizeChange(val) {
      this.pagination.size = val
      this.pagination.page = 1
      this.getMyInqueryMaterials()
    },
    handleCurrentChange(val) {
      this.pagination.page = val
      this.getMyInqueryMaterials()
    },
    // formatDate(date, fmt) {
    //   if (/(y+)/.test(fmt)) {
    //     fmt = fmt.replace(
    //       RegExp.$1,
    //       (date.getFullYear() + '').substr(4 - RegExp.$1.length)
    //     )
    //   }
    //   let o = {
    //     'M+': date.getMonth() + 1,
    //     'd+': date.getDate(),
    //     'h+': date.getHours(),
    //     'm+': date.getMinutes(),
    //     's+': date.getSeconds(),
    //   }
    //   for (let k in o) {
    //     if (new RegExp(`(${k})`).test(fmt)) {
    //       let str = o[k] + ''
    //       fmt = fmt.replace(
    //         RegExp.$1,
    //         RegExp.$1.length === 1 ? str : padLeftZero(str)
    //       )
    //     }
    //   }
    //   return fmt
    // },
  },
}
</script>

<style lang="less" scoped>
.bid-management {
  max-width: 1200px;
  margin: auto;
  .tabs {
    background-color: #fff;
    padding: 20px 0;
    .tabs-list {
      max-width: 1000px;
      margin: auto;
      padding: 0 20px;
    }
    /deep/.el-tabs__header {
      margin-bottom: 0;
    }
    /deep/.el-tabs__item.is-active {
      color: rgba(20, 99, 158, 1);
    }
    /deep/.el-tabs__item:hover {
      color: rgba(20, 99, 158, 1);
    }
    /deep/.el-tabs__active-bar {
      background-color: rgba(20, 99, 158, 1);
    }
  }
  .none {
    font-size: 25px;
    color: #aaa;
    text-align: center;
    line-height: 400px;
  }
  .bid-list {
    margin-top: 15px;
    min-height: 400px;
    .card:hover {
      box-shadow: 0 0 10px rgba(20, 99, 158, 0.3);
    }
    .card {
      cursor: pointer;
      box-shadow: 0 0 10px #ddd;
      border-radius: 8px;
      padding: 20px 20px 10px;
      margin-bottom: 20px;
      display: flex;
      flex-wrap: wrap;
      &-title {
        font-size: 16px;
        display: flex;
        align-items: center;
        width: 100%;
        margin-bottom: 10px;
        .l-style {
          display: inline-block;
          width: 10px;
          height: 18px;
          background: rgb(20, 99, 158);
          margin-right: 6px;
          border-radius: 4px;
        }
        .count-down {
          width: 26%;
          margin-left: auto;
          text-align: center;
          span {
            color: red;
          }
        }
      }
      &-con {
        font-size: 14px;
        width: 100%;
        &-item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin: 10px 0;
          .item1 {
            width: calc((100% - 100px) / 2);
          }
          // .item1:last-child{
          //     width: 200px;
          // }
          .item2 {
            position: relative;
            .btns {
              position: absolute;
              font-size: 16px;
              padding: 4px 16px;
              border: 1px solid rgb(20, 99, 158);
              border-radius: 4px;
              top: -10px;
              right: 0;
              cursor: pointer;
              color: rgb(20, 99, 158);
            }
            .btns:hover {
              background-color: rgba(20, 99, 158, 0.1);
            }
          }
          .item3 {
            color: #999;
            cursor: pointer;
          }
        }
      }
      &-con-right {
        width: 20%;
        text-align: center;
        &-img {
          width: 100px;
          height: 100px;
          margin: 10px auto 0;
          display: block;
        }
        &-value {
          font-size: 12px;
        }
      }
      &-con-imgs {
        width: 100%;
        .con-imgs-item {
          width: 50px;
          height: 50px;
          margin: 0 4px;
        }
      }
      .images-style {
        width: 10%;
        padding: 4px;
        box-sizing: border-box;
        margin-bottom: 6px;
      }
    }
  }
  .pagination-style {
    text-align: right;
    margin-bottom: 20px;
  }
}
</style>
