<template>
  <div class="zsDetail">
    <div style="min-height: 435px;">
      <div style=" margin-top: 20px;width: 100%;">
        <div class="body">
          <div style="width: 100%;min-height: 800px;">
            <el-card class="box-card">
              <div slot="header" class="clearfix">
                <span style="font-weight: 700;font-size: 20px;">信息数据</span>
              </div>
              <div v-for="(item,index) in priceList" :key="index" class="text item">
                <div class="content" @click="toDetail(item,type)">
                  <div class="title">
                    <div class="text">
                      {{ item.title }}
                    </div>
                    <div class="footer">
                      {{ item.pubDate }}
                    </div>
                  </div>
                </div>
                <!-- {{ '列表内容 ' + o }} -->
              </div>
              <el-pagination
            class="pagination-style"
            @size-change="handleSizeChange2"
            @current-change="handleCurrentChange2"
            :current-page="pagination2.page"
            :page-sizes="[1, 10, 20, 50, 100]"
            :page-size="pagination2.size"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pagination2.total"
          >
          </el-pagination>
            </el-card>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {
  zhishuDetail,
  getAllNewsList,
  zhishuList,
} from "@/api/price";
import {
    saveView,
  } from '@/api'
export default {
  name: "listMore",
  data() {
    return {
      type: null,
      liulan: "",
      time: "",
      title: "",
      content: "",
      priceList:[],
      pagination2: {
        total: 0,
        size: 10,
        page: 1,
      },
    };
  },
  created() {
    this.type = this.$route.query.type;
    if(this.type == "codex"){
      this.getCodeList()
    }else{
      this.getDetail(this.type);
    }
    
  },
  methods: {
    toDetail (row,type) {
      if(this.type=='codex'){
        type = 'coden'
      }else{
        type = 'news'
      }
      saveView({
        viewType: 2,
        relateId: row.id,
      }).then((res) => { });
      this.$router.push({
        name: "newsDetail",
        query: {
          id: row.id,
          type: type
        },
      });
    },
    handleSizeChange2 (val) {
      this.pagination2.size = val;
      this.pagination2.page = 1;
      if(this.type=='codex'){
        this.getCodeList();
      }else{
        this.getDetail(this.type);
      }
    },
    handleCurrentChange2 (val) {
      this.pagination2.page = val;
      if(this.type=='codex'){
        this.getCodeList();
      }else{
        this.getDetail(this.type);
      }
    },
    getDetail(type) {
      zhishuList({
        newstype: type,
        page: this.pagination2.page,
        limit: this.pagination2.size,
      }).then(res => {
        this.priceList = res.data.content || [];
        this.pagination2.total = res.data.totalElements;
      })
    },
    getCodeList() {
      getAllNewsList({
        newstype: 1,
        pageIndex: this.pagination2.page,
        pageSize: this.pagination2.size,
      }).then(res => {
        this.priceList = res.data.content || [];
        this.priceList.map(item=>{
          if(item.pubDate){
            item.pubDate = new Date(item.pubDate).toLocaleString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '');
          }
        })
        this.pagination2.total = res.data.totalElements;
      })
    },
  },
};
</script>

<style lang="less" scoped>
 .pagination-style {
    text-align: right;
    margin-top: 10px;
    background: #fff;
    padding: 16px 0;
    padding-right: 20px;
    // border-bottom-left-radius:5px;
    //  border-bottom-right-radius:5px;
  }
.zsDetail {
  max-width: 1190px;
  margin: 40px auto 0;

  .body {
    // width: 1190px;
    // margin: 20px auto;
    // display: flex;
    // justify-content: space-between;
  }

  // display: flex;
  // flex-direction: column;
  .text {
    font-size: 14px;
  }

  .item {
    margin: 0 50px;
    padding: 25px 0;
    border-bottom: 1px solid #d1d1d1;
    // margin-bottom: 18px;
  }

  .content {
    display: flex;
    justify-content: space-between;
    width: 100%;
    cursor: pointer;

    .title {
      width: 100%;
      height: 100%;
      font-size: 20px;
      font-weight: 500;
      display: flex;
      flex-direction: column;
      margin-right: 20px;

      .text {
        font-size: 20px;
        font-weight: 500;
      }

      .footer {
        margin-top: 15px;
        height: 30px;
        width: 100%;
        font-size: 14px;
        color: #6d6d6d;
      }
    }
  }

  .zs-title {
    width: 100%;
    text-align: center;
    font-size: 34px;
  }

  .zs-subin {
    padding: 20px 10px;
    color: #888;
    border-bottom: 1px dashed #ddd;
  }

  .zs-html {
    padding: 20px 10px;
    letter-spacing: 1px;
    line-height: 30px;
  }
}</style>