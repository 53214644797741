<template>
  <div class="my-shop">
    <div
      class="goback"
      @click="goback"
    >
      <el-button
        type="primary"
        style="width: 100px;"
        @click="goback"
        >返回</el-button
      >
    </div>
    <el-tabs v-model="activeName" @tab-click="handleClick" type="border-card">
      <el-tab-pane label="药材首页" name="first">
        <shop-detail
          v-if="activeName == 'first'"
          ref="shopDetail1"
          :username="username"
          :name="activeName"
        />
      </el-tab-pane>
      <el-tab-pane label="出售中的药材" name="second">
        <shop-detail
          v-if="activeName == 'second'"
          ref="shopDetail2"
          :name="activeName"
          :username="username"
          :type="0"
        />
      </el-tab-pane>
      <el-tab-pane label="求购中的药材" name="third">
        <shop-detail
          v-if="activeName == 'third'"
          ref="shopDetail3"
          :name="activeName"
          :username="username"
          :type="1"
        />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import router from '@/router'
import shopDetail from './shopDetail.vue'
export default {
  components: { shopDetail },
  data () {
    return {
      activeName: 'first',
      dataList: [],
      pagination: {
        total: 0,
        size: 10,
        page: 1,
      },
      urls: [],
      form: {
        name: '',
        qstatus: -1,
        payment: 2,
      },
      payment: [],
      dataList1: [],
      username: '',
    }
  },


  computed: {

  },
  mounted () {
  },
  created () {
    if (this.$route.query.username) {
      this.username = this.$route.query.username
    }

  },
  methods: {
    handleClick (tab, event) {
    },
    goback() {
      this.$router.push({
        name: 'gqDetail',
        query: {
          id: this.$route.query.id,
          type: this.$route.query.type,
          purchaseType: this.$route.query.purchaseType,
        },
      })
    },
  }
}
</script>

<style lang="less" scoped>
.my-shop {
  max-width: 1190px;
  margin: 20px auto 0;
  padding-bottom: 40px;
  display: flex;
  justify-content: space-around;
}
.goback {
  padding: 10px;
 }
</style>
