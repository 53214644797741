import Vue from 'vue'
import VueRouter from 'vue-router'

import Layout from '@/views/index.vue'
// import Home from '@/views/home/index.vue'
import Home2 from '@/views/home/index2.vue'
import Login from '@/views/login/login.vue'
import Shopping from '@/views/shopping/index.vue'
import Message from '@/views/message/index.vue'
import Finance from '@/views/finance/index.vue'
import FinanceDetail from '@/views/finance/detail.vue'
import FinanceToDetail from '@/views/finance/toDetail.vue'
import Bid from '@/views/bid/index.vue'
import Information from '@/views/information/index.vue'
import Gq from '@/views/gq/index.vue'
import GqDetail from '@/views/gq/gqDetail.vue'
import QyDetail from '@/views/gq/qyDetail.vue'

import MyShop from '@/views/myShop/index.vue'
import Notice from '@/views/notice/index.vue'
import Comment from '@/views/comment/index.vue'
import Release from '@/views/release/index.vue'

// import InformationDetail from '@/views/information/detail.vue'
import InformationDetail from '@/views/information/fwdt/header.vue'
import ComDesc from '@/views/information/comDesc.vue'
import ComPhotos from '@/views/information/comPhotos.vue'
import Gsjs from '@/views/information/gsjs/gsjs.vue'
import Production from '@/views/information/production/index.vue'
import ProDetail from '@/views/information/proDetail/index.vue'

import Price from '@/views/price/index.vue'
import BidDetail from '@/views/bid/detail.vue'
import QuotedPrice from '@/views/bid/quotedPrice.vue'
import PriceDetail from '@/views/price/priceDetail.vue'
import GyDetail from '@/views/price/gyDetail.vue'
import GhDetail from '@/views/price/ghDetail.vue'
import ZsDetail from '@/views/price/zsDetail.vue'
import ListMore from '@/views/news/newsList.vue'
import newsDetail from '@/views/news/newsDetail.vue'
import BidManagement from '@/views/bid/bidManagement.vue'
import BidManagementTwo from '@/views/bid/bidManagementTwo.vue'
import InformationSearch from '@/views/information/search.vue'
import FooterTemplate from '@/views/footer/footerTemplate'
import Register from '@/views/login/register.vue'
import PersonalCenter from '@/views/personalCenter/personalCenter.vue'
import Qualifications from '@/views/qualifications/index.vue'
import Qualifications2 from '@/views/qualifications/index2.vue'
import NoticeDetail from '@/views/bid/noticeMessage/noticeDetail.vue'
import ProductFix from '@/views/personalCenter/productFix.vue'
import CompanyProfile from '@/views/personalCenter/companyProfile.vue'
import BzjPay from '@/views/personalCenter/bzjPay.vue'
import JoinApply from '@/views/personalCenter/joinApply.vue'
import Agreement from '@/views/agreements/index.vue'
import AgreeDetails from '@/views/agreements/agreeDetails.vue'
import paycallback from '@/views/message/paycallback.vue'

Vue.use(VueRouter)

export const routes = [
  {
    path: '/home',
    name: 'home',
    component: Home2
  },
  {
    path: '/shopping',
    name: 'shopping',
    component: Shopping
  },
  {
    path: '/information',
    name: 'information',
    component: Information
  },
  {
    path: '/bid',
    name: 'bid',
    component: Bid
  },
  {
    path: '/finance',
    name: 'finance',
    component: Finance
  },
  {
    path: '/price',
    name: 'price',
    component: Price
  },
  {
    path: '/bidDetail',
    name: 'bidDetail',
    component: BidDetail
  },
  {
    path: '/quotedPrice',
    name: 'quotedPrice',
    component: QuotedPrice
  },
  {
    path: '/priceDetail',
    name: 'priceDetail',
    component: PriceDetail
  },
  {
    path: '/gyDetail',
    name: 'gyDetail',
    component: GyDetail
  },
  {
    path: '/ghDetail',
    name: 'ghDetail',
    component: GhDetail
  },
  {
    path: '/zsDetail',
    name: 'zsDetail',
    component: ZsDetail
  },
  {
    path: '/newsDetail',
    name: 'newsDetail',
    component: newsDetail
  },
  {
    path: '/listMore',
    name: 'listMore',
    component: ListMore
  },

  {
    path: '/bidManagementTwo',
    name: 'bidManagementTwo',
    component: BidManagementTwo
  },
  {
    path: '/informationDetail',
    name: 'informationDetail',
    component: InformationDetail
  },
  {
    path: '/comDesc',
    name: 'comDesc',
    component: ComDesc
  },
  {
    path: '/comPhotos',
    name: 'comPhotos',
    component: ComPhotos
  },
  {
    path: '/gsjs',
    name: 'gsjs',
    component: Gsjs
  },
  {
    path: '/production',
    name: 'production',
    component: Production
  },
  {
    path: '/proDetail',
    name: 'proDetail',
    component: ProDetail
  },
  {
    path: '/financeDetail',
    name: 'financeDetail',
    component: FinanceDetail
  },
  {
    path: '/financeToDetail',
    name: 'financeToDetail',
    component: FinanceToDetail
  },
  {
    path: '/informationSearch',
    name: 'InformationSearch',
    component: InformationSearch
  },
  {
    path: '/footerTemplate',
    name: 'footerTemplate',
    component: FooterTemplate
  },
  // {
  //   path: '/register',
  //   name: 'register',
  //   component: Register
  // },
  {
    path: '/personalCenter',
    name: 'personalCenter',
    component: PersonalCenter,
    children: [
      {
        path: '/qualifications',
        name: 'qualifications',
        component: Qualifications
      },
      {
        path: '/qualifications2',
        name: 'qualifications2',
        component: Qualifications2
      },
      {
        path: '/notice',
        name: 'notice',
        component: Notice
      },
      {
        path: '/comment',
        name: 'comment',
        component: Comment
      },
      {
        path: '/release',
        name: 'release',
        component: Release
      },
      {
        path: '/bidManagement',
        name: 'bidManagement',
        component: BidManagement
      },
      {
        path: '/agreement',
        name: 'agreement',
        component: Agreement
      },
      {
        path: '/productFix',
        name: 'productFix',
        component: ProductFix
      },
      {
        path: '/companyProfile',
        name: 'companyProfile',
        component: CompanyProfile
      },
      {
        path: '/bzjPay',
        name: 'bzjPay',
        component: BzjPay
      }
    ]
  },
  {
    path: '/joinApply',
    name: 'joinApply',
    component: JoinApply
  },
  {
    path: '/agreeDetails',
    name: 'agreeDetails',
    component: AgreeDetails
  },
  {
    path: '/message',
    name: 'message',
    component: Message
  },
  {
    path: '/noticeDetail',
    name: 'noticeDetail',
    component: NoticeDetail
  },
  {
    path: '/gq',
    name: 'gq',
    component: Gq
  },
  {
    path: '/gqDetail',
    name: 'gqDetail',
    component: GqDetail
  },
  {
    path: '/qyDetail',
    name: 'qyDetail',
    component: QyDetail
  },
  {
    path: '/myShop',
    name: 'myShop',
    component: MyShop
  },
  {
    path: '/paycallback',
    name: 'paycallback',
    component: paycallback
  },
]
const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}
const router = new VueRouter({
  scrollBehavior: () => ({
    y: 0
  }),
  routes: [
    {
      path: '/',
      redirect: '/home',
      name: 'Main',
      meta: {
        title: '中药产业'
      },
      component: Layout,
      children: routes
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
    },
    {
      path: '/register',
      name: 'register',
      component: Register,
    }
  ]
})

export default router
