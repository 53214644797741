<template>
  <div class="w">
    <div class="com-photos">
      <div class="h2-box">
        <img referrerpolicy="no-referrer"
          src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng9f0924056986b6c9fb7451131964e5e769f444a1eecac30042c39eac0e3d6a33" />
        <h2>公司相册</h2>
        <img referrerpolicy="no-referrer"
          src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng753270a6fd442eec7c7de7ae8a3a2ecc8b057ca5d7e52609ab8556e6a2b62f11" />
      </div>
      <img class="com-photo" v-for="item in comPhotos" :key="item" :src="'/info-admin' + item">
    </div>
  </div>
</template>
<script>
import { getCompany } from '@/api/information'
import { INFO_URL } from '@/config/globalConfig'
export default {
  data() {
    return {
      comPhotos: [],
      constants: {},
      pagenum: 1,
      pageSize: 10,
      total: 0,
      searchVal: '',
      currentId: '',
      baseUrl: INFO_URL
    }
  },
  props: {
    // currentid: {
    //   type: String,
    //   default: ''
    // }
  },
  created() {
    this.getCompany()
  },
  methods: {
    getCompany() {
      const id = this.$route.query.id
      getCompany(id).then((res) => {
        if (res.data.pic) {
          this.comPhotos = res.data.pic.split(',')
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.w {
  background-color: #f2f2f2;
  padding-top: 20px;
  padding-bottom: 20px;
}
.com-photos {
  max-width: 1100px;
  margin: 0 auto;
  background-color: #fff;
  padding: 20px;
}
.com-photo {
    max-width: 100%;
}
.h2-box {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  img {
    width: 28px;
    height: 22px;
  }
  h2 {
    color: #16191c;
    margin: 0 40px;
    font-size: 24px;
    font-family: PingFangSC-Semibold;
    font-weight: 600;
    line-height: 45px;
  }
}
</style>
