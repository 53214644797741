<!--
 * @Author: guojiren 7755@123.com
 * @Date: 2023-03-02 14:04:24
 * @LastEditors: guojiren 7755@123.com
 * @LastEditTime: 2023-03-02 17:12:07
 * @FilePath: \xinghe-xinxi-pc\src\views\message\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div>
    <div class="home">
      <!-- <img class="banner-img" :src="bannerImg" /> -->
      <div class="block">
        <!-- <span class="demonstration">Click 指示器触发</span> -->
        <el-carousel height="500px" trigger="click">
          <el-carousel-item v-for="(item, index) in lunboList" :key="index">
            <!-- <h3 class="small">{{ item }}</h3> -->
            <img :src="item.url" class="banner-img" />
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="topOne">
        <div class="cons" style="line-height: 24px">
          <h2 style="text-align: center; font-size: 20px">
            安徽亳州中医药集团创建亳药安心购中药材在线交易平台开始运营了！
          </h2>
          <p>
            建立的初心是主张回归药材交易本身，我们基于广大药商和消费者的差异化需求，为药商提供了一流的仓储条件🕋，其仓库累计占地18000平米；提供了专业的技术体系‍，拥有从检测到入库的一体化流程；并为消费者提供了安全的交易方式和优秀的售后服务，把客户的感受永远放在第一位，重视才能走得长远。
          </p>
          <p>
            我们与客户开诚相见、同舟共济，让药商存的放心，卖的安心；让消费者买的舒心，用得放心。
          </p>
        </div>
        <div class="btns">
          <el-button
            style="width: 250px; height: 60px; font-size: 18px"
            type="success"
            @click="dialogVisible = true"
            >点击预约
          </el-button>
        </div>
      </div>
    </div>
    <el-dialog
      :visible.sync="dialogVisible"
      append-to-body
      title="预约申请"
      width="800px"
      :close-on-click-modal="false"
    >
      <div>
        <el-form ref="form" :model="form" :rules="rules" label-width="120px">
          <el-row>
            <el-col span="12">
              <el-form-item label="您的姓名" prop="name">
                <el-input v-model="form.name"></el-input>
              </el-form-item>
            </el-col>
            <el-col span="12">
              <el-form-item label="您的电话" prop="phoneNum">
                <el-input v-model="form.phoneNum" type="tel"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col span="12">
              <el-form-item label="品名" prop="materialName">
                <el-input v-model="form.materialName"></el-input>
              </el-form-item>
            </el-col>

            <el-col span="12">
              <el-form-item label="产地" prop="materialAddr">
                <el-input v-model="form.materialAddr"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col span="12">
              <el-form-item label="数量" prop="quantity">
                <el-input v-model="form.quantity"></el-input>
              </el-form-item>
            </el-col>

            <el-col span="12">
              <el-form-item label="规格" prop="materialSpec">
                <el-input v-model="form.materialSpec"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col span="12">
              <el-form-item label="备注" prop="productRequirement">
                <el-input v-model="form.productRequirement"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="warning" @click="resetForm('form')">重 置</el-button>
        <el-button type="primary" @click="submitForm('form')">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { subscribe } from '@/api'

export default {
  data () {
    return {
      lunboList: [
        {
          url: require('@/assets/imgs/tu1.jpg'),
          index: 0,
        },
        {
          url: require('@/assets/imgs/tu2.jpg'),
          index: 1,
        },
      ],
      dialogVisible: false,
      form: {
        name: '',
        phoneNum: '',
        materialName: '',
        quantity: '',
        productRequirement: '',
        materialSpec: '',
        materialAddr: '',
      },
      rules: {
        name: [{ required: true, message: '请填写您的姓名', trigger: 'blur' }],
        phoneNum: [
          { required: true, message: '请填写您的电话', trigger: 'blur' },
        ],
      },
    }
  },
  created () {
  },
  methods: {
    submitForm (form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          subscribe(this.form).then((res) => {
            console.log(res, 'resss')
            debugger
            this.$message.info("提交成功!");
            this.dialogVisible = false;
          })
        }
      })
    },
    resetForm (form) {
      this.$refs[form].resetFields()
    },
  },
}
</script>
<style lang="less" scoped>
.btns {
  margin-top: 50px;
  width: 100%;
  text-align: center;
}

.block {
  width: 1190px;
  margin: 0 auto;
  height: 500px;

  .banner-img {
    width: 100%;
    height: 500px;
  }

  .linkLun {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }
}

.topOne {
  height: 500px;
  width: 1050px;
  margin: 20px auto;
  padding-bottom: 50px;

  h2 {
    margin-bottom: 20px;
  }

  p {
    // margin-top: 5px;
    text-indent: 2rem;
    font-size: 18px;
    margin-top: 15px;
    line-height: 30px;
  }
}
</style>
