<!-- eslint-disable vue/multi-word-component-names -->
<!--
 * @Author: guojiren 7755@123.com
 * @Date: 2023-03-24 15:59:44
 * @LastEditors: guojiren 7755@123.com
 * @LastEditTime: 2023-03-28 16:59:03
 * @FilePath: \xinghe-xinxi-pc\src\views\bid\noticeMessage\notice.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div style="flex: 1">
    <div class="noticeCon">
      <div class="headTab">
        <el-radio-group v-model="radio1" @input="checkNo">
          <el-radio-button label="变更公告"></el-radio-button>
          <el-radio-button label="澄清公告"></el-radio-button>
        </el-radio-group>
      </div>
      <!-- 流标信息 -->
      <div class="massage" v-if="radio1 == '变更公告'">
        <div class="search">
          <!-- <el-input
            placeholder="请输入品名"
            v-model="searceValue"
            style="margin-right: 20px"
          ></el-input> -->
          <el-input
            placeholder="请输入标题"
            v-model="listParam.title"
          ></el-input>
          <el-button class="search-btn" type="primary" @click="getFicationList"
            >搜索</el-button
          >
          <el-button class="search-btn" @click="reset">重置</el-button>
        </div>
        <div class="messList">
          <div class="messItem" v-for="(item, index) in titleList" :key="index">
            <div class="title">
              <div class="head">{{ item.title }}</div>
            </div>
            <!--            <div class="bts">-->
            <!--              <el-button-->
            <!--                type="primary"-->
            <!--                size="small"-->
            <!--                style="border-radius: 5px; height: 36px; width: 95%"-->
            <!--                plain-->
            <!--                @click="detailBtn(item.id)"-->
            <!--                >查看详情-->
            <!--              </el-button>-->
            <!--            </div>-->
            <div class="bts">
              <el-button
                type="primary"
                size="small"
                style="border-radius: 5px; height: 36px; width: 95%"
                plain
                @click="detailBtn(item.id)"
                >查看详情
              </el-button>
            </div>
          </div>
        </div>

        <!-- 分页器 -->
        <div class="pagination">
          <el-pagination
            class="pagination-style"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="listParam.pageNum"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="listParam.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
      <!-- 澄清公告 -->
      <div class="massage" v-if="radio1 == '澄清公告'">
        <div class="search">
          <el-input
            placeholder="请输入标题"
            v-model="listParam.title"
          ></el-input>
          <el-button class="search-btn" type="primary" @click="getFicationList"
            >搜索</el-button
          >
          <el-button class="search-btn" @click="reset">重置</el-button>
        </div>
        <div class="messList">
          <div class="messItem" v-for="(item, index) in titleList" :key="index">
            <div class="title">
              <div class="head">{{ item.title }}</div>
            </div>
            <div class="bts">
              <el-button
                type="primary"
                size="small"
                style="border-radius: 5px; height: 36px; width: 95%"
                plain
                @click="detailBtn(item.id)"
                >查看详情
              </el-button>
            </div>
          </div>
        </div>

        <!-- 分页器 -->
        <div class="pagination">
          <el-pagination
            class="pagination-style"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="listParam.pageNum"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="listParam.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getFicationList } from '@/api/bid'
export default {
  data() {
    return {
      radio1: '变更公告',
      // titList: [
      //   {
      //     title: '说的就是点击后房卡的实际付款，',
      //     id: 0,
      //   },
      //   {
      //     title:
      //       '放得开飞机上课了发的会计师风口浪尖地方可视对讲，三分大赛凤凰健康地方撒看到回复的开发计划的授课计划打卡接电话奥斯卡的叫号机',
      //     id: 1,
      //   },
      //   {
      //     title: '说的就是点击后房卡的实际付款，',
      //     id: 2,
      //   },
      //   {
      //     title: '说的就是点击后房卡的实际付款，',
      //     id: 3,
      //   },
      // ],
      listParam: {
        pageNum: 0,
        pageSize: 10,
        type: 3,
        title: '',
        zbNo: '',
      },
      titleList: [],
      total: 0,
    }
  },
  mounted() {
    this.getFicationList()
  },
  methods: {
    getFicationList() {
      getFicationList(this.listParam).then((res) => {
        console.log(res, '公告列表')
        if (res.code == 200) {
          this.titleList = res.data.content
          this.total = res.data.totalElements
        } else {
          this.$message.warning(res.msg)
        }
      })
    },
    checkNo(e) {
      if (e == '澄清公告') {
        this.listParam.type = 1
        this.getFicationList()
      } else {
        this.listParam.type = 3
        this.getFicationList()
      }
      console.log(e, 'eeee')
    },

    reset() {
      this.listParam.title = ''
      this.getFicationList()
    },

    handleSizeChange(val) {
      this.listParam.pageSize = val
      this.listParam.pageNum = 0
      // debugger
      this.getFicationList()
    },
    handleCurrentChange(val) {
      this.listParam.pageNum = val
      this.getFicationList()
    },

    detailBtn(id) {
      console.log('dsda')
      this.$router.push({
        path: '/noticeDetail?id=' + id,
      })
    },
  },
}
</script>
<style scoped lang="less">
@import url('@/assets/common.less');
.noticeCon {
  width: 90%;
  margin: 0 auto;
  padding-bottom: 30px;
  .headTab {
    width: 100%;
  }
  .massage {
    width: 90%;
    margin: 0 auto;
    padding-bottom: 20px;
  }
  .search {
    display: flex;
    flex-direction: row;
    text-align: center;
    margin-bottom: 5px;
    margin-top: 40px;
    // padding-top: 20px;
    // margin-left: 20px;
    width: 50%;

    /deep/ .el-input__inner {
      border: none;
      -webkit-appearance: none;
      background-color: #fff;
      background-image: none;
      border: 1px solid #aaa;
      box-sizing: border-box;
      color: #606266;
      display: inline-block;
      font-size: inherit;
      height: 30px;
      line-height: 30px;
      outline: 0;
      padding: 0 15px;
      width: 100%;
      border-radius: 5px;
    }
    /deep/ .el-button {
      padding: 6px 20px !important;
    }
    .search-btn {
      width: 100px;
      margin-left: 15px;
      border-radius: 5px;
    }
  }
  .messList {
    margin-top: 30px;
    min-height: 300px;
    .messItem {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 30px 0;
      border-bottom: 1px dashed #dfdfdf;
      cursor: pointer;
      // margin: 40px 0;
      // height: 36px;
      line-height: 36px;
      font-size: 15px;

      .title {
        width: 80%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        .head {
          width: 70%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          color: @primary;
          font-size: 16px;
          font-weight: bold;
        }
        .infos {
          color: #666;
          font-size: 13px;
        }
        .dataName {
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          // color: #666;
        }
      }
      .bts {
        width: 10%;
      }
    }
  }

  .pagination {
    display: flex;
    // align-items: center;
    // width: 70%;

    .pagination-style {
      margin: 10px auto;
    }
  }
}
</style>
