<template>
  <el-dialog
    title="申请"
    :visible.sync="visibleDetail"
    :close-on-click-modal="false"
    width="600px"
    class="quoted-price"

  >
    <div class="form">
      <div class="form-tit">{{obj.name}}</div>
      <ul class="form-info">
        <li>资金方：<span>{{obj.financier}}</span></li>
        <li>单笔最高期限：{{obj.deadline}}年</li>
        <li>单笔最高额度：{{obj.quota}}万</li>
        <li>利率：{{obj.rate * 100}}%</li>
      </ul>
      <el-form size="small">
          <el-form-item label="用户名字" required>
            <div class="price-module">
              <el-input placeholder="请输入用户名字" size="small" v-model="form.uname"></el-input>
            </div>
          </el-form-item>
          <el-form-item label="用户电话" required>
            <div class="price-module">
              <el-input placeholder="请输入用户电话" size="small" v-model="form.utel"></el-input>
            </div>
          </el-form-item>
          <el-form-item label="用户身份证号码" required>
            <div class="price-module">
              <el-input placeholder="请输入用户身份证号码" size="small" v-model="form.ucard"></el-input>
            </div>
          </el-form-item>
          <el-form-item label="申请金额" required>
            <div class="price-module">
              <el-input type="number" placeholder="请输入申请金额" size="small" v-model="form.uamount"></el-input>
            </div>
          </el-form-item>
          <el-form-item label="备注">
            <el-input v-model="form.remark" type="textarea" placeholder="请描述备注"></el-input>
          </el-form-item>
      </el-form>
    </div>
    <div slot="footer" class="flex-jac">
      <el-button size="small" @click="visibleDetail = false">取消</el-button>
      <el-button size="small" type="primary" @click="addApplyFinance">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { addApplyFinance , getApplyFinance } from '@/api/finance'
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    fid: {
      type: String,
      default: '',
    },
    obj: {
      type: Object,
      default: {
        name:'中药材订单数据贷',
        financier:'农业银行',
        quota:10,
        rate:0.01,
        deadline:5,
      }
    }
  },
  computed: {
    visibleDetail: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit('update:visible', val);
      },
    },
  },
//   watch: {
//     visible(val) {
//       if (val) {
//       }
//     },
//   },
  name:'applyFinance',
  data() {
    return {
      form:{
        uname:'',
        utel:'',
        ucard: '',
        uamount:'',
        remark: ''
      },
      dialogImageUrl: '',
      dialogVisible: false,
      fileList:[]
    };
  },
  methods: {
      handleCommand(val){
        console.log(val,'val')
        this.form.unit = `元/${val}`
      },
      handleRemove(file, fileList) {
        // console.log(file, 'ppppppppppppppp',fileList);
      },
      handlePictureCardPreview(file) {
        this.dialogImageUrl = file.url;
        this.dialogVisible = true;
      },
      addApplyFinance(){
				let uname = this.form.uname;
				let utel = this.form.utel;
				let ucard = this.form.ucard;
				let uamount = this.form.uamount;
				let remark = this.form.remark;
				//console.log(bjcount+'::::'+bjprice);
				if(uname === "" || uname === null || uname === undefined){
          this.$message.warning('请填写申请用户名称')
					return;
				};
				if(utel === "" || utel === null || utel === undefined){
          this.$message.warning('请填写申请用户电话')
					return;
				};
				if(ucard === "" || ucard === null || ucard === undefined){
          this.$message.warning('请填写申请用户身份证号码')
					return;
				};
        if(uamount === 0 || uamount === "" || uamount === null || uamount === undefined){
          this.$message.warning('请填写申请金额')
					return;
				};
				const that = this;
        addApplyFinance({
              fid: that.fid,
              uname: uname,
              utel: utel,
              remark: remark,
              ucard: ucard,
              uamount: uamount
            }).then(res=>{
                //console.log(res);
                if (res.code === 200) {
                  that.$message.success(res.msg)
                  that.$emit('closeq')
                } else {
                }
            })
			},
      getApplyFinance(fid) {
        const that = this;
        getApplyFinance({
              fid: fid
            }).then(res=>{
              if(res.code == 200){
                that.form.uname = res.data.uname!=null?res.data.uname:'';
                that.form.utel = res.data.utel!=null?res.data.utel:'';
                that.form.ucard = res.data.ucard!=null?res.data.ucard:"";
                that.form.uamount = res.data.uamount!=null?res.data.uamount:'';
                that.form.remark = res.data.remark!=null?res.data.remark:'';
                that.$emit('openq')
              }
              if(res.code == 301){
                // that.$message.warning("请先登录")
                 this.$alert('请先登录', '提示', {
                    confirmButtonText: '确定',
                    // callback: action => {
                    //   this.$message({
                    //     type: 'info',
                    //     message: `action: ${ action }`
                    //   });
                    // }
                });
              }
            })
      }
  },
};
</script>

<style lang="less" scoped>
.quoted-price{
  .form{
    padding: 0 20px;
    &-tit{
      font-size: 18px;
      font-weight: bold;
      border-left: 4px solid rgb(20, 99, 158);
      padding-left: 10px;
      line-height: 20px;
    }
    &-info{
      margin: 20px 0 ;
      display: flex;
      flex-wrap: wrap;
      border-bottom: 1px solid #eee;
      li{
        width: 50%;
        margin-bottom: 18px;
      }
    }
    // .upload-style{
    //   width: 50px;
    //   height: 50px;
    // }
    /deep/ .el-upload--picture-card{
      background-color:  #fbfdff;
      border: 1px dashed  #c0ccda;
      border-radius: 6px;
      box-sizing: border-box;
      width: 80px;
      height: 80px;
      cursor: pointer;
      line-height: 80px;
      vertical-align: top;
    }
    .el-upload--picture-card i{
      font-size: 24px;
      color: #8c939d;
    }
  }
  .flex-jac{
    text-align: center;
  }
  .price-module{
    display: flex;
     /deep/ .el-input__inner{
            border: none;
            border-bottom: 1px solid #DCDFE6;
        }
  }
  /deep/.el-dialog__body{
    padding: 20px;
  }
}
</style>
