<template>
  <div class="reg-bg">
    <div class="reg-outer">
      <div class="logo" @click="goIndex">
        <img src="@/assets/imgs/logo.png" />
        <h1>安徽亳州中医药集团</h1>
      </div>
      <div class="reg-box">
        <div class="reg-inner">
          <h2>欢迎注册</h2>
          <el-form ref="registerForm" :model="registerForm" :rules="rules">
            <el-form-item label="">
              <el-input v-model="registerForm.account" placeholder="手机号">
                <template slot="prepend"><i class="el-icon-user-solid icon-login"></i></template>
              </el-input>
            </el-form-item>

            <el-form-item label="">
              <el-input type="password" v-model="registerForm.password" placeholder="密码"
                auto-complete="new-password">
                <template slot="prepend"><i class="el-icon-lock icon-login"></i></template>
              </el-input>
            </el-form-item>

            <el-form-item label="" required>
              <div class="code-style">
                <el-input v-model="registerForm.code" placeholder="请输入验证码" autocomplete="off"
                  readOnly="true" @click.native="inputControl">
                  <template slot="prepend"><i
                      class="el-icon-mobile-phone icon-login"></i></template>
                </el-input>
                <span class="code-btn" @click="sendSmsCodeForRegister">{{
                codeShow ? `重新获取(${codeCountNum})s` : '获取验证码'
              }}</span>
              </div>
            </el-form-item>
            <el-form-item>
              <el-button class="login-btn" type="primary" @click="register">注 册</el-button>
            </el-form-item>
          </el-form>

          <div class="agreement">
            <el-checkbox v-model="checked"><span class="hasRead">我已阅读</span><span
                class="lookAgree">《注册协议》</span></el-checkbox>

          </div>
        </div>
        <svg class="login-nav-svg" @click="toLogin" width="70" height="70"
          xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <defs>
            <linearGradient x1="17.621%" y1="50%" x2="100%" y2="50%" id="a">
              <stop stop-color="#06c" offset="0%" />
              <stop stop-color="#1ba1fb" offset="100%" />
            </linearGradient>
            <filter x="-10.7%" y="-10.7%" width="121.4%" height="121.4%"
              filterUnits="objectBoundingBox" id="c">
              <feGaussianBlur stdDeviation="6" in="SourceAlpha" result="shadowBlurInner1" />
              <feOffset dx="3" in="shadowBlurInner1" result="shadowOffsetInner1" />
              <feComposite in="shadowOffsetInner1" in2="SourceAlpha" operator="arithmetic" k2="-1"
                k3="1" result="shadowInnerInner1" />
              <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
                in="shadowInnerInner1" />
            </filter>
            <path id="b" d="m1291 377 70 70v-70z" />
          </defs>
          <g transform="translate(-1291 -377)" fill="none" fill-rule="evenodd">
            <use fill="url(#a)" xlink:href="#b" />
            <use fill="#000" filter="url(#c)" xlink:href="#b" />
          </g>
        </svg>
        <div class="login-nav-text" @click="toLogin">登录</div>
      </div>
    </div>
    <div class="reg-footer">
      <div class="copright">
        <span><i>地址：</i>{{ companyAddr }}</span>
        <span><i>电话：</i>{{ telPhone }}</span>
        <span><i>邮箱：</i>{{ email }}</span>
      </div>
      <div class="copright">
        <span class="cop2">互联网药品信息服务资格证书</span>
        <span class="cop2">{{ certificate }}</span>
        <img src="https://www.beian.gov.cn/img/new/gongan.png" alt=""
          style="vertical-align: middle; margin-left: 20px;margin-right: 2px" />
        <a class="beian"
          href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=34160202000846"
          target="_blank">皖公网安备：34160202000846</a>
        <i style="margin-left: 20px">备案号：</i>
        <a class="beian" href="https://beian.miit.gov.cn/" target="_blank">{{
            beian
          }}</a>
      </div>
    </div>

  </div>
</template>

<script>
import { register, getSmsCode } from '@/api'
import { agreeCheck, checkCode, checkPhone, checkPwd } from '@/utils/validator'
import { COMPANY_ADDR, TEL_PHONE, CERTIFICATE, EMAIL, BEIAN } from '@/config/globalConfig'

export default {
  data() {
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } else if (value.length < 8) {
        callback(new Error('密码长度小于8!'))
      } else {
        callback()
      }
    }
    return {
      registerForm: {
        account: '',
        code: '',
        password: ''
      },
      rules: {
        account: [
          {
            required: true,
            message: '请输入手机号码',
            trigger: 'blur'
          }
        ],
        code: [
          {
            required: true,
            message: '请输入验证码',
            trigger: 'blur'
          }
        ],
        password: [
          {
            validator: validatePass2,
            trigger: 'blur'
          }
        ]
      },
      codeCountNum: '',
      checked: false,
      time: 60,
      codeShow: false,
      timer: null,
      companyAddr: COMPANY_ADDR,
      telPhone: TEL_PHONE,
      email: EMAIL,
      certificate: CERTIFICATE,
      beian: BEIAN
    }
  },
  methods: {
    goIndex() {
      this.$router.push({
        path: '/'
      })
    },
    inputControl(e) {
      e.target.readOnly = false
    },
    sendSmsCodeForRegister() {
      if (this.codeShow) {
        return
      }
      this.codeShow = true
      this.codeCountNum = 60
        this.timer = setInterval(() => {
          if (this.codeCountNum > 0) {
            this.codeCountNum--
          } else {
            this.codeShow = false
            clearInterval(this.timer)
            this.timer = null
          }
        }, 1000)

      let requestData = {
        phone: this.registerForm.account,
        bizType: 'register'
      }

      getSmsCode(requestData)
        .then((res) => {
          if (res.code === 200) {
            this.$message.success('发送成功')
          } else {
            this.$message.warning(res.msg)
          }
        })
        .catch((exp) => {
          console.log('异常信息' + exp.msg)
        })
    },
    register() {
      this.$refs['registerForm'].validate((valid) => {
        if (valid) {
          let that = this
          if (!checkPhone(that.registerForm.account)) {
            return
          }
          if (!checkPwd(that.registerForm.password)) {
            return
          }
          if (!checkCode(that.registerForm.code)) {
            return
          }
          if (!agreeCheck(that.checked)) {
            return
          }
          let requestData = {
            phone: that.registerForm.account,
            smsCode: that.registerForm.code,
            password: that.registerForm.password
          }
          register(requestData)
            .then((res) => {
              if (res.code === 200) {
                this.$message.success('您已注册成功，请登录！')
                that.isLogin = true
              } else {
                this.$message.warning(res.msg)
              }
            })
            .catch((exp) => {
              console.log('异常信息' + exp.msg)
              // this.visibleDetail = false
            })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    toLogin() {
      this.$router.push('/login')
    }
  }
}
</script>

<style lang="less">
.reg-bg {
  height: 100%;
  background-image: url('~@/assets/imgs/login-bg.jpg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .logo {
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
    h1 {
      font-size: 26px;
      margin-left: 8px;
      color: #fff;
      font-weight: 700;
      line-height: 62px;
    }
    img {
      width: 62px;
      height: 62px;
    }
  }

  .reg-footer {
    position: absolute;
    left: 0;
    bottom: 50px;
    right: 0;
    font-size: 12px;
    .copright {
      text-align: center;
      max-width: 1190px;
      margin: 10px auto 0;
      color: #fff;
      .beian {
        color: #fff;
      }
    }
  }

  .agreement {
    display: flex;
    align-items: center;
    .el-checkbox {
      width: 264px;
      margin-right: 10px;
    }
    .hasRead,
    .lookAgree {
      color: #fff;
    }
  }

  .code-style {
    display: flex;
    .el-input-group {
      width: 180px !important;
    }

    .code-btn {
      width: 88px;
      color: #fff;
      cursor: pointer;
    }

    .codeCount {
      color: #fff;
    }
  }
}
.reg-box {
  width: 420px;
  height: 400px;
  position: relative;
  background-color: rgba(255, 255, 255, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
}
.reg-inner {
  h2 {
    font-size: 24px;
    color: #fff;
    margin-bottom: 22px;
  }
  .el-form-item__content .el-input-group {
    border-radius: 50px;
    overflow: hidden;
    width: 264px;
  }
  .el-button--primary {
    border-radius: 50px;
    width: 264px;
  }
  .el-input-group__prepend {
    background-color: transparent;
    border: none;
    i {
      font-size: 24px;
      color: #fff;
    }
  }
  .el-input-group--prepend .el-input__inner {
    border: none;
    padding: 0;
    background-color: transparent;
    border-bottom: 1px solid #fff;
    color: #fff;
  }
}
</style>